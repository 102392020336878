import { observer } from "mobx-react";
import React from "react";
import AppStore from "../../store/AppStore";
import {
  borderRadius,
  Color,
  defaultStyles,
  Elevation,
  FontSizes,
  setAlpha,
  spacings,
  truncateTextWithEllipsis,
} from "../../styles/default";
import { css } from "glamor";
import Avatar from "react-avatar";
import { user } from "../../store/User";
import Divider from "./Divider";
import { Portal } from "./Portal";
import { portal } from "../../store/common/Portal";
import { ViewOptionsType } from "../../store/View";
import { FormattedMessage, useIntl } from "react-intl";
import { queries } from "../../styles/mediaQueries";
import ConnectionStatusIcon from "./ConnectionStatusIcon";
import { logEvent } from "@firebase/analytics";

interface Props {
  store: AppStore;
  isDocumentView: boolean;
}

const UserMenu: React.FC<Props> = observer(
  ({ store, isDocumentView }): React.ReactElement | null => {
    const intl = useIntl();

    const user = store.user;
    const authorized = user.authorized;
    const firstName = user.displayName?.split(" ")[0] || "";
    const isProfilePageOpen = store.view.id === ViewOptionsType.userProfile;
    const textColor = isProfilePageOpen ? Color.secondary : Color.onBackground;
    const backgroundColor = isProfilePageOpen
      ? setAlpha(Color.secondary, 0.2)
      : "transparent";
    return (
      <div
        {...css({
          padding: spacings.space2,
          display: "flex",
          alignItems: "center",
        })}
      >
        {!authorized && (
          <button
            {...css({
              ...defaultStyles.button.link,
              fontWeight: 700,
              ":hover": {
                color: Color.secondary,
              },
            })}
            onClick={() => {
              logEvent(user.analytics, "btn_click_openSignIn");
              store.openSignIn(intl.formatMessage({ id: "label.sign.in" }));
            }}
          >
            <FormattedMessage id="label.sign.in" />
          </button>
        )}

        {authorized && (
          <div {...css({ display: "flex", gridGap: spacings.space2 })}>
            <button
              onClick={() => {
                store.openProfile();
              }}
              {...css({
                position: "relative",
                display: "flex",
                gridGap: spacings.space2,
                alignItems: "center",
                width: "auto",
                borderRadius: borderRadius.borderRadius10,
                backgroundColor,
                padding: spacings.space1,
                paddingRight: spacings.space2,
                cursor: "pointer",
                ":hover": {
                  backgroundColor: setAlpha(Color.onBackground, 0.1),
                },
              })}
            >
              <Avatar
                name={user.displayName}
                size="26"
                color={Color.secondary}
                round={true}
              />

              <span
                {...css({
                  ...truncateTextWithEllipsis,
                  fontWeight: 700,
                  fontSize: FontSizes.fontSize4,
                  color: textColor,
                  [queries.phone]: {
                    display: "none",
                  },
                })}
              >
                {firstName}
              </span>
              <ConnectionStatusIcon connected={user.connected} />
            </button>
            <UserPopoutMenu store={store} />
          </div>
        )}
      </div>
    );
  }
);

export default UserMenu;

const UserPopoutMenu: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const id = "user-popout-menu";
  portal.setId(id);
  return (
    <Portal>
      <div
        {...css({
          width: "300px",
          ...Elevation.depth1,
          backgroundColor: Color.surface,
          padding: spacings.space3,
          borderRadius: borderRadius.borderRadius1,
        })}
      >
        <UserDetailCard store={store} />
        <Divider />
        <button
          {...css({ ...defaultStyles.button.list })}
          onClick={() => {
            logEvent(user.analytics, "btn_showUserPopoutMenu");
            portal.setShow(false);
            // TODO
          }}
        >
          <span className="material-icons">contact_support</span>
          <FormattedMessage id="btn.request.help" />
        </button>
        <button
          {...css({ ...defaultStyles.button.list })}
          onClick={() => {
            portal.setShow(false);
            store.logout();
          }}
        >
          <span className="material-icons">logout</span>
          <FormattedMessage id="btn.logout" />
        </button>
      </div>
    </Portal>
  );
});

const UserDetailCard: React.FC<{ store: AppStore }> = observer(({ store }) => {
  return (
    <div
      {...css({
        display: "flex",
      })}
    >
      <button
        {...css({ ...defaultStyles.button.list, gridGap: spacings.space2 })}
        onClick={(e) => {
          store.openProfile();
          portal.setShow(false);
        }}
      >
        <Avatar name={user.displayName} round size="50px" />
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gridGap: spacings.space0,
            width: "100%",
          })}
        >
          <span {...css({ fontWeight: 700 })}>{user.displayName}</span>
          <span>
            <FormattedMessage id="profile.open.label" />
          </span>
        </div>
      </button>
    </div>
  );
});
