import React from "react";
import { css } from "glamor";
import { observer } from "mobx-react";
import {
  borderRadius,
  borders,
  Color,
  defaultStyles,
  FontFamilies,
  FontSizes,
  InputStyle,
  InputStyleDialogue,
  spacings,
} from "../../styles/default";
import { CharacterExtensionTypes } from "../../store/textEditor/Dialogue";
import TextareaAutosize from "react-textarea-autosize";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import Breadcrumbs from "../common/Breadcrumbs";
import AppStore, { FormOptions } from "../../store/AppStore";
import DocumentEditorFilters from "./DocumentEditorFilters";
import InputWithSuggestion from "../common/InputWithSuggestion";
import CreateNewDocumentButton from "../document/CreateNewDocumentButton";
import { sceneOptionsPlace } from "../../store/data/suggestionOptions";
import {
  Document,
  DocumentTypeOptions,
  shouldDisableTextEdits,
} from "../../store/textEditor/Document";
import Files from "../finder/Files";
import Screenplay from "../../store/textEditor/Screenplay";
import Scene, {
  hasPredefinedTransitionSubheader,
  ScenePlaceOptions,
  SceneTimeOfDayOptions,
  TransitionOptions,
} from "../../store/textEditor/Scene";
import DialogueLine from "./DialogueLine";
import { ActionButton } from "../common/ActionButton";
import {
  focusOnDialogueCharacterInput,
  focusOnInsertInput,
  moveCursorToEndAndFocus,
  scrollToElement,
} from "../common/utils/DomUtils";
import { ViewOptionsType } from "../../store/View";
import DocumentTitleEditor from "./DocumentTitleEditor";
import { ui } from "../../store/Ui";
import { shortcutCombos } from "../../store/common/Shortcuts";
import Droppable from "../common/dragNdrop/Droppable";
import { dragSelect } from "../..";
import { useIntl, FormattedMessage } from "react-intl";
import { PopupConfirmDataType } from "../popups/PopupConfirmBeforeAction";
import { PopupOptions } from "../../store/Popup";
import NonCharacterDialogue from "./NonCharacterDialogue";
import SubtleErrorMessage from "../common/SubtleErrorMessage";
import { EDITOR_MIN_WIDTH } from "./Editor";
import { Checkbox } from "../common/Checkbox";
import Draggable from "../common/Draggable";
import Editor from "./Editor";
import { Form } from "../../store/Form";
import { logEvent } from "@firebase/analytics";
import { user } from "../../store/User";
import { hasSearchTerm } from "../../lib/audiox/models/utils";
import SearchResultSummary from "../SearchResultSummary";
import { queries } from "../../styles/mediaQueries";

export const ScreenplayDocument: React.FC<{
  store: AppStore;
  document?: Document;
}> = observer(({ store, document }) => {
  const intl = useIntl();
  const crumbs = [
    {
      id: "home",
      onClick: () => {
        store.openHome();
      },
      title: intl.formatMessage({ id: "breadcrumbs.all" }),
    },
    {
      id: "screenplay",
      onClick: () => {
        store.files.setFilterBy(DocumentTypeOptions.screenplay);
        store.openDocumentsTab(ViewOptionsType.screenplay);
      },
      title: intl.formatMessage({ id: "document.type.screenplay" }),
    },
  ];
  let current = "screenplay";

  const screenplay: Screenplay = document?.document as Screenplay;
  if (document?.id) {
    crumbs.push({
      id: document.id,
      onClick: () => {
        store.openDocument(DocumentTypeOptions.screenplay, document.id);
      },
      title: document.document?.title || "",
    });
    current = document.id;
  }

  return (
    <div>
      <div
        {...css({
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
          marginRight: spacings.space8,
        })}
      >
        <Breadcrumbs crumbs={crumbs} current={current} />
        {!document?.id && (
          <div
            {...css({
              display: "none",
              [queries.phone]: {
                display: "block",
              },
            })}
          >
            <CreateNewDocumentButton store={store} />
          </div>
        )}
      </div>
      {screenplay && <ScreenplayEditor document={document} store={store} />}
      {!screenplay && <Files store={store} title="recent" />}
    </div>
  );
});

const ScreenplayEditor: React.FC<{
  document: Document | undefined;
  store: AppStore;
}> = observer(({ document, store }) => {
  if (!document) {
    return null;
  }
  const screenplay: Screenplay = document?.document as Screenplay;
  const screenplayScenes = screenplay.scenes;
  if (!screenplayScenes) {
    return null;
  }

  if (!screenplay.sceneOrder) {
    return (
      <SubtleErrorMessage error="Corrupted Document. No scene order encountered.">
        <button
          {...css(defaultStyles.button.secondary)}
          onClick={() => {
            logEvent(
              user.analytics,
              "btn_click_autoGenerateScreenplaySceneOrderForMissingSceneOrder"
            );
            store.document?.autoGenerateScreenplaySceneOrderForMissingSceneOrder();
          }}
        >
          Fix it!
        </button>
      </SubtleErrorMessage>
    );
  }
  const disableTextEdits = shouldDisableTextEdits(store.user, document);
  const scenesList = document.scenesList;

  return (
    <Editor store={store}>
      <div
        {...css({
          marginBottom: "100px",
          width: "100%",
          minWidth: EDITOR_MIN_WIDTH,
        })}
      >
        <DocumentTitleEditor store={store} document={document} />
        <DocumentFilters store={store} document={document} />
        <DocumentEditorFilters store={store} document={document} />
        <SearchResultSummary store={store} />
        {screenplay.sceneOrder.map((sceneId: string, index: number) => {
          if (!sceneId) {
            return null;
          }
          const scene = screenplay.scenes[sceneId];
          if (!scene) {
            return null;
          }

          const showScene = shouldShowScene(
            scene.characterDialogueCount,
            ui.filterDialogueByCharacterId
          );
          if (!showScene) {
            return null;
          }

          if (!scene.hasDialoguesWithSearchTerm(screenplay.searchTerm)) {
            return null;
          }
          const sceneIndex = scenesList.indexOf(sceneId) + 1;
          return (
            <div
              id={`scene-${sceneId}`}
              data-attrdocumentitem={true}
              key={sceneId}
              onDrop={() => {
                if (!disableTextEdits) {
                  document.handleListItemSwap(
                    dragSelect.draggingItemListIndex,
                    dragSelect.dropAtIndex,
                    true
                  );
                  dragSelect.clearDraggingData();
                  ui.setSelectedId(undefined);
                }
              }}
            >
              <Draggable
                data-draggablescene={scene.id}
                itemId={scene.id}
                index={index}
                disabled={disableTextEdits}
                onDrop={() => {
                  if (!disableTextEdits) {
                    document.handleListItemSwap(
                      dragSelect.draggingItemListIndex,
                      dragSelect.dropAtIndex,
                      true
                    );
                    dragSelect.clearDraggingData();
                    ui.setSelectedId(undefined);
                  }
                }}
              >
                <SceneComponent
                  scene={scene}
                  document={document}
                  sceneIndex={sceneIndex}
                  store={store}
                />
              </Draggable>
              <Droppable
                index={index + 1}
                onDrop={(draggedItemIndex?: number, droppedIndex?: number) => {
                  if (!disableTextEdits) {
                    document.handleListItemSwap(
                      draggedItemIndex,
                      droppedIndex,
                      true
                    );
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </Editor>
  );
});

const SceneSubheader: React.FC<{ store: AppStore; scene: Scene }> = observer(
  ({ store, scene }) => {
    const subheader = scene.subheader.trim();
    const needsSemicolon =
      subheader === TransitionOptions.fadeIn ||
      subheader === TransitionOptions.beginMontage;
    return (
      <div
        {...css({
          display: "flex",
          height: "20px",
          alignItems: "center",
          marginLeft: spacings.space6,
        })}
      >
        <span {...css({ fontFamily: FontFamilies.dialoguePrint })}>
          <FormattedMessage id={`scene.subheader.${subheader}`} />
          {needsSemicolon && ":"}
        </span>
      </div>
    );
  }
);

const SceneComponent: React.FC<{
  store: AppStore;
  document: Document;
  scene: Scene;
  sceneIndex: number;
}> = observer(({ store, document, scene, sceneIndex }) => {
  const intl = useIntl();

  const INDEX = sceneIndex;
  const disableTextEdits = shouldDisableTextEdits(store.user, document);
  const searchTerm = document.document?.searchTerm;
  const sceneDialogues = scene.dialogueOrder.map(
    (dialogueId) => scene.dialogues[dialogueId]
  );

  const isSelectedScene = ui.selectedSceneId[scene.id];
  const selectedSceneStyle = isSelectedScene
    ? { border: borders.light }
    : { border: borders.clear };

  const sceneActionsMenuOptions = [
    {
      id: "delete",
      label: intl.formatMessage({ id: "action.dialogue.delete" }),
      icon: "delete_outline",
      action: (e?: any) => {
        logEvent(user.analytics, "btn_click_delete_dialogue");
        const popupData: PopupConfirmDataType = {
          callback: (action: "cancelled" | "accepted") => {
            if (action === "accepted") {
              document.removeScene(scene.id);
            }
          },
          title: intl.formatMessage({
            id: "confirm.delete.scene.title",
          }),
          copy: intl.formatMessage({
            id: "confirm.delete.scene.copy",
          }),
          additionalCopy: intl.formatMessage({
            id: "confirm.delete.scene.additionalCopy",
          }),
        };
        store.popups.set(
          PopupOptions.confirmBeforeAction,
          "Delete Scene",
          popupData
        );
      },
    },
  ];
  const sceneId = scene.id;
  const expandedScene = ui.expandedViews[sceneId];
  const hasPredefinedSceneSubheader = hasPredefinedTransitionSubheader(
    scene.subheader
  );

  return (
    <div
      {...css(
        {
          marginBottom: spacings.space2,
          backgroundColor: Color.surface,
          padding: spacings.space4,
          borderRadius: borderRadius.borderRadius2,
          position: "relative",
        },
        selectedSceneStyle
      )}
      data-sceneid={sceneId}
      tabIndex={0}
      onFocus={() => {
        if (scene) {
          ui.setSelectedSceneId(sceneId);
        }
      }}
      onBlur={() => {
        if (scene) {
          ui.setSelectedSceneId(undefined);
        }
      }}
    >
      {!hasPredefinedSceneSubheader && (
        <button
          {...css(defaultStyles.button.cleanSecondary, {
            display: "flex",
            marginLeft: spacings.space5,
          })}
          onClick={() => {
            logEvent(user.analytics, "btn_toggle_showScene");
            ui.setShowScene(sceneId, !expandedScene);
          }}
          aria-label={intl.formatMessage({ id: "expand.scene" })}
        >
          <span
            className="material-icons"
            {...css({ fontSize: FontSizes.fontSize7 })}
          >
            {expandedScene ? "arrow_drop_down" : "arrow_right"}
          </span>

          <FormattedMessage
            id="scene.header.withCount"
            values={{
              index: INDEX,
            }}
          />
        </button>
      )}
      {!disableTextEdits && (
        <div
          {...css({
            position: "absolute",
            top: spacings.space2,
            right: spacings.space2,
          })}
        >
          <MenuActionsTrigger options={sceneActionsMenuOptions} />
        </div>
      )}

      {hasPredefinedSceneSubheader && (
        <SceneSubheader store={store} scene={scene} />
      )}

      {!hasPredefinedSceneSubheader && (
        <>
          <SceneHeadingComponent document={document} scene={scene} />

          {ui.expandedViews[scene.id] && (
            <div>
              <SceneActionEditor document={document} scene={scene} />
              <div
                {...css({
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: spacings.space4,
                })}
              >
                {sceneDialogues.map((dialogue, index) => {
                  if (!dialogue) {
                    return null;
                  }

                  if (!hasSearchTerm(searchTerm, dialogue.text)) {
                    return null;
                  }
                  const handleAddCharacterExtension = () => {
                    const currentExtension = dialogue.characterExtension;
                    const title = intl.formatMessage({
                      id: "popup.title.addExtension",
                    });
                    const form = new Form({
                      id: FormOptions.addCharacterExtension,
                      fields: {
                        characterExtension: currentExtension || "",
                      },
                      options: {
                        allowTranslation: true,
                        characterExtension: CharacterExtensionTypes.map(
                          (value: string, i: number) => ({
                            value,
                            label: intl.formatMessage(
                              {
                                id: `screenplay.characterExtension.${value}`,
                              },
                              {
                                abrev: intl
                                  .formatMessage({
                                    id: `screenplay.characterExtension.${value}.abrev`,
                                  })
                                  .trim(),
                              }
                            ),
                            index: i,
                            details: {},
                          })
                        ),
                      },
                      title,
                      validations: {},
                      required: [],
                    });
                    store.popups.set(PopupOptions.genericFormUpdate, title, {
                      form,
                      onSubmit: (data: any) => {
                        dialogue.setCharacterExtension(data.characterExtension);
                        store.popups.clear();
                      },
                    });
                  };

                  if (!dialogue) {
                    return (
                      <div>
                        <FormattedMessage id="missing.dialogue" />
                      </div>
                    );
                  }
                  const isNonCharacterDialogue =
                    dialogue?.type === "note" || dialogue?.type === "action";
                  const characterId = dialogue?.characterId;
                  const filterDialogueByCurrentCharacterId =
                    (characterId &&
                      ui.filterDialogueByCharacterId[characterId]) ||
                    false;

                  if (
                    ui.hasFiltersByCharacters &&
                    filterDialogueByCurrentCharacterId === false
                  ) {
                    // if we are filtering dialogues by character id and
                    // the character id for this line is not being filtered
                    // let's return null
                    return null;
                  }

                  if (isNonCharacterDialogue) {
                    return (
                      <NonCharacterDialogue
                        key={dialogue.id}
                        dialogue={dialogue}
                        document={document}
                        disableTextEdits={disableTextEdits}
                        sceneId={scene.id}
                        index={index}
                        store={store}
                      />
                    );
                  }
                  const dialogueActions = [
                    {
                      id: "addNote",
                      label: intl.formatMessage({
                        id: "action.dialogue.add.note",
                      }),
                      icon: "edit_note",
                      action: async (e?: any) => {
                        logEvent(user.analytics, "btn_click_addNote");
                        const dialogue = await document.addNewLine(
                          "note",
                          "Note: ",
                          scene.id,
                          index + 1
                        );
                        setTimeout(() => {
                          if (dialogue) {
                            dialogue && scrollToElement(dialogue.id);
                            dialogue &&
                              moveCursorToEndAndFocus(`${dialogue.id}-insert`);
                          }
                        }, 300);
                      },
                    },
                    {
                      id: "addCharacterExtension",
                      label: intl.formatMessage({
                        id: "action.dialogue.addCharacterExtension",
                      }),
                      icon: "supervised_user_circle",
                      action: async (e?: any) => {
                        logEvent(
                          user.analytics,
                          "btn_click_handleAddCharacterExtension"
                        );
                        handleAddCharacterExtension();
                      },
                    },
                    {
                      id: "removeCharacterExtension",
                      label: intl.formatMessage({
                        id: "action.dialogue.removeCharacterExtension",
                      }),
                      icon: "supervised_user_circle",
                      action: async (e?: any) => {
                        logEvent(
                          user.analytics,
                          "btn_click_setCharacterExtension"
                        );
                        dialogue.setCharacterExtension(undefined);
                      },
                    },
                    {
                      id: "delete",
                      label: intl.formatMessage({
                        id: "action.delete.dialogue",
                      }),
                      icon: "delete_outline",
                      action: (e?: any) => {
                        logEvent(user.analytics, "btn_click_remove_dialogue");
                        document.removeDialogue(dialogue.id, scene.id);
                      },
                    },
                  ];

                  return (
                    <div>
                      <DialogueLine
                        store={store}
                        key={dialogue.id}
                        dialogue={dialogue}
                        index={index}
                        disableTextEdits={disableTextEdits}
                        actionOptions={dialogueActions}
                        document={document}
                        sceneId={scene.id}
                      />
                      <Droppable
                        index={index}
                        onDrop={(
                          draggedItemIndex?: number,
                          droppedIndex?: number
                        ) => {
                          document.handleListItemSwap(
                            draggedItemIndex,
                            droppedIndex,
                            false,
                            scene.id
                          );
                          dragSelect.clearDraggingData();
                          ui.setSelectedId(undefined);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <SceneTransitionEditor document={document} scene={scene} />
              <SceneActions store={store} document={document} scene={scene} />
            </div>
          )}
        </>
      )}
    </div>
  );
});

const DocumentFilters: React.FC<{ store: AppStore; document: Document }> =
  observer(({ store, document }) => {
    const intl = useIntl();
    return (
      <div {...css({ marginBottom: spacings.space2 })}>
        <Checkbox
          id="toggle-scenes"
          label={intl.formatMessage({ id: "toggle.scene.headings.only" })}
          checked={ui.hideAllScenes}
          onToggle={() => {
            logEvent(user.analytics, "btn_toggle_showAllScenes");
            document?.showAllScenes(ui.hideAllScenes);
          }}
        />
      </div>
    );
  });

const SceneActions: React.FC<{
  scene: Scene;
  document: Document;
  store: AppStore;
}> = observer(({ scene, document, store }) => {
  const intl = useIntl();
  return (
    <div
      {...css({
        boxSizing: "border-box",
        display: "flex",
        gridGap: spacings.space4,
        justifyContent: "space-between",
        width: "100%",
      })}
    >
      <div
        {...css({
          boxSizing: "border-box",
          display: "flex",
          gridGap: spacings.space2,
        })}
      >
        <ActionButton
          key="add-dialogue-in-scene"
          label={intl.formatMessage({ id: "actions.add.dialogue" })}
          shortcut={shortcutCombos.addNewDialogueLine}
          icon="playlist_add"
          onClick={async () => {
            logEvent(user.analytics, "btn_click_addNewDialogueInScene");
            const line = await document.addNewLine("dialogue", "", scene.id);
            setTimeout(() => {
              line && scrollToElement(line.id);
              line && focusOnDialogueCharacterInput(line.id);
            }, 200);
          }}
        />
        <ActionButton
          key="add-dialogue-action-in-scene"
          label={intl.formatMessage({
            id: "actions.add.scene.action.dialogue",
          })}
          shortcut={shortcutCombos.addNewSceneDialogueAction}
          icon="directions_run"
          onClick={async () => {
            logEvent(user.analytics, "btn_click_addNewActionInScene");
            const line = await document.addNewLine("action", "", scene.id);
            setTimeout(() => {
              line && scrollToElement(line.id);
              line && focusOnInsertInput(line.id);
            }, 200);
          }}
        />
      </div>

      <ActionButton
        key="delete-all-lines-in-scene"
        label={intl.formatMessage({ id: "actions.delete.scene.allLines" })}
        icon="delete_sweep"
        iconOnly={true}
        destructive={true}
        onClick={() => {
          logEvent(user.analytics, "btn_click_deleteAllLinesInScene");
          const popupData: PopupConfirmDataType = {
            callback: (action: "cancelled" | "accepted") => {
              if (action === "accepted") {
                document.deleteAllLines(scene.id);
              }
            },
            title: intl.formatMessage({
              id: "confirmation.delete.all.dialogue.title",
            }),
            copy: intl.formatMessage({
              id: "confirmation.delete.all.dialogue.copy",
            }),
            additionalCopy: intl.formatMessage({
              id: "confirmation.delete.all.dialogue.additionalCopy",
            }),
          };
          store.popups.set(
            PopupOptions.confirmBeforeAction,
            intl.formatMessage({
              id: "confirmation.delete.all.dialogue.title",
            }),
            popupData
          );
        }}
        shortcut={shortcutCombos.deleteAllLinesInScene}
      />
    </div>
  );
});

const SceneHeadingComponent: React.FC<{
  scene: Scene;
  document: Document;
}> = observer(({ scene, document }) => {
  const intl = useIntl();
  const screenplay: Screenplay = document?.document as Screenplay;
  const locations = screenplay.currentSceneLocations.map((location, i) => ({
    value: location,
    label: location,
    index: i,
    details: {},
  }));
  return (
    <div
      {...css({ display: "flex", gridGap: spacings.space4, flexWrap: "wrap" })}
      onBlur={() => {
        logEvent(user.analytics, "blur_sync_updateScene");
        document.sync_updateScene(document.id, scene.id, scene);
      }}
    >
      <InputWithSuggestion
        key="place"
        id={`${scene.id}-place`}
        suggestions={sceneOptionsPlace}
        value={scene.place}
        placeholder={intl.formatMessage({
          id: "scene.heading.placeholder.place",
        })}
        onChange={(value: string) => {
          scene.setPlace(value.toUpperCase() as ScenePlaceOptions);
        }}
      />

      <InputWithSuggestion
        key="location"
        id={`${scene.id}-location`}
        suggestions={locations}
        value={scene.location}
        placeholder={intl.formatMessage({
          id: "scene.heading.placeholder.location",
        })}
        onChange={(value: string) => {
          scene.setLocation(value.toUpperCase() as ScenePlaceOptions);
        }}
      />

      <InputWithSuggestion
        key="timeOfDay"
        id={`${scene.id}-timeofday`}
        suggestions={Object.keys(SceneTimeOfDayOptions).map((key, i) => ({
          value: key,
          label: intl.formatMessage({ id: `timeOfDay.${key}` }),
          details: undefined,
          index: i,
        }))}
        value={scene.timeOfDay}
        placeholder={intl.formatMessage({
          id: "scene.heading.placeholder.timeOfDay",
        })}
        onChange={(value: string) => {
          scene.setTimeOfDay(value.toUpperCase() as SceneTimeOfDayOptions);
        }}
      />
    </div>
  );
});

const SceneActionEditor: React.FC<{ document: Document; scene: Scene }> =
  observer(({ document, scene }) => {
    const intl = useIntl();
    return (
      <TextareaAutosize
        id={`${scene.id}-action`}
        {...css(InputStyle, InputStyleDialogue, {
          marginBottom: spacings.space2,
          marginTop: 0,
          paddingTop: spacings.space0,
          width: "100%",
        })}
        value={scene.action}
        onChange={(e: any) => {
          const action = e.target.value;
          scene.setAction(action);
          document.sync_updateScene(document.id, scene.id, scene);
        }}
        spellCheck={true}
        placeholder={intl.formatMessage({ id: "scene.action.placeholder" })}
      />
    );
  });

const SceneTransitionEditor: React.FC<{ document: Document; scene: Scene }> =
  observer(({ document, scene }) => {
    const intl = useIntl();
    return (
      <TextareaAutosize
        id={`${scene.id}-action`}
        {...css(InputStyle, InputStyleDialogue, {
          marginBottom: spacings.space4,
          marginTop: 0,
          width: "100%",
        })}
        value={scene.transition}
        onChange={(e: any) => {
          const transition = e.target.value;
          scene.setTransition(transition);
          document.sync_updateScene(document.id, scene.id, scene);
        }}
        spellCheck={true}
        placeholder={intl.formatMessage({ id: "scene.transition.placeholder" })}
      />
    );
  });

function shouldShowScene(
  characterDialogueCount: { [characterId: string]: number },
  filteredCharacters: { [characterId: string]: boolean }
): boolean {
  const filteredChars = Object.keys(filteredCharacters);
  if (filteredChars.length === 0) {
    // there are no filters set so just show
    return true;
  }

  // check if there are characters but they are all false
  // if (filteredChars.filter(charId => filteredChars[charId] === true))
  // TODO - continue here

  return true;
}
