import { css, CSSProperties } from "glamor";
import * as React from "react";
import { Color, Bold, FontSizes, spacings } from "../../styles/default";
import { merge } from "lodash";

const defaultStyles = {
  container: {
    backgroundColor: "transparent",
    border: 0,
    color: Color.onPrimary,
    display: "flex",
    fontSize: FontSizes.fontSize6,
    height: "40px",
    paddingLeft: spacings.space4,
    paddingRight: spacings.space4,
    borderBottom: `1px solid ${Color.onBackground}`,
    ":hover": {
      color: Color.onPrimary300,
    },
  },
  selected: {
    borderBottom: `1px solid ${Color.secondary}`,
    color: Color.onPrimary300,
    fontWeight: Bold,
  },
};
export interface TabItemStyles {
  container?: CSSProperties;
  selected?: CSSProperties;
}

export interface TabItemOptionType {
  action: () => void;
  icon?: any;
  id: string;
  label: any;
  type?: string;
}

export interface TabItemType {
  style?: TabItemStyles;
  item: TabItemOptionType;
  isSelected: boolean;
}
export const TabItem: React.FC<TabItemType> = ({ item, isSelected, style }) => {
  const styles = merge({}, defaultStyles, style);
  return (
    <button
      key={item.id}
      onClick={() => item.action()}
      {...css(
        styles.container,
        isSelected ? styles.selected : { opacity: 0.4 }
      )}
    >
      {item.label}
    </button>
  );
};
