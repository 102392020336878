import React from "react";
import { observer } from "mobx-react";
import { Character } from "../../store/textEditor/Character";
import { CharacterCard } from "./CharacterCard";
import { css } from "glamor";
import {
  Color,
  defaultStyles,
  hint,
  hintLeft,
  spacings,
} from "../../styles/default";
import AppStore from "../../store/AppStore";
import ScrollView from "../common/ScrollView";
import { feedback } from "../../store/Feedback";
import {
  Document,
  shouldDisableTextEdits,
} from "../../store/textEditor/Document";
import { ui } from "../../store/Ui";
import { useIntl } from "react-intl";
import ToolbarWrapper from "../common/ToolbarWrapper";

export const Characters: React.FC<{
  store: AppStore;
  document: Document;
  characters: { [characterId: string]: Character };
}> = observer(({ characters, store, document }) => {
  const intl = useIntl();
  const disableEdits = shouldDisableTextEdits(store.user, document);
  const characterKeys = Object.keys(characters);
  return (
    <ToolbarWrapper
      store={store}
      title={intl.formatMessage(
        { id: "title.characters" },
        { count: characterKeys.length }
      )}
      actions={
        <AddCharacterButton
          onClick={() => {
            store.openCharacterPopup(
              intl.formatMessage({ id: "popup.character.add.title" })
            );
          }}
        />
      }
    >
      <ScrollView id="characters">
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            gridGap: spacings.space2,
            marginTop: spacings.space2,
            marginBottom: spacings.space8,
          })}
        >
          {characterKeys.map((characterId) => {
            const character = characters[characterId];
            const selected = ui.filterDialogueByCharacterId[characterId];
            return (
              <CharacterCard
                key={characterId}
                character={character}
                isDefault={
                  document.document?.defaultCharacter?.id === characterId
                }
                onClick={() => {
                  // TODO: UNDO THIS
                  // store.document?.showAllScenes(true);
                  // if (selected) {
                  //   ui.setFilterDialogueByCharacterId(characterId, false);
                  // } else {
                  //   ui.setFilterDialogueByCharacterId(characterId, true);
                  // }
                }}
                selected={selected === true}
                disableEdits={disableEdits}
                actionOptions={[
                  {
                    id: "editCharacter",
                    label: intl.formatMessage({ id: "btn.edit.character" }),
                    icon: "create",
                    action: async () => {
                      store.openCharacterPopup(
                        intl.formatMessage({
                          id: "popup.character.edit.title",
                        }),
                        character
                      );
                    },
                  },
                  {
                    id: "deleteCharacter",
                    label: intl.formatMessage({ id: "btn.delete.character" }),
                    icon: "delete_outline",
                    action: async () => {
                      feedback.setFeedback({
                        message: intl.formatMessage({
                          id: "feedback.deleting.character",
                        }),
                        variant: "info",
                      });
                      const deleted = await document.deleteCharacter(
                        characterId
                      );
                      if (deleted === true) {
                        feedback.setFeedback({
                          message: intl.formatMessage({
                            id: "feedback.character.deleted.success",
                          }),
                          variant: "success",
                        });
                        // when deleting a character, also remove any filters since
                        // it could leave the app in an bad state (i.e. have filters
                        // by the deleted character, but the characters doesn't exist
                        // anymore)
                        ui.setFilterDialogueByCharacterId();
                      }
                    },
                  },
                ]}
              />
            );
          })}
        </div>
      </ScrollView>
    </ToolbarWrapper>
  );
});

const AddCharacterButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      aria-label={useIntl().formatMessage({ id: "actions.add.character" })}
      {...css(hint, hintLeft)}
    >
      <button {...css(defaultStyles.button.primary)} onClick={onClick}>
        <span className="material-icons" {...css({ color: Color.secondary })}>
          person_add_alt
        </span>
      </button>
    </div>
  );
};
