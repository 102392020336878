import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import {
  subscriptionOptions,
  SubscriptionOptionType,
} from "../store/data/subscriptionPlanOptions";
import {
  FeatureDescription,
  SubscriptionOption,
  ToggleBillingPlan,
} from "./SubscriptionPlansTable";
import { css } from "glamor";
import {
  borderRadius,
  Color,
  defaultStyles,
  FontSizes,
  setAlpha,
  spacings,
} from "../styles/default";
import { FormattedMessage, useIntl } from "react-intl";
import { PopupOptions } from "../store/Popup";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

const SubscriptionPlans: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const [billingType, setBillingType] = React.useState("billAnnually");
  const isBilledAnnually = billingType === "billAnnually";
  return (
    <div data-id="SubscriptionPlans" {...css({ width: "100%" })}>
      <ToggleBillingPlan
        value={billingType === "billAnnually"}
        onToggle={() => {
          if (!isBilledAnnually) {
            setBillingType("billAnnually");
          } else {
            setBillingType("billMonthly");
          }
        }}
      />
      {subscriptionOptions.map((subscriptionOption) => {
        return (
          <Plan
            key={subscriptionOption.planId}
            store={store}
            subscriptionOption={subscriptionOption}
            billingType={billingType}
          />
        );
      })}
    </div>
  );
};

export default observer(SubscriptionPlans);

const Plan: React.FC<{
  store: AppStore;
  subscriptionOption: SubscriptionOptionType;
  billingType: string;
}> = observer(({ store, subscriptionOption, billingType }) => {
  const [show, setShow] = React.useState(false);
  const planId = subscriptionOption.planId;
  return (
    <div
      data-id="Plan"
      {...css({
        backgroundColor: setAlpha(subscriptionOption.color, 0.2),
        borderRadius: borderRadius.borderRadius3,
        marginBottom: spacings.space2,
        padding: spacings.space4,
        width: "100%",
      })}
    >
      <button
        {...css({ width: "100%" })}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span {...css({ width: "100%" })}>
          <span {...css({ fontSize: FontSizes.fontSize7, fontWeight: 700 })}>
            <SubscriptionOption value="planId" option={subscriptionOption} />
          </span>
          <SubscriptionOption value={billingType} option={subscriptionOption} />
        </span>
        <span
          className="material-icons"
          {...css({ fontSize: FontSizes.fontSize10 })}
        >
          {show ? "arrow_drop_down" : "arrow_right"}
        </span>
      </button>

      {show && (
        <div>
          {subscriptionOption.features.map((feature, i) => {
            return (
              <div
                key={i}
                {...css({
                  marginTop: spacings.space2,
                  marginBottom: spacings.space2,
                })}
              >
                <FeatureDescription option={feature} />
              </div>
            );
          })}
          <WaitlistJoinButton store={store} planId={planId} />
        </div>
      )}
    </div>
  );
});

export const WaitlistJoinButton: React.FC<{ store: AppStore; planId: string }> =
  observer(({ store, planId }) => {
    const intl = useIntl();
    return (
      <div>
        {planId !== "free" && (
          <button
            {...css(defaultStyles.button.default, {
              backgroundColor: Color.surface,
              ":hover": {
                backgroundColor: Color.background,
              },
            })}
            onClick={() => {
              logEvent(user.analytics, "btn_click_joinWaitlist");
              if (!store.popups) {
                alert("nos popups");
              }
              store.popups?.set(
                PopupOptions.joinWaitlist,
                intl.formatMessage({ id: "join.waitlist" }),
                { planId }
              );
            }}
          >
            <FormattedMessage id="btn.join.waitlist.label" />
          </button>
        )}
      </div>
    );
  });
