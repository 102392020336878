import * as React from "react";
import { css } from "glamor";
import { merge } from "lodash";

export interface LoadingStyleType {
  container?: React.CSSProperties;
  label?: React.CSSProperties;
  wrapper?: React.CSSProperties;
}
export interface LoadingType {
  color?: string;
  thickness?: number;
  width?: number;
  height?: number;
  backgroundColor?: string;
  style?: LoadingStyleType;
  label?: any;
}

const defaultStyles = {
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {},
  wrapper: {
    diplay: "flex",
  },
};

export const Loading: React.FC<LoadingType> = ({
  thickness = 1,
  width = 50,
  height = 50,
  color,
  backgroundColor = "#2296f3",
  style = {},
  label = "Loading results",
}) => {
  const styles = merge({}, defaultStyles, style);
  return (
    <div {...css(styles.container)} data-style="loading:container">
      <div {...css(styles.wrapper)} data-style="loading:wrapper">
        <svg
          width={`${width}px`}
          height={`${height}px`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className="lds-ring"
          style={{ background: "none" }}
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            r="30"
            stroke={backgroundColor}
            strokeWidth={thickness}
          />
          <circle
            cx="50"
            cy="50"
            fill="none"
            r="30"
            stroke={color}
            strokeWidth={thickness}
            strokeLinecap="square"
            transform="rotate(167.066 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              calcMode="linear"
              values="0 50 50;180 50 50;720 50 50"
              keyTimes="0;0.5;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-dasharray"
              calcMode="linear"
              values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
              keyTimes="0;0.5;1"
              dur="1"
              begin="0s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
      <div {...css(styles.label)} data-style="loading:label">
        {label}
      </div>
    </div>
  );
};
