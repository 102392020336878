export const MIN_MOBILE_WIDTH = 768;
export const queries = {
  phone: `@media (max-width: ${MIN_MOBILE_WIDTH}px)`,
  print: `@media print`,
  screen: `@media screen`,
  page: `@page`,
};

// iPad Air 4 (2020)	1180 × 820	2360 × 1640	23:16	2×	10.9″	264 PPI
// iPad 10.2″ (2020)	1080 × 810	2160 × 1620	4:3	2×	10.2″	264 PPI
// iPhone 12 pro max (2020)	926 × 428	2778 × 1284	19.5:9	3×	6.7″	458 PPI
// iPhone 12 pro (2020)	844 × 390	2532 × 1170	19.5:9	3×	6.1″	466 PPI
// iPhone 12 (2020)	844 × 390	2532 × 1170	19.5:9	3×	6.1″	460 PPI
// iPhone 12 mini (2020)	780 × 360	2340 × 1080	19.5:9	3×	5.4″	476 PPI
// iPad Pro 11″ (2018-2020)	1194 × 834	2388 × 1668	4:3	2×	11″	264 PPI
// iPad Pro 12.9″ (2018-2020)	1366 × 1024	2732 × 2048	4:3	2×	12.9″	264 PPI
// iPhone 11 Pro Max (2019)	896 × 414	2688 × 1242	19.5:9	3×	6.5″	458 PPI
// iPhone 11 Pro (2019)	812 × 375	2436 × 1125	19.5:9	3×	5.8″	458 PPI
// iPhone 11 (2019)	812 × 375	1792 × 828	19.5:9	3×	6.1″	458 PPI
// iPad Air 3 (2019)	1112 × 834	2224 × 1668	4:3	2×	10.5″	264 PPI
// iPad 7 (2019)	1024 × 768	2160 × 1620	4:3	2×	10.2″	264 PPI
// iPad Mini 5 (2019)	1024 × 768	2048 × 1536	4:3	2×	7.9″	326 PPI
// iPhone XR (2018)	896 × 414	1792 × 828	19.5:9	3×	6.1″	326 PPI
// iPhone XS Max (2018)	896 × 414	2688 × 1242	19.5:9	3×	6.5″	458 PPI
// iPhone XS (2018)	812 × 375	2436 × 1125	19.5:9	3×	5.8″	458 PPI
// iPad 6 (2018)	1024 × 768	2048 × 1536	4:3	2×	9.7″	264 PPI
// iPad Pro 10.5″ (2017)	1112 × 834	2224 × 1668	4:3	2×	10.5″	264 PPI
// iPad Pro 12.9″ (2017)	1366 × 1024	2732 × 2048	4:3	2×	12.9″	264 PPI
// iPad Mini 4 (2015)
