import { makeAutoObservable } from "mobx";

export const SHORTCUT_BLOCKER_CLASS = "block-shortcut";

export interface DropdownOptionType {
  value: string;
  label: string;
  action: () => void;
  disabled?: boolean;
}

export interface DropdownSelectedType {
  value: string;
  label: string;
}

export interface DropdownStoreType {
  id: string;
  selected: DropdownSelectedType;
  options: DropdownOptionType[];
  disabled?: boolean;
}

export default class DropdownStore {
  id: string;
  selected: DropdownSelectedType;
  options: DropdownOptionType[];
  selectedIndex: number;
  searchTerm?: string;
  showOptions?: boolean;
  disabled?: boolean;
  hoveredOption?: DropdownSelectedType; // for keyboard interactions use only
  hoveredIndex: number; // for keyboard interactions use only

  constructor(dropdown: DropdownStoreType) {
    this.id = dropdown.id;
    this.selected = dropdown.selected;
    this.options = dropdown.options;
    this.selectedIndex = this.getIndexForSelected(dropdown.selected);
    this.hoveredIndex = -1;
    this.showOptions = false;
    this.disabled = false;

    makeAutoObservable(this);
  }

  get hasSearchTerm(): boolean {
    const searchTerm = this.searchTerm;
    return searchTerm !== undefined && searchTerm !== "";
  }

  get filteredOptions(): DropdownOptionType[] {
    const searchTerm = this.searchTerm;
    if (searchTerm !== undefined && searchTerm !== "") {
      return this.options
        .slice()
        .filter((option) => option.label.includes(searchTerm));
    }
    return this.options;
  }

  setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  setSelected = (selected: DropdownSelectedType) => {
    this.selected = selected;
  };

  setOptions = (options: DropdownOptionType[]) => {
    this.options = options;
  };

  setShowOptions = (value: boolean, viaKeyboard?: boolean) => {
    this.showOptions = value;
    if (this.showOptions === true) {
      document.addEventListener("click", this.handleClickOutside, true);
    } else {
      document.removeEventListener("click", this.handleClickOutside, true);
    }

    // let's handle keyboard interactions
    if (viaKeyboard) {
      // let's set hovered option if we are displaying options
      if (value === true) {
        this.setHovered(this.selected);
      } else if (value === false) {
        this.setHovered(undefined);
      }
    }
  };

  setDisabled = (value: boolean) => {
    this.disabled = value;
  };

  getIndexForSelected = (selected: DropdownSelectedType): number => {
    let index = -1;

    this.options?.forEach((option, i) => {
      if (option.value === selected.value) {
        index = i;
      }
    });

    return index;
  };

  setSelectedIndex = (value: number) => {
    this.selectedIndex = value;
  };

  handleSelected = (selected: DropdownSelectedType) => {
    if (this.selected.label !== selected.label) {
      this.setShowOptions(false);
    } else {
      this.setSelected(selected);
      const selectedIndex = this.getIndexForSelected(selected);
      if (selectedIndex >= 0) {
        this.setSelectedIndex(selectedIndex);
        this.setShowOptions(false);
      }
    }
  };

  handleClickOutside = (e: any) => {
    const elemWrapper = document.getElementById(this.id);
    if (!elemWrapper?.contains(e.target)) {
      // clicked outside the menu
      this.setShowOptions(false);
      this.setHovered(undefined);
      this.setHoveredIndex(-1);
    }
  };

  // keyboard actions
  handleDropdownKeyDown = (key: string) => {
    switch (key) {
      case "Enter":
        if (this.hoveredIndex === -1) {
          this.setShowOptions(true, true);
        } else {
          // user is hitting enter to click on the list item
          const hoveredOption = this.hoveredOption;
          const hoveredIndex = this.hoveredIndex;
          if (hoveredOption) {
            this.setSelected(hoveredOption);
            this.setSelectedIndex(this.getIndexForSelected(hoveredOption));
            if (hoveredIndex >= 0 && hoveredIndex < this.options.length) {
              this.options[hoveredIndex].action();
              this.setHovered(undefined);
              this.setHoveredIndex(-1);
            }
          }
          this.setShowOptions(false, true);
        }

        return;
      case "Esc":
        this.setShowOptions(false, true);
        this.setHovered(undefined);
        this.setHoveredIndex(-1);

        return;
      case "ArrowDown":
        return this.hoverNext();
      case "ArrowUp":
        return this.hoverPrev();
      default:
        return null;
    }
  };

  hoverNext = () => {
    let hoveredIndex = this.hoveredIndex;
    if (hoveredIndex === -1) {
      hoveredIndex = this.selectedIndex;
    }
    if (hoveredIndex < this.options.length) {
      this.setHoveredIndex(hoveredIndex + 1);
    }
  };

  hoverPrev = () => {
    let hoveredIndex = this.hoveredIndex;
    if (hoveredIndex === -1) {
      hoveredIndex = this.selectedIndex;
    }
    if (hoveredIndex > 0) {
      this.setHoveredIndex(hoveredIndex - 1);
    }
  };

  setHoveredIndex = (value: number) => {
    this.hoveredIndex = value;
    if (value >= 0 && value < this.options.length) {
      this.setHovered(this.options[value]);
    }
  };

  setHovered = (option?: DropdownSelectedType) => {
    this.hoveredOption = option;
  };
}
