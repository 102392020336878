import { css } from "glamor";
import { observer } from "mobx-react";
import { shortcuts } from "../..";
import { Dialogue } from "../../store/textEditor/Dialogue";
import { ui } from "../../store/Ui";
import { borderRadius, borders } from "../../styles/default";

export const DialogueBindings: React.FC<{
  dialogue: Dialogue;
  sceneId?: string;
  disableTextEdits: boolean;
}> = observer(({ disableTextEdits, dialogue, sceneId, children }) => {
  let dialogueId = dialogue.id;
  if (sceneId) {
    dialogueId = `${sceneId}scene${dialogue.id}`;
  }
  let isDialogueSelected = ui.selectedIds[dialogueId];

  const selectedStyle = isDialogueSelected
    ? {
        padding: 0,
        border: borders.light,
        borderRadius: borderRadius.borderRadius2,
      }
    : {
        border: borders.clear,
      };
  return (
    <div
      {...css(selectedStyle, {
        width: "100%",
        userSelect: "none",
      })}
      onClick={() => {
        if (!disableTextEdits) {
          if (shortcuts.shiftPress === true) {
            ui.setSelectedId(dialogueId);
          } else {
            ui.clearPreviouslySelectedId();
            ui.setSelectedId(dialogueId);
          }
        }
      }}
      onBlur={() => {
        if (!disableTextEdits) {
          if (!shortcuts.shiftPress) {
            ui.setSelectedId(undefined);
          }
        }
      }}
    >
      {children}
    </div>
  );
});
