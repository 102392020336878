import { makeAutoObservable } from "mobx";

export enum NetworkStatusOptions {
  offline = "offline",
  online = "online",
}

export class NetworkStatus {
  status: string;

  constructor() {
    this.status = NetworkStatusOptions.online;

    window.addEventListener("online", this.checkNetworkStatus, false);
    window.addEventListener("offline", this.checkNetworkStatus, false);

    this.checkNetworkStatus();
    makeAutoObservable(this);
  }

  get currentNetworkStatus() {
    return this.status;
  }

  checkNetworkStatus = () => {
    if (navigator.onLine) {
      this.status = NetworkStatusOptions.online;
    } else {
      this.status = NetworkStatusOptions.offline;
    }
  };
}

export const networkStatus = new NetworkStatus();
