import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AppStore from "../../store/AppStore";
import Popup from "../../store/Popup";
import { Color, spacings, Text } from "../../styles/default";
import { Popup as PopupComponent } from "../common/Popup";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupCloudSavingWarning: React.FC<PopupsType> = ({ store, popup }) => {
  const intl = useIntl();

  return (
    <PopupComponent
      id="PopupCloudSavingWarning-popup"
      title={intl.formatMessage({ id: "popup.warning.cloud.saving.title" })}
      width="575px"
      cancel={{ action: () => store.popups.clear(), label: "Ok" }}
      headerIcon="cloud_off"
      confirm={{
        action: async () => {
          store.saveDraft(
            intl.formatMessage({
              id: "default.document.title",
            })
          );
        },
        enabled: true,
        label: intl.formatMessage({
          id: "btn.save.document.label",
        }),
      }}
      style={{
        headerIcon: {
          color: Color.warning,
        },
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space5,
          flexDirection: "column",
          gridGap: spacings.space2,
          width: "100%",
          boxSizing: "border-box",
        })}
      >
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            gridGap: spacings.space2,
            paddingRight: spacings.space5,
          })}
        >
          <span {...css(Text.body)}>
            <FormattedMessage id="cloud.saving.message" />
          </span>
          <span {...css(Text.body)}>
            <FormattedMessage id="cloud.saving.message.risk" />
          </span>
          <span {...css(Text.body)}>
            <FormattedMessage id="cloud.saving.message.recommendation" />
          </span>
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupCloudSavingWarning);
