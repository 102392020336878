import { makeAutoObservable, toJS } from "mobx";
import {
  Character,
  CharacterType,
  DefaultCharacterType,
  DEFAULT_CHARACTER_COLOR,
} from "./Character";
import Scene, { NO_PREDEFINED_SUBHEADER, SceneType } from "./Scene";
import { v4 } from "uuid";
import { Dialogue, DialogueType } from "./Dialogue";
import { DocumentMetadataType, DocumentTypeOptions } from "./Document";
import { user } from "../User";
import { DB } from "../Firestore";
import Apis from "../Api";

export type CharacterId = string;
export type ScriptType = "spec" | "shooting";

interface ScreenplayType {
  defaultCharacter?: DefaultCharacterType;
  documentId: string;
  scenes?: { [sceneId: string]: Scene };
  title?: string;
  characters?: { [CharacterId: string]: Character };
  starred: boolean;
  sceneOrder?: string[];
  seriesId: string;
  scriptType: ScriptType;
}
/**
 * A screenplay is comprised of a number of different scenes, and every scene starts with a scene heading, also known as a slug line.
 */
export default class Screenplay {
  defaultCharacter?: DefaultCharacterType;
  documentId: string;
  scenes: { [sceneId: string]: Scene };
  title: string;
  characters: { [CharacterId: string]: Character };
  starred: boolean;
  sceneOrder: string[];
  seriesId: string;
  searchTerm: string;
  scriptType: ScriptType;

  constructor(screenplay: ScreenplayType) {
    this.defaultCharacter = screenplay.defaultCharacter;
    this.documentId = screenplay.documentId;
    this.scenes = screenplay.scenes || {};
    this.title = screenplay.title || "";
    this.characters = screenplay.characters || {};
    this.starred = screenplay.starred || false;
    this.seriesId = screenplay.seriesId;
    this.sceneOrder = screenplay.sceneOrder || [];
    this.searchTerm = "";
    this.scriptType = screenplay.scriptType;

    makeAutoObservable(this);
  }

  get currentSceneLocations(): string[] {
    const locations: { [location: string]: string } = {};
    Object.keys(this.scenes).forEach((sceneId) => {
      const scene = this.scenes[sceneId];
      if (scene.location && scene.location !== "") {
        locations[scene.location.trim()] = scene.location.trim();
      }
    });
    return Object.keys(locations);
  }

  get charactersByName(): { [characterName: string]: Character } {
    const charsByName: { [characterName: string]: Character } = {};
    Object.keys(this.characters).forEach((id) => {
      const character = this.characters[id];
      const name = character.name.toLocaleUpperCase();
      if (!charsByName[name]) {
        charsByName[name] = character;
      }
    });
    return charsByName;
  }

  search(term: string) {
    this.setSearchTerm(term);
  }

  setSearchTerm(term: string) {
    this.searchTerm = term;
  }
  setSeriesId(seriesId: string) {
    this.seriesId = seriesId;
  }

  setCharacters(characters: { [characterId: string]: Character }) {
    this.characters = characters;
  }

  setSceneOrder = (sceneOrder: string[]) => {
    this.sceneOrder = sceneOrder;
  };

  addToSceneOrder = (sceneId: string) => {
    const sceneOrder = this.sceneOrder || [];
    this.sceneOrder = [...sceneOrder, sceneId];
  };

  removeFromSceneOrder = (sceneId: string) => {
    const sceneOrder = toJS(this.sceneOrder);
    const index = sceneOrder?.indexOf(sceneId);
    sceneOrder.splice(index, 1);
    this.setSceneOrder(sceneOrder);
  };

  addScene = (scene: SceneType): Scene => {
    const sceneId = scene.id || v4();
    const s = new Scene({
      id: sceneId,
      subheader: scene.subheader || NO_PREDEFINED_SUBHEADER,
      place: scene.place || "",
      location: scene.location || "",
      timeOfDay: scene.timeOfDay || "",
      action: scene.action || "",
      transition: scene.transition || "",
      dialogueOrder: scene.dialogueOrder || [],
    });

    this.scenes[sceneId] = s;
    return s;
  };

  addNewScene(scene: SceneType): Scene {
    const sceneId = scene.id || v4();
    const s = new Scene({
      id: sceneId,
      subheader: scene.subheader || NO_PREDEFINED_SUBHEADER,
      place: scene.place || "",
      location: scene.location || "",
      timeOfDay: scene.timeOfDay || "",
      action: scene.action || "",
      transition: scene.transition || "",
      dialogueOrder: scene.dialogueOrder || [],
    });

    this.scenes[sceneId] = s;
    this.addToSceneOrder(sceneId);
    return s;
  }

  addNewCharacter(
    characterName: string,
    isDefault: boolean = false
  ): Character {
    const characterId: string = v4();
    this.characters[characterId] = new Character({
      id: characterId,
      name: characterName,
      color: DEFAULT_CHARACTER_COLOR,
      isDefault: isDefault,
    });
    return this.characters[characterId];
  }

  updateCharacter(character: CharacterType) {
    if (this.characters[character.id]) {
      this.characters[character.id].update(character);
    }
  }

  addCharacter(character: CharacterType): string {
    let characterId = character.id || v4();
    if (this.characters[characterId]) {
      // character already exists, let's update it
      this.updateCharacter(character);
    } else {
      // character does not exist, let's create it
      this.characters[characterId] = new Character({
        ...character,
        id: characterId,
      });
    }
    return characterId;
  }

  addCharacterIfDoesNotExist = (characterName?: string): string | undefined => {
    const newCharacterName = characterName?.trim().toLocaleUpperCase();
    if (newCharacterName && characterName) {
      if (!this.charactersByName[newCharacterName]) {
        const characterId = v4();
        // if this character does not exist yet, automatically create new character
        this.addCharacter({
          name: characterName,
          id: characterId,
          isDefault: false,
        });
        return characterId;
      } else {
        return this.charactersByName[newCharacterName].id;
      }
    }

    return undefined;
  };

  addContent(dialogue: DialogueType, sceneId?: string): Dialogue | undefined {
    if (!sceneId) {
      return undefined;
    }

    if (sceneId) {
      const d = new Dialogue(dialogue);
      const scene = this.scenes[sceneId];
      if (scene) {
        scene.addDialogueLine(d);
      }
      return d;
    }
  }

  setTitle(value?: string) {
    this.title = value || "";
  }

  setStarred(value: boolean) {
    this.starred = value;
  }

  saveDocumentMetadata() {
    const documentMetadata = this.getDocumentMetadata();
    if (user.authorized) {
      // save to db
      DB.update("files", this.documentId, documentMetadata);
    } else {
      // save to local storage
      localStorage.set(this.documentId, {
        ...documentMetadata,
        updatedAt: new Date().getTime(),
      });
    }
  }

  updateDefaultCharacterName(name: string) {
    if (this.defaultCharacter) {
      this.defaultCharacter = {
        ...this.defaultCharacter,
        name,
      };
    }
  }

  updateDefaultCharacterColor(color: string) {
    if (this.defaultCharacter) {
      this.defaultCharacter = {
        ...this.defaultCharacter,
        color,
      };
    }
  }

  updateDefaultCharacterId(id: string) {
    if (this.defaultCharacter) {
      this.defaultCharacter = {
        ...this.defaultCharacter,
        id,
      };
    }
  }

  getDocumentMetadata(): DocumentMetadataType {
    return {
      id: this.documentId,
      title: this.title,
      type: DocumentTypeOptions.screenplay,
      defaultCharacterName: this.defaultCharacter?.name,
      defaultCharacterColor: this.defaultCharacter?.color,
      defaultCharacterId: this.defaultCharacter?.id,
      starred: this.starred,
      seriesId: this.seriesId,
      scriptType: this.scriptType,
    };
  }

  sync_addAllContent = async () => {
    // add scenes
    await Object.keys(this.scenes).forEach(async (sceneId: string) => {
      const scene = this.scenes[sceneId];
      await Apis.addScreenplayScene(this.documentId, scene);
      // add scene dialogues
      await Object.keys(scene.dialogues).forEach(async (dialogueId: string) => {
        const dialogue = scene.dialogues[dialogueId];
        await Apis.addSceneDialogue(this.documentId, sceneId, dialogue);
      });
    });
  };

  sync_allCharacters = async () => {
    await Object.keys(this.characters).forEach(async (characterId) => {
      const character = this.characters[characterId];
      await Apis.addCharacter(
        DocumentTypeOptions.podcast,
        this.documentId,
        character
      );
    });
  };

  deleteAllDialoguesForScene(sceneId: string) {
    this.scenes[sceneId].clearDialogue();
  }

  isCharacterAssignedToDialogue(characterId: string): boolean {
    let assigned = false;
    Object.keys(this.scenes).forEach((sceneId) => {
      const dialogues = this.scenes[sceneId].dialogues;
      Object.keys(dialogues).forEach((dialogueId) => {
        const dialogue = dialogues[dialogueId];
        if (
          (dialogue.characterId === characterId && dialogue.text !== "") ||
          dialogue.text !== undefined
        ) {
          assigned = true;
        }
      });
    });

    return assigned;
  }
}
