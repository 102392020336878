import { css, CSSProperties } from "glamor";
import { observer } from "mobx-react";
import { InputStyle } from "../../styles/default";
import TextareaAutosize from "react-textarea-autosize";
import { MIN_MOBILE_WIDTH, queries } from "../../styles/mediaQueries";

const LETTER_SPACE_SIZE = 13;
const AutoSizedInput: React.FC<{
  defaultValue?: string;
  letterSpacing?: number;
  onBlur?: (e: any) => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: any) => void;
  onKeyDown: (e: any) => void;
  placeholder: string;
  style?: CSSProperties;
  value?: string;
  id: string;
}> = ({
  defaultValue,
  letterSpacing = LETTER_SPACE_SIZE,
  onBlur = (e) => {},
  onChange,
  onFocus = (e) => {},
  onKeyDown,
  placeholder,
  style,
  value,
  id,
}) => {
  const overrideStyle = style || {};
  const length = value ? value.length : placeholder.length;
  return (
    <div
      {...css({
        width: length * letterSpacing,
        [queries.phone]: {
          maxWidth: MIN_MOBILE_WIDTH,
        },
      })}
    >
      <TextareaAutosize
        id={id}
        {...css(InputStyle, overrideStyle, { width: "100%" })}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default observer(AutoSizedInput);
