import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import {
  Document,
  DocumentTypeOptions,
  NO_SERIES_ATTRIBUTION_ID,
} from "../../store/textEditor/Document";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "glamor";
import { defaultStyles, FontSizes, spacings, Text } from "../../styles/default";
import ToolbarWrapper from "../common/ToolbarWrapper";
import Screenplay from "../../store/textEditor/Screenplay";
import { Podcast } from "../../store/textEditor/Podcast";
import { Outline } from "../../store/textEditor/Outline";
const DocumentInfo: React.FC<{
  store: AppStore;
  document: Document;
}> = ({ store, document }) => {
  const intl = useIntl();
  let doc;
  let scenes;
  const isScreenplay = document.type === DocumentTypeOptions.screenplay;
  const isPodcast = document.type === DocumentTypeOptions.podcast;
  const isOutline = document.type === DocumentTypeOptions.outline;
  let scriptType;
  if (isScreenplay) {
    doc = document.document as Screenplay;
    scenes = doc.scenes;
    scriptType = doc.scriptType;
  } else if (isPodcast) {
    doc = document.document as Podcast;
  } else if (isOutline) {
    doc = document.document as Outline;
  }
  const seriesId = doc?.seriesId;
  const isStandalone = seriesId === NO_SERIES_ATTRIBUTION_ID;
  const showType = isStandalone
    ? "document.series.standalone"
    : "document.series.series";

  const totalPages = document.pages;
  return (
    <ToolbarWrapper
      initializedPanelOpen={false}
      store={store}
      title={intl.formatMessage({ id: "document.info.tab.title" })}
    >
      <div {...css({ display: "flex" })}>
        {store.document && (
          <button
            {...css(defaultStyles.button.default, {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: spacings.space2,
            })}
            onClick={() => {
              const documentId = store.document?.id;
              if (documentId) {
                store.openSharePopup(
                  documentId,
                  intl.formatMessage({ id: "share.popup.title" })
                );
              }
            }}
          >
            <div
              {...css({
                display: "flex",
                alignItems: "center",
                gridGap: spacings.space2,
              })}
            >
              <span className="material-icons">group_add</span>
              <FormattedMessage id="label.share" />
            </div>
          </button>
        )}
      </div>

      <div {...css({ paddingRight: spacings.space2 })}>
        <Info
          title={intl.formatMessage({ id: "document.info.title.title" })}
          description={doc?.title}
        />
        <div {...css({ display: "flex", justifyContent: "space-between" })}>
          <div>
            <Info
              title={intl.formatMessage({ id: "document.info.series.title" })}
              description={intl.formatMessage({ id: showType })}
            />

            {!isStandalone && seriesId && store.folderReferences[seriesId] && (
              <Info
                title={intl.formatMessage({ id: "document.info.series.name" })}
                description={store.folderReferences[seriesId].name}
              />
            )}
            {isStandalone && (
              <ConvertToSeriesButton store={store} document={document} />
            )}
          </div>
          {scenes && (
            <Info
              title={intl.formatMessage({ id: "document.info.title.scenes" })}
              description={intl.formatMessage(
                { id: "document.scene.count" },
                { count: document.sceneCount }
              )}
            />
          )}
        </div>
        <Info
          title={intl.formatMessage({ id: "runtime.label" })}
          description={intl.formatMessage(
            { id: "screen.time" },
            { minutes: totalPages?.toFixed(1) }
          )}
        />

        <Info
          title={intl.formatMessage({ id: "total.pages" })}
          description={intl.formatMessage(
            {
              id: "total.pages.description",
            },
            { totalPages: Math.ceil(totalPages || 0) }
          )}
        />

        {scriptType && (
          <Info
            title={intl.formatMessage({ id: "script.type.label" })}
            description={`${intl.formatMessage({
              id: `script.type.${scriptType}`,
            })}`}
          />
        )}

        {scriptType && scriptType === "spec" && (
          <ConvertToShootingScriptButton store={store} document={document} />
        )}
      </div>
    </ToolbarWrapper>
  );
};

export default observer(DocumentInfo);

const Info: React.FC<{ title?: string; description?: string }> = ({
  title,
  description,
}) => {
  return (
    <div
      {...css({
        display: "flex",
        flexDirection: "column",
        marginTop: spacings.space4,
        marginBottom: spacings.space2,
        gridGap: spacings.space0,
      })}
    >
      <span {...css(Text.overline, { fontWeight: 500 })}>{title || ""}</span>
      <span {...css(Text.bodyBold)}>{description || ""}</span>
    </div>
  );
};

const ConvertToShootingScriptButton: React.FC<{
  store: AppStore;
  document: Document;
}> = observer(({ store, document }) => {
  const intl = useIntl();
  let enabled = true;
  if (!store.user.authorized) {
    enabled = false;
  }
  return (
    <div
      {...css({
        display: "flex",
        marginTop: spacings.space4,
      })}
    >
      <button
        {...css({
          ...defaultStyles.button.primary,
          fontSize: FontSizes.fontSize1,
        })}
        onClick={() => {
          if (enabled) {
            store.openConvertStandaloneDocumentToSeriesPopup(
              document,
              intl.formatMessage({
                id: "popup.title.convert.toSeries",
                defaultMessage: "Assign Standalone To Series",
              })
            );
          } else {
            store.openSharePopup(
              "",
              intl.formatMessage({
                id: "create.account.label",
                defaultMessage: "",
              })
            );
          }
        }}
      >
        <span
          className="material-icons"
          {...css({ fontSize: FontSizes.fontSize4 })}
        >
          auto_fix_normal
        </span>
        <FormattedMessage id="btn.convert.toShootingScript" />
      </button>
    </div>
  );
});

const ConvertToSeriesButton: React.FC<{ store: AppStore; document: Document }> =
  observer(({ store, document }) => {
    const intl = useIntl();
    let enabled = true;
    if (!store.user.authorized) {
      enabled = false;
    }
    return (
      <div
        {...css({
          display: "flex",
          marginTop: spacings.space4,
        })}
      >
        <button
          {...css({
            ...defaultStyles.button.primary,
            fontSize: FontSizes.fontSize1,
          })}
          onClick={() => {
            if (enabled) {
              store.openConvertStandaloneDocumentToSeriesPopup(
                document,
                intl.formatMessage({
                  id: "popup.title.convert.toSeries",
                  defaultMessage: "Assign Standalone To Series",
                })
              );
            } else {
              store.openSharePopup(
                "",
                intl.formatMessage({
                  id: "create.account.label",
                  defaultMessage: "",
                })
              );
            }
          }}
        >
          <span
            className="material-icons"
            {...css({ fontSize: FontSizes.fontSize4 })}
          >
            create_new_folder
          </span>
          <FormattedMessage
            id="link.convert.to.series"
            defaultMessage="Assign to series"
          />
        </button>
      </div>
    );
  });
