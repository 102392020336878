import { css } from "glamor";
import {
  Color,
  Paddings,
  Family,
  defaults,
  FontSizes,
  hintTop,
  hint,
} from "../../styles/default";
import { getContrastRatio } from "./utils/DomUtils";

const defaultStyles = {
  container: {
    color: Color.light,
    display: "inline-grid",
    gridTemplateColumns: "20px 1fr 20px",
    borderRadius: "4px",
    alignItems: "center",
    fontFamily: Family,
    fontSize: defaults.fontSize,
    fontWeight: 500,
  },
  btnDelete: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    border: `1px solid`,
    outline: "none",
    borderRadius: "0px 4px 4px 0px",
    height: "24px",
    width: "24px",
  },
  btnArrow: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    border: `1px solid`,
    outline: "none",
    borderRadius: "4px 0px 0px 4px",
    height: "24px",
    width: "24px",
  },
  label: {
    paddingLeft: Paddings.relaxed,
    paddingRight: Paddings.relaxed,
    fontWeight: 700,
  },
};

interface ActionType {
  id: string;
  label: string;
  action: () => void;
}

export interface TagWithActionType {
  id: string;
  label: any;
  showBorder?: boolean;
  backgroundColor?: string;
  showDelete?: boolean;
  onDelete?: () => void;
  actions?: ActionType[];
  truncateOnWidth?: number;
  hintDeleteLabel?: string;
}
export const TagWithAction = ({
  label,
  backgroundColor = Color.secondary,
  actions = undefined,
  showDelete = true,
  onDelete,
  truncateOnWidth,
  hintDeleteLabel = "Remove",
}: TagWithActionType) => {
  const iw = 20; // icons width
  const colorContrastRatio = getContrastRatio(backgroundColor, "#FFFFFF");

  const color = colorContrastRatio > 2 ? "white" : "black";
  let gridTemplateColumns = `${iw}px 1fr ${iw}px`;
  let width = truncateOnWidth ? truncateOnWidth - 2 * iw : 0;
  if (!showDelete && actions) {
    gridTemplateColumns = `${iw}px  1fr`;
    width = truncateOnWidth ? truncateOnWidth - iw : 0;
  }
  if (!actions && !showDelete) {
    gridTemplateColumns = "1fr";
    width = truncateOnWidth ? truncateOnWidth + 2 * iw : 0;
  }
  if (showDelete && !actions) {
    gridTemplateColumns = "1fr 20px";
    width = truncateOnWidth ? truncateOnWidth - iw : 0;
  }

  return (
    <div
      {...css(defaultStyles.container, {
        backgroundColor,
        color,
        gridTemplateColumns,
      })}
    >
      {actions && (
        <button
          {...css(defaultStyles.btnArrow, {
            backgroundColor,
            borderColor: backgroundColor,
          })}
          onClick={(e) => onDelete && onDelete()}
        >
          <span {...css({ color })} className="material-icons">
            keyboard_arrow_down
          </span>
        </button>
      )}
      <span
        {...css(
          defaultStyles.label,
          truncateOnWidth
            ? {
                width: `${width}px`,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }
            : {}
        )}
        title={label}
      >
        {label}
      </span>
      {showDelete && (
        <span aria-label={hintDeleteLabel} {...css(hint, hintTop)}>
          <button
            {...css(defaultStyles.btnDelete, {
              backgroundColor: backgroundColor,
              borderColor: backgroundColor,
            })}
            onClick={(e) => onDelete && onDelete()}
          >
            <span
              {...css({
                color,
                fontSize: FontSizes.fontSize4,
                fontWeight: 700,
              })}
              className="material-icons"
            >
              clear
            </span>
          </button>
        </span>
      )}
    </div>
  );
};
