import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import { ui } from "../../store/Ui";
import { Document } from "../../store/textEditor/Document";
import { css } from "glamor";
import {
  defaultStyles,
  hint,
  hintTop,
  spacings,
  Text,
} from "../../styles/default";
import { useIntl, FormattedMessage } from "react-intl";
import { TagWithAction } from "../common/TagWithAction";

const DocumentEditorFilters: React.FC<{
  store: AppStore;
  document: Document;
}> = ({ store, document }) => {
  const intl = useIntl();
  const characterFilters = ui.filterDialogueByCharacterId;
  const characterIds = Object.keys(characterFilters).filter(
    (characterId) => characterFilters[characterId]
  );
  const characters = document?.document?.characters;
  const filtersLength = characterIds.length;
  const hasFilters = filtersLength > 0;
  if (!characters || !hasFilters) {
    return null;
  }
  return (
    <div
      {...css({
        display: "flex",
        gridGap: spacings.space2,
        alignItems: "center",
        marginBottom: spacings.space4,
        flexWrap: "wrap",
      })}
    >
      <span {...css(Text.overline)}>
        <FormattedMessage id="filterBy.label" />{" "}
      </span>
      <div
        {...css({
          display: "flex",
          gridGap: spacings.space2,
          flexWrap: "wrap",
        })}
      >
        {characterIds.map((charId) => {
          const character = characters[charId];
          if (!character) {
            return null;
          }
          const characterName = character.name;
          return (
            <div key={`chip-${charId}`}>
              <TagWithAction
                id={`chip-${charId}`}
                backgroundColor={character.color}
                label={characterName}
                showDelete
                onDelete={() => {
                  ui.removeFilterDialogueByCharacterId(character.id);
                }}
                hintDeleteLabel={intl.formatMessage({
                  id: "btn.hintRemoveCharacterFilterLabel",
                })}
              />
            </div>
          );
        })}
      </div>
      {filtersLength > 1 && (
        <button
          aria-label={intl.formatMessage({
            id: "btn.hintRemoveAllCharacterFiltersLabel",
          })}
          {...css(
            defaultStyles.button.clearLink,
            {
              marginLeft: spacings.space2,
            },
            hint,
            hintTop
          )}
          onClick={() => {
            ui.removeFilterDialogueByCharacterId();
          }}
        >
          <FormattedMessage id="btn.filters.removeAll" />
        </button>
      )}
    </div>
  );
};

export default observer(DocumentEditorFilters);
