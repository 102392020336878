import { css } from "glamor";
import TextareaAutosize from "react-textarea-autosize";
import { observer } from "mobx-react";
import {
  borderRadius,
  Color,
  FontSizes,
  InputStyle,
  InputStyleDialogue,
  spacings,
} from "../../styles/default";
import {
  CharacterExtensionTypes,
  Dialogue,
} from "../../store/textEditor/Dialogue";
import InsertLine from "./InsertLine";
import { Document, DocumentTypeOptions } from "../../store/textEditor/Document";
import { Podcast } from "../../store/textEditor/Podcast";
import Screenplay from "../../store/textEditor/Screenplay";
import Avatar from "react-avatar";
import { Character } from "../../store/textEditor/Character";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import { MenuOptions } from "../../store/ActionMenu";
import { ui } from "../../store/Ui";
import { DialogueBindings } from "./DialogueBinding";
import Draggable from "../common/Draggable";
import { dragSelect } from "../..";
import { useIntl } from "react-intl";
import AppStore from "../../store/AppStore";
import InputWithSuggestion from "../common/InputWithSuggestion";
import { Outline } from "../../store/textEditor/Outline";
import Tip from "../Tip";
import { logEvent } from "@firebase/analytics";
import { user } from "../../store/User";

interface DialogueLineType {
  store: AppStore;
  document: Document;
  dialogue: Dialogue;
  actionOptions: MenuOptions[];
  editedCharacterName?: string;
  sceneId?: string;
  index: number;
  disableTextEdits: boolean;
}

const DialogueLine: React.FC<DialogueLineType> = ({
  store,
  dialogue,
  actionOptions,
  document,
  sceneId,
  index,
  disableTextEdits,
}) => {
  const intl = useIntl();
  const isOutline = document.type === DocumentTypeOptions.outline;
  const placeholder = isOutline
    ? intl.formatMessage({
        id: "outline.placeholder",
      })
    : intl.formatMessage({
        id: "placeholder.dialogue.label",
      });
  if (!dialogue) {
    // if there's no dialogue
    return null;
  }

  if (dialogue?.type === "insert") {
    return (
      <Draggable
        itemId={dialogue.id}
        index={index}
        disabled={disableTextEdits}
        onDrop={() => {
          if (!disableTextEdits) {
            document.handleListItemSwap(
              dragSelect.draggingItemListIndex,
              dragSelect.dropAtIndex
            );
            dragSelect.clearDraggingData();
            ui.setSelectedId(undefined);
          }
        }}
      >
        <InsertLine
          disableTextEdits={disableTextEdits}
          dialogue={dialogue}
          store={store}
          document={document}
        />
      </Draggable>
    );
  }

  const draggedStyle =
    dragSelect.draggingItemListIndex === index
      ? { backgroundColor: Color.surface }
      : {};

  const showParentheticalWarning = hasPotentiallyWrongParenthetical(
    dialogue.text
  );

  return (
    <Draggable
      itemId={dialogue.id}
      disabled={disableTextEdits}
      index={index}
      onDrop={() => {
        if (!disableTextEdits) {
          document.handleListItemSwap(
            dragSelect.draggingItemListIndex,
            dragSelect.dropAtIndex
          );
          dragSelect.clearDraggingData();
          ui.setSelectedId(undefined);
        }
      }}
    >
      <DialogueBindings
        disableTextEdits={disableTextEdits}
        dialogue={dialogue}
        sceneId={sceneId}
      >
        <div
          {...css(
            {
              margin: 0,
              backgroundColor: Color.surface100,
              padding: spacings.space4,
              borderRadius: borderRadius.borderRadius2,
              paddingLeft: spacings.space8,
            },
            draggedStyle
          )}
          id={dialogue.id}
          key={dialogue.id}
          data-dialogueid={dialogue.id}
          onMouseOver={() => {
            if (!disableTextEdits) {
              if (dragSelect.isSelecting) {
                ui.setSelectedId(dialogue.id);
              }
            }
          }}
        >
          <div
            {...css({
              display: "flex",
              marginLeft: 0,
              width: "100%",
              gridGap: spacings.space4,
              margin: 0,
            })}
          >
            <div
              {...css({
                display: "flex",
                flexDirection: "column",
                width: "100%",
              })}
            >
              <div {...css({ display: "flex", alignItems: "center" })}>
                <div>
                  <CharacterInput
                    store={store}
                    document={document}
                    sceneId={sceneId}
                    dialogue={dialogue}
                    disableTextEdits={disableTextEdits}
                  />
                </div>

                {document.type === DocumentTypeOptions.screenplay && (
                  <div>
                    <CharacterExtensionInput
                      store={store}
                      document={document}
                      sceneId={sceneId}
                      dialogue={dialogue}
                      disableTextEdits={disableTextEdits}
                    />
                  </div>
                )}
              </div>

              <div>
                <Tip
                  id="parenthetical"
                  store={store}
                  show={showParentheticalWarning}
                  type="warning"
                  message={intl.formatMessage({
                    id: "tip.parenthetical.possible.error",
                  })}
                  position="right"
                />
              </div>

              <TextareaAutosize
                id={`${dialogue.id}-dialogue`}
                {...css(InputStyle, InputStyleDialogue, {
                  margin: 0,
                })}
                value={dialogue.text}
                onChange={(e) => {
                  if (!disableTextEdits) {
                    ui.setSelectedId(undefined);
                    dialogue.setText(e.target.value);
                    document.sync_updateDialogue(
                      document.id,
                      dialogue,
                      sceneId
                    );
                  }
                }}
                disabled={disableTextEdits}
                onFocus={() => {
                  if (!disableTextEdits) {
                    if (sceneId) {
                      ui.setSelectedSceneId(sceneId);
                    }
                  }
                }}
                className="mousetrap"
                spellCheck={true}
                placeholder={placeholder}
              />
            </div>
            {!disableTextEdits && (
              <MenuActionsTrigger options={actionOptions} />
            )}
          </div>
        </div>
      </DialogueBindings>
    </Draggable>
  );
};

export default observer(DialogueLine);

const CharacterInput: React.FC<{
  store: AppStore;
  document: Document;
  dialogue: Dialogue;
  sceneId?: string;
  disableTextEdits: boolean;
}> = observer(({ document, dialogue, sceneId, disableTextEdits, store }) => {
  const intl = useIntl();
  let entity: Podcast | Screenplay | Outline | undefined = document.document;
  const character: Character | undefined =
    (dialogue.characterId &&
      document.document?.characters[dialogue.characterId]) ||
    undefined;
  const defaultCharacterName =
    dialogue.characterId && document.document?.characters[dialogue.characterId]
      ? document.document?.characters[dialogue.characterId].name
      : "";
  const characterColor =
    character?.color && character.color !== ""
      ? character.color
      : Color.secondary;
  const availableCharacters = document.document?.characters || {};
  const characterOptions = Object.keys(availableCharacters).map((key, i) => ({
    value: document.document?.characters[key].id || "",
    label: document.document?.characters[key].name || "",
    index: i,
    details: {},
  }));

  const isOutline = document.type === DocumentTypeOptions.outline;
  const placeholder = isOutline
    ? intl.formatMessage({
        id: "voice.actor.placeholder.label",
      })
    : intl.formatMessage({
        id: "character.placeholder",
      });
  return (
    <div
      {...css({
        display: "flex",
        width: "100%",
        gridGap: spacings.space2,
        alignItems: "center",
        margin: 0,
      })}
      data-characterid={dialogue.characterId}
    >
      <button
        onClick={(e) => {
          logEvent(user.analytics, "btn_click_openCharacterPopup");
          e.stopPropagation();
          e.preventDefault();
          store.openCharacterPopup(
            intl.formatMessage({
              id: "popup.character.edit.title",
            }),
            character
          );
        }}
      >
        <Avatar
          name={defaultCharacterName}
          round={true}
          size="30px"
          color={characterColor}
        />
      </button>
      <InputWithSuggestion
        key={`${dialogue.id}-character`}
        suggestions={characterOptions}
        placeholder={placeholder}
        id={`${dialogue.id}-character`}
        disabled={disableTextEdits}
        defaultValue={defaultCharacterName}
        onFocus={() => {
          if (sceneId) {
            ui.setSelectedSceneId(sceneId);
          }
        }}
        onChange={(value: string) => {
          dialogue.setEditedCharacter(value);
        }}
        onBlur={() => {
          ui.setSelectedId(undefined);
          const trimmedCharacterName = dialogue.editedCharacter?.trim();
          trimmedCharacterName &&
            dialogue.setEditedCharacter(trimmedCharacterName);
          const characterName = dialogue.editedCharacter;
          const characterId = entity?.addCharacterIfDoesNotExist(characterName);
          if (characterId) {
            // character doesn't exist, let's add
            dialogue.setCharacterId(characterId);
            const character = document.document?.characters[characterId];
            if (character) {
              document.sync_addCharacter(character.fromValue());
              document.sync_updateDialogue(document.id, dialogue, sceneId);
            }
          } else {
            // character already exists, let's swap
            if (characterName) {
              const existingCharacter =
                document.document?.charactersByName[characterName];
              existingCharacter &&
                dialogue.setCharacterId(existingCharacter.id);
              document.sync_updateDialogue(document.id, dialogue, sceneId);
            }
          }
        }}
        value={dialogue.editedCharacter}
        styles={{
          fontSize: FontSizes.fontSize6,
        }}
      />
    </div>
  );
});

const CharacterExtensionInput: React.FC<{
  store: AppStore;
  document: Document;
  dialogue: Dialogue;
  sceneId?: string;
  disableTextEdits: boolean;
}> = observer(({ document, dialogue, sceneId, disableTextEdits, store }) => {
  const intl = useIntl();
  if (!dialogue.characterExtension) {
    return null;
  }
  const characterExtensionOptions = CharacterExtensionTypes.map(
    (value: string, i: number) => ({
      value,
      label: intl.formatMessage(
        {
          id: `screenplay.characterExtension.${value}`,
        },
        {
          abrev: intl
            .formatMessage({
              id: `screenplay.characterExtension.${value}.abrev`,
            })
            .trim(),
        }
      ),
      index: i,
      details: {},
    })
  );
  const isOutline = document.type === DocumentTypeOptions.outline;
  const placeholder = isOutline
    ? intl.formatMessage({
        id: "voice.actor.placeholder.label",
      })
    : intl.formatMessage({
        id: "character.placeholder",
      });
  let value = dialogue.characterExtension;
  if (value) {
    value = `(${intl
      .formatMessage({
        id: `generic.term.${dialogue.characterExtension}`,
        defaultMessage: dialogue.characterExtension,
      })
      .trim()})`;
  }
  return (
    <div
      {...css({
        display: "flex",
        width: "100%",
        gridGap: spacings.space2,
        alignItems: "center",
        margin: 0,
        marginLeft: spacings.space3,
      })}
      data-characterid={dialogue.characterId}
    >
      <InputWithSuggestion
        suggestions={characterExtensionOptions}
        placeholder={placeholder}
        id={`${dialogue.id}-character-ext`}
        disabled={disableTextEdits}
        defaultValue={value}
        onFocus={() => {
          if (sceneId) {
            ui.setSelectedSceneId(sceneId);
          }
        }}
        onChange={(value: string) => {
          dialogue.setCharacterExtension(value);
        }}
        onBlur={() => {}}
        value={value}
      />
    </div>
  );
});

function hasPotentiallyWrongParenthetical(text?: string) {
  if (!text) {
    return false;
  }
  let error = false;

  const breakLines = text.split("\n");

  breakLines.forEach((line) => {
    if (hasParenthesisNotInBeginning(line)) {
      error = true;
    }
  });

  if (breakLines.length > 0) {
    const lastLine = breakLines[breakLines.length - 1];
    const hasParentheticalAtTheEnd = hasParenthesisInBeginning(lastLine);
    if (hasParentheticalAtTheEnd) {
      error = true;
    }
  }

  return error;
}

function hasParenthesisInBeginning(text: string) {
  return text.includes("(") && text.charAt(0) === "(";
}

function hasParenthesisNotInBeginning(text: string) {
  return text.includes("(") && text.charAt(0) !== "(";
}
