import { css } from "glamor";
import * as React from "react";
import { merge } from "lodash";
import {
  Color,
  Borders,
  defaults,
  Margins,
  setAlpha,
  FontSizes,
} from "../../../styles/default";

export interface SearchWrapperStyleType {
  container?: React.CSSProperties;
  button?: React.CSSProperties;
  icon?: React.CSSProperties;
  typeaheadcontainer?: React.CSSProperties;
}

const defaultStyles = {
  icon: {
    border: 0,
    color: setAlpha(Color.onPrimary400, 0.7),
    fontSize: defaults.fontSize,
  },
  button: {
    border: 0,
    top: "-14px",
    left: "6px",
    position: "absolute",
    background: "transparent",
    cursor: "pointer",
    outline: "none",
    margin: Margins.slim,
    zIndex: 1,
  },
  container: {
    position: "relative",
    boxSizing: "border-box",
    borderRadius: Borders.radius,
    width: "100%",
    height: "inherit",
  },
  typeaheadcontainer: {
    position: "relative",
    display: "inline-block",
    width: "100%",
  },
};
interface SearchWrapperType {
  /**
   * DOM elements to be placed inside of this wrapper
   * @type {DOMElements}
   */
  children?: any;
  /**
   * Flag that holds the focused state of the input, if true the user
   * is currently focused on the input, if false, user is not focusing on the
   * input
   * @type {Bool}
   */
  active: boolean;
  /**
   * Query typed on the input
   * @type {String}
   */
  searchTerm: string;
  /**
   * Callback function to handle when user clicks on clear button
   * @type {Function}
   */
  handleClearInput?: () => void;
  showClearSearchBtn: boolean;
  handleShowSuggestions?: () => void;
  style?: SearchWrapperStyleType;
}

export const SearchWrapper: React.FC<SearchWrapperType> = (props) => {
  const {
    handleClearInput,
    searchTerm,
    handleShowSuggestions,
    showClearSearchBtn,
    style,
  } = props;
  const hasQuery = searchTerm !== "";
  const styles = merge({}, defaultStyles, style);
  return (
    <div {...css(styles.container)} data-style="searchWrapper:container">
      {hasQuery && showClearSearchBtn && (
        <button
          title="clear search"
          {...css(styles.button)}
          onClick={() => handleClearInput && handleClearInput()}
          data-style="searchWrapper:button"
        >
          <span
            {...css({ fontSize: FontSizes.fontSize6 })}
            className="material-icons"
          >
            close
          </span>
        </button>
      )}
      {(!hasQuery || !showClearSearchBtn) && (
        <button
          {...css(styles.button)}
          onClick={() => handleShowSuggestions && handleShowSuggestions()}
          data-style="searchWrapper:button"
        >
          <span
            {...css({ fontSize: FontSizes.fontSize6 })}
            className="material-icons"
          >
            search
          </span>
        </button>
      )}
      <div
        {...css(styles.typeaheadcontainer)}
        data-style="searchWrapper:typeaheadcontainer"
      >
        {props.children}
      </div>
    </div>
  );
};
