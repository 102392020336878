import React, { useState } from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import { css } from "glamor";
import {
  borderRadius,
  Color,
  FontSizes,
  hint,
  hintLeft,
  setAlpha,
  spacings,
  Text,
  truncateTextWithEllipsis,
} from "../../styles/default";
import { useIntl } from "react-intl";
import { queries } from "../../styles/mediaQueries";

const ToolbarWrapper: React.FC<{
  store: AppStore;
  title: string;
  width?: number;
  initializedPanelOpen?: boolean;
  actions?: any;
}> = ({
  store,
  title,
  children,
  width = 400,
  initializedPanelOpen = true,
  actions,
}) => {
  const intl = useIntl();
  const [display, setDisplay] = useState(initializedPanelOpen);
  const br2 = borderRadius.borderRadius2;
  return (
    <div
      {...css({
        backgroundColor: setAlpha(Color.surface100, 0.5),
        borderRadius: borderRadius.borderRadius2,
        marginTop: spacings.space0,
        marginBottom: spacings.space0,
        width,
        [queries.phone]: {
          width: "300px",
        },
      })}
    >
      <div
        {...css({
          padding: spacings.space2,
          backgroundColor: Color.surface10,
          borderRadius: `${br2} ${br2} 0 0`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
          height: "60px",
        })}
        onClick={() => {
          setDisplay(!display);
        }}
      >
        <div
          {...css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingRight: spacings.space4,
            paddingLeft: spacings.space4,
          })}
        >
          <span {...css(Text.bodyBold, truncateTextWithEllipsis)}>{title}</span>
          {actions}
        </div>
        <button
          {...css({ display: "flex", alignItems: "center" })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setDisplay(!display);
          }}
        >
          <span
            aria-label={
              display
                ? intl.formatMessage({ id: "btn.details.hide" })
                : intl.formatMessage({ id: "btn.details.show" })
            }
            className="material-icons"
            {...css({ fontSize: FontSizes.fontSize4 }, hint, hintLeft)}
          >
            {display ? "close_fullscreen" : "open_in_full"}
          </span>
        </button>
      </div>
      {display && <div {...css({ padding: spacings.space2 })}>{children}</div>}
    </div>
  );
};

export default observer(ToolbarWrapper);
