import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import { css } from "glamor";
import { defaultStyles, FontSizes, spacings } from "../../styles/default";
import { DocumentTypeOptions } from "../../store/textEditor/Document";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import {
  getIconColorForDocumentType,
  getIconForDocumentType,
} from "../finder/Files";
import { FormattedMessage, useIntl } from "react-intl";
import { user } from "../../store/User";
import { logEvent } from "firebase/analytics";

const CreateNewDocumentButton: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  return (
    <div>
      <MenuActionsTrigger
        options={[
          {
            id: "podcast",
            label: intl.formatMessage({ id: "document.type.podcast" }),
            icon: getIconForDocumentType(DocumentTypeOptions.podcast),
            iconColor: getIconColorForDocumentType(DocumentTypeOptions.podcast),
            action: () => {
              store.openNewDocument(
                intl.formatMessage({ id: "default.document.title" }),
                DocumentTypeOptions.podcast,
                intl.formatMessage({
                  id: `default.podcast.character.name`,
                })
              );
              logEvent(user.analytics, "btn_click_newDocument_podcast");
            },
          },
          {
            id: "screenplay",
            label: intl.formatMessage({ id: "document.type.screenplay" }),
            icon: getIconForDocumentType(DocumentTypeOptions.screenplay),
            iconColor: getIconColorForDocumentType(
              DocumentTypeOptions.screenplay
            ),
            action: () => {
              store.openNewDocument(
                intl.formatMessage({ id: "default.document.title" }),
                DocumentTypeOptions.screenplay
              );
              logEvent(user.analytics, "btn_click_newDocument_screenplay");
            },
          },
          {
            id: "presentation",
            label: intl.formatMessage({ id: "document.type.outline" }),
            iconColor: getIconColorForDocumentType(DocumentTypeOptions.outline),
            icon: getIconForDocumentType(DocumentTypeOptions.outline),
            action: () => {
              store.openNewDocument(
                intl.formatMessage({ id: "default.document.title" }),
                DocumentTypeOptions.outline
              );
              logEvent(user.analytics, "btn_click_newDocument_outline");
            },
          },
        ]}
      >
        <button
          {...css(defaultStyles.button.default, {
            fontWeight: 700,
            fontSize: FontSizes.fontSize4,
            padding: spacings.space2,
          })}
        >
          <span
            className="material-icons"
            {...css({ fontSize: FontSizes.fontSize4 })}
          >
            create
          </span>
          <span>
            <FormattedMessage id="btn.createNew" />
          </span>
          <span className="material-icons">chevron_right</span>
        </button>
      </MenuActionsTrigger>
    </div>
  );
};

export default observer(CreateNewDocumentButton);
