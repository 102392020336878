import { css } from "glamor";
import { Color, setAlpha, spacings } from "../../styles/default";

const Divider = () => {
  return (
    <div
      {...css({
        marginTop: spacings.space4,
        marginBottom: spacings.space4,
        width: "100%",
        height: "1px",
        backgroundColor: setAlpha(Color.onBackground, 0.1),
      })}
    />
  );
};

export default Divider;
