import React from "react";
import { NewDocumentOptions } from "./components/NewDocumentOptions";
import { observer } from "mobx-react";
import AppStore from "./store/AppStore";
import { Document } from "./components/Document";
import Files from "./components/finder/Files";
import { after, css } from "glamor";
import Header from "./components/Header";
import Popups from "./components/common/Popups";
import Navigation from "./components/Navigation";
import PageNotFound from "./components/PageNotFound";
import {
  a,
  body,
  button,
  input,
  radio,
  textarea,
  fontFace,
  h1,
  h2,
  inputSearch,
  html,
  betaRibbon,
} from "./styles/global";
import {
  Color,
  defaultStyles,
  setAlpha,
  spacings,
  toRem,
} from "./styles/default";
import { LayoutHeights, LayoutPaddings, ZIndexes } from "./store/constants";
import { ViewOptionsType } from "./store/View";
import UserProfile from "./components/UserProfile";
import { Feedback } from "./components/common/Feedback";
import { feedback } from "./store/Feedback";
import { actionMenu } from "./store/ActionMenu";
import Menu from "./components/common/ActionMenu";
import Viewer from "./components/finder/Viewer";
import { LoadingScreen } from "./components/common/LoadingScreen";
import { FormattedMessage, useIntl } from "react-intl";
import SplashPage, { Pricing } from "./components/SplashPage";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import { queries } from "./styles/mediaQueries";
import { SupportedLocales } from "./store/common/i18n/Locale";
import PressRelease from "./components/PressRelease";
// import PreBetaPassBlocker from "./components/PreBetaPassBlocker";
import { logEvent } from "@firebase/analytics";
import PrintPreview from "./components/PrintPreview";

css.global("html", html);
css.global("@font-face", fontFace);
css.global("body", body);
css.global("button", button);
css.global("a", a);
css.global("h1", h1);
css.global("h2", h2);
css.global("input", input);
css.global("input[type='search']", inputSearch);
css.global("input[type='radio']", radio);
css.global("textarea", textarea);
css.global("p", {
  color: setAlpha(Color.onPrimary, 0.8),
  fontSize: toRem(14),
  lineHeight: "1rem",
});
// Scrollbar
css.global("::-webkit-scrollbar", {
  width: "10px",
  overflow: "overlay",
  display: "none",
});
// track
css.global("::-webkit-scrollbar-track", {
  background: "transparent",
});
// handle
css.global("::-webkit-scrollbar-thumb", {
  background: "transparent",
  borderRadius: "5px",
});
// handle on hover
css.global("::-webkit-scrollbar-thumb:hover", {
  background: "black",
});

css.global("::-webkit-scrollbar-corner", {
  background: "transparent",
});

const App: React.FC<{ store: AppStore }> = ({ store }) => {
  // const [show, setShow] = React.useState(false);
  const intl = useIntl();
  const user = store.user;

  if (!user) {
    return (
      <LoadingScreen
        id="loading-app"
        message={intl.formatMessage({
          id: "loading.document",
          defaultMessage: "Loading document ...",
        })}
      />
    );
  }
  // if (!show) {
  //   return (
  //     <PreBetaPassBlocker
  //       onBlockPass={() => {
  //         setShow(true);
  //       }}
  //     />
  //   );
  // }

  if (user && !user.authenticated) {
    return (
      <LoadingScreen
        id="loading-app"
        message={intl.formatMessage({
          id: "loading.profile",
          defaultMessage: "Loading personal profile ...",
        })}
      />
    );
  }
  if (store.loadingDocumentForFileType === "PENDING_DOCUMENT") {
    return (
      <LoadingScreen
        id="loading-app"
        message={intl.formatMessage({
          id: "loading.document",
          defaultMessage: "Loading document ...",
        })}
      />
    );
  }
  const currentViewId = store.view.id;
  const authorized = user.authorized;

  if (currentViewId === ViewOptionsType.preview) {
    return <PrintPreview store={store} document={store.document} />;
  }

  if (currentViewId === ViewOptionsType.loading) {
    return (
      <LoadingScreen
        id="loading-app"
        message={intl.formatMessage({
          id: "loading.profile",
          defaultMessage: "Loading personal profile ...",
        })}
      />
    );
  }

  if (currentViewId === ViewOptionsType.notFound) {
    return <PageNotFound store={store} />;
  }

  let marginLeft = LayoutPaddings.appContainer.marginLeft;
  if (["podcast", "screenplay", "outline"].includes(currentViewId)) {
    marginLeft = LayoutPaddings.appContainerContracted.marginLeft;
  }
  let styles = {
    container: {
      marginLeft: `${marginLeft}px`,
      [queries.phone]: {
        marginLeft: spacings.space8,
      },
    },
  };

  return (
    <div {...css(after(betaRibbon))}>
      <Header store={store} />
      <Navigation store={store} />

      {currentViewId === ViewOptionsType.home && !authorized && (
        <div
          {...css({
            marginTop: "200px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: spacings.space8,
            boxSizing: "border-box",
            width: "100%",
          })}
        >
          <NewDocumentOptions store={store} />
          <SplashPage store={store} />
        </div>
      )}
      <div id="body" data-id="body-wrapper" {...css(styles.container)}>
        <div {...css({ marginTop: `${LayoutHeights.appHeader + 20}px` })}>
          {currentViewId === ViewOptionsType.home && authorized && (
            <Files store={store} title="recent" />
          )}

          {currentViewId === ViewOptionsType.userProfile && (
            <UserProfile store={store} />
          )}

          {currentViewId === ViewOptionsType.finder && <Viewer store={store} />}

          {currentViewId === ViewOptionsType.terms && <Terms store={store} />}

          {currentViewId === ViewOptionsType.privacy && (
            <Privacy store={store} />
          )}
          {currentViewId === ViewOptionsType.pressRelease && (
            <PressRelease store={store} />
          )}

          {currentViewId === ViewOptionsType.pricing && (
            <Pricing store={store} />
          )}
        </div>

        <div {...css({ marginTop: `${LayoutHeights.appHeader + 20}px` })}>
          <Document store={store} document={store.document} />
        </div>
      </div>
      <Popups store={store} />
      <div
        {...css({
          position: "fixed",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
          top: 0,
          zIndex: ZIndexes.feedback,
          height: !feedback.open ? 0 : "auto",
        })}
      >
        <Feedback
          type={feedback.type}
          position={feedback.position}
          variant={feedback.variant}
          open={feedback.open}
          onClose={() => {
            feedback.onClose();
          }}
          dismissButtonLabel={feedback.dismissButtonLabel}
          showLoader={feedback.showLoader}
          message={feedback.message}
        />
      </div>
      <Menu menu={actionMenu} />
      <div
        {...css({
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        {SupportedLocales.map((locale) => {
          return (
            <button
              key={locale}
              {...css(defaultStyles.button.clearLink, {
                margin: spacings.space2,
              })}
              onClick={() => {
                logEvent(user.analytics, "btn_click_selectLanguage", {
                  locale,
                });
                store.user.setLanguage(locale);
              }}
            >
              <FormattedMessage id={`language.${locale}`} />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default observer(App);
