import { css, CSSProperties, keyframes } from "glamor";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  FeedbackPositionOptions,
  FeedbackTypeOptions,
  FeedbackVariantType,
} from "../../store/Feedback";
import {
  toRem,
  Margins,
  Color,
  Paddings,
  Bold,
  Regular,
  Elevation,
  spacings,
  FontSizes,
} from "../../styles/default";

const spin = keyframes({
  "100%": { transform: "rotate(360deg)" },
});

const StyleContainer = {
  backgroundColor: Color.surface,
  borderRadius: "5px",
  display: "grid",
  maxWidth: "400px",
  gridTemplateColumns: "1fr auto",
  gridGap: spacings.space3,
  margin: Margins.default,
  marginLeft: Margins.default,
  marginTop: Margins.regular,
  padding: Paddings.regular,
  paddingBottom: Paddings.regular,
  paddingTop: Paddings.regular,
  position: "fixed",
};
const StyleIcon = {
  fontSize: toRem(20),
  marginRight: Margins.regular,
};
const StyleSpinner = {
  fontSize: toRem(18),
  marginRight: Margins.regular,
  animation: `${spin} 1s linear infinite`,
};
const StyleText = {
  fontSize: FontSizes.fontSize1,
  fontWeight: Bold,
  marginRight: Margins.slim,
};
const StyleDetails = {
  fontWeight: Regular,
  marginRight: Margins.slim,
};
const StyleBtnClose = {
  background: "none",
  border: 0,
  cursor: "pointer",
};

export interface FeedbackStyle {
  btnClose: CSSProperties;
  container: CSSProperties;
  details: CSSProperties;
  icon: CSSProperties;
  iconError: CSSProperties;
  iconSuccess: CSSProperties;
  iconWarning: CSSProperties;
  spinner: CSSProperties;
  text: CSSProperties;
}

export interface FeedbackType {
  message?: any;
  details?: any;
  variant?: FeedbackVariantType;
  open: boolean;
  onClose: () => void;
  zIndex?: number;
  style?: FeedbackStyle;
  type?: FeedbackTypeOptions;
  position?: FeedbackPositionOptions;
  dismissButtonLabel?: string;
  showLoader?: boolean;
}

export const Feedback: React.FC<FeedbackType> = ({
  message,
  details,
  onClose,
  zIndex = 1000,
  style,
  variant = "regular",
  type = "hanging",
  open = true,
  dismissButtonLabel,
  showLoader = false,
  position = "bottom",
}) => {
  if (!message) {
    return null;
  }
  const containerStyle = getContainerStyle(position);
  const iconColor = getIconColorForVariant(variant);
  const textColor = getTextColorForVariant(variant);
  return (
    <div
      tabIndex={0}
      {...css(
        StyleContainer,
        {
          color: textColor,
          backgroundColor: getBackgroundColorForVariant(variant),
        },

        Elevation.depth2,
        style?.container,
        { zIndex },
        open ? containerStyle.open : containerStyle.closed
      )}
      data-style="container"
    >
      <div {...css({ display: "flex", alignItems: "center" })}>
        {showLoader && (
          <span
            className="material-icons"
            {...css(StyleIcon, StyleSpinner, style?.spinner)}
          >
            loop
          </span>
        )}
        {variant === "error" && (
          <span
            className="material-icons"
            {...css(StyleIcon, { color: iconColor }, style?.iconError)}
          >
            error
          </span>
        )}
        {variant === "warning" && (
          <span
            className="material-icons"
            {...css(StyleIcon, { color: iconColor }, style?.iconWarning)}
          >
            warning
          </span>
        )}
        {variant === "success" && (
          <span
            className="material-icons"
            {...css(StyleIcon, { color: iconColor }, style?.iconSuccess)}
          >
            check_circle
          </span>
        )}
        <span
          {...css(StyleText, { color: textColor }, style?.text)}
          data-style="text"
        >
          <FormattedMessage id={message} />
        </span>
        <span
          {...css(StyleDetails, { color: textColor }, style?.details)}
          data-style="details"
        >
          {details}
        </span>
      </div>

      <button
        onClick={() => onClose()}
        {...css(style?.btnClose)}
        data-style="btnClose"
      >
        {(
          <span {...css({ color: textColor })}>
            <FormattedMessage
              id="feedback.dismiss"
              defaultMessage={dismissButtonLabel}
            />
          </span>
        ) || (
          <span {...css(StyleBtnClose)} className="material-icons">
            close
          </span>
        )}
      </button>
    </div>
  );
};

const getContainerStyle = (
  position: FeedbackPositionOptions
): { open: CSSProperties; closed: CSSProperties } => {
  switch (position) {
    case "bottom": {
      const disappear = keyframes({
        "0%": { bottom: 0 },
        "100%": { bottom: -100 },
      });

      const appear = keyframes({
        "0%": { bottom: -100 },
        "100%": { bottom: 0 },
      });
      return {
        open: {
          bottom: 0,
          animation: `${appear} 0.3s ease-in`,
        },
        closed: {
          bottom: -100,
          animation: `${disappear} 0.3s ease-out`,
        },
      };
    }
    default: {
      // default is top
      const disappear = keyframes({
        "0%": { top: 0 },
        "100%": { top: -100 },
      });

      const appear = keyframes({
        "0%": { top: -100 },
        "100%": { top: 0 },
      });
      return {
        open: {
          top: 0,
          animation: `${appear} 0.3s ease-in`,
        },
        closed: {
          top: -100,
          animation: `${disappear} 0.3s ease-out`,
        },
      };
    }
  }
};
const getIconColorForVariant = (variant: FeedbackVariantType) => {
  switch (variant) {
    case "success":
      return Color.onSuccess;
    case "warning":
      return Color.onWarning;
    case "error":
      return Color.onError;
    default:
      return Color.onBackground;
  }
};

const getTextColorForVariant = (variant: FeedbackVariantType) => {
  switch (variant) {
    case "info":
      return Color.onSecondary;
    case "success":
      return Color.onSuccess;
    case "warning":
      return Color.onWarning;
    case "error":
      return Color.onError;
    default:
      return Color.onBackground;
  }
};

const getBackgroundColorForVariant = (variant: FeedbackVariantType) => {
  switch (variant) {
    case "info":
      return Color.secondary;
    case "success":
      return Color.success;
    case "warning":
      return Color.warning;
    case "error":
      return Color.error;
    default:
      return Color.onBackground;
  }
};
