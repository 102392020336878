import { css, CSSProperties } from "glamor";
import * as React from "react";
import {
  Color,
  Paddings,
  Hover,
  Family,
  defaults,
  Margins,
  borderRadius,
  setAlpha,
  Text,
  spacings,
} from "../../../styles/default";
import { merge } from "lodash";
import { observer } from "mobx-react";
import DropdownStore, {
  DropdownOptionType,
  SHORTCUT_BLOCKER_CLASS,
} from "./DropdownStore";

const DropdownStyles = {
  container: {
    position: "relative",
  },
  list: {
    backgroundColor: Color.primary200,
    border: `1px solid ${setAlpha(Color.onBackground, 0.1)}`,
    borderRadius: borderRadius.borderRadius2,
    boxSizing: "border-box",
    left: 0,
    maxHeight: "200px",
    overflow: "scroll",
    position: "absolute",
    top: 0,
    zIndex: 1,
  },
  listItem: {
    paddingTop: Paddings.slim,
    paddingBottom: Paddings.slim,
    paddingLeft: Paddings.relaxed,
    paddingRight: Paddings.relaxed,
    color: Color.light,
    fontFamily: Family,
    fontSize: defaults.fontSize,
    display: "flex",
    alignItems: "Center",
    height: "30px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: Hover.items.color,
    },
  },
  selected: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: Paddings.regular,
    borderRadius: borderRadius.borderRadius2,
    border: `1px solid ${setAlpha(Color.onBackground, 0.1)}`,
  },
  selectedText: {
    color: Color.light,
    fontFamily: Family,
    fontSize: defaults.fontSize,
  },
  arrowIcon: {
    color: Color.light,
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: Margins.slim,
  },
  search: {
    position: "relative",
    width: "100%",
  },
  disabled: { opacity: 0.7, cursor: "not-allowed" },
};

interface DropdownStyleType {
  container?: CSSProperties;
  list?: CSSProperties;
  listItem?: CSSProperties;
  selected?: CSSProperties;
  selectedText?: CSSProperties;
  arrowIcon?: CSSProperties;
  search?: CSSProperties;
}

export const Dropdown: React.FC<{
  dropdownStore: DropdownStore;
  style?: DropdownStyleType;
  width?: string;
  label?: string;
  required?: boolean;
}> = observer(
  ({ dropdownStore, style, label, required = false, width = "100%" }) => {
    const {
      filteredOptions,
      selected,
      hasSearchTerm,
      searchTerm,
      disabled,
      id,
    } = dropdownStore;
    const disabledStyle = disabled ? DropdownStyles.disabled : {};
    const styles = style ? merge({}, DropdownStyles, style) : DropdownStyles;
    const defaultSelectedLabel = selected.label;

    return (
      <div
        {...css(styles.container, { width })}
        data-style="container"
        tabIndex={0}
        role="button"
        aria-label={label}
        id={id}
        className={SHORTCUT_BLOCKER_CLASS}
        onKeyDown={(e: any) => {
          dropdownStore.handleDropdownKeyDown(e.key);
        }}
      >
        {label && (
          <div {...css({ marginBottom: spacings.space2 })}>
            <label {...css(Text.bodyBold)} htmlFor={id}>
              {label}
              {required ? (
                <span {...css({ fontWeight: 700, color: Color.error })}>*</span>
              ) : (
                ""
              )}
            </label>
          </div>
        )}
        <div
          {...css(styles.selected, disabledStyle)}
          data-style="selected"
          onClick={() => {
            if (!disabled) {
              dropdownStore.setShowOptions(!dropdownStore.showOptions);
            }
          }}
        >
          {hasSearchTerm && (
            <div {...css(styles.search, disabledStyle)}>
              <span {...css(styles.selectedText)} data-style="selectedText">
                {searchTerm}
              </span>
              <span {...css(styles.arrowIcon)} data-style="arrowIcon">
                <span className="material-icons">close</span>
              </span>
            </div>
          )}
          {!hasSearchTerm && (
            <div {...css(styles.search, disabledStyle)} data-style="search">
              <span {...css(styles.selectedText)} data-style="selectedText">
                {defaultSelectedLabel}
              </span>
              <span {...css(styles.arrowIcon)} data-style="arrowIcon">
                <span className="material-icons">keyboard_arrow_down</span>
              </span>
            </div>
          )}
        </div>
        {dropdownStore.showOptions && (
          <div
            {...css(styles.list, { width })}
            style={
              style &&
              style.list &&
              style.list.width && { width: style.list.width }
            }
            data-style="list"
            tabIndex={0}
          >
            {filteredOptions.map((option) => {
              return (
                <DropdownOptions
                  key={`dropdown-option${option.value}`}
                  option={option}
                  style={styles}
                  dropdownId={id}
                  dropdownStore={dropdownStore}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

const DropdownOptions: React.FC<{
  option: DropdownOptionType;
  style: DropdownStyleType;
  dropdownStore: DropdownStore;
  dropdownId: string;
}> = observer(({ dropdownId, dropdownStore, option, style }) => {
  const hoveredOption = dropdownStore.hoveredOption;
  const hoveredStyle =
    hoveredOption?.value && hoveredOption.value === option.value
      ? { backgroundColor: Hover.items.color }
      : {};
  return (
    <div
      {...css(style.listItem, hoveredStyle)}
      data-style="listItem"
      key={`${dropdownId}-${option.value}`}
      onKeyDown={(e: any) => {
        console.log("listItem: ", e.key);
      }}
      onClick={() => {
        dropdownStore.handleSelected({
          value: option.value,
          label: option.label,
        });
        option && option.action && option.action();
      }}
    >
      <span {...css(style.selectedText)} data-style="selectedText">
        {option.label}
      </span>
    </div>
  );
});
