import { css, CSSProperties } from "glamor";
import { Color, defaultStyles, hint, hintTop } from "../../styles/default";
import { queries } from "../../styles/mediaQueries";

export const ActionButton: React.FC<{
  destructive?: boolean;
  icon: string;
  iconColor?: string;
  iconOnly?: boolean;
  label: string;
  onClick: () => void;
  shortcut: string;
  style?: CSSProperties;
}> = ({
  destructive,
  icon,
  iconColor = Color.secondary,
  iconOnly,
  label,
  onClick,
  shortcut,
  style = {},
}) => {
  const destructiveStyles =
    destructive === true ? { color: Color.destructive } : {};
  const shortcutLabel = shortcut !== "" ? `${label} [${shortcut}]` : label;
  return (
    <div aria-label={shortcutLabel} {...css(hint, hintTop, style)}>
      <button
        {...css(
          defaultStyles.button.primary,
          destructive ? { border: `1px solid red` } : {}
        )}
        onClick={onClick}
      >
        {iconOnly !== true && (
          <span
            {...css(destructiveStyles, {
              [queries.phone]: {
                display: "none",
              },
            })}
          >
            {label}
          </span>
        )}

        <span
          className="material-icons"
          {...css({ color: iconColor }, destructiveStyles)}
        >
          {icon}
        </span>
      </button>
    </div>
  );
};
