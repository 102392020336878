import { useIntl } from "react-intl";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { observer } from "mobx-react";
import * as React from "react";
import {
  Color,
  FontSizes,
  Margins,
  SearchInputStyle,
  setAlpha,
  spacings,
} from "../styles/default";

const defaultStyles = {
  container: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  icon: {
    position: "absolute",
    top: "10px",
    left: Margins.relaxed,
    color: Color.primary100,
  },
  input: {
    ...SearchInputStyle,
    width: "300px",
    paddingLeft: "34px",
  },
};

const DocumentEditorSearch: React.FC<{
  id?: string;
  store: AppStore;
  searchAttributes: string[];
}> = ({ store, id, searchAttributes }) => {
  const intl = useIntl();
  const doc = store.document?.document;

  if (!doc) {
    return null;
  }

  const title = intl.formatMessage({ id: "search.document.placeholder" });
  return (
    <div
      {...css({
        display: "flex",
        gridGap: spacings.space4,
        marginTop: spacings.space2,
        marginBottom: spacings.space2,
      })}
    >
      <div {...css(defaultStyles.container)}>
        <span
          className="material-icons"
          {...css({
            color: setAlpha(Color.onBackground, 0.5),
            position: "absolute",
            left: 0,
            marginTop: spacings.space2,
            marginLeft: spacings.space3,
            fontSize: FontSizes.fontSize6,
            opacity: 1,
          })}
        >
          search
        </span>

        <input
          {...css(defaultStyles.input)}
          id={id}
          onFocus={(e) => {}}
          onBlur={() => {}}
          onChange={(e) => {
            doc?.search(e.target.value);
          }}
          placeholder={title}
          value={doc?.searchTerm}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default observer(DocumentEditorSearch);
