import React from "react";
import { observer } from "mobx-react";
import AppStore, { FormOptions } from "../store/AppStore";
import { Input } from "./common/Input";
import { css } from "glamor";
import {
  borders,
  Color,
  defaultStyles,
  spacings,
  Text,
} from "../styles/default";
import { Checkbox } from "./common/Checkbox";
import Apis from "../store/Api";
import { Dropdown } from "./common/dropdown/Dropdown";
import DropdownStore from "./common/dropdown/DropdownStore";
import { useIntl } from "react-intl";
import { currentLocale } from "../store/common/i18n/Locale";
import { Form } from "../store/Form";
import { LoadingScreen } from "./common/LoadingScreen";
import { FormattedMessage } from "react-intl";
import { logEvent } from "@firebase/analytics";

const UserProfile: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = React.useState<"profile" | "plan">(
    "profile"
  );
  const user = store.user;

  if (!user.authorized) {
    return <LoadingScreen id="loading-profile" message="Loading profile" />;
  }
  const settingsOptions = [
    {
      id: "profile",
      label: intl.formatMessage({ id: "profile.tab.profile.label" }),
      action: () => setSelectedTab("profile"),
    },
    {
      id: "plan",
      label: intl.formatMessage({ id: "profile.tab.subscriptionPlan.label" }),
      action: () => setSelectedTab("plan"),
    },
  ];
  return (
    <div>
      <h1>{user.displayName || user.email}</h1>
      <div
        {...css({
          display: "flex",
          gridGap: spacings.space2,
        })}
      >
        <div
          {...css({
            alignItems: "flex-end",
            borderRight: borders.default,
            display: "flex",
            flexDirection: "column",
            gridGap: spacings.space2,
            justifyContent: "flex-start",
            marginRight: spacings.space4,
            paddingBottom: spacings.space4,
            paddingRight: spacings.space4,
            paddingTop: spacings.space4,
            width: "140px",
          })}
        >
          {settingsOptions.map((option) => {
            return (
              <button
                key={`settings-${option.id}`}
                {...css({
                  fontWeight: 700,
                  ":hover": { color: Color.secondary },
                })}
                onClick={() => {
                  logEvent(user.analytics, "btn_click_userProfile_action", {
                    option,
                  });
                  option.action();
                }}
              >
                {option.label}
              </button>
            );
          })}
        </div>
        {selectedTab === "plan" && <SubscriptionPlan store={store} />}
        {selectedTab === "profile" && <UserProfileData store={store} />}
      </div>
    </div>
  );
};

export default observer(UserProfile);

const UserProfileData: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const user = store.user;
  const form = store.forms.getForm(FormOptions.userProfile);
  const fields = form.fields;
  if (!form) {
    return null;
  }

  return (
    <div>
      <h2>
        <FormattedMessage id="profile.title" />
      </h2>
      <div
        {...css({
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space5,
          marginTop: spacings.space5,
        })}
      >
        <LanguageSelector store={store} form={form} />
        <Checkbox
          id="allowDiscovery"
          checked={fields.allowDiscovery.value}
          label={useIntl().formatMessage({
            id: "profile.form.allowDiscovery.label",
          })}
          onToggle={() => {
            fields.allowDiscovery.set(!fields.allowDiscovery.value);
          }}
        />
        <Input
          label={useIntl().formatMessage({
            id: "profile.form.displayName.label",
          })}
          value={fields.displayName.value}
          onChange={(e: any) => {
            fields.displayName.set(e.target.value);
          }}
        />
      </div>

      <div {...css({ marginTop: spacings.space4 })}>
        <button
          {...css(defaultStyles.button.default)}
          onClick={() => {
            const formData = form.getData;

            if (formData.displayName) {
              user.updateDisplayName(formData.displayName);
            }
            if (formData.allowDiscovery) {
              if (user.email) {
                Apis.updateUser(user.email, {
                  allowDiscovery: formData.allowDiscovery,
                });
              }
            }

            if (formData.language) {
              if (user.email) {
                user.setLanguage(formData.language);
                Apis.updateUser(user.email, {
                  language: formData.language,
                });
              }
            }
          }}
        >
          <FormattedMessage id="btn.save" />
        </button>
      </div>
    </div>
  );
});

const SubscriptionPlan: React.FC<{ store: AppStore }> = observer(
  ({ store }) => {
    return (
      <div>
        <h2>
          <FormattedMessage id="profile.tab.subscriptionPlan.label" />
        </h2>
        <span>Free Tier</span>
      </div>
    );
  }
);

const LanguageSelector: React.FC<{ store: AppStore; form: Form }> = observer(
  ({ store, form }) => {
    const fields = form.fields;
    const intl = useIntl();
    const language = fields.language.value;
    const languageOptions = currentLocale.supportedLanguages.map((lang) => ({
      value: lang,
      label: intl.formatMessage({
        id: `language.${lang}`,
        defaultMessage: `language.${lang}`,
      }),
      action: () => {
        fields.language.set(lang);
      },
    }));

    const dropdownStore = new DropdownStore({
      id: "files-sortBy",
      options: languageOptions,
      selected: {
        value: language,
        label: intl.formatMessage({
          id: `language.${language}`,
          defaultMessage: `language.${language}`,
        }),
      },
      disabled: false,
    });
    return (
      <div
        {...css({
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space2,
        })}
      >
        <span {...css(Text.bodyBold)}>
          <FormattedMessage id="profile.form.preferredLanguage.label" />
        </span>
        <Dropdown dropdownStore={dropdownStore} width="200px" />
      </div>
    );
  }
);
