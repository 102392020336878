import { makeAutoObservable } from "mobx";
import { IPromiseBasedObservable, PENDING } from "mobx-utils";
import { hasTerm } from "./utils/sort";

export interface SuggestionOptionType {
  value: string;
  label: string;
  details: any;
  group?: string;
  index: number;
}

interface SuggestionType {
  id: string;
  initialSuggestions?: Array<SuggestionOptionType> | undefined;
  api: (searchTerm: string) => IPromiseBasedObservable<any>;
  maxSuggestions?: number;
  options?: SuggestionOptionType[];
  normalizer?: (response: any) => SuggestionOptionType[];
}

export class Suggestion {
  api: (searchTerm: string) => IPromiseBasedObservable<any>;
  error: any;
  filter: any; // filtering function to apply to suggestions
  id: string;
  maxSuggestions: number;
  options: SuggestionOptionType[];
  searchTerm?: string;
  selected: SuggestionOptionType | undefined;
  status: any;
  normalizer?: (response: any) => SuggestionOptionType[];
  suggestions?: Array<SuggestionOptionType>;
  mostRecentRequestFiredAt: number;

  constructor(props: SuggestionType) {
    this.id = props.id;
    this.suggestions = props.initialSuggestions || [];
    this.status = false;
    this.options = props.options || [];
    this.selected = undefined;
    this.error = undefined;
    this.api = props.api;
    this.normalizer = props.normalizer;
    this.filter = undefined;
    this.mostRecentRequestFiredAt = 0;
    this.maxSuggestions =
      props.maxSuggestions || Object.keys(this.options).length;

    makeAutoObservable(this);
  }

  get currentSelected() {
    return this.selected;
  }

  get isLoading() {
    return this.status.state === PENDING;
  }

  get term() {
    return this.searchTerm;
  }

  get filterFn() {
    return this.filter;
  }

  get optionsList(): SuggestionOptionType[] {
    const searchTerm = this.searchTerm;
    return this.options
      .filter((suggestion) => {
        if (searchTerm && searchTerm !== "") {
          return hasTerm(searchTerm, suggestion.label);
        } else {
          return true;
        }
      })
      .slice(0, this.maxSuggestions);
  }

  get searchTermOptionsResultsFromApi(): SuggestionOptionType[] {
    return this.options;
  }

  clear = () => {
    this.searchTerm = "";
    this.suggestions = [];
    this.error = undefined;
    this.status = false;
    this.selected = undefined;
  };

  clearSuggestions = () => {
    this.suggestions = [];
  };

  setSelected = (selected: SuggestionOptionType) => {
    // clear options and set selected (user made a choice)
    this.clearSuggestions();
    this.selected = selected;
  };

  setSearchTerm = (term?: string) => {
    this.searchTerm = term;
  };

  load = (term: string): Promise<SuggestionOptionType[]> => {
    this.setSearchTerm(term);

    const firedAt = new Date().getTime();
    this.mostRecentRequestFiredAt = firedAt;
    this.clearSuggestions();
    if (term) {
      this.status = this.api(term);
      return this.status.then((response: any) => {
        if (firedAt >= this.mostRecentRequestFiredAt) {
          if (this.normalizer) {
            const options = this.normalizer(response);
            this.setOptions(options);
            return options;
          } else {
            this.setOptions(response);
            return response;
          }
        }
      });
    }
    return new Promise((accept, reject) => accept([]));
  };

  setOptions = (options: SuggestionOptionType[]) => {
    this.options = options;
  };

  setSuggestions = (suggestions: Array<SuggestionOptionType>) => {
    this.suggestions = suggestions;
  };

  setFilterFn = (fn: any) => {
    this.filter = fn;
  };
}
