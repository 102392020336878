import { makeAutoObservable } from "mobx";
import Popup, { PopupOptions } from "./Popup";

class PopupStore {
  stack: { [popupId: string]: Popup };
  length: number;

  constructor() {
    this.stack = {};
    this.length = 0;

    makeAutoObservable(this);
  }

  get list() {
    return Object.keys(this.stack).map((key) => this.stack[key]);
  }

  get active(): Popup | undefined {
    let activePopup;
    if (this.length > 0) {
      this.list.forEach((popup) => {
        if (popup.active) {
          activePopup = popup;
        }
      });
    }
    return activePopup;
  }

  get activeIds() {
    if (this.length > 0) {
      return this.list.map((popup) => popup.id);
    }
    return [];
  }

  clear = () => {
    this.stack = {};
    this.length = 0;
  };

  pop = () => {
    const length = this.length;
    // set the new to be last item to active
    if (length > 1) {
      this.list.forEach((popup) => {
        if (popup.index === length - 2) {
          // set active
          this.stack[popup.id].active = true;
        }
      });

      // delete top popup
      this.list.forEach((popup) => {
        if (popup.index === length - 1) {
          delete this.stack[popup.id];
          this.length = length - 1;
        }
      });
    }

    if (length === 1) {
      const id = this.stack[0].id;
      delete this.stack[id];
      this.length = 0;
    }
  };

  set = (id: PopupOptions, title?: string, data?: any) => {
    // then add new pop up, new popups are set to active by default
    const index = this.length || 0;
    if ((this.active && this.active.id !== id) || index === 0) {
      // if popup is being stacked, set all previous popups to innactive
      this.setAllInactive();
      this.stack[id] = new Popup({
        id,
        index,
        data,
        title,
      });
      this.length = index + 1;
    }
  };

  setAllInactive = () => {
    this.list.forEach((popup) => {
      popup.setInactive();
    });
  };
}

export default PopupStore;
