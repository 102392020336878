import { css, CSSProperties } from "glamor";
import * as React from "react";
import { DefaultSpinner, SpinnerType } from "./DefaultSpinner";
import ScrollView from "./ScrollView";
import { merge } from "lodash";
import { Color, Family, toRem } from "../../styles/default";

const defaultStyles = {
  container: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  content: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100px",
  },
  loadingWrapper: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    justifySelf: "center",
    color: Color.onPrimary,
    fontFamily: Family,
    fontSize: toRem(13),
  },
};

interface LoadingStylesType {
  container?: CSSProperties;
  content?: CSSProperties;
  loadingWrapper?: CSSProperties;
  message?: CSSProperties;
}
interface LoadingScreenType {
  id: string;
  message?: string;
  style?: LoadingStylesType;
  spinner?: SpinnerType;
}
export const LoadingScreen: React.SFC<LoadingScreenType> = ({
  message,
  style,
  spinner,
  id,
}) => {
  const styles = merge({}, defaultStyles, style);
  return (
    <ScrollView id={id}>
      <div {...css(styles.container)}>
        <div {...css(styles.content)}>
          <div {...css(styles.loadingWrapper)}>
            <DefaultSpinner {...spinner} />
          </div>
          {message && <div {...css(styles.message)}>{message}</div>}
        </div>
      </div>
    </ScrollView>
  );
};
