import { makeAutoObservable } from "mobx";
import { user } from "./User";
import { feedback } from "./Feedback";
import {
  updateDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";

export type CollectionType =
  | "users"
  | "files"
  | "folders"
  | "waitlist"
  | "permissions";
// | "podcast"
// | "screenplay"
// | "presentation"
// | "assigned"
// | "shared";
export class Firestore {
  db?: any;

  constructor() {
    initializeApp(firebaseConfig);
    this.db = getFirestore();
    makeAutoObservable(this);
  }

  setDb(db: any) {
    this.db = db;
  }

  fetch = async (
    collectionName: string,
    docName?: string,
    orderByField: string = "updatedAt"
  ) => {
    console.log("fetch", collectionName, docName, orderByField);
    if (docName) {
      const docRef = doc(this.db, collectionName, docName);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return undefined;
      }
    }
    const c = collection(this.db, collectionName);
    return await getDocs(c);
  };

  setWithoutCredentials = (
    collectionName: CollectionType,
    dcmt: string,
    data: any
  ) => {
    try {
      console.log("db set", { collection, doc, data });
      const docRef = doc(this.db, collectionName, dcmt);
      return setDoc(docRef, data);
    } catch (error) {
      feedback.setFeedback({
        message: `${collection} ${doc} ${JSON.stringify(error)}`,
        variant: "error",
      });
    }
  };

  set = (collectionName: CollectionType, dcmt: string, data: any) => {
    try {
      console.log("db set", { collection, doc, data });
      const docRef = doc(this.db, collectionName, dcmt);
      return setDoc(docRef, {
        ...data,
        createdBy: user.email,
        ownerUid: user.uid,
        lastUpdatedBy: user.email,
        updatedAt: new Date().getTime(),
      });
    } catch (error) {
      feedback.setFeedback({
        message: `${collection} ${doc} ${JSON.stringify(error)}`,
        variant: "error",
      });
    }
  };

  update = (collectionName: CollectionType, dcmt: string, data: any) => {
    try {
      console.log("db update", { collection, doc, data });
      const docRef = doc(this.db, collectionName, dcmt);
      return updateDoc(docRef, {
        ...data,
        lastUpdatedBy: user.email,
        updatedAt: data.updatedAt || new Date().getTime(),
      });
    } catch (error) {
      feedback.setFeedback({
        message: `${collection} ${doc} ${JSON.stringify(error)}`,
        variant: "error",
      });
    }
  };
}

export const DB = new Firestore();
