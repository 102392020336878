import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import {
  FeatureOptions,
  subscriptionOptions,
  SubscriptionOptionType,
} from "../store/data/subscriptionPlanOptions";
import { css } from "glamor";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import {
  borders,
  FontFamilies,
  FontSizes,
  setAlpha,
  spacings,
  Text,
} from "../styles/default";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Toggle } from "./common/Toggle";
import { getMonthlyPriceBilledAnnually } from "../lib/audiox/models/utils";
import { WaitlistJoinButton } from "./SubscriptionPlans";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

const gridTemplateColumns = "2fr 1fr 1fr";
const SubscriptionPlansTable: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const [billingType, setBillingType] = React.useState("billAnnually");
  const isBilledAnnually = billingType === "billAnnually";
  return (
    <div
      {...css({ display: "flex", flexDirection: "column", maxWidth: "960px" })}
    >
      <ToggleBillingPlan
        value={billingType === "billAnnually"}
        onToggle={() => {
          if (!isBilledAnnually) {
            setBillingType("billAnnually");
          } else {
            setBillingType("billMonthly");
          }
        }}
      />
      <TableRow
        value="planId"
        subscriptionOptions={subscriptionOptions}
        style={{ fontSize: FontSizes.fontSize8, fontWeight: 700 }}
      />
      <TableRow value={billingType} subscriptionOptions={subscriptionOptions} />

      {Object.keys(FeatureOptions).map((key) => {
        return <FeatureOptionsRow key={key} store={store} feature={key} />;
      })}
    </div>
  );
};

export default observer(SubscriptionPlansTable);

const FeatureOptionsRow: React.FC<{ store: AppStore; feature: string }> =
  observer(({ feature, store }) => {
    const option = feature;
    return (
      <div
        {...css({
          display: "grid",
          gridTemplateColumns,
          borderBottom: borders.light,
          minHeight: "40px",
          boxSizing: "border-box",
          paddingTop: spacings.space2,
          paddingBottom: spacings.space2,
        })}
      >
        <FeatureDescription option={option} />
        {subscriptionOptions.map((item, i) => {
          const hasWaitlistJoin =
            option === FeatureOptions.joinWaitlist &&
            item.features.includes(option as FeatureOptions);
          return (
            <div
              key={`${item.planId}-${i}`}
              {...css({
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              {hasWaitlistJoin && (
                <WaitlistJoinButton store={store} planId={item.planId} />
              )}
              {!hasWaitlistJoin && (
                <div>
                  {item.features.includes(option as FeatureOptions) ? (
                    <span className="material-icons">check</span>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  });

export const FeatureDescription: React.FC<{ option: string }> = ({
  option,
}) => {
  if (option === FeatureOptions.joinWaitlist) {
    return <div />;
  }
  return (
    <div {...css({ display: "flex", flexDirection: "column" })}>
      <span {...css({ fontWeight: 700 })}>
        <FormattedMessage
          id={`subscription.plan.${option}`}
          defaultMessage={`subscription.plan.${option}`}
        />
      </span>
      <span {...css(Text.bodyFaded)}>
        <FormattedMessage
          id={`subscription.plan.${option}.description`}
          defaultMessage={`subscription.plan.${option}.description`}
        />
      </span>
    </div>
  );
};

export const ToggleBillingPlan: React.FC<{
  onToggle: () => void;
  value: boolean;
}> = ({ onToggle, value }) => {
  return (
    <div
      {...css({
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginBottom: spacings.space5,
      })}
    >
      <div
        {...css({
          display: "flex",
          gridGap: spacings.space2,
          alignItems: "center",
        })}
      >
        <span {...css(Text.overline)}>
          <FormattedMessage id="bill.monthly" defaultMessage="Bill monthly" />
        </span>
        <Toggle
          on={value}
          onToggle={() => {
            logEvent(user.analytics, "btn_toggle_BillingPlan");
            onToggle();
          }}
        />

        <div
          {...css({
            position: "relative",
            display: "flex",
            alignItems: "center",
          })}
        >
          <span {...css(Text.overline)}>
            <FormattedMessage
              id="bill.annually"
              defaultMessage="Bill annually"
            />
          </span>
          <span className="material-icons">arrow_left</span>
          <span
            {...css({
              fontFamily: FontFamilies.cursive,
              fontSize: FontSizes.fontSize8,
              fontWeight: 700,
              position: "absolute",
              top: -25,
              left: 0,
              marginLeft: "90px",
              transform: "rotate(-20deg)",
              width: "100px",
            })}
          >
            <FormattedMessage id="save.20.percent" defaultMessage="Save 20%" />
          </span>
        </div>
      </div>
    </div>
  );
};
export const TableRow: React.FC<{
  value: string;
  subscriptionOptions: SubscriptionOptionType[];
  style?: CSSProperties;
}> = observer(({ value, subscriptionOptions, style }) => {
  return (
    <div
      {...css({
        display: "grid",
        gridTemplateColumns,
        boxSizing: "border-box",
      })}
    >
      <div {...css({ display: "flex", flexDirection: "column" })}>
        {/* <span {...css({ fontWeight: 700 })}>
          <FormattedMessage
            id={`subscription.plan.${value}`}
            defaultMessage={`subscription.plan.${value}`}
          />
        </span>
        <span>
          <FormattedMessage
            id={`subscription.plan.${value}.description`}
            defaultMessage={`subscription.plan.${value}.description`}
          />
        </span> */}
      </div>
      {subscriptionOptions.map((option) => {
        return (
          <SubscriptionOption
            key={option.planId}
            value={value}
            option={option}
            style={style}
          />
        );
      })}
    </div>
  );
});

export const SubscriptionOption: React.FC<{
  value: string;
  option: SubscriptionOptionType;
  style?: CSSProperties;
}> = observer(({ value, option, style }) => {
  const intl = useIntl();
  const isBillMonthly = value === "billMonthly";
  const isBillAnnually = value === "billAnnually";

  const currency = intl
    .formatMessage({
      id: "money.currency",
    })
    .trim();
  // @ts-ignore
  let content = option[value];
  if (value === "billAnnually") {
    content = option["billMonthly"];
  }
  if (content === undefined) {
    return null;
  }
  const monthlyPriceBilledAnnually = getMonthlyPriceBilledAnnually(content);

  return (
    <div
      {...css({
        backgroundColor: setAlpha(option.color, 0.2),
        padding: spacings.space2,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
      })}
    >
      <span {...css({ color: option.color, margin: 0, ...style })}>
        <span
          {...css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          })}
        >
          {isBillMonthly && (
            <span {...css({ fontSize: FontSizes.fontSize6, fontWeight: 700 })}>
              <FormattedNumber
                value={content}
                style="currency"
                currency={currency}
              />
              /
              <FormattedMessage
                id={`subscription.plan.${value}.cadence`}
                defaultMessage="mo."
              />
            </span>
          )}
          {isBillAnnually && (
            <span {...css({ textDecoration: "line-through" })}>
              <FormattedNumber
                value={content}
                style="currency"
                currency={currency}
              />
              /
              <FormattedMessage
                id={`subscription.plan.billMonthly.cadence`}
                defaultMessage=".mo"
              />
            </span>
          )}
          {isBillAnnually && (
            <span {...css({ fontSize: FontSizes.fontSize6, fontWeight: 700 })}>
              <FormattedNumber
                value={monthlyPriceBilledAnnually}
                style="currency"
                currency={currency}
              />
              /
              <FormattedMessage
                id={`subscription.plan.billMonthly.cadence`}
                defaultMessage="mo."
              />
            </span>
          )}
        </span>

        {!isBillMonthly && !isBillAnnually && (
          <FormattedMessage
            id={`subscription.plan.${value}.${content}`}
            defaultMessage={content}
          />
        )}
      </span>
    </div>
  );
});
