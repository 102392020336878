import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import AppStore, { FormOptions, SuggestionOptions } from "../../store/AppStore";
import Popup from "../../store/Popup";
import { CharacterType } from "../../store/textEditor/Character";
import { Input } from "../common/Input";
import { Popup as PopupComponent } from "../common/Popup";
import { CirclePicker } from "react-color";
import {
  borderRadius,
  Color,
  defaultStyles,
  Elevation,
  hint,
  hintRight,
  setAlpha,
  spacings,
  Text,
} from "../../styles/default";
import Avatar from "react-avatar";
import TypeAheadV2 from "../common/Typeahead/TypeAheadV2";
import { TypeAheadSelectedItem } from "../common/Typeahead/Typeahead";
import Users from "../../store/Users";
import { useIntl, FormattedMessage } from "react-intl";
import { Form } from "../../store/Form";
import { Checkbox } from "../common/Checkbox";
import Divider from "../common/Divider";
import { queries } from "../../styles/mediaQueries";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupCharacter: React.FC<PopupsType> = ({ store, popup }) => {
  const intl = useIntl();
  const form = store.forms.getForm(FormOptions.character);
  const fields = form.fields;
  const documentId = store.document?.id;

  if (!form || !fields || !documentId) {
    return null;
  }
  const characterColor = fields.color.value;
  const overwriteInputStyle = {
    border: `1px solid ${setAlpha(Color.onBackground, 0.3)}`,
    caretColor: characterColor,
    ":focus": {
      border: `1px solid ${setAlpha(Color.onBackground, 0.8)}`,
      caretColor: characterColor,
    },
  };
  const isDefaultCharacter =
    store.document?.document?.defaultCharacter?.id === fields.id.value;
  const talentName = fields.talent.value.name;
  return (
    <>
      <PopupComponent
        id="character-popup"
        title={popup.title}
        width="700px"
        cancel={{
          action: () => store.popups.clear(),
          label: intl.formatMessage({ id: "btn.cancel" }),
        }}
        confirm={{
          action: () => {
            const character: CharacterType = form.getData as CharacterType;
            store.addCharacterToOpenDocument(character);
            store.popups.clear();
          },
          enabled: form.isValid,
          label: intl.formatMessage({ id: "btn.save" }),
        }}
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <h2>{popup.title}</h2>

          <Checkbox
            id="default-character"
            disabled={isDefaultCharacter}
            label={intl.formatMessage({
              id: "popup.character.default.label",
            })}
            checked={fields.isDefault.value}
            onToggle={() => {
              fields.isDefault.set(!fields.isDefault.value);
            }}
          />
        </div>

        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            gridGap: spacings.space3,
            marginTop: spacings.space3,
          })}
        >
          <div
            {...css({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gridGap: spacings.space3,
              [queries.phone]: {
                flexDirection: "column",
              },
            })}
          >
            <div
              {...css({
                display: "flex",
                alignItems: "flex-start",
                gridGap: spacings.space4,
                justifyContent: "flex-start",
                [queries.phone]: {
                  flexDirection: "column",
                  justifyContent: "center",
                },
              })}
            >
              <Avatar
                color={fields.color.value}
                round={true}
                size="50"
                name={fields.name.value}
              />

              <div
                {...css({
                  display: "flex",
                  flexDirection: "column",
                  gridGap: spacings.space3,
                })}
              >
                <div>
                  <Input
                    label={intl.formatMessage({
                      id: "popup.character.name.label",
                    })}
                    required
                    value={fields.name.value}
                    onChange={(e: any) => {
                      fields.name.set(e.target.value);
                    }}
                    style={{
                      input: overwriteInputStyle,
                    }}
                  />
                </div>
                {/* 
                <Checkbox
                  id="voice-over"
                  label={intl.formatMessage({
                    id: "popup.character.voiceOver.label",
                  })}
                  checked={fields.voiceOver.value}
                  onToggle={() => {
                    const isVoiceOver = !fields.voiceOver.value;
                    fields.voiceOver.set(isVoiceOver);
                    if (isVoiceOver) {
                      fields.name.set(
                        `${
                          fields.name.value?.trim() || ""
                        } (${voiceOverAbbrev.trim()})`
                      );
                    } else {
                      const name = fields.name.value.trim().split("(")[0];
                      fields.name.set(name);
                    }
                  }}
                /> */}
                <Input
                  label={intl.formatMessage({
                    id: "popup.character.notes.label",
                  })}
                  multiline
                  value={fields.notes.value}
                  onChange={(e: any) => {
                    fields.notes.set(e.target.value);
                  }}
                  style={{
                    input: overwriteInputStyle,
                  }}
                />
              </div>
            </div>
            <div
              {...css({
                display: "flex",
                flexDirection: "column",
                borderRadius: borderRadius.borderRadius2,
                border: `1px solid ${setAlpha(Color.onBackground, 0.2)}`,
                padding: spacings.space3,
              })}
            >
              <span {...css(Text.bodyBold, { marginBottom: spacings.space1 })}>
                <FormattedMessage id="popup.character.color.label" />
              </span>
              <div {...css({ width: "220px" })}>
                <CirclePicker
                  color={characterColor}
                  onChangeComplete={(color) => {
                    fields.color.set(color.hex);
                  }}
                  circleSize={18}
                />
              </div>
            </div>
          </div>
          {store.user.authorized && (
            <>
              <VoiceActorHeadings />
              <Input
                label={intl.formatMessage({
                  id: "voice.actor.placeholder.label",
                })}
                onChange={(e: any) => fields.voiceActoName.set(e.target.value)}
              />
              {talentName && talentName?.trim() !== "" && (
                <AssignedTo store={store} form={form} />
              )}
              {!(talentName && talentName?.trim() !== "") && (
                <div {...css({ height: "100px" })}>
                  <AssignToUser
                    store={store}
                    onSelected={(
                      user: { name: string; email: string } | undefined
                    ) => {
                      if (user) {
                        fields.talent.set(user);
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </PopupComponent>
    </>
  );
};

export default observer(PopupCharacter);

const AssignToUser: React.FC<{
  store: AppStore;
  onSelected: (user: { email: string; name: string } | undefined) => void;
}> = observer(({ store, onSelected }) => {
  const intl = useIntl();
  const typeaheadStore = store.typeahead;
  const userSuggestions = store.suggestions.current[SuggestionOptions.users];
  const searchTerm = typeaheadStore.searchTerm;
  const isValidSearch = Users.validateEmail(searchTerm);
  const searchResultsCount = typeaheadStore.options?.length || 0;
  const listItemHeight = 40;
  const searchInputHeight = 60;
  const height =
    isValidSearch && searchResultsCount > 0
      ? searchResultsCount * listItemHeight + searchInputHeight
      : listItemHeight + searchInputHeight;
  return (
    <div {...css({ width: "200px" })}>
      <TypeAheadV2
        showClearSearchBtn={false}
        composeTypeahead={{
          EmptySuggestion: <div>Hello!</div>,
        }}
        store={typeaheadStore}
        label="Cast"
        placeholder={intl.formatMessage({
          id: "popup.character.placeholder.search.assignTo",
        })}
        // value={currentValue}
        emptyLabel={<Empty searchTerm={searchTerm} />}
        isValidSearch={isValidSearch}
        onChange={(searchTerm: string, e: any) => {
          typeaheadStore.setSearching(true);
          typeaheadStore.setHasFocus(true);
          const load = userSuggestions.load(searchTerm);
          load.then((response: TypeAheadSelectedItem[]) => {
            typeaheadStore.setOptions(response);
            typeaheadStore.setSearching(false);
          });
        }}
        onSelected={(option?: TypeAheadSelectedItem) => {
          onSelected({
            name: option?.label || "",
            email: option?.value || "",
          });
          typeaheadStore.reset();
        }}
        style={{
          searchWrapper: {
            button: {
              marginTop: "25px",
            },
          },
          searchResults: {
            list: {
              listbox: {
                background: "transparent",
                backgroundColor: "transparent",
              },
            },
            listItem: {
              item: {
                backgroundColor: "transparent",
                width: "100%",
              },
            },
          },
          searchInput: {
            input: {
              marginBottom: spacings.space2,
            },
          },
          container: {
            padding: spacings.space2,
            borderRadius: "5px 5px 0 0",
            width: "300px",
            height,
          },
          containerWithResults: {
            backgroundColor: Color.surface100,
            padding: spacings.space2,
            borderRadius: borderRadius.borderRadius2,
            boxShadow: Elevation.depth3.boxShadow,
            height,
            position: "absolute",
            width: "300px",
          },
        }}
      />
    </div>
  );
});

const Empty: React.FC<{ searchTerm: string }> = ({ searchTerm }) => {
  if (Users.validateEmail(searchTerm)) {
    return (
      <div
        {...css({
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space2,
          padding: spacings.space2,
        })}
      >
        <span {...css(Text.body, { marginTop: spacings.space2 })}>
          <FormattedMessage
            id="empty.search.results.no.users"
            defaultMessage="No user with this email address was found"
          />
        </span>
        <Divider />
        <span {...css(defaultStyles.button.clearLink)}>
          <FormattedMessage
            id="empty.search.results.invite.label"
            defaultMessage="Assign recording to user and invite record on this app"
          />
        </span>
        <Divider />
      </div>
    );
  }
  return null;
};

const AssignedTo: React.FC<{
  store: AppStore;
  form: Form;
}> = observer(({ store, form }) => {
  const fields = form.fields;
  const talent = fields.talent.value;
  return (
    <div
      {...css({
        display: "flex",
        flexDirection: "column",
        gridGap: spacings.space2,
        marginBottom: spacings.space8,
      })}
    >
      <div
        {...css({
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space2,
          position: "relative",
          width: "300px",
        })}
      >
        <div
          {...css({
            display: "flex",
            gridGap: spacings.space2,
            alignItems: "center",
          })}
        >
          <Avatar name={talent.name} round={true} size="30px" />
          <div
            {...css({
              display: "flex",
              gridGap: spacings.space0,
              flexDirection: "column",
            })}
          >
            <span {...css(Text.bodyBold)}>{talent.name}</span>
            <span {...css(Text.bodyFaded)}>{talent.email}</span>
          </div>
        </div>
      </div>
      <div>
        <button
          {...css(defaultStyles.button.clearLink)}
          onClick={() => {
            fields.talent.set({ name: "", email: "" });
          }}
        >
          <FormattedMessage id="btn.remove.assignment" />
        </button>
      </div>
    </div>
  );
});

const VoiceActorHeadings: React.FC = ({ children }) => {
  const intl = useIntl();
  return (
    <div
      {...css({
        display: "flex",
        alignItems: "center",
        gridGap: spacings.space2,
      })}
    >
      <h2>
        <FormattedMessage id="popup.character.recording.title" />
      </h2>
      <div
        aria-label={intl.formatMessage({ id: "voiceactor.help.label" })}
        {...css(hint, hintRight, { cursor: "default" })}
      >
        <span className="material-icons">help_outline</span>
      </div>
    </div>
  );
};
