import React from "react";
import { css } from "glamor";
import { observer } from "mobx-react";
import { spacings } from "../../styles/default";
import { Dialogue } from "../../store/textEditor/Dialogue";
import Breadcrumbs from "../common/Breadcrumbs";
import AppStore from "../../store/AppStore";
import {
  DocumentTypeOptions,
  shouldDisableTextEdits,
} from "../../store/textEditor/Document";
import Files from "../finder/Files";
import DocumentTitleEditor from "./DocumentTitleEditor";
import { ViewOptionsType } from "../../store/View";
import DialogueLine from "./DialogueLine";
import { Document } from "../../store/textEditor/Document";
import Droppable from "../common/dragNdrop/Droppable";
import { dragSelect } from "../..";
import { ui } from "../../store/Ui";
import DocumentHeaderActions from "./DocumentHeaderActions";
import DocumentEditorFilters from "./DocumentEditorFilters";
import { useIntl } from "react-intl";
import { Outline } from "../../store/textEditor/Outline";
import Editor, { EDITOR_MIN_WIDTH } from "./Editor";
import { hasSearchTerm } from "../../lib/audiox/models/utils";
import { queries } from "../../styles/mediaQueries";
import CreateNewDocumentButton from "./CreateNewDocumentButton";

export const OutlineDocument: React.FC<{
  store: AppStore;
  document?: Document;
}> = observer(({ store, document }) => {
  const intl = useIntl();
  const crumbs = [
    {
      id: "home",
      onClick: () => {
        store.openHome();
      },
      title: intl.formatMessage({ id: "breadcrumbs.all" }),
    },
    {
      id: "outline",
      onClick: () => {
        store.files.setFilterBy(DocumentTypeOptions.outline);
        store.openDocumentsTab(ViewOptionsType.outline);
      },
      title: intl.formatMessage({ id: "document.type.outline" }),
    },
  ];
  let current = "outline";

  const documentId = document?.id;
  if (documentId) {
    crumbs.push({
      id: documentId,
      onClick: () => {
        store.openDocument(DocumentTypeOptions.outline, documentId);
      },
      title: document?.document?.title || "",
    });
    current = documentId;
  }

  const outline = document?.document;

  return (
    <div>
      <div
        {...css({
          display: "flex",
          justifyContent: "space-between",
          marginRight: spacings.space8,
        })}
      >
        <Breadcrumbs crumbs={crumbs} current={current} />
        {!document?.id && (
          <div
            {...css({
              display: "none",
              [queries.phone]: {
                display: "block",
              },
            })}
          >
            <CreateNewDocumentButton store={store} />
          </div>
        )}
      </div>

      {document && outline && (
        <OutlineEditor document={document} store={store} />
      )}
      {!outline && <Files store={store} title="recent" />}
    </div>
  );
});

const OutlineEditor: React.FC<{ document: Document; store: AppStore }> =
  observer(({ document, store }) => {
    return (
      <Editor store={store}>
        <div
          {...css({
            marginBottom: "100px",
            width: "100%",
            minWidth: EDITOR_MIN_WIDTH,
          })}
        >
          <DocumentTitleEditor store={store} document={document} />
          <DocumentEditorFilters store={store} document={document} />
          <DocumentHeaderActions store={store} document={document} />
          <OutlineContent store={store} document={document} />
        </div>
      </Editor>
    );
  });

const OutlineContent: React.FC<{ document: Document; store: AppStore }> =
  observer(({ store, document }) => {
    const intl = useIntl();
    const outline = document?.document as Outline;
    const disableTextEdits = shouldDisableTextEdits(store.user, document);

    return (
      <>
        {outline.dialogueOrder?.map((dialogueId: string, index: number) => {
          const dialogue: Dialogue = outline.dialogues[dialogueId];
          const characterId = dialogue?.characterId;
          const filterDialogueByCurrentCharacterId =
            (characterId && ui.filterDialogueByCharacterId[characterId]) ||
            false;

          if (
            (ui.hasFiltersByCharacters &&
              filterDialogueByCurrentCharacterId === false) ||
            !hasSearchTerm(outline.searchTerm, dialogue?.text)
          ) {
            // if we are filtering dialogues by character id and
            // the character id for this line is not being filtered
            // let's return null
            return null;
          }
          return (
            <div
              data-attrdocumentitem={true}
              key={dialogueId}
              onDrop={() => {
                if (!disableTextEdits) {
                  document.handleListItemSwap(
                    dragSelect.draggingItemListIndex,
                    dragSelect.dropAtIndex
                  );
                  dragSelect.clearDraggingData();
                  ui.setSelectedId(undefined);
                }
              }}
            >
              <DialogueLine
                store={store}
                dialogue={dialogue}
                index={index}
                disableTextEdits={disableTextEdits}
                actionOptions={[
                  {
                    id: "newDialogueAbove",
                    label: intl.formatMessage({
                      id: "action.outline.insert.above",
                    }),
                    action: (e?: any) => {
                      document.addNewLine("dialogue", "", undefined, index);
                    },
                  },
                  {
                    id: "newDialogueBelow",
                    label: intl.formatMessage({
                      id: "action.outline.insert.below",
                    }),
                    action: (e?: any) => {
                      document.addNewLine("dialogue", "", undefined, index + 1);
                    },
                  },
                  {
                    id: "newInsertAbove",
                    label: intl.formatMessage({
                      id: "action.insert.insert.above",
                    }),
                    action: (e?: any) => {
                      document.addNewLine("insert", "", undefined, index);
                    },
                  },
                  {
                    id: "newInsertBelow",
                    label: intl.formatMessage({
                      id: "action.insert.insert.below",
                    }),
                    action: (e?: any) => {
                      document.addNewLine("insert", "", undefined, index + 1);
                    },
                  },
                  {
                    id: "delete",
                    label: intl.formatMessage({ id: "action.dialogue.delete" }),
                    icon: "delete_outline",
                    action: (e?: any) => {
                      document.removeDialogue(dialogueId);
                    },
                  },
                ]}
                document={document}
              />
              <Droppable
                index={index + 1}
                onDrop={(draggedItemIndex?: number, droppedIndex?: number) => {
                  if (!disableTextEdits) {
                    document.handleListItemSwap(draggedItemIndex, droppedIndex);
                  }
                }}
              />
            </div>
          );
        })}
      </>
    );
  });
