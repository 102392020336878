import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { Color, hint, hintBottom, setAlpha, spacings } from "../styles/default";
import { LayoutHeights, LayoutPaddings } from "../store/constants";
import { ViewOptionsType } from "../store/View";
import UserMenu from "./common/UserMenu";
import AppLogo from "../images/p1fulllogopink.png";
import AppLogoShort from "../images/p1LogoSmall.png";
import { user } from "../store/User";
import DocumentSearch from "./DocumentSearch";
import { NavigationTabOptions, ui } from "../store/Ui";
import CreateNewDocumentButton from "./document/CreateNewDocumentButton";
import { useIntl } from "react-intl";
import HomeLinks from "./HomeLinks";
import { queries } from "../styles/mediaQueries";
import { windowSizes } from "../store/WindowSizeStore";
import AppActionsBar from "./AppActionsBar";
import Avatar from "react-avatar";
import { logEvent } from "@firebase/analytics";
import DocumentEditorSearch from "./DocumentEditorSearch";

const Header: React.FC<{ store: AppStore }> = ({ store }) => {
  return (
    <div
      {...css({
        alignItems: "center",
        backgroundColor: Color.surface,
        borderBottom: `1px solid ${setAlpha(Color.onBackground, 0.1)}`,
        display: "flex",
        flexDirection: "column",

        height: `${LayoutHeights.appHeader}px`,

        left: 0,
        margin: 0,
        padding: 0,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2,
      })}
    >
      <AppHeader store={store} />
      <AppActionsBar store={store} />
    </div>
  );
};

export default observer(Header);

const AppHeader: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const intl = useIntl();
  const viewId = store.view.id;
  const isHome = viewId === ViewOptionsType.home;
  const isPodcast = viewId === ViewOptionsType.podcast;
  const isOutline = viewId === ViewOptionsType.outline;
  const isScreenplay = viewId === ViewOptionsType.screenplay;
  const isDocumentView = isPodcast || isOutline || isScreenplay;
  const isMobile = windowSizes.hasMinimalPortraitMobileWidth;
  return (
    <div
      {...css({
        display: "flex",
        height: `${LayoutHeights.header}px`,
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      })}
    >
      <div
        {...css({
          paddingLeft: `${LayoutPaddings.appContainer.marginLeft}px`,
          display: "flex",
          gridGap: spacings.space5,
          [queries.phone]: {
            paddingLeft: spacings.space4,
          },
        })}
      >
        <button
          {...css({
            marginLeft: -160,
            paddingRight: spacings.space8,
            [queries.phone]: {
              marginLeft: 20,
              paddingRight: 0,
            },
          })}
          title="Home"
          onClick={() => {
            logEvent(user.analytics, "btn_click_openHome");
            ui.setSelectedNavigationTab(NavigationTabOptions.recent);
            store.openHome();
          }}
        >
          {(!isMobile || (isHome && !store.user.authorized)) && (
            <img src={AppLogo} alt="App logo" width={100} />
          )}
          {isMobile && !isHome && (
            <img src={AppLogoShort} alt="App logo" width={20} />
          )}
        </button>

        {!user.authorized && !store.document && <HomeLinks store={store} />}

        {user.authorized && isHome && (
          <DocumentSearch
            store={store}
            placeholder={intl.formatMessage({ id: "search.all" })}
            onChange={(searchTerm: string) => {
              // TODO: search all
            }}
          />
        )}
        {user.authorized && isPodcast && !store.document && (
          <DocumentSearch
            store={store}
            placeholder={intl.formatMessage({ id: "search.podcasts" })}
            onChange={(searchTerm: string) => {
              // TODO: search screenplays
            }}
          />
        )}
        {user.authorized && isOutline && !store.document && (
          <DocumentSearch
            store={store}
            placeholder={intl.formatMessage({ id: "search.outline" })}
            onChange={(searchTerm: string) => {
              // TODO: search screenplays
            }}
          />
        )}
        {user.authorized && isScreenplay && !store.document && (
          <DocumentSearch
            store={store}
            placeholder={intl.formatMessage({ id: "search.screenplays" })}
            onChange={(searchTerm: string) => {
              // TODO: search screenplays
            }}
          />
        )}
        <DocumentEditorSearch
          store={store}
          id={store.document?.type}
          searchAttributes={["data-attrdocumentitem"]}
        />
      </div>
      <div
        {...css({
          paddingRight: spacings.space4,
          display: "flex",
          alignItems: "center",
          gridGap: spacings.space4,
        })}
      >
        <div {...css({ [queries.phone]: { display: "none" } })}>
          {store.document === undefined && (
            <CreateNewDocumentButton store={store} />
          )}
        </div>

        <OnlineUsers store={store} />
        <UserMenu store={store} isDocumentView={isDocumentView} />
      </div>
    </div>
  );
});

const OnlineUsers: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const doc = store.document;
  if (!doc) {
    return null;
  }
  const connectedUsers = doc.onlinePresence.connectedUsers;
  const userIds = Object.keys(connectedUsers);
  return (
    <div
      {...css({
        display: "flex",
      })}
    >
      {userIds.map((userId) => {
        return (
          <div key={userId} {...css(hint, hintBottom)} aria-label={userId}>
            <Avatar name={userId} round size="28px" />
          </div>
        );
      })}
    </div>
  );
});
