import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import { Color, Elevation, FontSizes, spacings } from "../../styles/default";

const ConnectionStatusIcon: React.FC<{
  connected: boolean;
}> = ({ connected }) => {
  return (
    <span
      className="material-icons"
      {...css({
        boxShadow: Elevation.depth2.boxShadow,
        position: "absolute",
        left: spacings.space5,
        bottom: spacings.space0,
        fontSize: FontSizes.fontSize2,
        color: connected ? Color.online : Color.offline,
      })}
    >
      circle
    </span>
  );
};

export default observer(ConnectionStatusIcon);
