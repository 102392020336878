import { toJS } from "mobx";
import { localStorage } from "./LocalStorage";
import {
  Character,
  CharacterType,
  DefaultCharacterType,
  DEFAULT_CHARACTER_COLOR,
} from "./textEditor/Character";
import {
  CharacterExtensionType,
  Dialogue,
  DialogueType,
  DialogueTypeOptions,
} from "./textEditor/Dialogue";
import { DocumentTypeOptions } from "./textEditor/Document";

interface CharacterObject {
  isDefault: boolean;
  afraidOf?: string;
  color?: string;
  id: string;
  imageUrl?: string;
  motivation?: string;
  name: string;
  notes?: string;
  talent?: {
    name?: string;
    email?: string;
  };
  voiceOver?: boolean;
}

interface DialogueObject {
  text?: string;
  characterId?: string;
  type: DialogueTypeOptions | string;
  characterName?: string;
  id?: string;
  characterExtension?: string | CharacterExtensionType;
  parentheticals?: string;
}

interface SceneObject {
  id?: string;
  subheader: string;
  place?: string;
  location?: string;
  timeOfDay?: string;
  action?: string; // Action tells you what is happening in the scene
  dialogues?: { [dialogueId: string]: DialogueObject }; // dialogue indicates what each character is saying
  // You don’t need to include a transition between scenes, as it’s understood that one scene naturally flows into the next.
  // However, sometimes you might want to use a specific transition as a stylistic choice, such as a SMASH CUT or FADE TO BLACK.
  // Final Draft has many different transition options, and will format the transition flush right according to industry standards.
  transition?: string;
  createdAt?: number;
  dialogueOrder?: string[];
}

export interface LocalStorageDocumentType {
  id: string;
  type: DocumentTypeOptions;
  title: string;
  characters?: { [characterId: string]: CharacterObject };
  dialogues?: { [id: string]: DialogueObject };
  scenes?: { [sceneId: string]: SceneObject };
  createdAt?: number;
  updatedAt?: number;
  createdBy: string;
  lastUpdatedBy: string;
  defaultCharacter?: DefaultCharacterType;
  dialogueOrder?: string[];
  sceneOrder?: string[];
  starred: boolean;
  sharedWith?: string[];
  assignedTo?: string[];
  seriesId: string;
  ownerUid: string;
}

// handles retrieving and adding to local storage
export class LocalApi {
  getDocument = () => {
    return this.getCurrentSavedDocumentFromLocalStorage();
  };

  getDocumentType = () => {
    const document = this.getCurrentSavedDocumentFromLocalStorage();
    if (document) {
      return document.type;
    }
    return undefined;
  };

  getDocumentId = () => {
    const document = this.getCurrentSavedDocumentFromLocalStorage();
    if (document) {
      return document.id;
    }
    return undefined;
  };

  getCurrentDocument = () => {
    const document = this.getCurrentSavedDocumentFromLocalStorage();
    if (document) {
      return document;
    }
  };

  updateDialogueOrder = (
    documentId: string,
    order: string[],
    sceneId?: string
  ) => {
    localStorage.set("documentId", documentId);
    const document = this.getDocument();
    if (document) {
      if (!sceneId) {
        // podcast
        document.dialogueOrder = order;
        localStorage.set(document.id, document);
      } else if (sceneId) {
        //screenplay
        if (document.scenes) {
          if (document.scenes[sceneId]) {
            document.scenes[sceneId].dialogueOrder = order;
            localStorage.set(document.id, document);
          } else {
            alert("No scene Id found");
          }
        }
      }
    }
  };

  updateSceneOrder = (order: string[]) => {
    const document = this.getDocument();
    if (document) {
      document.sceneOrder = order;
      localStorage.set(document.id, document);
    }
  };

  addDialogue = (
    docType: DocumentTypeOptions,
    documentId: string,
    dialogue: Dialogue | DialogueType,
    sceneId?: string
  ) => {
    if (docType === DocumentTypeOptions.podcast) {
      this.addDialogueToPodcast(documentId, dialogue);
    }

    if (docType === DocumentTypeOptions.outline) {
      this.addDialogueToPodcast(documentId, dialogue);
    }

    if (docType === DocumentTypeOptions.screenplay && sceneId) {
      this.addDialogueToScreenplayScene(documentId, sceneId, dialogue);
    }
  };

  addDialogueToPodcast = (
    documentId: string,
    dialogue: Dialogue | DialogueType
  ) => {
    const dialogueId = dialogue.id;
    const document = this.getCurrentSavedDocumentFromLocalStorage();
    if (document && dialogueId) {
      const dialogues = document.dialogues;
      if (dialogues) {
        dialogues[dialogueId] = toJS(dialogue);
        document.dialogues = dialogues;
      } else {
        document.dialogues = {
          [dialogueId]: toJS(dialogue),
        };
      }
    }

    localStorage.set(documentId, document);
  };

  updateDocumentTitle = (documentId: string, title: string) => {
    const document = this.getDocument();
    if (document) {
      document.title = title;
    }
    localStorage.set(documentId, document);
  };

  updateScreenplaySceneData = (
    documentId: string,
    sceneId: string,
    data: any
  ) => {
    const document = this.getDocument();
    if (document) {
      if (document.scenes) {
        const scene = document.scenes[sceneId];
        if (scene) {
          const updatedScene = { ...scene, ...toJS(data) };
          document.scenes[sceneId] = updatedScene;
        }
      }
    }
    localStorage.set(documentId, document);
  };

  addDialogueToScreenplayScene = (
    documentId: string,
    sceneId: string,
    dialogue: Dialogue | DialogueType
  ) => {
    localStorage.set("documentId", documentId);
    const dialogueId: string | undefined = dialogue.id;
    if (!dialogueId) {
      return undefined;
    }

    const document = this.getDocument();
    if (document) {
      if (document.scenes) {
        if (document.scenes[sceneId]) {
          document.scenes[sceneId].dialogues = {
            ...document.scenes[sceneId].dialogues,
            [dialogueId]: toJS(dialogue),
          };
        }
      }

      localStorage.set(document.id, document);
    }
  };

  addScreenplayScene = (documentId: string, scene: SceneObject) => {
    localStorage.set("documentId", documentId);
    const sceneId = scene.id;
    if (!sceneId) {
      return null;
    }
    const document = this.getDocument();
    const scenes = document?.scenes;
    if (document) {
      if (scenes) {
        document.scenes = {
          ...document.scenes,
          [sceneId]: scene,
        };
      } else {
        document.scenes = {
          [sceneId]: scene,
        };
      }
      localStorage.set(document.id, document);
    }
  };

  addDocumentMetadata = (documentId: string, type: string, metadata: any) => {
    localStorage.set("documentId", documentId);
    const currentMetadata = this.getCurrentDocument() || {};
    localStorage.set(documentId, {
      scenes: {},
      dialogues: {},
      dialogueOrder: [],
      sceneOrder: [],
      ...currentMetadata,
      ...metadata,
      updatedAt: metadata.updatedAt || new Date().getTime(),
    });
  };

  updateDocumentMetadata = (documentId: string, metadata: any) => {
    localStorage.set("documentId", documentId);
    const currentMetadata = this.getCurrentDocument() || {};
    localStorage.set(documentId, {
      ...currentMetadata,
      ...metadata,
      updatedAt: new Date().getTime(),
    });
  };

  addCharacter = (character: Character | CharacterType) => {
    if (character.isDefault === true) {
      this.setUpdatedDefaultCharacter(character);
    }
    // add to local storage
    const currentCharacters = localStorage.get("characters") || "";
    if (currentCharacters) {
      localStorage.set("characters", {
        ...JSON.parse(currentCharacters),
        [character.id]: toJS(character),
      });
    }

    const document = this.getDocument();
    if (document) {
      const characters = document.characters;
      document.characters = {
        ...characters,
        [character.id]: toJS(character),
      };

      localStorage.set(document.id, document);
    }
  };

  setUpdatedDefaultCharacter = (character: Character | CharacterType) => {
    const document = this.getCurrentSavedDocumentFromLocalStorage();
    if (document) {
      document.defaultCharacter = {
        color: character.color || DEFAULT_CHARACTER_COLOR,
        name: character.name,
        id: character.id,
        isDefault: true,
      };
      localStorage.set(document.id, document);
    }
  };

  updateCharacter = (character: Character | CharacterType) => {
    this.addCharacter(character);
  };

  addAssignedTo = (assignedTo: string[]) => {
    const documentId = this.getDocumentId();
    if (documentId) {
      this.updateDocumentMetadata(documentId, assignedTo);
    }
  };

  deleteCharacter = (characterId: string) => {
    // TODO: Ensure does not delete default character

    // remove character from local storage
    const currentCharacters = localStorage.get("characters") || "";
    const characters = JSON.parse(currentCharacters);
    if (characters) {
      delete characters[characterId];
    }
    if (currentCharacters) {
      localStorage.set("characters", characters);
    }

    const document = this.getDocument();
    if (document) {
      const characters = document.characters;
      if (characters) {
        delete characters[characterId];
      }
      document.characters = characters;

      localStorage.set(document.id, document);
    }
  };

  deleteDocument = (documentId: string) => {
    // TODO
    return true;
  };

  getCurrentSavedDocumentFromLocalStorage = ():
    | LocalStorageDocumentType
    | undefined => {
    try {
      const documentId = localStorage.get("documentId");
      if (documentId !== undefined) {
        const documentObj = localStorage.get(JSON.parse(documentId));
        if (documentObj) {
          return JSON.parse(documentObj);
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    } catch (error) {
      // TODO: Log error
    }
  };
}

export const localApi = new LocalApi();
