import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import {
  Color,
  hint,
  hintBottom,
  hintLeft,
  hintRight,
  hintTop,
} from "../styles/default";

const Tip: React.FC<{
  id: string;
  store: AppStore;
  show: boolean;
  message: string;
  type: "warning" | "tip";
  position: "left" | "right" | "top" | "bottom";
}> = ({ store, id, show, message, type, position }) => {
  if (!show) {
    return null;
  }
  const hintStyle = getHintStyle(position);
  return (
    <div id={`tip-${id}`} aria-label={message} {...css(hint, hintStyle)}>
      {type === "warning" && (
        <span className="material-icons" {...css({ color: Color.warning })}>
          warning
        </span>
      )}
      {type === "tip" && (
        <span className="material-icons" {...css({ color: Color.secondary })}>
          live_help
        </span>
      )}
    </div>
  );
};

export default observer(Tip);

function getHintStyle(position: "left" | "right" | "top" | "bottom") {
  switch (position) {
    case "left":
      return hintLeft;
    case "right":
      return hintRight;
    case "top":
      return hintTop;
    case "bottom":
      return hintBottom;
    default:
      return hintTop;
  }
}
