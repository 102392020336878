import { css, CSSProperties } from "glamor";
import { observer } from "mobx-react";
import { merge } from "lodash";
import * as React from "react";
import P1Logo from "../../images/p1LogoSmall.png";
import {
  borderRadius,
  Color,
  defaultStyles,
  Paddings,
  setAlpha,
  spacings,
} from "../../styles/default";
import { windowSizes } from "../../store/WindowSizeStore";
import { ZIndexes } from "../../store/constants";
import { queries } from "../../styles/mediaQueries";

const currentStyles = {
  container: {
    backgroundColor: Color.surface,
    borderRadius: borderRadius.borderRadius2,
    display: "grid",
    outline: "none",
    marginTop: spacings.space8,
  },
  header: {
    borderBottom: `1px solid ${setAlpha(Color.onPrimary, 0.1)}`,
    borderRadius: "6px 6px 0 0",
    paddingBottom: Paddings.default,
    paddingLeft: Paddings.default,
    paddingTop: Paddings.default,
    display: "flex",
    alignItems: "center",
    gridGap: spacings.space2,
  },
  title: {
    color: Color.onPrimary,
    margin: 0,
    padding: 0,
  },
  btnCancel: {
    fontWeight: 700,
  },
  btnConfirm: {},
  body: {
    backgroundColor: Color.primary,
    paddingBottom: Paddings.slim,
    paddingLeft: Paddings.default,
    paddingRight: Paddings.default,
    paddingTop: Paddings.slim,
    outline: "none",
    overflow: "hidden",
  },
  footer: {
    borderTop: `1px solid ${setAlpha(Color.onPrimary, 0.1)}`,
    display: "flex",
    justifyContent: "flex-end",
    gridGap: spacings.space5,
    paddingBottom: Paddings.relaxed,
    paddingLeft: Paddings.default,
    paddingRight: Paddings.relaxed,
    paddingTop: Paddings.relaxed,
  },
};

export interface PopupStyles {
  container?: CSSProperties;
  body?: CSSProperties;
  btnCancel?: CSSProperties;
  btnConfirm?: CSSProperties;
  footer?: CSSProperties;
  header?: CSSProperties;
  title?: CSSProperties;
  headerIcon?: CSSProperties;
}

export interface ActionButtonType {
  action: () => void;
  label: any;
  enabled?: boolean;
}
export interface PopupType {
  id: string;
  cancel?: ActionButtonType;
  children?: any;
  clearHeader?: boolean;
  confirm?: ActionButtonType;
  style?: PopupStyles;
  title: any;
  width?: string;
  headerIcon?: string;
}

export const Popup: React.FC<PopupType> = observer(
  ({
    id,
    cancel,
    confirm,
    title,
    style,
    children,
    clearHeader = false,
    width = "500px",
    headerIcon,
  }) => {
    const styles = merge({}, currentStyles, style);
    return (
      <div
        {...css({
          backgroundColor: setAlpha(Color.surface10, 0.9),
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: ZIndexes.popups,
        })}
      >
        <div
          id={id}
          {...css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxHeight: windowSizes.height,
          })}
        >
          <div
            tabIndex={0}
            {...css(styles.container, {
              width,
              [queries.phone]: {
                width: "300px",
              },
            })}
            data-style="container"
          >
            <header
              {...css(
                styles.header,
                clearHeader
                  ? { backgroundColor: Color.primary, borderBottom: 0 }
                  : {}
              )}
              data-style="header"
            >
              {!headerIcon && (
                <img src={P1Logo} alt="Production One Logo" width="15" />
              )}
              {headerIcon && (
                <span
                  className="material-icons"
                  {...css(styles.headerIcon)}
                  data-style="body"
                >
                  {headerIcon}
                </span>
              )}
              <h2 {...css(styles.title)} data-style="title">
                {title}
              </h2>
            </header>

            <div
              tabIndex={0}
              {...css(styles.body, {
                [queries.phone]: {
                  maxHeight: "400px",
                  overflowY: "scroll",
                },
              })}
              data-style="body"
            >
              {children}
            </div>

            <footer {...css(styles.footer)} data-style="footer">
              {cancel && (
                <button
                  {...css(
                    defaultStyles.button.secondary,
                    styles.btnCancel,
                    cancel.enabled === false
                      ? { opacity: 0.5, cursor: "blocked" }
                      : {}
                  )}
                  onClick={() => cancel.action()}
                  data-style="btnCancel"
                >
                  {cancel.label}
                </button>
              )}
              {confirm && (
                <button
                  {...css(
                    defaultStyles.button.default,
                    styles.btnConfirm,
                    confirm.enabled === false
                      ? {
                          opacity: 0.5,
                          cursor: "blocked",
                          ":hover": { backgroundColor: "transparent" },
                        }
                      : {}
                  )}
                  onClick={() => {
                    if (confirm.enabled === true) {
                      confirm.action();
                    }
                  }}
                  data-style="btnConfirm"
                >
                  {confirm.label}
                </button>
              )}
            </footer>
          </div>
        </div>
      </div>
    );
  }
);
