import { ZIndexes } from "../store/constants";
import {
  Color,
  Family,
  Paddings,
  toRem,
  setAlpha,
  Regular,
  Bold,
  FontFamilies,
  FontSizes,
  spacings,
} from "./default";

export const fontFace = {
  fontFamily: Family,
  fontWeight: Regular,
};

export const html = {
  overflowX: "hidden",
};

export const body = {
  margin: 0,
  padding: 0,
  fontFamily: Family,
  color: Color.onPrimary,
  backgroundColor: Color.background,
  overflowX: "hidden",
  position: "relative",
};

export const betaRibbon = {
  content: '"beta"',
  position: "fixed",
  zIndex: ZIndexes.betaRibbon,
  width: "80px",
  height: "25px",
  background: "#04ff00",
  top: "7px",
  left: "-20px",
  textAlign: "center",
  fontSize: "13px",
  fontFamily: "sans-serif",
  textTransform: "uppercase",
  fontWeight: "bold",
  color: "#000000",
  lineHeight: "27px",
  transform: "rotate(-45deg)",
};

export const button = {
  margin: 0,
  padding: 0,
  background: "none",
  cursor: "pointer",
  border: 0,
  color: Color.onBackground,
  display: "flex",
  alignItems: "center",
  gridGap: spacings.space2,
  ":hover": {
    color: Color.secondary,
  },
};

export const h1 = {
  fontFamily: FontFamilies.heading,
  fontSize: FontSizes.fontSize12,
  fontWeight: 900,
  lineHeight: "76px",
  letterSpacing: "-2px",
};

export const h2 = {
  fontFamily: FontFamilies.default,
  fontSize: FontSizes.fontSize5,
  fontWeight: "bold",
  letterSpacing: "-1px",
};

export const a = {
  fontFamily: Family,
  margin: 0,
  padding: 0,
  background: "none",
  cursor: "pointer",
  color: Color.onBackground,
  textDecoration: "underline",
  fontWeight: Bold,
  border: 0,
  ":hover": {
    color: Color.secondary,
  },
};

export const input = {
  backgroundColor: setAlpha(Color.primary400, 0.3),
  color: Color.onPrimary400,
  fontSize: toRem(18),
  border: `1px solid ${setAlpha(Color.onBackground, 0.2)}`,
  margin: 0,
  outline: 0,
  height: "32px",
  borderRadius: "3px",
  ":focus": {
    border: `1px solid ${Color.secondary}`,
  },
};

export const inputSearch = {
  // backgroundColor: setAlpha(Color.primary400, 0.3),
  // color: Color.onPrimary400,
  // fontSize: toRem(18),
  // border: `1px solid ${setAlpha(Color.onBackground, 0.2)}`,
  // margin: 0,
  // outline: 0,
  // height: "32px",
  // borderRadius: "3px",
  // ":focus": {
  //   border: `1px solid ${Color.secondary}`,
  // },

  backgroundColor: "#3A3B3C",
  border: 0,
  display: "flex",
  alignItems: "center",
  paddingBottom: spacings.space2,
  paddingRight: spacings.space4,
  paddingLeft: spacings.space8,
  paddingTop: spacings.space1,
  fontSize: FontSizes.fontSize3,
  height: "40px",
  ":focus": {
    border: 0,
  },
};

export const radio = {
  backgroundColor: Color.primary,
  color: Color.onPrimary,
  fontSize: toRem(14),
  border: `1px solid ${Color.primary200}`,
  margin: 0,
  outline: 0,
  padding: Paddings.default,
};

export const textarea = {
  backgroundColor: setAlpha(Color.primary400, 0.3),
  color: Color.onPrimary,
  fontSize: toRem(12),
  border: `1px solid ${setAlpha(Color.onBackground, 0.2)}`,
  margin: 0,
  outline: 0,
  padding: Paddings.slim,
  borderRadius: "3px",
  ":focus": {
    border: `1px solid ${Color.secondary}`,
  },
};
