import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { css } from "glamor";
import { merge } from "lodash";
import { observer } from "mobx-react";
import { actionMenu, MenuOptions } from "../../store/ActionMenu";
import { defaultStyles } from "../../styles/default";

export const MenuActionsTrigger: React.FC<{
  options: MenuOptions[];
  styles?: CSSProperties;
  onClick?: () => void;
  disappearOnScroll?: boolean;
}> = observer(
  ({ options, children, styles = {}, onClick, disappearOnScroll = false }) => {
    const style = merge({}, defaultStyles.button.icon, styles);
    return (
      <div>
        <button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            e.preventDefault();
            actionMenu.setOptions(options);
            actionMenu.open(e);
            onClick && onClick();
            if (disappearOnScroll) {
              actionMenu.setRemoveOnScroll(true);
            } else {
              actionMenu.setRemoveOnScroll(false);
            }
          }}
          {...css(style)}
        >
          {!children && <span className="material-icons">more_horiz</span>}
          {children}
        </button>
      </div>
    );
  }
);
