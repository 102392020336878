import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import { dragSelect } from "../..";
import { spacings } from "../../styles/default";

const Draggable: React.FC<{
  itemId: string;
  index: number;
  onDrop: () => void;
  disabled: boolean;
}> = ({ disabled, itemId, index, children, onDrop }) => {
  return (
    <div {...css({ position: "relative" })} data-attrdocumentitem={true}>
      <button
        draggable={!disabled}
        onDragStart={(e) => {
          if (!disabled) {
            dragSelect.setDragType("moving");
            const draggedElement: HTMLElement = e.target as HTMLElement;
            const parentElement = draggedElement.parentElement;

            if (parentElement !== null) {
              const dimensions = parentElement?.getBoundingClientRect();
              e.dataTransfer.setDragImage(parentElement, 0, 0);

              const w = dimensions?.width;
              const h = dimensions?.height;
              dragSelect.setDraggingId(itemId);
              dragSelect.setDimensionValues(w, h);

              dragSelect.setDraggingItemListIndex(index);
            }
          }
        }}
        onDragOver={() => {
          if (!disabled) {
            dragSelect.setDropAtIndex(index);
          }
        }}
        onDrop={onDrop}
        {...css({
          userSelect: "none",
        })}
        {...css({
          position: "absolute",
          cursor: "grab",
          marginLeft: spacings.space2,
          marginTop: spacings.space4,
          opacity: disabled ? 0 : 0.4,
          transition: "opacity 0.1s linear",
          ":hover": {
            opacity: 1,
          },
          zIndex: 1,
        })}
      >
        <span className="material-icons">drag_indicator</span>
      </button>
      <div>{children}</div>
    </div>
  );
};

export default observer(Draggable);
