import React from "react";
import { observer } from "mobx-react";
import { Document } from "../../store/textEditor/Document";
import AppStore from "../../store/AppStore";
import { css } from "glamor";
import { Color, hint, hintTop } from "../../styles/default";

const DocumentHeaderActions: React.FC<{
  store: AppStore;
  document: Document;
}> = ({ store, document }) => {
  return (
    <div>{/* <StarDocumentButton store={store} document={document} /> */}</div>
  );
};

export default observer(DocumentHeaderActions);

export const StarDocumentButton: React.FC<{
  store: AppStore;
  document: Document;
}> = observer(({ store, document }) => {
  const starred = document.document?.starred;
  const starredIcon = starred ? "star" : "star_outline";
  return (
    <div aria-label="Mark starred" {...css(hint, hintTop)}>
      <button onClick={() => document.setStarred(!starred)}>
        <span
          className="material-icons"
          {...css({ color: starred ? "yellow" : Color.onBackground })}
        >
          {starredIcon}
        </span>
      </button>
    </div>
  );
});
