import { autorun, makeAutoObservable, toJS } from "mobx";
import { v4 } from "uuid";
import { Forms } from "./Forms";
import { PopupOptions } from "./Popup";
import PopupStore from "./PopupStore";
import { FileStore } from "./FileStore";
import Settings from "./Settings";
import { Suggestions } from "./Suggestions";
import { get } from "lodash";
import {
  Character,
  CharacterType,
  DEFAULT_CHARACTER_COLOR,
} from "./textEditor/Character";
import {
  Document,
  DocumentTypeOptions,
  NO_SERIES_ATTRIBUTION_ID,
} from "./textEditor/Document";
import Users from "./Users";
import View, { ViewOptionsType } from "./View";
import User, { user } from "./User";
import { DB } from "./Firestore";
import { Podcast } from "./textEditor/Podcast";
import {
  Dialogue,
  DialogueType,
  DialogueTypeOptions,
} from "./textEditor/Dialogue";
import { NavigationTabOptions, ui } from "./Ui";
import { localStorage } from "./LocalStorage";
import { feedback } from "./Feedback";
import Screenplay from "./textEditor/Screenplay";
import Apis from "./Api";
import { TypeAheadStore } from "../components/common/Typeahead/Typeahead";
import { localApi, LocalStorageDocumentType } from "./LocalApi";
import Scene, {
  NO_PREDEFINED_SUBHEADER,
  ScenePlaceOptions,
  SceneTimeOfDayOptions,
  SceneType,
} from "./textEditor/Scene";
import { Talent } from "./textEditor/Talent";
import { saveDocumentToComputer } from "../lib/audiox/models/utils";
import { Outline } from "./textEditor/Outline";
import { DocumentData } from "firebase/firestore";
import { NetworkStatus } from "./NetworkStatus";

export type LoadingOptionsType =
  | "PENDING_FETCH_ALL"
  | "PENDING_DOCUMENT"
  | "LOADED"
  | "ERROR";

export enum FormOptions {
  character = "character",
  signIn = "signIn",
  signUp = "signUp",
  userProfile = "userProfile",
  convertStandaloneToSeries = "convertStandaloneToSeries",
  addCharacterExtension = "addCharacterExtension",
  addParentheticals = "addParentheticals",
  shareDocument = "shareDocument",
  editSceneTransitionSubheader = "editSceneTransitionSubheader",
}
export enum SuggestionOptions {
  users = "users",
}

export interface FolderReferenceType {
  id: string;
  name: string;
  documentIds: string[];
}
export default class AppStore {
  view: View;
  document?: Document;
  files: FileStore;
  settings: Settings;
  popups: PopupStore;
  forms: Forms;
  user: User;
  suggestions: Suggestions;
  typeahead: TypeAheadStore;
  folderReferences: { [folderId: string]: FolderReferenceType };
  networkStatus: NetworkStatus;

  loadingDocumentForFileType?: LoadingOptionsType;

  constructor() {
    this.view = new View();
    this.settings = new Settings();
    this.popups = new PopupStore();
    this.forms = new Forms();
    this.files = new FileStore();
    this.user = user;
    this.suggestions = new Suggestions();
    this.typeahead = new TypeAheadStore();
    this.folderReferences = {};
    this.networkStatus = new NetworkStatus();

    // load default suggestions for users
    this.suggestions.set(
      Users.searchUsers,
      SuggestionOptions.users,
      Users.normalizeUsersResponse,
      [],
      []
    );

    makeAutoObservable(this);

    // we only initialize online presence if the user is logged in and there's a document
    // open
    autorun(() => {
      if (
        this.user &&
        this.user.authorized &&
        this.user.authorized &&
        this.document !== undefined
      ) {
        this.document.onlinePresence.initializeOnlinePresence();
      }
    });

    autorun(() => {
      if (this.user && this.user.authorized && this.user.email !== undefined) {
        this.loadAllFolderReferencesForCurrentUser();
      }
    });

    autorun(() => {
      // We only attempt to load documents when user is logged in and in the home page
      if (
        this.user &&
        this.user.authorized &&
        this.user.email !== undefined &&
        this.view.id === ViewOptionsType.home
      ) {
        this.files.clear();
        this.loadDocumentsForCurrentUser(NavigationTabOptions.recent);
      }
    });

    autorun(() => {
      // reload documents when user switches tabs in the finder viewer
      if (
        this.user &&
        this.user.authorized &&
        this.user.email !== undefined &&
        this.view.id === ViewOptionsType.finder &&
        ui.selectedNavigationTab !== undefined
      ) {
        this.files.clear();
        this.loadDocumentsForCurrentUser(ui.selectedNavigationTab);
      }
    });

    autorun(() => {
      // only load podcasts when in podcasts tab and user has been authorized
      if (
        this.user &&
        this.user.authorized &&
        this.user.email !== undefined &&
        this.view.id === ViewOptionsType.podcast
      ) {
        this.files.clear();
        this.loadDocumentsForCurrentUser(
          NavigationTabOptions.recent,
          DocumentTypeOptions.podcast
        );
      }
    });

    autorun(() => {
      // only load outlines when in outlines tab and user has been authorized
      if (
        this.user &&
        this.user.authorized &&
        this.user.email !== undefined &&
        this.view.id === ViewOptionsType.outline
      ) {
        this.files.clear();
        this.loadDocumentsForCurrentUser(
          NavigationTabOptions.recent,
          DocumentTypeOptions.outline
        );
      }
    });

    autorun(() => {
      // only load screenplay when in screenplay tab and user has been authorized
      if (
        this.user &&
        this.user.authorized &&
        this.user.email !== undefined &&
        this.view.id === ViewOptionsType.screenplay
      ) {
        this.files.clear();
        this.loadDocumentsForCurrentUser(
          NavigationTabOptions.recent,
          DocumentTypeOptions.screenplay
        );
      }
    });
  }

  addFolderReference = (
    folderId: string,
    name: string,
    documentIds: string[]
  ) => {
    this.folderReferences[folderId] = {
      name,
      documentIds,
      id: folderId,
    };
  };

  setFolderReferences = (folderReferences: {
    [folderId: string]: FolderReferenceType;
  }) => {
    this.folderReferences = folderReferences;
  };

  loadAllFolderReferencesForCurrentUser = async () => {
    const folderReferences: { [folderId: string]: FolderReferenceType } = {};
    const folders = await Apis.getAllFolderNamesForCurrentUser();

    const docs = folders?.docs;

    docs?.forEach((doc: any) => {
      const data = doc.data();
      folderReferences[data.id] = {
        id: data.id,
        name: data.name,
        documentIds: data.documentIds,
      };
    });

    this.setFolderReferences(folderReferences);
  };

  getFolderNameForFolderId = async (
    folderId: string
  ): Promise<DocumentData | undefined | string> => {
    if (this.folderReferences[folderId]) {
      return this.folderReferences[folderId].name;
    }

    return await Apis.getFolderNameForFolderId(folderId);
  };

  setLoadingDocumentForFileType = (status: LoadingOptionsType) => {
    this.loadingDocumentForFileType = status;
  };

  setDocument(document?: Document) {
    this.document = document;
  }

  createNewDocument = async (
    type: DocumentTypeOptions,
    title: string,
    defaultCharacterName?: string
  ): Promise<Document | undefined> => {
    const userEmail = user.email;
    let defaultCharacter;
    if (defaultCharacterName) {
      defaultCharacter = {
        name: defaultCharacterName.trim(),
        id: v4(),
        color: DEFAULT_CHARACTER_COLOR,
        isDefault: true,
      };
    }

    const documentId = v4();
    const document = new Document({
      id: documentId,
      type,
      title,
      defaultCharacter,
      starred: false,
      createdBy: userEmail || "unknown",
      lastUpdatedBy: userEmail || "unknown",
      seriesId: NO_SERIES_ATTRIBUTION_ID,
      ownerUid: user.uid || "unknown",
    });

    if (defaultCharacter) {
      document.addCharacters({
        [defaultCharacter.id]: new Character(defaultCharacter),
      });
    }

    this.files.add(document);
    const doc = document.fromValue();

    // add new document to the collection of type
    await this.sync_addDocument(document.id, doc);

    if (defaultCharacter) {
      // sync default character to document
      document.sync_addCharacter(defaultCharacter);
    }

    // add default dialogue to podcast if podcast
    let dialogue: Dialogue | undefined;
    if (type === DocumentTypeOptions.podcast) {
      const podcast: Podcast = document.document as Podcast;
      dialogue = podcast?.addContent({
        type: "dialogue",
        text: "",
        characterName: defaultCharacter?.name || "",
        characterId: defaultCharacter?.id || "",
      });
      if (dialogue) {
        await document.sync_addDialogue(document.id, dialogue);
        const dialogueOrder = [dialogue.id];
        document.setDialogueOrder(dialogueOrder);
      }
    }

    if (type === DocumentTypeOptions.outline) {
      const outline: Outline = document.document as Outline;
      dialogue = outline?.addContent({
        type: "dialogue",
        text: "",
        characterName: defaultCharacter?.name || "",
        characterId: defaultCharacter?.id || "",
      });
      if (dialogue) {
        await document.sync_addDialogue(document.id, dialogue);
        const dialogueOrder = [dialogue.id];
        document.setDialogueOrder(dialogueOrder);
      }
    }

    // add default scene and dialogue to screenplay if screenplay
    if (type === DocumentTypeOptions.screenplay) {
      const screenplay: Screenplay = document.document as Screenplay;
      await this.createScreenplayScene(
        document,
        screenplay,
        {
          subheader: NO_PREDEFINED_SUBHEADER,
        },
        {
          type: "dialogue",
          text: "",
          id: v4(),
          characterName: defaultCharacter?.name || "",
          characterId: defaultCharacter?.id || "",
        }
      );
    }

    if (!this.user.authorized) {
      // save document metadata to local storage
      const documentObject = document.fromValue();
      localStorage.set(document.id, documentObject);
      localStorage.set("characters", document.document?.characters);
      this.showWarningSignInToSave();
      document.saveContentsToLocalStorage();
    }

    return document;
  };

  createScreenplayScene = async (
    document: Document,
    screenplay: Screenplay,
    sceneData: SceneType,
    dialogueData?: DialogueType
  ) => {
    const documentId = document.id;
    const scene = screenplay.addNewScene(sceneData);
    if (scene) {
      await document.sync_addScene(documentId, scene);
      await document.sync_updateSceneOrder(screenplay.sceneOrder);

      const sceneId = scene.id;
      const dialogueId = dialogueData?.id;
      if (dialogueData) {
        const dialogue = screenplay?.addContent(dialogueData, sceneId);
        if (dialogueId) {
          screenplay.scenes[sceneId].setDialogueOrder([dialogueId]);
          if (dialogue) {
            await document.sync_addDialogue(document.id, dialogue, sceneId);
            document.showAllScenes(false);
            ui.setShowScene(sceneId, true);
            ui.setSelectedSceneId(sceneId);
          }
        }
      }
    }
  };

  fetchDocumentId = async (
    documentId: string,
    type: string
  ): Promise<Document | undefined> => {
    if (type === DocumentTypeOptions.podcast) {
      return await this.fetchPodcastDocument(documentId);
    } else if (type === DocumentTypeOptions.screenplay) {
      return await this.fetchScreenplayDocument(documentId);
    } else if (type === DocumentTypeOptions.outline) {
      return await this.fetchOutlineDocument(documentId);
    }
    return undefined;
  };

  fetchCharactersForDocumentId = async (
    documentId: string
  ): Promise<{ [characterId: string]: Character }> => {
    const characters: { [characterId: string]: Character } = {};
    const result = await DB.fetch(`files/${documentId}/characters/`);
    result?.forEach((doc: any) => {
      // add line to document
      const character = doc.data();
      characters[character.id] = new Character(character);
    });

    return characters;
  };

  saveDraft = (title: string) => {
    const doc = this.document;
    if (doc) {
      if (
        doc.type === DocumentTypeOptions.podcast ||
        doc.type === DocumentTypeOptions.outline
      ) {
        const podcast: Podcast = doc.document as Podcast;
        const document: LocalStorageDocumentType = {
          id: doc.id,
          type: doc.type,
          title: podcast?.title || title,
          dialogues: podcast.dialogues,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          createdBy: doc.createdBy,
          lastUpdatedBy: doc.lastUpdatedBy,
          defaultCharacter: podcast.defaultCharacter,
          dialogueOrder: podcast.dialogueOrder,
          starred: false,
          seriesId: NO_SERIES_ATTRIBUTION_ID,
          characters: podcast.characters,
          ownerUid: doc.ownerUid,
        };
        saveDocumentToComputer(JSON.stringify(document), document.title);
      } else if (doc.type === DocumentTypeOptions.screenplay) {
        const screenplay: Screenplay = doc.document as Screenplay;
        const document: LocalStorageDocumentType = {
          id: doc.id,
          type: doc.type,
          title: screenplay?.title || title,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          createdBy: doc.createdBy,
          lastUpdatedBy: doc.lastUpdatedBy,
          defaultCharacter: screenplay.defaultCharacter,
          sceneOrder: screenplay.sceneOrder,
          scenes: toJS(screenplay.scenes),
          starred: false,
          seriesId: NO_SERIES_ATTRIBUTION_ID,
          characters: screenplay.characters,
          ownerUid: doc.ownerUid,
        };
        saveDocumentToComputer(JSON.stringify(document), document.title);
      }
    }
  };

  openSharePopup = (documentId: string, title: string) => {
    this.forms.init({
      id: FormOptions.shareDocument,
      fields: {
        email: "",
        permission: "allowRead",
      },
      options: {},
      title,
      validations: {},
      required: [],
    });
    const form = this.forms.getForm(FormOptions.shareDocument);
    this.popups.set(PopupOptions.shareDocument, title, {
      form,
      onSubmit: (data: any) => {
        Apis.setCapabilitiesDocumentForUser(
          documentId,
          data.email,
          data.permission === "allowEdit",
          data.permission === "allowRead"
        );
        this.document?.addSharedWith(data.email);
        this.document?.sync_updateDocumentMetadata();
        this.popups.clear();
      },
    });
  };

  openPreview = async (docType: DocumentTypeOptions, documentId: string) => {
    this.setLoadingDocumentForFileType("PENDING_DOCUMENT");
    ui.resetSelections();

    const path = `/document/${docType}/${documentId}/preview`;
    if (this.document?.id === documentId) {
      this.view.setView(ViewOptionsType.preview, path, "Preview");
      this.setLoadingDocumentForFileType("LOADED");
    } else {
      const disposer1 = autorun(async () => {
        if (user.authorized && user.authenticated) {
          // user is logged in!
          // user is authorized let's attempt to fetch the document from db
          const document: Document | undefined = await this.loadDocument(
            documentId,
            docType
          );
          if (!document) {
            this.openNotFound();
            this.setLoadingDocumentForFileType("LOADED");
            disposer1();
            return false;
          }

          this.setDocument(document);
          this.view.setView(
            ViewOptionsType.preview,
            path,
            `[Preview ${docType}]: ${documentId}`
          );
          disposer1();
          this.setLoadingDocumentForFileType("LOADED");
        }
      });

      const disposer2 = autorun(() => {
        if (!user.authorized && user.authenticated) {
          console.log("getting data from local storage [disposer2]");
          // user is not logged in

          // if we have specified the document id, but user is not logged in
          //let's check to see if this document is available in storage
          const document = this.fetchDocumentFromLocalStorage(documentId);
          // if no such document was found in storage we
          if (!document) {
            disposer2();
            this.setLoadingDocumentForFileType("LOADED");
            return false;
          } else {
            this.setDocument(document);
            this.view.setView(
              ViewOptionsType.preview,
              path,
              `[Preview ${docType}]: ${documentId}`
            );
            disposer2();
            this.setLoadingDocumentForFileType("LOADED");
            return true;
          }
        }
      });
    }
  };

  openFilesTab = async (tab: NavigationTabOptions) => {
    const didSetView = await this.setView(
      ViewOptionsType.finder,
      `/files/${tab}`,
      `Production One: ${tab}`
    );
    if (!didSetView) return;
    this.clearSelections();
    this.view.setView(
      ViewOptionsType.finder,
      `/files/${tab}`,
      `Production One: ${tab}`
    );
    ui.setSelectedNavigationTab(tab);
  };

  openHome = async () => {
    const didSetView = await this.setView(
      ViewOptionsType.home,
      "/",
      "Production One Studio"
    );
    if (!didSetView) return;
    this.clearSelections();
    this.view.setView(ViewOptionsType.home, "/", "Production One Studio");
  };

  openTerms = () => {
    this.clearSelections();
    this.view.setView(
      ViewOptionsType.terms,
      "/terms",
      "Terms: Production One Studio"
    );
  };

  openPressRelease = () => {
    this.clearSelections();
    this.view.setView(
      ViewOptionsType.pressRelease,
      "/pressrelease",
      "Press Release: Production One Studio"
    );
  };

  openPricing = () => {
    this.clearSelections();
    this.view.setView(
      ViewOptionsType.pricing,
      "/pricing",
      "Pricing: Production One Studio"
    );
  };

  openPrivacy = () => {
    this.clearSelections();
    this.view.setView(
      ViewOptionsType.privacy,
      "/privacy",
      "Privacy: Production One Studio"
    );
  };

  openDocumentsTab = async (viewId: ViewOptionsType) => {
    const path = `/document/${viewId}`;
    const didSetView = await this.setView(
      viewId as ViewOptionsType,
      path,
      viewId
    );
    if (!didSetView) return;
    this.setDocument(undefined);
    ui.setSelectedNavigationTab(NavigationTabOptions.recent);
    this.files.setFilterBy(viewId as unknown as DocumentTypeOptions);
    // Documents will be loaded once the autorun runs (user is authenticated)
  };

  openNewDocument = async (
    title: string,
    documentType: DocumentTypeOptions,
    defaultCharacterName?: string
  ) => {
    this.setLoadingDocumentForFileType("PENDING_DOCUMENT");
    // let's create a brand new document
    const document = await this.createNewDocument(
      documentType,
      title,
      defaultCharacterName
    );

    if (!document) {
      // TODO: show unable to create new document message
      return undefined;
    }

    const path = `/document/${documentType}/${document.id}`;
    this.setDocument(document);

    const viewId = documentType as unknown as ViewOptionsType;
    if (document) {
      this.view.setView(viewId, path, documentType);
    }
    this.setLoadingDocumentForFileType("LOADED");
  };

  loadDocument = async (
    documentId: string,
    documentType: string
  ): Promise<Document | undefined> => {
    const document: Document | undefined = await this.fetchDocumentId(
      documentId,
      documentType
    );
    if (!document) {
      return undefined;
    }
    document && Apis.markRead(document?.id);
    const characters = await this.fetchCharactersForDocumentId(documentId);
    document?.addCharacters(characters);
    return document;
  };

  // openDocument will return a boolean value that will indicate if the document
  // was opened successfully or not
  openDocument = async (
    documentType: DocumentTypeOptions,
    documentId: string
  ) => {
    this.setLoadingDocumentForFileType("PENDING_DOCUMENT");
    ui.resetSelections();

    const path = `/document/${documentType}/${documentId}`;
    const viewId = documentType as unknown as ViewOptionsType;

    if (this.document?.id === documentId) {
      // if document is already loaded, let's just set the view
      this.view.setView(viewId, path, `[${documentType}]: ${documentId}`);
      this.setLoadingDocumentForFileType("LOADED");
    } else {
      const disposer1 = autorun(async () => {
        if (user.authorized && user.authenticated) {
          console.log("getting data from db [disposer1]");
          // user is logged in!
          // user is authorized let's attempt to fetch the document from db
          const document: Document | undefined = await this.loadDocument(
            documentId,
            documentType
          );

          if (!document) {
            this.openNotFound();
            this.setLoadingDocumentForFileType("LOADED");
            disposer1();
            return false;
          }
          // ONLY ALLOW VIEWING DOCUMENT IF YOU ARE THE OWNER
          // if you are not the owner
          if (document?.ownerUid !== user.uid) {
            // UNLESS YOU ARE IN THE SHARED WITH LIST
            // and your name is not in the shared with list
            if (user.email && !document.sharedWith.includes(user.email)) {
              // redirect to unauthorized
              this.openUnauthorized(documentId);
              this.setLoadingDocumentForFileType("LOADED");
              disposer1();
              return false;
            }
          }

          this.setDocument(document);
          this.view.setView(viewId, path, `[${documentType}]: ${documentId}`);
          disposer1();
          this.setLoadingDocumentForFileType("LOADED");
        }
      });

      const disposer2 = autorun(() => {
        if (!user.authorized && user.authenticated) {
          console.log("getting data from local storage [disposer2]");
          // user is not logged in

          // if we have specified the document id, but user is not logged in
          //let's check to see if this document is available in storage
          const document = this.fetchDocumentFromLocalStorage(documentId);
          // if no such document was found in storage we
          if (!document) {
            disposer2();
            this.setLoadingDocumentForFileType("LOADED");
            return false;
          } else {
            this.setDocument(document);
            this.view.setView(viewId, path, `[${documentType}]: ${documentId}`);
            this.showWarningSignInToSave();
            disposer2();
            this.setLoadingDocumentForFileType("LOADED");
            return true;
          }
        }
      });
    }
  };

  openFinderView(tab: NavigationTabOptions) {
    this.view.setView(ViewOptionsType.finder, `/files/${tab}`, tab);
  }

  showWarningSignInToSave() {
    feedback.setFeedback({
      message: "unsaved.script.warning.label",
      variant: "warning",
    });
  }

  openCharacterPopup(title: string, character?: Character) {
    this.typeahead.reset();
    let characterId = character?.id;
    if (!characterId) {
      characterId = v4();
    }
    this.popups.set(PopupOptions.character, title);
    const isDefaultCharacter =
      this.document?.document?.defaultCharacter?.id === characterId;
    this.forms.init({
      id: FormOptions.character,
      fields: {
        isDefault: isDefaultCharacter,
        afraidOf: character?.afraidOf,
        color: character?.color || DEFAULT_CHARACTER_COLOR,
        id: characterId,
        voiceOver: character?.voiceOver || false,
        imageUrl: character?.imageUrl,
        motivation: character?.motivation,
        name: character?.name,
        notes: character?.notes,
        talent: {
          name: character?.talent?.name,
          email: character?.talent?.email,
        },
      },
      options: {},
      title,
      validations: {},
      required: ["name"],
    });
  }

  openSignIn(title: string) {
    this.popups.set(PopupOptions.signIn, title);
    this.forms.init({
      id: FormOptions.signIn,
      fields: {
        username: "",
        password: "",
      },
      options: {},
      title,
      validations: {},
      required: ["username", "password"],
    });
  }

  openSignUp(title: string) {
    this.popups.set(PopupOptions.signUp, title);
    this.forms.init({
      id: FormOptions.signUp,
      fields: {
        username: undefined,
        password: undefined,
        passwordConfirmation: undefined,
      },
      options: {},
      title,
      validations: {},
      required: ["username", "password", "passwordConfirmation"],
    });
  }

  openProfile = () => {
    this.clearSelections();
    this.view.setView(ViewOptionsType.userProfile, `/user`, "User Profile");

    const disposer = autorun(() => {
      if (this.user.authorized && this.user.authenticated) {
        this.forms.init({
          id: FormOptions.userProfile,
          fields: {
            language: this.user.language,
            displayName: this.user.displayName,
            photoUrl: this.user.photoURL || "",
            allowDiscovery: this.user.allowDiscovery,
          },
          options: {},
          title: "User Profile",
          validations: {},
          required: [],
        });
        disposer();
      }
    });
  };

  addCharacterToOpenDocument(character: CharacterType) {
    if (this.document) {
      this.document.addCharacter(character);
      this.document.sync_addCharacter(character);

      // update all dialogues with character
      if (
        this.document.type === DocumentTypeOptions.podcast ||
        this.document.type === DocumentTypeOptions.outline
      ) {
        // update all dialogue lines
        const podcast: Podcast = this.document?.document as Podcast;
        if (podcast) {
          Object.keys(podcast.dialogues).forEach((dialogueId: string) => {
            const dialogue = podcast.dialogues[dialogueId];
            if (dialogue.characterId === character.id) {
              dialogue.setEditedCharacter(character.name);
            }
          });
        }
      } else if (this.document.type === DocumentTypeOptions.screenplay) {
        // update all dialogue lines
        const screenplay: Screenplay = this.document?.document as Screenplay;
        if (screenplay) {
          Object.keys(screenplay.scenes).forEach((sceneId: string) => {
            const scene = screenplay.scenes[sceneId];
            Object.keys(scene.dialogues).forEach((key) => {
              const dialogue = scene.dialogues[key];
              if (dialogue.characterId === character.id) {
                dialogue.setEditedCharacter(character.name);
              }
            });
          });
        }
      }
      if (character.isDefault) {
        // add/update default character
        this.document?.sync_updateCharacter(character);
      }
    }
  }

  logout = () => {
    this.clearSelections();
    ui.setShowUserProfileMenu(false);
    this.files.clear();
    this.user.logout();
    this.openHome();
  };

  clearSelections = () => {
    this.setDocument(undefined);
    this.files.setFilterBy(undefined);
    ui.resetSelections();
  };

  openNotFound() {
    this.view.setView(ViewOptionsType.notFound, "/404", "Not Found");
  }

  openUnauthorized(documentId: string) {
    this.view.setView(
      ViewOptionsType.unauthorized,
      "/404",
      "Unauthorized Access"
    );
  }

  fetchPodcastDocument = async (
    documentId: string
  ): Promise<Document | undefined> => {
    const dcmt = await DB.fetch("files", documentId);

    const d = dcmt;
    if (!d) {
      // TODO: show document not found
    } else {
      const document = new Document({
        id: d.id,
        type: d.type,
        title: d.title,
        defaultCharacter: {
          color: d.defaultCharacterColor,
          id: d.defaultCharacterId,
          name: d.defaultCharacterName,
          isDefault: true,
        },
        sharedWith: d.sharedWith,
        dialogueOrder: d.dialogueOrder || [],
        starred: d.starred,
        createdBy: d.createdBy,
        lastUpdatedBy: d.lastUpdatedBy,
        seriesId: d.seriesId || NO_SERIES_ATTRIBUTION_ID,
        ownerUid: d.ownerUid,
      });

      // let's fetch the content for the podcast document
      const dialogues = await DB.fetch(`files/${documentId}/dialogues/`);
      dialogues?.forEach((doc: any) => {
        // add line to document
        const dialogue = doc.data();
        document.document?.addContent({
          type: dialogue.type,
          text: dialogue.text,
          id: dialogue.dialogueId,
          characterId: dialogue.characterId,
        });
      });
      return document;
    }
  };

  fetchOutlineDocument = async (documentId: string) => {
    const dcmt = await DB.fetch("files", documentId);

    const d = dcmt;
    if (!d) {
      // TODO: show document not found
    } else {
      const document = new Document({
        id: d.id,
        type: d.type,
        title: d.title,
        defaultCharacter: {
          color: d.defaultCharacterColor,
          id: d.defaultCharacterId,
          name: d.defaultCharacterName,
          isDefault: true,
        },
        dialogueOrder: d.dialogueOrder || [],
        starred: d.starred,
        createdBy: d.createdBy,
        lastUpdatedBy: d.lastUpdatedBy,
        sharedWith: d.sharedWith,
        seriesId: d.seriesId || NO_SERIES_ATTRIBUTION_ID,
        ownerUid: d.ownerUid,
      });

      // let's fetch the content for the podcast document
      const dialogues = await DB.fetch(`files/${documentId}/dialogues/`);
      dialogues?.forEach((doc: any) => {
        // add line to document
        const dialogue = doc.data();
        document.document?.addContent({
          type: dialogue.type,
          text: dialogue.text,
          id: dialogue.dialogueId,
          characterId: dialogue.characterId,
        });
      });
      return document;
    }
  };

  fetchScreenplayDocument = async (documentId: string) => {
    const dcmt = await DB.fetch("files", documentId);
    const d = dcmt;
    if (!d) {
      // TODO: show document not found
    } else {
      const document = new Document({
        id: d.id,
        type: d.type,
        title: d.title,
        defaultCharacter: {
          color: d.defaultCharacterColor,
          id: d.defaultCharacterId,
          name: d.defaultCharacterName,
          isDefault: true,
        },
        sceneOrder: d.sceneOrder || [],
        starred: d.starred,
        createdBy: d.createdBy,
        lastUpdatedBy: d.lastUpdatedBy,
        sharedWith: d.sharedWith,
        seriesId: d.seriesId || NO_SERIES_ATTRIBUTION_ID,
        ownerUid: d.ownerUid,
      });

      const screenplay: Screenplay = document.document as Screenplay;
      screenplay?.setSceneOrder(d.sceneOrder);

      // let's fetch the content for the podcast document
      const scenes = await DB.fetch(`files/${documentId}/scenes/`);
      scenes?.forEach(async (doc: any) => {
        // add line to document
        const scene = doc.data();
        screenplay.addScene(scene);

        const dialogues = await DB.fetch(
          `files/${documentId}/scenes/${scene.id}/dialogues/`
        );
        dialogues?.forEach((d: any) => {
          const line = d.data();
          screenplay.addContent({ ...line, id: line.dialogueId }, scene.id);
        });
      });
      return document;
    }
  };

  fetchDocumentFromLocalStorage(documentId: string): Document | undefined {
    const docId = localStorage.get(documentId);
    if (docId) {
      try {
        const documentObj = localApi.getCurrentSavedDocumentFromLocalStorage();
        if (documentObj) {
          const type = documentObj.type;
          if (type === DocumentTypeOptions.screenplay) {
            return convertContentObjectToScreenplay(documentObj);
          } else if (type === DocumentTypeOptions.podcast) {
            return convertContentObjectToPodcast(documentObj);
          } else if (type === DocumentTypeOptions.outline) {
            return convertContentObjectToOutline(documentObj);
          }
        }
      } catch (error) {
        console.warn({ error });
        return undefined;
      }
    }
    return undefined;
  }

  loadDocumentsForCurrentUser = async (
    tab: NavigationTabOptions,
    docType?: DocumentTypeOptions
  ) => {
    const limit = 10;
    const user = this.user;
    const userEmail = user.email;
    let querySnapshot;

    this.setLoadingDocumentForFileType("PENDING_FETCH_ALL");
    if (docType) {
      querySnapshot = await Apis.fetchAllDocumentsOfTypeForCurrentUser(
        docType,
        limit
      );
      this.setFiles(querySnapshot);
      this.setLoadingDocumentForFileType("LOADED");
      return;
    }
    const isRecent = tab === NavigationTabOptions.recent;
    const isStarred = tab === NavigationTabOptions.starred;
    const isAssigned = tab === NavigationTabOptions.assigned;
    const isShared = tab === NavigationTabOptions.shared;

    if (user && userEmail) {
      if (isRecent) {
        querySnapshot = await Apis.fetchAllDocumentsForCurrentUser(limit);
      } else if (isStarred) {
        querySnapshot = await Apis.fetchAllStarredDocumentsForCurrentUser(
          limit
        );
      } else if (isShared) {
        querySnapshot = await Apis.fetchAllSharedDocumentsForCurrentUser(limit);
      } else if (isAssigned) {
        querySnapshot = await Apis.fetchAllAssignedDocumentsForCurrentUser(
          limit
        );
      }
      this.setFiles(querySnapshot);
      this.setLoadingDocumentForFileType("LOADED");
    }
  };

  setFiles = (querySnapshot: any) => {
    querySnapshot?.forEach((doc: any) => {
      // doc.data() is never undefined for query doc snapshots
      const d = doc.data();
      const document = {
        id: d.id,
        type: d.type,
        title: d.title,
        updatedAt: d.updatedAt,
        createdAt: d.createdAt,
        defaultCharacter: {
          id: d.defaultCharacterId,
          name: d.defaultCharacterName,
          color: d.defaultCharacterColor,
          isDefault: true,
        },
        starred: d.starred,
        createdBy: d.createdBy,
        lastUpdatedBy: d.lastUpdatedBy,
        seriesId: d.seriesId,
        ownerUid: d.ownerUid,
      };
      this.files.add(new Document(document));
    });
  };

  addDocumentToUser = (documentId: string, type: DocumentTypeOptions) => {
    this.user.addToDocumentRef(documentId, type);
    const docRefsById = this.user.documentRefsByType;
    if (this.user.authorized && this.user.email) {
      DB.update("users", this.user.email, docRefsById);
    }
  };

  removeCurrentDocumentInLocalStorage = () => {
    const documentId = localStorage.get("documentId");
    try {
      documentId && localStorage.remove(JSON.parse(documentId));
      // remove document content
      localStorage.remove("characters");
      // remove reference to document id
      localStorage.remove("documentId");
      if (documentId) {
        localStorage.remove(JSON.parse(documentId));
      }
    } catch (error) {
      console.log("no metadata encountered", error);
    }
  };
  openCloudSavingWarningPopup = (popupTitle: string) => {
    this.popups.set(PopupOptions.cloudSavingWarning, popupTitle);
  };

  clearFiles = () => {
    this.files.setFiles(undefined);
  };

  canChangeView = (
    id: ViewOptionsType,
    path: string,
    title?: string
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      if (this.shouldConfirmChange()) {
        this.popups.set(PopupOptions.leaveDocumentWarning, "Warning", {
          callback: (msg: string) => {
            if (msg === "accepted") {
              resolve(true);
            } else {
              resolve(false);
            }
          },
        });
      } else {
        resolve(true);
      }
    });
  };

  shouldConfirmChange = (): boolean => {
    // Reasons for blocking change view
    // when theres currently a document saved in local storage
    if (
      this.view.id === ViewOptionsType.podcast ||
      this.view.id === ViewOptionsType.screenplay ||
      this.view.id === ViewOptionsType.outline
    ) {
      if (localStorage.get("documentId") !== undefined) {
        return true;
      }
    }
    return false;
  };

  setView = async (
    id: ViewOptionsType,
    path: string,
    title?: string
  ): Promise<boolean> => {
    if (await this.canChangeView(id, path, title)) {
      this.clearSelections();
      this.view.setView(id, path, title);
      return true;
    } else {
      return false;
    }
  };

  signIn = async (email: string, password: string): Promise<boolean> => {
    const authorized = await this.user.signIn(email, password);
    if (authorized) {
      await this.saveUnsavedDocuments();
      return true;
    }
    return false;
  };

  reconstructDocumentFromLocalStorage = (): Document | undefined => {
    const document: LocalStorageDocumentType =
      localApi.getCurrentSavedDocumentFromLocalStorage() as LocalStorageDocumentType;
    return this.reconstructDocumentFromObject(document);
  };

  reconstructDocumentFromObject = (
    docObject: LocalStorageDocumentType
  ): Document | undefined => {
    const document = docObject;
    try {
      if (document?.type === DocumentTypeOptions.podcast) {
        return convertContentObjectToPodcast(document);
      } else if (document?.type === DocumentTypeOptions.screenplay) {
        return convertContentObjectToScreenplay(document);
      } else if (document?.type === DocumentTypeOptions.outline) {
        return convertContentObjectToOutline(document);
      }
    } catch (error) {
      console.log("no metadata encountered", error);
      return undefined;
    }
    return undefined;
  };

  saveUnsavedDocuments = async () => {
    // const document = this.reconstructDocumentFromLocalStorage();
    const document = this.document;
    if (document) {
      await document.sync_document();
      // clear local storage
      this.removeCurrentDocumentInLocalStorage();
    }
  };

  openConvertStandaloneDocumentToSeriesPopup = (
    document: Document,
    popupTitle: string
  ) => {
    this.forms.init({
      id: FormOptions.convertStandaloneToSeries,
      fields: {
        seriesId: undefined,
        seriesName: undefined,
        documentId: document.id,
        seriesReference: undefined,
      },
      options: {},
      title: popupTitle,
      validations: {},
      required: ["seriesReference"],
    });
    this.popups.set(PopupOptions.convertStandaloneDocumentToSeries, popupTitle);
  };

  importFromFile = () => {
    const uploadInput = document.createElement("input");
    uploadInput.accept = ".postudio";
    uploadInput.type = "file";
    uploadInput.onchange = (e: any) => {
      this.handleImportedDocument(e);
    };
    uploadInput.click();
  };

  readFile = async (file: File) => {
    const fileData = await new Response(file).json();
    this.openDocumentFromData(fileData);
  };

  openDocumentFromData = (fileData: LocalStorageDocumentType) => {
    const path = `/document/${fileData.type}/${fileData.id}`;
    const viewId = fileData.type as unknown as ViewOptionsType;
    this.view.setView(viewId, path, `[${fileData.type}]: ${fileData.id}`);
    const document = this.reconstructDocumentFromObject(fileData);
    this.setDocument(document);
    // save loaded document current available DB
    this.loadDocumentIntoLocalStorage(fileData);
  };

  openDocumentFromDocument = (document: Document) => {
    const path = `/document/${document.type}/${document.id}`;
    const viewId = document.type as unknown as ViewOptionsType;
    this.view.setView(viewId, path, `[${document.type}]: ${document.id}`);

    this.setDocument(document);
    // save loaded document current available DB
    if (document.document) {
      const fileData = this.convertDocumentToObj(document);
      if (fileData) {
        this.loadDocumentIntoLocalStorage(fileData);
      }
    }
  };

  convertDocumentToObj = (
    document: Document
  ): LocalStorageDocumentType | undefined => {
    if (
      document.type === DocumentTypeOptions.podcast ||
      document.type === DocumentTypeOptions.outline
    ) {
      const doc: Podcast = document.document as Podcast;
      const docObj: LocalStorageDocumentType = {
        id: document.id,
        type: document.type,
        title: document.document?.title || "",
        characters: toJS(doc?.characters),
        dialogues: toJS(doc?.dialogues),
        createdAt: document.createdAt,
        updatedAt: document.updatedAt,
        createdBy: document.createdBy,
        lastUpdatedBy: document.lastUpdatedBy,
        defaultCharacter: doc?.defaultCharacter,
        dialogueOrder: doc?.dialogueOrder,
        starred: false,
        sharedWith: document.sharedWith,
        assignedTo: document.assignedTo,
        seriesId: doc.seriesId,
        ownerUid: document.ownerUid,
      };

      return docObj;
    }

    if (document.type === DocumentTypeOptions.screenplay) {
      const doc: Screenplay = document.document as Screenplay;
      const docObj: LocalStorageDocumentType = {
        id: document.id,
        type: document.type,
        title: document.document?.title || "",
        characters: toJS(doc?.characters),
        scenes: toJS(doc?.scenes),
        createdAt: document.createdAt,
        updatedAt: document.updatedAt,
        createdBy: document.createdBy,
        lastUpdatedBy: document.lastUpdatedBy,
        defaultCharacter: doc?.defaultCharacter,
        sceneOrder: doc?.sceneOrder,
        starred: false,
        sharedWith: document.sharedWith,
        assignedTo: document.assignedTo,
        seriesId: doc.seriesId,
        ownerUid: document.ownerUid,
      };
      return docObj;
    }

    return undefined;
  };

  loadDocumentIntoLocalStorage = (document: LocalStorageDocumentType) => {
    localStorage.set("documentId", document.id);
    localStorage.set(document.id, document);
  };

  handleImportedDocument = (e: any) => {
    e.preventDefault();

    const files = get(e, "dataTransfer.files") || get(e, "target.files");
    if (!files) {
      return;
    } else {
      const file = files[0];
      this.readFile(file);
    }
  };

  convertStandaloneDocumentToSeries = async (
    seriesName: string,
    documentId: string,
    seriesId?: string
  ): Promise<string> => {
    if (!seriesId) {
      const folderId = v4();
      // user is creating a new series/folder
      await this.sync_addNewFolder(seriesName, folderId, [documentId]);
      this.document?.document?.setSeriesId(folderId);
      await this.document?.sync_updateDocumentMetadata();
      return folderId;
    }

    // user is assigning the standalone to a current series
    const folderId = seriesId;
    this.document?.document?.setSeriesId(folderId);
    await this.document?.sync_updateDocumentMetadata();
    return folderId;
  };

  sync_addNewFolder = (
    folderName: string,
    folderId: string,
    documentIds: string[]
  ) => {
    return Apis.createFolder(folderName, folderId, documentIds);
  };

  deleteSelectedDialogues = () => {
    if (this.document?.type === DocumentTypeOptions.podcast) {
      const podcast = this.document?.document as Podcast;
      Object.keys(ui.selectedIds).forEach((dialogueId) => {
        if (podcast) {
          if (podcast.dialogues[dialogueId]) {
            this.document?.removeDialogue(dialogueId);
          }
        }
      });
    }
  };

  deleteSelectedDialoguesInSelectedScene = () => {
    if (this.document?.type === DocumentTypeOptions.screenplay) {
      const screenplay = this.document?.document as Screenplay;
      Object.keys(ui.selectedIds).forEach((selectedId) => {
        // dialogues in scenes are stored using the following convention
        // {sceneId}scene{DialogueId}
        // so we will split the id using the separator scene so we can get
        // the scene id and dialogue id
        const ids = selectedId.split("scene");
        if (ids.length > 1) {
          const sceneId = ids[0];
          const dialogueId = ids[1];
          if (screenplay) {
            const scene = sceneId && screenplay.scenes[sceneId];
            if (scene) {
              dialogueId && scene.removeLine(dialogueId);
            }
          }
        }
      });
    }
  };

  deleteDocument = async (documentId: string): Promise<boolean> => {
    return Document.sync_deleteDocument(documentId);
  };

  sync_addDocument = async (documentId: string, documentData: any) => {
    // TODO: add document data type

    if (this.user && this.user.authorized && this.user.authenticated) {
      // add document (file) to firebase database
      await DB.set("files", documentId, documentData);
    } else {
      // add document to storage
      localStorage.set(documentId, documentData);
    }
  };
}

const convertContentObjectToOutline = (
  documentObj: LocalStorageDocumentType
): Document | undefined => {
  const p = documentObj;
  let defaultCharacter;
  if (p.defaultCharacter) {
    defaultCharacter = {
      color: p.defaultCharacter?.color,
      id: p.defaultCharacter?.id,
      name: p.defaultCharacter?.name,
      isDefault: true,
    };
  }
  const document = new Document({
    id: p.id,
    type: DocumentTypeOptions.outline,
    title: p.title || "Untitled",
    defaultCharacter,
    dialogueOrder: p.dialogueOrder,
    starred: p.starred,
    createdBy: "unknown",
    lastUpdatedBy: "unknown",
    seriesId: p.seriesId || NO_SERIES_ATTRIBUTION_ID,
    ownerUid: p.ownerUid || "unknown",
  });

  const outline = document.document;

  const characters = documentObj.characters;
  if (characters) {
    Object.keys(characters).forEach((key: string) => {
      //@ts-ignore
      const character = characters[key];
      outline?.addCharacter(character);
    });
  }

  const dialogues = documentObj.dialogues;
  if (dialogues) {
    Object.keys(dialogues).forEach((key: string) => {
      //@ts-ignore
      const dialogue = dialogues[key];
      outline?.addContent({
        type: dialogue.type as DialogueTypeOptions,
        text: dialogue.text,
        id: dialogue.id,
        characterId: dialogue.characterId,
      });
    });
  }

  return document;
};

const convertContentObjectToPodcast = (
  documentObj: LocalStorageDocumentType
): Document | undefined => {
  const p = documentObj;
  let defaultCharacter;
  if (p.defaultCharacter) {
    defaultCharacter = {
      color: p.defaultCharacter?.color,
      id: p.defaultCharacter?.id,
      name: p.defaultCharacter?.name,
      isDefault: true,
    };
  }
  const document = new Document({
    id: p.id,
    type: DocumentTypeOptions.podcast,
    title: p.title || "Untitled",
    defaultCharacter,
    dialogueOrder: p.dialogueOrder,
    starred: p.starred,
    createdBy: "unknown",
    lastUpdatedBy: "unknown",
    seriesId: p.seriesId || NO_SERIES_ATTRIBUTION_ID,
    ownerUid: p.ownerUid || "unknown",
  });

  const podcast = document.document;

  const characters = documentObj.characters;
  if (characters) {
    Object.keys(characters).forEach((key: string) => {
      //@ts-ignore
      const character = characters[key];
      podcast?.addCharacter(character);
    });
  }

  const dialogues = documentObj.dialogues;
  if (dialogues) {
    Object.keys(dialogues).forEach((key: string) => {
      //@ts-ignore
      const dialogue = dialogues[key];
      podcast?.addContent({
        type: dialogue.type as DialogueTypeOptions,
        text: dialogue.text,
        id: dialogue.id,
        characterId: dialogue.characterId,
      });
    });
  }

  return document;
};

const convertContentObjectToScreenplay = (
  documentObj: LocalStorageDocumentType
): Document => {
  const s = documentObj;
  let defaultCharacter;
  if (s.defaultCharacter) {
    defaultCharacter = {
      color: s.defaultCharacter?.color,
      id: s.defaultCharacter?.id,
      name: s.defaultCharacter?.name,
      isDefault: true,
    };
  }
  const document = new Document({
    id: s.id,
    type: DocumentTypeOptions.screenplay,
    title: s.title || "Untitled",
    defaultCharacter,
    sceneOrder: s.sceneOrder || [],
    starred: s.starred,
    createdBy: "unknown",
    lastUpdatedBy: "unknown",
    seriesId: s.seriesId || NO_SERIES_ATTRIBUTION_ID,
    ownerUid: s.ownerUid || "unknown",
  });

  if (s.characters) {
    Object.keys(s.characters).forEach((characterId: string) => {
      if (s.characters) {
        let talent;
        const character = s.characters[characterId];
        if (character.talent) {
          talent = new Talent({
            name: character.talent.name,
            email: character.talent.email,
          });
        }
        character.talent = talent;
        document.addCharacter(character);
      }
    });
  }

  const screenplay: Screenplay = document.document as Screenplay;
  screenplay?.setSceneOrder(s.sceneOrder || []);
  const scenes = documentObj.scenes;

  if (scenes) {
    Object.keys(scenes).forEach(async (sceneId: any) => {
      const sceneObj = scenes[sceneId];

      const scene = new Scene({
        id: sceneObj.id,
        subheader: sceneObj.subheader,
        place: sceneObj.place as ScenePlaceOptions,
        location: sceneObj.location,
        timeOfDay: sceneObj.timeOfDay as SceneTimeOfDayOptions,
        action: sceneObj.action, // Action tells you what is happening in the scene
        dialogues: {}, // dialogue indicates what each character is saying
        // You don’t need to include a transition between scenes, as it’s understood that one scene naturally flows into the next.
        // However, sometimes you might want to use a specific transition as a stylistic choice, such as a SMASH CUT or FADE TO BLACK.
        // Final Draft has many different transition options, and will format the transition flush right according to industry standards.
        transition: sceneObj.transition,
        createdAt: sceneObj.createdAt,
        dialogueOrder: sceneObj.dialogueOrder,
      });

      screenplay.addScene(scene);

      const dialogues = sceneObj.dialogues;
      if (dialogues) {
        Object.keys(dialogues).forEach((dialogueId: any) => {
          const dialogue = dialogues[dialogueId];

          screenplay.addContent(
            {
              text: dialogue.text,
              characterId: dialogue.characterId,
              type: dialogue.type as DialogueTypeOptions,
              characterName: dialogue.characterName,
              id: dialogue.id,
              characterExtension: dialogue.characterExtension,
              parentheticals: dialogue.parentheticals,
            },
            sceneId
          );
        });
      }
    });
  }

  return document;
};
