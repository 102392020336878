import { makeAutoObservable, toJS } from "mobx";
import {
  Character,
  CharacterType,
  DefaultCharacterType,
  DEFAULT_CHARACTER_COLOR,
} from "./Character";
import { Podcast } from "./Podcast";
import Screenplay, { ScriptType } from "./Screenplay";
import { localStorage } from "../LocalStorage";
import User, { user } from "../User";
import Apis from "../Api";
import { Dialogue, DialogueType, DialogueTypeOptions } from "./Dialogue";
import Scene, { SceneType } from "./Scene";
import { v4 } from "uuid";
import { feedback } from "../Feedback";
import { dragSelect } from "../..";
import { sortByField, SortByOrderOptions } from "../utils/sort";
import { ui } from "../Ui";
import { localApi } from "../LocalApi";
import { Outline } from "./Outline";
import OnlinePresence from "../OnlinePresence";
import { getTotalPagesInDocument } from "../pageStats";

export interface DocumentMetadataType {
  assignedTo?: string[];
  createdAt?: number;
  createdBy?: string;
  defaultCharacterColor?: string;
  defaultCharacterId?: string;
  defaultCharacterName?: string;
  dialogueOrder?: string[];
  id?: string;
  lastUpdatedBy?: string;
  sceneOrder?: string[];
  seriesId?: string;
  sharedWith?: string[];
  title?: string;
  type?: DocumentTypeOptions;
  updatedAt?: number;
  starred?: boolean;
  scriptType?: ScriptType;
}
export function shouldDisableTextEdits(
  user: User,
  document: Document
): boolean {
  return (
    (user.authorized && user.email !== document.createdBy) ||
    (!user.authorized && user.authenticated && document.createdBy !== "unknown")
  );
}
export const NO_SERIES_ATTRIBUTION_ID = "standalone";

export enum DocumentTypeOptions {
  screenplay = "screenplay",
  podcast = "podcast",
  outline = "outline",
  import = "import",
}

export interface DocumentType {
  id: string;
  type: DocumentTypeOptions;
  title: string;
  document?: Screenplay | Podcast | Outline;
  createdAt?: number;
  updatedAt?: number;
  createdBy: string;
  lastUpdatedBy: string;
  defaultCharacter?: DefaultCharacterType;
  dialogueOrder?: string[];
  sceneOrder?: string[];
  starred: boolean;
  sharedWith?: string[];
  assignedTo?: string[];
  seriesId: string;
  ownerUid: string;
  scriptType?: ScriptType;
}

export class Document {
  ownerUid: string;
  createdAt: number;
  updatedAt: number;
  document?: Screenplay | Podcast | Outline;
  id: string;
  type: DocumentTypeOptions;
  sharedWith: string[];
  assignedTo: string[];
  createdBy: string;
  lastUpdatedBy: string;
  onlinePresence: OnlinePresence;

  constructor(document: DocumentType) {
    this.id = document.id;
    this.ownerUid = document.ownerUid;
    this.type = document.type;
    this.createdAt = document.createdAt || new Date().getTime();
    this.updatedAt = document.updatedAt || new Date().getTime();
    this.createdBy = document.createdBy;
    this.lastUpdatedBy = document.lastUpdatedBy;
    this.sharedWith = document.sharedWith || [];
    this.assignedTo = document.assignedTo || [];
    this.onlinePresence = new OnlinePresence(this.id);

    if (!document.document) {
      if (this.type === DocumentTypeOptions.screenplay) {
        this.document = new Screenplay({
          documentId: this.id,
          title: document.title,
          defaultCharacter: document.defaultCharacter,
          starred: document.starred,
          seriesId: document.seriesId,
          scriptType: document.scriptType || "spec",
        });
      } else if (this.type === DocumentTypeOptions.podcast) {
        this.document = new Podcast({
          documentId: this.id,
          title: document.title,
          defaultCharacter: document.defaultCharacter,
          dialogueOrder: document.dialogueOrder || [],
          starred: document.starred,
          seriesId: document.seriesId,
        });
      } else if (this.type === DocumentTypeOptions.outline) {
        this.document = new Outline({
          documentId: this.id,
          title: document.title,
          defaultCharacter: document.defaultCharacter,
          dialogueOrder: document.dialogueOrder || [],
          starred: document.starred,
          seriesId: document.seriesId,
        });
      }
    } else {
      this.document = document.document;
    }

    makeAutoObservable(this);
  }

  get scenesList(): string[] {
    let list: string[] = [];
    const isScreenplay = this.type === DocumentTypeOptions.screenplay;
    const screenplay = this.document as Screenplay;
    if (isScreenplay) {
      screenplay.sceneOrder.forEach((sceneId) => {
        const scene = screenplay.scenes[sceneId];
        if (scene) {
          if (!scene.excludeFromSceneCount) {
            list = [...list, sceneId];
          }
        }
      });
    }
    return list;
  }

  get sceneCount(): number {
    let count = 0;
    if (this.type === DocumentTypeOptions.screenplay) {
      const screenplay = this.document as Screenplay;
      Object.keys(screenplay.scenes).forEach((sceneId) => {
        if (!screenplay.scenes[sceneId].excludeFromSceneCount) {
          count = count + 1;
        }
      });
    }
    return count;
  }

  get pages() {
    return getTotalPagesInDocument(this);
  }

  addCharacter(character: CharacterType): string | undefined {
    return this.document?.addCharacter(character);
  }

  fromValue(): DocumentMetadataType {
    const value: DocumentMetadataType = {
      assignedTo: this.assignedTo,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      id: this.id,
      lastUpdatedBy: this.lastUpdatedBy,
      sharedWith: this.sharedWith,
      title: this.document?.title || "",
      type: this.type,
      defaultCharacterColor: this.document?.defaultCharacter?.color || "",
      defaultCharacterId: this.document?.defaultCharacter?.id || "",
      defaultCharacterName: this.document?.defaultCharacter?.name || "",

      seriesId: this.document?.seriesId,
      starred: this.document?.starred,
    };
    if (
      this.type === DocumentTypeOptions.podcast ||
      this.type === DocumentTypeOptions.outline
    ) {
      const doc = this.document as Podcast;
      value.dialogueOrder = doc?.dialogueOrder;
    } else if (this.type === DocumentTypeOptions.screenplay) {
      const doc = this.document as Screenplay;
      value.sceneOrder = doc?.sceneOrder;
    }
    return value;
  }

  raw(): object {
    return toJS(this);
  }

  rawDocument(): object {
    if (this.document) {
      return toJS(this.document);
    }
    return {};
  }

  saveContentsToLocalStorage() {
    localStorage.set("documentId", this.id);

    if (this.document) {
      const metadata = this.document.getDocumentMetadata();
      const document = {
        ...metadata,
        ...this.rawDocument(),
      };
      localStorage.set(this.id, document);
    }
  }

  addCharacters = (characters: { [characterId: string]: Character }) => {
    this.document?.setCharacters(characters);
  };

  addNewCharacter = async (
    characterName: string
  ): Promise<Character | undefined> => {
    const character: Character | undefined =
      this.document?.addNewCharacter(characterName);
    if (character) {
      await this.sync_addCharacter(character);
      return character;
    }
    // character was not added, let's return false
    return new Promise((accept, reject) => {
      accept(undefined);
    });
  };

  addNewLine = async (
    type: DialogueTypeOptions,
    text: string,
    sceneId?: string,
    atIndex?: number
  ): Promise<Dialogue | undefined> => {
    if (this.document) {
      // default content for insert
      let content: DialogueType = { type, text };

      if (type === "dialogue") {
        content = {
          type,
          text,
          characterId: undefined,
          characterName: undefined,
        };
      }
      const dialogue: Dialogue | undefined = this.document?.addContent(
        content,
        sceneId
      );

      if (sceneId && dialogue) {
        // when adding a new line to a scene we have to add to dialogue order
        const screenplay = this.document as Screenplay;
        const scene = screenplay?.scenes[sceneId];
        scene.insertNewDialogueInDialogueOrder(dialogue.id, atIndex);
        this.sync_updateScene(this.id, scene.id, scene);
      }

      if (!dialogue) {
        return undefined;
      }

      await this.sync_addDialogue(this.id, dialogue, sceneId);

      if (this.type === DocumentTypeOptions.podcast) {
        const podcast = this.document as Podcast;
        podcast.insertNewDialogueInDialogueOrder(dialogue.id, atIndex);
        await this.sync_updateDialogueOrder(toJS(podcast.dialogueOrder));
      }

      if (this.type === DocumentTypeOptions.outline) {
        const outline = this.document as Outline;
        outline.insertNewDialogueInDialogueOrder(dialogue.id, atIndex);
        await this.sync_updateDialogueOrder(toJS(outline.dialogueOrder));
      }
      return dialogue;
    }

    return undefined;
  };

  deleteAllLines = (sceneId?: string) => {
    if (this.type === DocumentTypeOptions.podcast) {
      const document = this.document as Podcast;

      Object.keys(document.dialogues).forEach((dialogueId) => {
        this.sync_removeDialogue(dialogueId);
      });
      document.deleteAllLines();
    }

    if (this.type === DocumentTypeOptions.screenplay && sceneId) {
      const document = this.document as Screenplay;
      const scene = document.scenes[sceneId];
      Object.keys(scene.dialogues).forEach((dialogueId) => {
        this.sync_removeDialogue(dialogueId, sceneId);
      });

      document.deleteAllDialoguesForScene(sceneId);
    }
  };
  handleListItemSwap = async (
    currentItemIndex?: number,
    swapWithListItemIndex?: number,
    swappingScenes?: boolean,
    sceneId?: string
  ) => {
    dragSelect.clearDraggingData();
    const i = currentItemIndex;
    const j = swapWithListItemIndex;
    if (i === undefined || j === undefined) {
      return false; // don't swap
    }

    if (this.type === DocumentTypeOptions.podcast) {
      // swapping dialogues
      const podcast: Podcast = this.document as Podcast;
      const dialogueOrder = podcast.dialogueOrder.slice();
      [dialogueOrder[i], dialogueOrder[j]] = [
        dialogueOrder[j],
        dialogueOrder[i],
      ];
      podcast.setDialogueOrder(dialogueOrder);
      await this.sync_updateDialogueOrder(podcast.dialogueOrder);
    } else if (this.type === DocumentTypeOptions.screenplay) {
      if (swappingScenes) {
        const screenplay: Screenplay = this.document as Screenplay;
        const sceneOrder = screenplay.sceneOrder.slice();
        [sceneOrder[i], sceneOrder[j]] = [sceneOrder[j], sceneOrder[i]];
        screenplay.setSceneOrder(sceneOrder);
        await this.sync_updateSceneOrder(sceneOrder);
      } else {
        // swapping dialogues in scene
        if (sceneId) {
          const screenplay: Screenplay = this.document as Screenplay;
          const scene = screenplay.scenes[sceneId];
          if (scene) {
            const dialogueOrder =
              screenplay.scenes[sceneId].dialogueOrder.slice();
            [dialogueOrder[i], dialogueOrder[j]] = [
              dialogueOrder[j],
              dialogueOrder[i],
            ];
            scene.setDialogueOrder(dialogueOrder);
            await this.sync_updateDialogueOrder(dialogueOrder);
          }
        }
      }
    } else if (this.type === DocumentTypeOptions.outline) {
      // swapping dialogues
      const outline: Outline = this.document as Outline;
      const dialogueOrder = outline.dialogueOrder.slice();
      [dialogueOrder[i], dialogueOrder[j]] = [
        dialogueOrder[j],
        dialogueOrder[i],
      ];
      outline.setDialogueOrder(dialogueOrder);
      await this.sync_updateDialogueOrder(outline.dialogueOrder);
    }
  };

  removeDialogue = async (dialogueId: string, sceneId?: string) => {
    if (this.type === DocumentTypeOptions.podcast) {
      // let's remove line from podcast
      const podcast = this.document as Podcast;
      // remove from order first
      podcast.removeFromDialogueOrder(dialogueId);
      await this.sync_updateDialogueOrder(toJS(podcast.dialogueOrder));
      // remove dialogue from podcast
      await this.removeDialogueFromPodcast(dialogueId);
    } else if (this.type === DocumentTypeOptions.screenplay && sceneId) {
      // let's remove line from scene
      const screenplay = this.document as Screenplay;
      const scene = screenplay.scenes[sceneId];
      if (scene) {
        // remove from order first
        scene.removeFromDialogueOrder(dialogueId);
        await this.sync_updateSceneDialogueOrder(sceneId, scene.dialogueOrder);
        // let's remove line from scene in screenplay
        await this.removeDialogueFromSceneInScreenplay(dialogueId, sceneId);
      }
    } else if (this.type === DocumentTypeOptions.outline) {
      const outline: Outline = this.document as Outline;
      // remove from order first
      outline.removeFromDialogueOrder(dialogueId);
      await this.sync_updateDialogueOrder(toJS(outline.dialogueOrder));
      // remove dialogue from podcast
      await this.removeDialogueFromOutline(dialogueId);
    }
  };

  removeDialogueFromOutline = (dialogueId: string) => {
    // let's remove line from podcast
    const outline: Outline = this.document as Outline;
    if (outline.dialogues[dialogueId]) {
      delete outline.dialogues[dialogueId];
      this.sync_removeDialogue(dialogueId);
    } else {
      return undefined;
    }
  };

  removeDialogueFromPodcast = (dialogueId: string) => {
    // let's remove line from podcast
    const document: Podcast = this.document as Podcast;
    if (document.dialogues[dialogueId]) {
      delete document.dialogues[dialogueId];
      this.sync_removeDialogue(dialogueId);
    } else {
      return undefined;
    }
  };

  removeDialogueFromSceneInScreenplay = async (
    dialogueId: string,
    sceneId: string
  ) => {
    // let's remove line from podcast
    const document: Screenplay = this.document as Screenplay;
    const scene = document.scenes[sceneId];
    if (scene) {
      scene.removeLine(dialogueId);
      await this.sync_removeDialogue(dialogueId, sceneId);
    } else {
      return undefined;
    }
  };

  deleteCharacter = async (characterId: string) => {
    if (this.document?.defaultCharacter?.id === characterId) {
      // don't allow to delete default character
      feedback.setFeedback({
        message: `Error deleting default character. Set another character as default to be able to delete this character.`,
        variant: "error",
      });
      return false;
    }
    if (this.type === DocumentTypeOptions.podcast) {
      const podcast: Podcast = this.document as Podcast;
      const isAssigned = podcast.isCharacterAssignedToDialogue(characterId);
      if (!isAssigned) {
        delete this.document?.characters[characterId];
        await this.sync_removeCharacter(
          DocumentTypeOptions.podcast,
          this.id,
          characterId
        );
        return true;
      } else {
        const characterName = this.document?.characters[characterId]?.name;
        // display feedback that cannot delete
        feedback.setFeedback({
          message: `Error deleting character. Your document contains dialogues assigned to this character. Make sure ${characterName} is not assigned to any dialogues before attempting to delete it.`,
          variant: "error",
        });
        return false;
      }
    }

    if (this.type === DocumentTypeOptions.screenplay) {
      const screenplay: Screenplay = this.document as Screenplay;
      const isAssigned = screenplay.isCharacterAssignedToDialogue(characterId);
      if (!isAssigned) {
        delete this.document?.characters[characterId];
        await this.sync_removeCharacter(
          DocumentTypeOptions.screenplay,
          this.id,
          characterId
        );
        return true;
      } else {
        const characterName = this.document?.characters[characterId]?.name;
        // display feedback that cannot delete
        feedback.setFeedback({
          message: `Error deleting character. Your document contains dialogues assigned to this character. Make sure ${characterName} is not assigned to any dialogues before attempting to delete it.`,
          variant: "error",
        });
        return false;
      }
    }
  };

  assignDialogueToCharacter = (
    dialogueId: string,
    characterId: string,
    sceneId?: string
  ) => {};

  updateTitle = (title: string) => {
    this.document?.setTitle(title);
    this.sync_updateDocumentTitle(this.id, title);
  };

  setSharedWith = (sharedWith: string[]) => {
    this.sharedWith = sharedWith;
  };

  setAssignedTo = (assignedTo: string[]) => {
    this.assignedTo = assignedTo;
  };

  addSharedWith = (email: string) => {
    const sharedWith = new Set([...this.sharedWith, email]);
    this.setSharedWith(Array.from(sharedWith));
  };

  addAssignedTo = (email: string) => {
    const assignments = new Set([...this.assignedTo, email]);
    this.setAssignedTo(Array.from(assignments));
  };

  setDialogueOrder = async (order: string[]) => {
    if (this.document) {
      if (
        this.type === DocumentTypeOptions.podcast ||
        this.type === DocumentTypeOptions.outline
      ) {
        const podcast: Podcast = this.document as Podcast;
        podcast.setDialogueOrder(order);
        this.sync_updateDialogueOrder(order);
      }
    }
  };

  setSceneOrder = async (order: string[]) => {
    if (this.document) {
      if (this.type === DocumentTypeOptions.screenplay) {
        const screenplay: Screenplay = this.document as Screenplay;
        screenplay.setSceneOrder(order);
        this.sync_updateSceneOrder(order);
      }
    }
  };

  setStarred = (value: boolean) => {
    this.document?.setStarred(value);
    this.sync_updateDocumentMetadata();
  };

  showAllScenes = (value: boolean) => {
    const doc = this.document as Screenplay;
    doc.sceneOrder.forEach((sceneId) => {
      ui.setShowScene(sceneId, value);
    });
    ui.setHideAllScenes(!value);
  };

  autoGenerateScreenplaySceneOrderForMissingSceneOrder = async () => {
    const document: Screenplay = this.document as Screenplay;
    const scenes = Object.keys(document.scenes)
      .map((key) => document.scenes[key])
      .sort((a, b) =>
        sortByField(a, b, "createdAt", SortByOrderOptions.ascending)
      );
    const sceneOrder = scenes.map((scene) => scene.id);
    document.setSceneOrder(sceneOrder);
    await this.sync_updateSceneOrder(sceneOrder);
  };

  sync_updateDialogueOrder = (order: string[]) => {
    if (user.authenticated && user.authorized) {
      return Apis.updateDocumentMapping(this.type, this.id, order);
    } else {
      localApi.updateDialogueOrder(this.id, order);
    }
  };

  sync_updateSceneOrder = (order: string[]) => {
    if (user.authenticated && user.authorized) {
      return Apis.updateDocumentMapping(this.type, this.id, order);
    } else {
      localApi.updateSceneOrder(order);
    }
  };

  addNewScene = async (scene: SceneType): Promise<Scene> => {
    const document: Screenplay = this.document as Screenplay;
    const s = { ...scene, id: scene.id || v4() };
    const newScene = document.addNewScene(s);

    await this.sync_addDocumentMetadata();
    await this.sync_updateSceneOrder(document.sceneOrder);
    await this.sync_addScene(this.id, s);
    return newScene;
  };

  removeScene = async (sceneId: string) => {
    const document: Screenplay = this.document as Screenplay;

    // remove all dialogues in this scene
    await this.deleteAllLines(sceneId);

    // remove from scene order
    document.removeFromSceneOrder(sceneId);
    await this.sync_updateSceneOrder(document.sceneOrder);

    // remove scene from document
    if (document.scenes[sceneId]) {
      delete document.scenes[sceneId];
    }

    // remove scene
    await this.sync_removeScene(sceneId);
  };

  sync_removeScene = async (sceneId: string) => {
    if (this.document) {
      // remove scene
      await Apis.deleteSceneInScreenplay(this.id, sceneId);
    }
  };

  sync_removeDialogue = async (dialogueId: string, sceneId?: string) => {
    if (this.document) {
      if (this.type === DocumentTypeOptions.outline) {
        Apis.deleteOutlineDialogue(this.id, dialogueId);
      } else if (this.type === DocumentTypeOptions.podcast) {
        Apis.deletePodcastDialogue(this.id, dialogueId);
      } else if (this.type === DocumentTypeOptions.screenplay && sceneId) {
        Apis.deleteSceneDialogueInScreenplay(this.id, sceneId, dialogueId);
      }
    }
  };

  sync_document = async (): Promise<any> => {
    if (user.authorized) {
      const documentMetadata = this.fromValue();
      const documentId = this.id;
      let document;
      let dialogueOrder;
      let sceneOrder;
      if (this.type === DocumentTypeOptions.outline) {
        document = this.document as Outline;
        dialogueOrder = document.dialogueOrder;
        const metadataResult = await Apis.addDocumentMetadata(documentId, {
          ...documentMetadata,
          dialogueOrder,
        });
        const contentResult = await this.document?.sync_addAllContent();
        const characters = await this.document?.sync_allCharacters();
        return { metadataResult, contentResult, characters };
      } else if (this.type === DocumentTypeOptions.podcast) {
        document = this.document as Podcast;
        dialogueOrder = document.dialogueOrder;
        const metadataResult = await Apis.addDocumentMetadata(documentId, {
          ...documentMetadata,
          dialogueOrder,
        });
        const contentResult = await this.document?.sync_addAllContent();
        const characters = await this.document?.sync_allCharacters();
        return { metadataResult, contentResult, characters };
      } else if (this.type === DocumentTypeOptions.screenplay) {
        document = this.document as Screenplay;
        sceneOrder = document.sceneOrder;
        const metadataResult = await Apis.addDocumentMetadata(documentId, {
          ...documentMetadata,
          sceneOrder,
        });
        const contentResult = await this.document?.sync_addAllContent();
        const characters = await this.document?.sync_allCharacters();
        return { metadataResult, contentResult, characters };
      }
    } else {
      alert("TODO");
    }
    return undefined;
  };

  sync_removeCharacter = async (
    documentType: DocumentTypeOptions,
    documentId: string,
    characterId: string
  ) => {
    if (user.authorized && user.authenticated) {
      return Apis.deleteCharacter(documentType, documentId, characterId);
    } else {
      return localApi.deleteCharacter(characterId);
    }
  };

  sync_addScene = async (documentId: string, scene: SceneType) => {
    if (user.authorized && user.authenticated) {
      await Apis.addScreenplayScene(documentId, scene);
    } else {
      localApi.addScreenplayScene(documentId, scene);
    }
  };

  sync_updateScene = async (
    documentId: string,
    sceneId: string,
    scene: SceneType
  ) => {
    if (user.authorized) {
      await Apis.updateScreenplayScene(documentId, sceneId, scene);
    } else {
      localApi.updateScreenplaySceneData(documentId, sceneId, scene);
    }
  };

  sync_updateSceneDialogueOrder = async (
    sceneId: string,
    dialogueOrder: string[]
  ) => {
    if (user.authorized) {
      await Apis.updateScreenplaySceneData(this.id, sceneId, { dialogueOrder });
    } else {
      localApi.updateScreenplaySceneData(this.id, sceneId, { dialogueOrder });
    }
  };

  sync_updateDialogue = (
    documentId: string,
    dialogue: Dialogue | DialogueType,
    sceneId?: string
  ) => {
    if (user.authorized) {
      if (this.type === DocumentTypeOptions.podcast) {
        Apis.updateDialogue(DocumentTypeOptions.podcast, documentId, dialogue);
      }

      if (this.type === DocumentTypeOptions.outline) {
        Apis.updateDialogue(DocumentTypeOptions.outline, documentId, dialogue);
      }

      if (this.type === DocumentTypeOptions.screenplay && sceneId) {
        Apis.updateSceneDialogue(documentId, sceneId, dialogue);
      }
    } else {
      localApi.addDialogue(this.type, documentId, dialogue, sceneId);
    }
  };

  sync_addDialogue = (
    documentId: string,
    dialogue: Dialogue | DialogueType,
    sceneId?: string
  ) => {
    if (user.authorized && user.authenticated) {
      // Add to DB
      if (this.type === DocumentTypeOptions.podcast) {
        Apis.addDialogue(DocumentTypeOptions.podcast, documentId, dialogue);
      }

      if (this.type === DocumentTypeOptions.outline) {
        Apis.addDialogue(DocumentTypeOptions.outline, documentId, dialogue);
      }

      if (this.type === DocumentTypeOptions.screenplay && sceneId) {
        Apis.addSceneDialogue(documentId, sceneId, dialogue);
      }
    } else {
      // Add to local storage
      localApi.addDialogue(this.type, documentId, dialogue, sceneId);
    }
  };

  sync_updateDocumentMetadata() {
    const documentMetadata = this.document?.getDocumentMetadata();
    if (user.authorized) {
      // save to db
      Apis.updateDocumentMetadata(this.id, {
        ...documentMetadata,
        sharedWith: this.sharedWith,
        assignedTo: this.assignedTo,
      });
    } else {
      // save to local storage
      localApi.updateDocumentMetadata(this.id, {
        ...documentMetadata,
        sharedWith: this.sharedWith,
        assignedTo: this.assignedTo,
      });
    }
  }

  sync_addDocumentMetadata() {
    const documentMetadata = this.document?.getDocumentMetadata();
    if (user.authorized) {
      // save to db
      Apis.addDocumentMetadata(this.id, documentMetadata);
    } else {
      // save to local storage
      localApi.addDocumentMetadata(this.id, this.type, documentMetadata);
    }
  }

  sync_addCharacter = async (character: Character | CharacterType) => {
    if (user.authenticated && user.authorized) {
      // add to db
      await Apis.addCharacter(this.type, this.id, character);
    } else {
      localApi.addCharacter(character);
    }

    const assignedTo = character.talent?.email;
    if (assignedTo) {
      this.addAssignedTo(assignedTo);
      this.sync_assignedTo();
    }
  };

  sync_sharedWith = async () => {
    const sharedWith = this.sharedWith;
    return await Apis.updateDocumentMetadata(this.id, {
      sharedWith,
    });
  };

  sync_assignedTo = async () => {
    const assignedTo = this.assignedTo;
    if (user.authorized && user.authenticated) {
      return await Apis.updateDocumentMetadata(this.id, {
        assignedTo,
      });
    } else {
      localApi.addAssignedTo(assignedTo);
    }
  };

  sync_updateCharacter = async (character: CharacterType) => {
    const documentId = this.id;
    const assignedTo = character.talent?.email;
    if (assignedTo) {
      this.addAssignedTo(assignedTo);
      this.sync_assignedTo();
    }
    if (user.authorized && user.authenticated) {
      await Apis.updateCharacter(this.type, documentId, character);
    } else {
      localApi.updateCharacter(character);
    }

    if (character.isDefault === true) {
      // update character default as well
      this.document?.updateDefaultCharacterId(character.id);
      this.document?.updateDefaultCharacterColor(
        character.color || DEFAULT_CHARACTER_COLOR
      );
      this.document?.updateDefaultCharacterName(character.name);
      await this.sync_updateDocumentMetadata();
    }
  };

  sync_updateDocumentTitle = (documentId: string, title: string) => {
    if (user.authorized && user.authenticated) {
      return Apis.updateDocumentTitle(documentId, title);
    } else {
      return localApi.updateDocumentTitle(documentId, title);
    }
  };

  static sync_deleteDocument = async (documentId: string) => {
    if (user.authorized && user.authenticated) {
      return Apis.deleteDocument(documentId);
    } else {
      return localApi.deleteDocument(documentId);
    }
  };
}
