import { css } from "glamor";
import { merge } from "lodash";
import React from "react";
import {
  Borders,
  Color,
  DefaultScrollbarStyle,
} from "../../../styles/default";

export interface ListStyleType {
  container?: React.CSSProperties;
  listbox?: React.CSSProperties;
  listboxbody?: React.CSSProperties;
}

const defaultStyles = {
  container: {
    position: "relative",
    width: "100%",
    outline: "none",
    border: 0,
  },
  listbox: {
    backgroundColor: Color.primary400,
    borderRadius: Borders.radius,
    boxSizing: "border-box",
    display: "block",
    left: "0px",
    maxHeight: "200px",
    overflowY: "scroll",
    position: "absolute",
    top: "100%",
    width: "100%",
    zIndex: 100,
    outline: "none",
    border: 0,
    ...DefaultScrollbarStyle,
  },
  listboxbody: {
    boxSizing: "border-box",
    width: "100%",
  },
};
interface ListType {
  id: string;
  children: any;
  style?: ListStyleType;
}

export const List: React.FC<ListType> = ({ id, children, style }) => {
  const styles = merge({}, defaultStyles, style);
  return (
    <div {...css(styles.container)} data-style="list:container">
      <div
        {...css(styles.listbox)}
        tabIndex={0}
        id={id}
        role="listbox"
        aria-expanded={true}
        data-style="list:listbox"
      >
        <div
          role="presentation"
          {...css(styles.listboxbody)}
          data-style="list:listboxbody"
        >
          {children}
        </div>
      </div>
    </div>
  );
};
