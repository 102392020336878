export const APP_NAME = "ProductionOne";

export const LayoutPaddings = {
  appContainer: {
    marginLeft: 200,
  },
  appContainerContracted: {
    marginLeft: 80,
  },
};

const HEADER_HEIGHT = 62;
const APP_BAR_HEIGHT = 30;
const APP_HEADER = HEADER_HEIGHT + APP_BAR_HEIGHT;

export const LayoutHeights = {
  header: HEADER_HEIGHT,
  appBar: APP_BAR_HEIGHT,
  appHeader: APP_HEADER,
  mobileHeader: 140,
};

export const ZIndexes = {
  betaRibbon: 5000,
  searchWrapper: 1,
  header: 100,
  popups: 200,
  feedback: 300,
  menu: 400,
  preview: 1000,
  panel: 3,
};

export const DEFAULT_APP_TITLE = "Production One Studio";

export const GOOGLE_PROJECT_PUBLIC_FACING_NAME = "project-935629340185";
// export const GAPI_CLIENT_ID =
//   "393434433315-pdevnltubvh6q3l76ic7eco5adf1l54o.apps.googleusercontent.com";
// // For server side operations
// export const GAP_CLIENT_SECRET = "K9VRAzX2vecPqsJazQc5mnyE";
