import React from "react";
import { css } from "glamor";
import { observer } from "mobx-react";
import { Document, DocumentTypeOptions } from "../../store/textEditor/Document";
import { Color, spacings } from "../../styles/default";
import { LayoutPaddings, ZIndexes } from "../../store/constants";
import {
  focusOnDialogueCharacterInput,
  focusOnDialogueInput,
  scrollToElement,
} from "../common/utils/DomUtils";
import { ActionButton } from "../common/ActionButton";
import { ui } from "../../store/Ui";
import { shortcutCombos } from "../../store/common/Shortcuts";
import AppStore, { FormOptions } from "../../store/AppStore";
import { useIntl } from "react-intl";
import { PopupOptions } from "../../store/Popup";
import { PopupConfirmDataType } from "../popups/PopupConfirmBeforeAction";
import UnsavedDocumentWarning from "../UnsavedDocumentWarning";
import { windowSizes } from "../../store/WindowSizeStore";
import { queries } from "../../styles/mediaQueries";
import { logEvent } from "@firebase/analytics";
import { user } from "../../store/User";
import {
  NO_PREDEFINED_SUBHEADER,
  TransitionOptions,
} from "../../store/textEditor/Scene";
import { Form } from "../../store/Form";

const styles = {
  container: {
    backgroundColor: Color.surface,
    width: "100%",
    boxSizing: "border-box",
    position: "fixed",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    paddingRight: spacings.space2,
    paddingTop: spacings.space2,
    paddingBottom: spacings.space2,
    marginLeft: `-${LayoutPaddings.appContainerContracted.marginLeft}px`,
    paddingLeft: `${LayoutPaddings.appContainerContracted.marginLeft}px`,
    zIndex: ZIndexes.panel - 1,
  },
};
export const DocumentActions: React.FC<{
  store: AppStore;
  document: Document;
}> = observer(({ store, document }) => {
  const canEdit = store.user.email === document.createdBy;
  if (store.user.authorized && !canEdit) {
    return null;
  }

  if (document.type === DocumentTypeOptions.podcast) {
    return <PodcastActions store={store} document={document} />;
  }

  if (document.type === DocumentTypeOptions.outline) {
    return <OutlineActions store={store} document={document} />;
  }

  if (document.type === DocumentTypeOptions.screenplay) {
    return <ScreenplayActions store={store} document={document} />;
  }
  return null;
});

const PodcastActions: React.FC<{ store: AppStore; document: Document }> =
  observer(({ document, store }) => {
    const mobileActionsWrapperStyle = {
      [queries.phone]: {
        marginLeft: 0,
        paddingLeft: spacings.space8,
        left: 0,
        bottom: 0,
        width: windowSizes.width,
      },
    };
    const intl = useIntl();
    return (
      <div
        {...css(styles.container, mobileActionsWrapperStyle)}
        role="toolbar"
        aria-label="Actions"
        aria-orientation="horizontal"
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
          })}
        >
          <ActionButton
            label={intl.formatMessage({ id: "actions.add.dialogue" })}
            icon="playlist_add"
            onClick={async () => {
              logEvent(user.analytics, "btn_click_addNewLine");
              ui.resetSelections();
              const dialogue = await document.addNewLine("dialogue", "");
              setTimeout(() => {
                dialogue && scrollToElement(dialogue.id);
                dialogue && focusOnDialogueCharacterInput(dialogue.id);
              }, 100);
            }}
            shortcut={shortcutCombos.addNewDialogueLine}
          />

          <ActionButton
            label={intl.formatMessage({ id: "actions.add.insert" })}
            icon="post_add"
            onClick={async () => {
              logEvent(user.analytics, "btn_click_addInsert");
              const dialogue = await document.addNewLine("insert", "");
              setTimeout(() => {
                dialogue && scrollToElement(dialogue.id);
                dialogue && focusOnDialogueInput(dialogue.id);
              }, 100);
            }}
            shortcut={shortcutCombos.addNewInsertLine}
          />

          <ActionButton
            label={intl.formatMessage({ id: "actions.delete.allLines" })}
            icon="delete_sweep"
            iconOnly={true}
            destructive={true}
            onClick={() => {
              logEvent(user.analytics, "btn_click_popup_deleteAllLines");
              const popupData: PopupConfirmDataType = {
                callback: (action: "cancelled" | "accepted") => {
                  if (action === "accepted") {
                    document.deleteAllLines();
                  }
                },
                title: intl.formatMessage({
                  id: "confirmation.delete.all.dialogue.title",
                }),
                copy: intl.formatMessage({
                  id: "confirmation.delete.all.dialogue.copy",
                }),
                additionalCopy: intl.formatMessage({
                  id: "confirmation.delete.all.dialogue.additionalCopy",
                }),
                confirmBtnLabel: intl.formatMessage({
                  id: "confirmation.delete.all.btn.label",
                }),
              };
              store.popups.set(
                PopupOptions.confirmBeforeAction,
                intl.formatMessage({ id: "actions.delete.allLines" }),
                popupData
              );
            }}
            shortcut={shortcutCombos.deleteAllLinesInPodcast}
          />
        </div>

        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
          })}
        >
          <ActionButton
            label={intl.formatMessage({ id: "actions.download" })}
            icon="download"
            onClick={() => {
              store.saveDraft(
                intl.formatMessage({
                  id: "default.document.title",
                  defaultMessage: "",
                })
              );
            }}
            shortcut={shortcutCombos.saveFileToDisk.join(", ")}
          />
          <ActionButton
            label={intl.formatMessage({ id: "actions.view.preview" })}
            icon="remove_red_eye"
            onClick={() => {
              logEvent(
                user.analytics,
                `btn_click_openPreview_${document.type}`
              );
              store.openPreview(document.type, document.id);
            }}
            shortcut={shortcutCombos.openPreview}
          />
        </div>
        <UnsavedDocumentWarning store={store} />
      </div>
    );
  });

const ScreenplayActions: React.FC<{ document: Document; store: AppStore }> =
  observer(({ store, document }) => {
    const mobileActionsWrapperStyle = {
      [queries.phone]: {
        marginLeft: 0,
        paddingLeft: spacings.space8,
        left: 0,
        bottom: 0,
        width: windowSizes.width,
      },
    };
    const intl = useIntl();
    return (
      <div
        {...css(styles.container, mobileActionsWrapperStyle)}
        role="toolbar"
        aria-label="Actions"
        aria-orientation="horizontal"
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
          })}
        >
          <ActionButton
            onClick={async () => {
              logEvent(user.analytics, "btn_click_addNewScene");
              const scene = await document.addNewScene({
                subheader: NO_PREDEFINED_SUBHEADER,
              });
              const sceneId = scene.id;
              document.showAllScenes(false);
              ui.setShowScene(sceneId, true);
              ui.setSelectedSceneId(sceneId);
              const line = await document.addNewLine("dialogue", "", sceneId);
              const lineId = line?.id;
              setTimeout(() => {
                line && scrollToElement(lineId);
                line && focusOnDialogueCharacterInput(lineId);
              }, 100);
            }}
            label={intl.formatMessage({ id: "actions.add.scene" })}
            icon="movie"
            shortcut={shortcutCombos.addNewScene}
          />

          <ActionButton
            onClick={async () => {
              logEvent(
                user.analytics,
                "btn_click_openTransitionSceneSelection"
              );

              const currentSubheader = "";
              const title = "Select Scene Transition";
              const form = new Form({
                id: FormOptions.editSceneTransitionSubheader,
                fields: {
                  subheader: currentSubheader || "",
                },
                options: {
                  allowTranslation: true,
                  subheader: Object.keys(TransitionOptions).map(
                    (value: string, i: number) => ({
                      value,
                      label: intl.formatMessage({
                        id: `scene.subheader.${value}`,
                      }),
                      index: i,
                      details: {},
                    })
                  ),
                },
                title,
                validations: {},
                required: [],
              });

              const onSubmit = async () => {
                const scene = await document.addNewScene({
                  subheader: form.fields.subheader.value,
                });
                const sceneId = scene.id;
                document.showAllScenes(false);
                ui.setShowScene(sceneId, true);
                ui.setSelectedSceneId(sceneId);
                const line = await document.addNewLine("dialogue", "", sceneId);
                const lineId = line?.id;
                store.popups.clear();
                setTimeout(() => {
                  line && scrollToElement(lineId);
                  line && focusOnDialogueCharacterInput(lineId);
                }, 100);
              };
              store.popups.set(PopupOptions.genericFormUpdate, title, {
                tip: intl.formatMessage({
                  id: "transitions.tip.description",
                }),
                description: intl.formatMessage({
                  id: "transitions.description.label",
                }),
                form,
                onSubmit,
              });
            }}
            label={intl.formatMessage({ id: "actions.add.transition.scene" })}
            icon="animation"
            shortcut={shortcutCombos.addNewTransitionScene}
          />
        </div>
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
          })}
        >
          <ActionButton
            label={intl.formatMessage({ id: "actions.download" })}
            icon="download"
            onClick={() => {
              logEvent(user.analytics, "btn_click_saveDraft");
              store.saveDraft(
                intl.formatMessage({
                  id: "default.document.title",
                  defaultMessage: "",
                })
              );
            }}
            shortcut={shortcutCombos.saveFileToDisk.join(" ")}
          />
          <ActionButton
            label={intl.formatMessage({ id: "actions.view.preview" })}
            icon="remove_red_eye"
            onClick={() => {
              logEvent(user.analytics, `btn_openPreview_${document.type}`);
              store.openPreview(document.type, document.id);
            }}
            shortcut={shortcutCombos.openPreview}
          />
        </div>

        <UnsavedDocumentWarning store={store} />
      </div>
    );
  });

const OutlineActions: React.FC<{ store: AppStore; document: Document }> =
  observer(({ document, store }) => {
    const mobileActionsWrapperStyle = {
      [queries.phone]: {
        marginLeft: 0,
        paddingLeft: spacings.space8,
        left: 0,
        bottom: 0,
        width: windowSizes.width,
      },
    };
    const intl = useIntl();
    return (
      <div
        {...css(styles.container, mobileActionsWrapperStyle)}
        role="toolbar"
        aria-label="Actions"
        aria-orientation="horizontal"
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
          })}
        >
          <ActionButton
            label={intl.formatMessage({ id: "actions.add.outline" })}
            icon="playlist_add"
            onClick={async () => {
              logEvent(user.analytics, "btn_click_outline_addNewScene");
              ui.resetSelections();
              const dialogue = await document.addNewLine("dialogue", "");
              setTimeout(() => {
                dialogue && scrollToElement(dialogue.id);
                dialogue && focusOnDialogueCharacterInput(dialogue.id);
              }, 100);
            }}
            shortcut={shortcutCombos.addNewDialogueLine}
          />

          <ActionButton
            label={intl.formatMessage({ id: "actions.add.insert" })}
            icon="post_add"
            onClick={async () => {
              logEvent(user.analytics, "btn_click_outline_addInsert");
              const dialogue = await document.addNewLine("insert", "");
              setTimeout(() => {
                dialogue && scrollToElement(dialogue.id);
                dialogue && focusOnDialogueInput(dialogue.id);
              }, 100);
            }}
            shortcut={shortcutCombos.addNewInsertLine}
          />

          <ActionButton
            label={intl.formatMessage({ id: "actions.delete.allLines" })}
            icon="delete_sweep"
            iconOnly={true}
            destructive={true}
            onClick={() => {
              logEvent(
                user.analytics,
                "btn_click_outline_deleteAllLines_confirmation"
              );
              const popupData: PopupConfirmDataType = {
                callback: (action: "cancelled" | "accepted") => {
                  if (action === "accepted") {
                    document.deleteAllLines();
                  }
                },
                title: intl.formatMessage({
                  id: "confirmation.delete.all.dialogue.title",
                }),
                copy: intl.formatMessage({
                  id: "confirmation.delete.all.dialogue.copy",
                }),
                additionalCopy: intl.formatMessage({
                  id: "confirmation.delete.all.dialogue.additionalCopy",
                }),
                confirmBtnLabel: intl.formatMessage({
                  id: "confirmation.delete.all.btn.label",
                }),
              };
              store.popups.set(
                PopupOptions.confirmBeforeAction,
                "Delete All Outlines",
                popupData
              );
            }}
            shortcut={shortcutCombos.deleteAllLinesInPodcast}
          />
        </div>

        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
          })}
        >
          <ActionButton
            label={intl.formatMessage({ id: "actions.download" })}
            icon="download"
            onClick={() => {
              logEvent(user.analytics, "btn_click_outline_saveDraft");
              store.saveDraft(
                intl.formatMessage({
                  id: "default.document.title",
                  defaultMessage: "",
                })
              );
            }}
            shortcut={shortcutCombos.saveFileToDisk.join(", ")}
          />
          <ActionButton
            label={intl.formatMessage({ id: "actions.view.preview" })}
            icon="remove_red_eye"
            onClick={() => {
              logEvent(user.analytics, "btn_click_outline_openPreview");
              store.openPreview(document.type, document.id);
            }}
            shortcut={shortcutCombos.openPreview}
          />
        </div>
        <UnsavedDocumentWarning store={store} />
      </div>
    );
  });
