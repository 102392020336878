import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { queries } from "../styles/mediaQueries";
import { FormattedMessage } from "react-intl";
import { WaitlistJoinButton } from "./SubscriptionPlans";
import { spacings } from "../styles/default";

const PressRelease: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  return (
    <div
      {...css({
        marginBottom: spacings.space8,
        maxWidth: "70%",
        [queries.phone]: {
          maxWidth: "90%",
        },
      })}
    >
      <h1>
        <FormattedMessage id="beta.version.title" />
      </h1>
      <p>
        <FormattedMessage id="beta.version.why" />
      </p>
      <p>
        <FormattedMessage id="beta.version.incomplete" />
      </p>
      <p>
        <FormattedMessage id="beta.version.autosave" />
      </p>
      <p>
        <FormattedMessage
          id="beta.version.help"
          values={{
            email: (
              <a
                href="mailto:hello@productiononestudio.com"
                title="Submit feedback"
                {...css({ fontWeight: 700 })}
              >
                hello@productiononestudio.com
              </a>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="beta.version.thanks" />
      </p>
      <p>
        <FormattedMessage id="beta.version.seeyou" />
      </p>

      <div {...css({ marginTop: spacings.space8 })}>
        <h2>
          <FormattedMessage id="beta.version.excited" />
        </h2>
        <WaitlistJoinButton store={store} planId="nextVersion" />
      </div>
    </div>
  );
};

export default observer(PressRelease);
