import { css } from "glamor";
import TextareaAutosize from "react-textarea-autosize";
import { observer } from "mobx-react";
import {
  borderRadius,
  Color,
  InputStyle,
  InputStyleDialogue,
  setAlpha,
  spacings,
} from "../../styles/default";
import { Dialogue } from "../../store/textEditor/Dialogue";
import { Document } from "../../store/textEditor/Document";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import { DialogueBindings } from "./DialogueBinding";
import { ui } from "../../store/Ui";
import { useIntl } from "react-intl";
import AppStore from "../../store/AppStore";

const styles = {
  insert: {
    marginBottom: spacings.space2,
    display: "flex",
    gridGap: spacings.space1,
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: 0,
    backgroundColor: setAlpha(Color.surface100, 0.5),
    padding: spacings.space4,
    boxSizing: "border-box",
    borderRadius: borderRadius.borderRadius2,
    paddingLeft: spacings.space8,
  },
};

const InsertLine: React.FC<{
  disableTextEdits: boolean;
  dialogue: Dialogue;
  store: AppStore;
  document: Document;
}> = ({ document, dialogue, store, disableTextEdits }) => {
  const intl = useIntl();
  const actionsMenuOptions = [
    // {
    //   id: "assign",
    //   label: intl.formatMessage({ id: "action.dialogue.assign" }),
    //   icon: "shortcut",
    //   action: (e?: any) => {
    //     if (user.authenticated && user.authorized) {
    //       // TODO
    //     } else {
    //       store.openSharePopup("", "");
    //     }
    //   },
    // },
    {
      id: "delete",
      label: intl.formatMessage({ id: "action.dialogue.delete" }),
      icon: "delete_outline",
      action: (e?: any) => {
        document.removeDialogue(dialogue.id);
      },
    },
  ];
  const value = dialogue.focus ? dialogue.text : `<${dialogue.text}>`;
  return (
    <div data-attrdocumentitem={true} key={dialogue.id}>
      <DialogueBindings disableTextEdits={disableTextEdits} dialogue={dialogue}>
        <div {...css(styles.insert)}>
          <div {...css({ width: "100%", marginTop: spacings.space2 })}>
            <TextareaAutosize
              {...css(InputStyle, InputStyleDialogue, {
                paddingTop: spacings.space0,
                marginTop: 0,
                paddingLeft: 0,
                marginLeft: 0,
                width: "100%",
              })}
              id={`${dialogue.id}-insert`}
              className="mousetrap"
              onFocus={() => {
                if (!disableTextEdits) {
                  dialogue.setFocus(true);
                }
              }}
              disabled={disableTextEdits}
              onBlur={() => {
                if (!disableTextEdits) {
                  dialogue.setFocus(false);
                }
              }}
              value={value}
              onChange={(e) => {
                if (!disableTextEdits) {
                  ui.setSelectedId(undefined);
                  dialogue.setText(e.target.value);
                  document.sync_updateDialogue(document.id, dialogue);
                }
              }}
              spellCheck={true}
              placeholder={intl.formatMessage({ id: "insert.placeholder" })}
            />
          </div>
          {!disableTextEdits && (
            <MenuActionsTrigger options={actionsMenuOptions} />
          )}
        </div>
      </DialogueBindings>
    </div>
  );
};

export default observer(InsertLine);
