import { makeAutoObservable } from "mobx";
// Keeps track of the features markup
// to show and hide features descriptions
// available in the ui
export class Features {
  show: boolean;

  // trigger selector is the html data attribute used for this feature
  constructor() {
    this.show = false;
    makeAutoObservable(this);
  }

  toggleShowFeatures = (value?: boolean) => {
    if (value !== undefined) {
      this.show = value;
    } else {
      this.show = !this.show;
    }
  };

  showFeatures = () => {
    this.toggleShowFeatures(true);
  };

  hideFeatures = () => {
    this.toggleShowFeatures(false);
  };
}

export const features = new Features();
