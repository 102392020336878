import { makeAutoObservable, toJS } from "mobx";
import { v4 } from "uuid";
import { hasSearchTerm } from "../../lib/audiox/models/utils";
import { Dialogue } from "./Dialogue";

export const NO_PREDEFINED_SUBHEADER = "none";

export enum TransitionOptions {
  fadeIn = "fadeIn",
  fadeOut = "fadeOut",
  fadeToBlack = "fadeToBlack",
  theEnd = "theEnd",
  dissolveTo = "dissolveTo",
  smashCut = "smashCut",
  overBlack = "overBlack",
  beginMontage = "beginMontage",
  endMontage = "endMontage",
}

export const SceneTransitionsKeys = Object.keys(TransitionOptions).map(
  (key) => key
);

export enum ScenePlaceOptions {
  interior = "INT.", // inside
  exterior = "EXT.", // outside
}

export enum SceneTimeOfDayOptions {
  day = "DAY",
  morning = "MORNING",
  noon = "NOON",
  afternoon = "AFTERNOON",
  evening = "EVENING",
  night = "NIGHT",
  dawn = "DAWN",
  sunrise = "SUNRISE",
  sunset = "SUNSET",
  sameDay = "SAME DAY",
  sameAfternoon = "SAME AFTERNOON",
  sameEvening = "SAME EVENING",
  laterThatEvening = "LATER THAT EVENING",
  lateThatNight = "LATE THAT NIGHT",
  lateNight = "LATE NIGHT",
}

export interface SceneHeadingType {
  place?: string | ScenePlaceOptions;
  location?: string;
  timeOfDay?: string | SceneTimeOfDayOptions;
}

export interface SceneType {
  id?: string;
  subheader: string;
  place?: string | ScenePlaceOptions;
  location?: string;
  timeOfDay?: string | SceneTimeOfDayOptions;
  action?: string; // Action tells you what is happening in the scene
  dialogues?: { [dialogueId: string]: Dialogue }; // dialogue indicates what each character is saying
  // You don’t need to include a transition between scenes, as it’s understood that one scene naturally flows into the next.
  // However, sometimes you might want to use a specific transition as a stylistic choice, such as a SMASH CUT or FADE TO BLACK.
  // Final Draft has many different transition options, and will format the transition flush right according to industry standards.
  transition?: string;
  createdAt?: number;
  dialogueOrder?: string[];
}
/**
 * A scene in a screenplay is composed of action and dialogue.
 */
export default class Scene {
  id: string;
  subheader: string; // for montage/fade in
  place?: string | ScenePlaceOptions;
  location?: string;
  timeOfDay?: string | SceneTimeOfDayOptions;
  action: string; // Action tells you what is happening in the scene
  dialogues: { [dialogueId: string]: Dialogue }; // dialogue indicates what each character is saying
  // You don’t need to include a transition between scenes, as it’s understood that one scene naturally flows into the next.
  // However, sometimes you might want to use a specific transition as a stylistic choice, such as a SMASH CUT or FADE TO BLACK.
  // Final Draft has many different transition options, and will format the transition flush right according to industry standards.
  transition: string;
  createdAt: number;
  dialogueOrder: string[];

  constructor(scene: SceneType) {
    this.id = scene.id || v4();
    this.subheader = scene.subheader;
    this.location = scene.location;
    this.place = scene.place;
    this.timeOfDay = scene.timeOfDay;
    this.action = scene.action || "";
    this.dialogues = scene.dialogues || {};
    this.transition = scene.transition || "";
    this.createdAt = scene.createdAt || new Date().getTime();
    this.dialogueOrder = scene.dialogueOrder || [];

    makeAutoObservable(this);
  }

  get excludeFromSceneCount(): boolean {
    const subheader = this.subheader;
    if (subheader) {
      return SceneTransitionsKeys.includes(subheader.trim()) === true;
    }

    return false;
  }

  get characterDialogueCount(): { [characterId: string]: number } {
    const charactersCount: { [characterId: string]: number } = {};
    Object.keys(this.dialogues).forEach((characterId) => {
      if (charactersCount[characterId]) {
        charactersCount[characterId] = charactersCount[characterId] + 1;
      } else {
        charactersCount[characterId] = 1;
      }
    });
    return charactersCount;
  }

  hasDialoguesWithSearchTerm(searchTerm: string) {
    if (searchTerm === "") {
      return true;
    }
    let matched = 0;
    Object.keys(this.dialogues).forEach((dialogueId) => {
      const dialogue = this.dialogues[dialogueId];
      if (dialogue) {
        if (hasSearchTerm(searchTerm, dialogue?.text)) {
          matched = matched + 1;
        }
      }
    });

    return matched > 0;
  }

  setSubheader(subheader: string) {
    this.subheader = subheader;
  }

  setAction(action: string) {
    this.action = action;
  }

  setDialogue(dialogues: { [dialogueId: string]: Dialogue }) {
    this.dialogues = dialogues;
  }

  setTransition(transition: string) {
    this.transition = transition;
  }

  setDialogueOrder = (dialogueOrder: string[]) => {
    this.dialogueOrder = dialogueOrder;
  };

  addDialogueLine(dialogue: Dialogue) {
    this.dialogues[dialogue.id] = dialogue;
  }

  insertNewDialogueInDialogueOrder(dialogueId: string, atIndex?: number) {
    if (atIndex === 0) {
      // append to beginning
      this.setDialogueOrder([dialogueId, ...this.dialogueOrder]);
    }
    if (atIndex && atIndex > 0) {
      // insert at position
      const currentOrder = toJS(this.dialogueOrder);
      currentOrder.splice(atIndex, 0, dialogueId);
      this.setDialogueOrder(currentOrder);
    } else {
      // append to end
      this.setDialogueOrder([...this.dialogueOrder, dialogueId]);
    }
  }

  removeFromDialogueOrder(dialogueId: string) {
    const dialogueOrder = toJS(this.dialogueOrder);
    const index = dialogueOrder.indexOf(dialogueId);
    dialogueOrder.splice(index, 1);
    this.setDialogueOrder(dialogueOrder);
  }

  removeLine(id: string) {
    if (this.dialogues[id]) {
      delete this.dialogues[id];
    }
  }

  setHeading(
    place: ScenePlaceOptions,
    location?: string,
    timeOfDay?: SceneTimeOfDayOptions
  ) {
    this.setPlace(place);
    this.setLocation(location);
    this.setTimeOfDay(timeOfDay);
  }

  setLocation(location?: string) {
    this.location = location;
  }

  setPlace(place?: ScenePlaceOptions) {
    this.place = place;
  }

  setTimeOfDay(timeOfDay?: SceneTimeOfDayOptions) {
    this.timeOfDay = timeOfDay;
  }

  clearDialogue() {
    this.dialogues = {};
  }

  clearAction() {
    this.action = "";
  }
}

/**
 * Note: props, sounds and character movements in your scenes are represented by UPPERCASE letters
 */

export function hasPredefinedTransitionSubheader(subheader?: string) {
  if (!subheader) {
    return false;
  }
  const predefinedSubheaders = Object.keys(TransitionOptions).map((key) => key);

  if (predefinedSubheaders.indexOf(subheader) > -1) {
    return true;
  }

  return false;
}
