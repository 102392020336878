import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import { dragSelect } from "../../..";

const Droppable: React.FC<{
  index: number;
  onDrop: (draggedItemIndex?: number, droppedItemIndex?: number) => void;
}> = ({ index, onDrop }) => {
  const over = dragSelect.dropAtIndex === index;
  const height = dragSelect.droppableStyle?.height;
  return (
    <div
      {...css({
        width: "100%",
        height: over ? height : "8px",
        transition: "height 0.25s ease-in",
      })}
      onDragOver={() => {
        if (!over) {
          dragSelect.setDropAtIndex(index);
        }
      }}
      onDrop={() => {
        onDrop(dragSelect?.draggingItemListIndex, dragSelect?.dropAtIndex);
      }}
      onDragLeave={() => {}}
    ></div>
  );
};

export default observer(Droppable);
