import { css } from "glamor";
import { observer } from "mobx-react";
import { portal } from "../../store/common/Portal";
import { defaultStyles } from "../../styles/default";

export const Portal: React.FC<{}> = observer(({ children }) => {
  return (
    <div id={portal.id} {...css({ position: "relative" })}>
      <button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          portal.open(e);
        }}
        {...css(defaultStyles.button.icon)}
      >
        <span className="material-icons">more_horiz</span>
      </button>
      <div {...css({ position: "fixed", ...portal.positionStyles })}>
        {portal.show && children}
      </div>
    </div>
  );
});
