import {
  DocumentMetadataType,
  DocumentTypeOptions,
} from "./textEditor/Document";
import { Character, CharacterType } from "./textEditor/Character";
import { DB } from "./Firestore";
import { DialogueType } from "./textEditor/Dialogue";
import { NO_PREDEFINED_SUBHEADER, SceneType } from "./textEditor/Scene";
import { user } from "./User";
import { feedback } from "./Feedback";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";

export default class Apis {
  static setCapabilitiesDocumentForUser = (
    documentId: string,
    userId: string,
    allowEdit: boolean,
    allowRead: boolean
  ) => {
    return DB.set("permissions", `${documentId}/users/${userId}`, {
      allowRead,
      allowEdit,
    });
  };

  static fetchUserPermissionsForDocumentId = (documentId: string) => {
    const result = DB.fetch("permissions", documentId);

    return result.then((querySnapshot: any) => {
      return querySnapshot;
    });
  };

  static fetchAllDocumentsForCurrentUser = async (lmt: number) => {
    if (user.authorized) {
      const c = collection(DB.db, "files");
      const w = where("createdBy", "==", user.email);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, o, l);

      return await getDocs(q);
    }
  };

  static joinAlphaWaitList(email: string) {
    return DB.setWithoutCredentials("waitlist", email, {
      email,
      timestamp: new Date().getTime(),
      currentVersion: "beta",
      wantedVersion: "alpha",
    });
  }

  static joinPlanWaitList(email: string, planId: string) {
    return DB.setWithoutCredentials("waitlist", email, {
      email,
      planId: planId || "unknown",
      timestamp: new Date().getTime(),
      currentVersion: "beta",
      wantedVersion: planId || "unknown",
    });
  }
  static fetchAllDocumentsOfTypeForCurrentUser = async (
    docType: DocumentTypeOptions,
    lmt: number
  ) => {
    if (user.authorized) {
      const c = collection(DB.db, "files");
      const w = where("createdBy", "==", user.email);
      const w2 = where("type", "==", docType);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, w2, o, l);

      return await getDocs(q);
    }
  };

  static fetchAllStarredDocumentsForCurrentUser = (lmt: number) => {
    if (user.authorized) {
      const c = collection(DB.db, "files");
      const w = where("createdBy", "==", user.email);
      const w2 = where("starred", "==", true);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, w2, o, l);

      return getDocs(q);
    }
  };

  static fetchAllSharedDocumentsForCurrentUser = (lmt: number) => {
    if (user.authorized) {
      const email = user.email;
      const c = collection(DB.db, "files");
      const w = where("sharedWith", "array-contains", email);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, o, l);
      return getDocs(q);
    }
  };

  static fetchAllAssignedDocumentsForCurrentUser = (lmt: number) => {
    if (user.authorized) {
      const c = collection(DB.db, "files");
      const w = where("assignedTo", "array-contains", user.email);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, o, l);
      return getDocs(q);
    }
  };

  static searchUsers = (searchTerm: string) => {
    const c = collection(DB.db, "users");
    const w = where("email", "==", searchTerm);
    const l = limit(5);
    const q = query(c, w, l);
    return getDocs(q);
  };

  static addCharacterAssignment(
    email: string,
    documentId: string,
    characterId: string
  ) {
    return DB.set("files", `${email}/documents/${documentId}`, {
      characterId,
      updatedAt: new Date().getTime(),
    });
  }

  static setShared(email: string, documentId: string, characterId: string) {
    // return DB.set("files", `${email}/documents/${documentId}`, {
    //   characterId,
    //   updatedAt: new Date().getTime(),
    // });
  }
  static fetchAssignments(email: string, limit: number) {
    return DB.fetch(`assigned/${email}/documents`);
  }

  static fetchShared(email: string, limit: number) {
    return DB.fetch(`shared/${email}/documents`);
  }

  static setUser = (email: string, userData: any) => {
    return DB.set("users", email, {
      ...userData,
      email,
      updatedAt: new Date().getTime(),
    });
  };

  static fetchUserProfile = (email: string) => {
    if (user.authorized) {
      return DB.fetch("users", email);
    }
  };

  static updateUser = (email: string, userData: any) => {
    return DB.update("users", email, {
      ...userData,
      email,
      updatedAt: new Date().getTime(),
    });
  };

  static searchDocumentsForDocTypeWithSearchTerm = (
    docType: DocumentTypeOptions,
    searchTerm: string,
    lmt: number
  ) => {
    if (user.authorized) {
      // TODO
      // const c = collection(DB.db, docType);
      // const w = where("createdBy", "==", user.email);
      // const o = orderByChild("updatedAt");
      // const l = limit(lmt);
      // const et = equalTo(searchTerm);
      // const q = query(c, w, o, l);
      // return getDocs(q);

      const podcastRef = DB.db.collection(docType);
      const query = podcastRef
        .where("createdBy", "==", user.email)
        .orderByChild("title")
        .limit(limit)
        .equalTo(searchTerm);
      return query.get().then((querySnapshot: any) => {
        return querySnapshot;
      });
    }
  };
  static addCharacter(
    documentType: DocumentTypeOptions,
    documentId: string,
    character: Character | CharacterType
  ) {
    const characterData = {
      name: character.name,
      id: character.id,
      color: character.color,
      notes: character.notes || "",
      isDefault: character.isDefault || false,
      voiceOver: character.voiceOver || false,
      talent: {
        name: character.talent?.name || "",
        email: character.talent?.email || "",
      },
    };
    return DB.set(
      "files",
      `${documentId}/characters/${character.id}`,
      characterData
    );
  }

  static updateCharacter(
    documentType: DocumentTypeOptions,
    documentId: string,
    character: CharacterType
  ) {
    const characterData = {
      name: character.name,
      id: character.id,
      color: character.color,
      notes: character.notes || "",
      isDefault: character.isDefault || false,
      voiceOver: character.voiceOver || false,
      talent: {
        name: character.talent?.name || "",
        email: character.talent?.email || "",
      },
    };
    return DB.update(
      "files",
      `${documentId}/characters/${character.id}`,
      characterData
    );
  }

  static createFolder = (
    folderName: string,
    folderId: string,
    documentIds: string[]
  ) => {
    return DB.set("folders", folderId, {
      id: folderId,
      name: folderName,
      documentIds,
      owner: user.email,
    });
  };

  static updateSceneDialogue(
    documentId: string,
    sceneId: string,
    dialogue: DialogueType
  ) {
    return DB.update(
      "files",
      `${documentId}/scenes/${sceneId}/dialogues/${dialogue.id}`,
      {
        characterId: dialogue.characterId || "",
        text: dialogue.text || "",
      }
    );
  }

  static addSceneDialogue(
    documentId: string,
    sceneId: string,
    dialogue: DialogueType
  ) {
    return DB.set(
      "files",
      `${documentId}/scenes/${sceneId}/dialogues/${dialogue.id}`,
      {
        dialogueId: dialogue.id,
        characterId: dialogue.characterId || "",
        text: dialogue.text || "",
        type: dialogue.type,
      }
    );
  }

  static updateDialogue(
    documentType: DocumentTypeOptions,
    documentId: string,
    dialogue: DialogueType
  ) {
    return DB.update("files", `${documentId}/dialogues/${dialogue.id}`, {
      characterId: dialogue.characterId || "", // character id can be undefined for inserts
      text: dialogue.text,
      characterExtension: dialogue.characterExtension || "",
      parentheticals: dialogue.parentheticals || "",
    });
  }

  static addDialogue(
    documentType: DocumentTypeOptions,
    documentId: string,
    dialogue: DialogueType
  ) {
    return DB.set("files", `${documentId}/dialogues/${dialogue.id}`, {
      dialogueId: dialogue.id,
      characterId: dialogue.characterId || "", // character id can be undefined for inserts
      text: dialogue.text,
      type: dialogue.type,
      characterExtension: dialogue.characterExtension || "",
      parentheticals: dialogue.parentheticals || "",
    });
  }

  static addDocumentMetadata(
    documentId: string,
    metadata: DocumentMetadataType | undefined
  ) {
    return DB.set("files", documentId, metadata);
  }

  static updateDocumentTitle(documentId: string, title: string) {
    return DB.update("files", documentId, { title });
  }
  static updateDocumentMetadata(
    documentId: string,
    metadata: DocumentMetadataType
  ) {
    return DB.update("files", documentId, metadata);
  }

  static getAllFolderNamesForCurrentUser = async () => {
    const c = collection(DB.db, "folders");
    const w = where("owner", "==", user.email);
    const q = query(c, w);
    return await getDocs(q);
  };

  static getFolderNameForFolderId = async (
    folderId: string
  ): Promise<DocumentData | undefined> => {
    return await DB.fetch("folders", folderId);
  };

  static updateDocumentMapping(
    docType: DocumentTypeOptions,
    documentId: string,
    mapping: string[]
  ) {
    if (
      docType === DocumentTypeOptions.podcast ||
      docType === DocumentTypeOptions.outline
    ) {
      return DB.update("files", documentId, {
        dialogueOrder: mapping,
      });
    } else if (docType === DocumentTypeOptions.screenplay) {
      return DB.update("files", documentId, {
        sceneOrder: mapping,
      });
    }
  }

  static deleteOutlineDialogue = async (
    documentId: string,
    dialogueId: string
  ) => {
    const db = DB.db;
    return deleteDoc(doc(db, "files", `${documentId}/dialogues/${dialogueId}`))
      .then(() => {
        return true;
      })
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        feedback.setFeedback({
          message: error,
          variant: "error",
        });
        return false;
      });
  };

  static deletePodcastDialogue = async (
    documentId: string,
    dialogueId: string
  ) => {
    const db = DB.db;
    return deleteDoc(doc(db, "files", `${documentId}/dialogues/${dialogueId}`))
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        feedback.setFeedback({
          message: error,
          variant: "error",
        });
        return false;
      });
  };

  static deleteCharacter = async (
    documentType: DocumentTypeOptions,
    documentId: string,
    characterId: string
  ) => {
    const db = DB.db;
    return deleteDoc(
      doc(db, "files", `${documentId}/characters/${characterId}`)
    )
      .then(() => {
        console.log("Character successfully deleted!");
        return true;
      })
      .catch((error: any) => {
        console.error("Error removing document: ", error);
        return false;
      });
  };

  static deleteSceneInScreenplay = async (
    documentId: string,
    sceneId: string
  ) => {
    const db = DB.db;
    return deleteDoc(doc(db, "files", `${documentId}/scenes/${sceneId}`))
      .then(() => {
        console.log("Scene successfully deleted!");
        return true;
      })
      .catch((error: any) => {
        console.error("Error removing scene: ", error);
        return false;
      });
  };

  static deleteSceneDialogueInScreenplay = async (
    documentId: string,
    sceneId: string,
    dialogueId: string
  ) => {
    const db = DB.db;
    return deleteDoc(
      doc(
        db,
        "files",
        `${documentId}/scenes/${sceneId}/dialogues/${dialogueId}`
      )
    )
      .then(() => {
        console.log("Dialogue Scene successfully deleted!");
        return true;
      })
      .catch((error: any) => {
        console.error("Error removing dialogue in scene: ", error);
        return false;
      });
  };
  static deleteDocument = async (documentId: string): Promise<boolean> => {
    const db = DB.db;
    return deleteDoc(doc(db, "files", documentId))
      .then(() => {
        console.log("Document successfully deleted!");
        return true;
      })
      .catch((error: any) => {
        console.error("Error removing document: ", error);
        return false;
      });
  };

  static addScreenplayScene = async (documentId: string, scene: SceneType) => {
    return DB.set("files", `${documentId}/scenes/${scene.id}`, {
      id: scene.id,
      subheader: scene.subheader || NO_PREDEFINED_SUBHEADER,
      location: scene.location || "",
      place: scene.place || "",
      timeOfDay: scene.timeOfDay || "",
      action: scene.action || "",
      transition: scene.transition || "",
      dialogueOrder: scene.dialogueOrder || [],
    });
  };

  static updateScreenplayScene = async (
    documentId: string,
    sceneId: string,
    scene: SceneType
  ) => {
    return DB.update("files", `${documentId}/scenes/${sceneId}`, {
      id: sceneId,
      subheader: scene.subheader || NO_PREDEFINED_SUBHEADER,
      location: scene.location || "",
      place: scene.place || "",
      timeOfDay: scene.timeOfDay || "",
      action: scene.action || "",
      transition: scene.transition || "",
      dialogueOrder: scene.dialogueOrder || [],
    });
  };

  static updateScreenplaySceneData = async (
    documentId: string,
    sceneId: string,
    sceneData: any
  ) => {
    return DB.update("files", `${documentId}/scenes/${sceneId}`, sceneData);
  };

  static markRead = (documentId: string) => {
    return Apis.updateDocumentMetadata(documentId, {
      updatedAt: new Date().getTime(),
    });
  };
}
