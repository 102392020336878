import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Apis from "../../store/Api";
import AppStore from "../../store/AppStore";
import { feedback } from "../../store/Feedback";
import Popup from "../../store/Popup";
import Users from "../../store/Users";
import { Color, spacings } from "../../styles/default";
import { queries } from "../../styles/mediaQueries";
import { Input } from "../common/Input";
// import { Input } from "../common/Input";
import { Popup as PopupComponent } from "../common/Popup";
// import SubscriptionPlansTable from "../SubscriptionPlansTable";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupJoinWaitlist: React.FC<PopupsType> = ({ store, popup }) => {
  const [email, setEmail] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const intl = useIntl();
  const isValid = Users.validateEmail(email);
  return (
    <PopupComponent
      id="signup-popup"
      title={popup.title}
      width="700px"
      cancel={{
        action: () => store.popups.clear(),
        label: intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: async () => {
          if (isValid) {
            await Apis.joinPlanWaitList(email, popup.data.planId);
            feedback.setFeedback({
              message: intl.formatMessage({
                id: "wait.list.joined.success",
              }),
              variant: "success",
            });

            setEmail("");
            setSubmitted(true);
          } else {
            feedback.setFeedback({
              message: intl.formatMessage({
                id: "wait.list.joined.failed",
              }),
              variant: "error",
            });
          }
        },
        enabled: isValid,
        label: intl.formatMessage({ id: "join.label" }),
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space5,
          flexDirection: "column",
          gridGap: spacings.space2,
        })}
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
            flexDirection: "column",
          })}
        >
          <h2>
            <FormattedMessage
              id="waitlist.interest.thanks"
              values={{
                planId: (
                  <span
                    {...css({ fontStyle: "italic", color: Color.secondary })}
                  >
                    {intl.formatMessage({
                      id: `subscription.plan.planId.${popup.data.planId}`,
                    })}
                  </span>
                ),
              }}
            />
          </h2>

          {submitted && <FormattedMessage id="join.wait.list.joined" />}

          {!submitted && (
            <>
              <FormattedMessage id="join.wait.list" />
              <div
                {...css({
                  alignItems: "center",
                  display: "flex",
                  [queries.phone]: {
                    flexDirection: "column",
                  },
                })}
              >
                <Input
                  placeholder={intl.formatMessage({ id: "enter.email" })}
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupJoinWaitlist);
