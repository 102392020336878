import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";
import AppStore, { FormOptions } from "../../store/AppStore";
import { feedback } from "../../store/Feedback";
import Popup from "../../store/Popup";
import { spacings } from "../../styles/default";
import { Dropdown } from "../common/dropdown/Dropdown";
import DropdownStore from "../common/dropdown/DropdownStore";
import { Input } from "../common/Input";
import { Popup as PopupComponent } from "../common/Popup";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupConvertStandaloneToSeries: React.FC<PopupsType> = ({
  store,
  popup,
}) => {
  const intl = useIntl();
  const folderReferences = store.folderReferences;
  const form = store.forms.getForm(FormOptions.convertStandaloneToSeries);
  const fields = form.fields;
  const dropdownOptions = Object.keys(folderReferences).map((key) => ({
    value: key,
    label: folderReferences[key].name,
    action: () => {
      fields.seriesId.set(key);
      fields.seriesReference.set(key);
    },
  }));
  const selectedSeriesId = fields.seriesId.value;
  const dropdownStore = new DropdownStore({
    id: "folder-references",
    options: dropdownOptions,
    selected: {
      value: selectedSeriesId || "",
      label:
        selectedSeriesId && folderReferences[selectedSeriesId]
          ? folderReferences[selectedSeriesId].name
          : intl.formatMessage({
              id: "select.series",
              defaultMessage: "Select from current series",
            }),
    },
    disabled: false,
  });

  return (
    <PopupComponent
      id="PopupConvertStandaloneToSeries-popup"
      title={popup.title}
      width="575px"
      cancel={{
        action: () => store.popups.clear(),
        label: intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: async () => {
          const formData = form.getData;
          const { seriesName, documentId, seriesId } = formData;
          const folderId = await store.convertStandaloneDocumentToSeries(
            seriesName,
            documentId,
            seriesId
          );
          if (folderId) {
            store.addFolderReference(folderId, seriesName, [documentId]);
            feedback.setFeedback({
              message: intl.formatMessage({
                id: "notification.standaloneToSeries.success",
              }),
              variant: "success",
            });
            store.popups.clear();
          }
        },
        enabled: form.isValid,
        label: intl.formatMessage({
          id: "btn.create.series",
          defaultMessage: "Create Series",
        }),
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space5,
          flexDirection: "column",
          gridGap: spacings.space2,
          width: "100%",
        })}
      >
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            gridGap: spacings.space4,
            width: "100%",
          })}
        >
          <div {...css({ width: "300px" })}>
            <Dropdown
              label={intl.formatMessage({
                id: "select.series",
                defaultMessage: "Select from current series",
              })}
              dropdownStore={dropdownStore}
            />
          </div>

          <span>Or</span>
          <Input
            width="90%"
            label={intl.formatMessage({
              id: "form.series.name.label",
              defaultMessage: "New Series Name",
            })}
            value={fields.seriesName.value}
            onChange={(e: any) => {
              const value = e.target.value;
              fields.seriesName.set(value);
              fields.seriesReference.set(value);
            }}
            type="text"
          />
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupConvertStandaloneToSeries);
