import { action, makeObservable, observable } from "mobx";
import { Midi } from "../Midi";

// Configurations
// For recording button - Ensure Midi CC1 Message Type: Midi CC(Toggle)
// For play button - Ensure Midi CC112 Message Type: Midi CC(Toggle
export enum MidiActions {
  changeVolumeTrack = "changeVolumeTrack",
  loop = "loop",
  play = "play",
  record = "record",
  stop = "stop",
  toggleRecordTrack = "toggleRecordTrack",
}

export interface PanoramaActions {
  onChangeVolumeTrack?: (track: number, value: number) => void;
  onLoop?: (loop: boolean) => void;
  onPlay?: (play: boolean) => void;
  onRecord?: (record: boolean) => void;
  onStop?: (stop: boolean) => void;
  onToggleRecordTrack?: (track: number, record: boolean) => void;
}
const getTrack = (key: number) => {
  switch (key) {
    case 56:
    case 40: {
      return 1;
    }
    case 57:
    case 41: {
      return 2;
    }
    case 58:
    case 42: {
      return 3;
    }
    case 59:
    case 43: {
      return 4;
    }
    case 60:
    case 44: {
      return 5;
    }
    case 61:
    case 45: {
      return 6;
    }
    case 62:
    case 46: {
      return 7;
    }
    case 63:
    case 47: {
      return 8;
    }
    default: {
      return undefined;
    }
  }
};

const getAction = (key: number) => {
  switch (key) {
    case 56:
    case 57:
    case 58:
    case 59:
    case 60:
    case 61:
    case 62:
    case 63: {
      return MidiActions.toggleRecordTrack;
    }

    case 40:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 47: {
      return MidiActions.changeVolumeTrack;
    }

    case 1:
      return MidiActions.record;
    case 112:
      return MidiActions.play;
    case 111:
      return MidiActions.stop;
    case 0:
      return MidiActions.loop;
    default:
      return undefined;
  }
};
export default class PanoramaP1 extends Midi {
  actions: PanoramaActions;

  constructor(actions: PanoramaActions) {
    super();
    this.actions = actions;

    makeObservable(this, {
      actions: observable,
      handleKey: action,
    });
  }

  handleKey = (key: number, pressed: boolean, value: number) => {
    const track = getTrack(key);
    const action = getAction(key);
    const actions = this.actions;
    switch (action) {
      case "play": {
        actions.onPlay && actions.onPlay(pressed);
        break;
      }
      case "stop": {
        actions.onStop && actions.onStop(pressed);
        break;
      }
      case "loop": {
        actions.onLoop && actions.onLoop(pressed);
        break;
      }
      case "record": {
        actions.onRecord && actions.onRecord(pressed);
        break;
      }
      case "changeVolumeTrack": {
        if (track && actions.onChangeVolumeTrack) {
          actions.onChangeVolumeTrack(track, value);
        }
        break;
      }
      case "toggleRecordTrack": {
        if (track && actions.onToggleRecordTrack) {
          actions.onToggleRecordTrack(track, pressed);
        }
        break;
      }
      default:
        break;
    }
  };
}
