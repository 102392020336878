import React from "react";
import { observer } from "mobx-react";
import { Document } from "../../store/textEditor/Document";
import { css } from "glamor";
import { InputStyleH1 } from "../../styles/default";
import AppStore from "../../store/AppStore";
import { StarDocumentButton } from "./DocumentHeaderActions";
import AutoSizedInput from "../common/AutoSizedInput";
import { useIntl } from "react-intl";
import { windowSizes } from "../../store/WindowSizeStore";
import { scrollToElement } from "../common/utils/DomUtils";

const DocumentTitleEditor: React.FC<{
  store: AppStore;
  document: Document;
}> = ({ store, document }) => {
  const intl = useIntl();
  return (
    <div
      id="title-editor"
      {...css({ display: "flex", alignItems: "center", maxWidth: "100%" })}
    >
      <StarDocumentButton store={store} document={document} />
      <h1 {...css({ margin: 0 })}>
        <AutoSizedInput
          id="document-title-editor"
          onBlur={() => {
            scrollToElement("title-editor");
          }}
          defaultValue={document.document?.title || ""}
          value={document.document?.title || ""}
          onChange={(e) => {
            const title = e.target.value;
            store.files.updateFileTitle(document.id, title);
            document.updateTitle(title);
          }}
          onKeyDown={() => {}}
          style={{
            ...InputStyleH1,
            minWidth: windowSizes.width - 200,
          }}
          placeholder={intl.formatMessage({ id: "document.title" })}
        />
      </h1>
    </div>
  );
};

export default observer(DocumentTitleEditor);
