import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { FormattedMessage, useIntl } from "react-intl";
import { defaultStyles, spacings } from "../styles/default";
import { css } from "glamor";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

const InviteToSubscribe: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  return (
    <div {...css({ padding: spacings.space4 })}>
      <FormattedMessage
        id="invite.to.subscribe"
        values={{
          signInLabel: (
            <button
              {...css(defaultStyles.button.link, { display: "inline-flex" })}
              onClick={() => {
                logEvent(user.analytics, "btn_click_inviteToSubscribe_signIn");
                store.openSignIn(intl.formatMessage({ id: "label.sign.in" }));
              }}
            >
              <FormattedMessage id="label.sign.in" />
            </button>
          ),
          createAccountLink: (
            <button
              {...css(defaultStyles.button.link, { display: "inline-flex" })}
              onClick={() => {
                logEvent(user.analytics, "btn_click_inviteToSubscribe_signUp");
                store.openSignUp(
                  intl.formatMessage({ id: "create.account.label" })
                );
              }}
            >
              <FormattedMessage id="create.account.label" />
            </button>
          ),
        }}
      />
    </div>
  );
};

export default observer(InviteToSubscribe);
