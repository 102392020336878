import { css } from "glamor";
import React from "react";
import { defaultStyles, Paddings, setAlpha, Color } from "../styles/default";

const styles = {
  jumbo: {
    minWidth: "180px",
    display: "flex",
    gridGap: "6px",
    padding: Paddings.default,
    borderRadius: "16px",
    ":hover": {
      backgroundColor: setAlpha(Color.onBackground, 0.1),
    },
  },
};
export const NewDocumentButton: React.FC<{
  title: string;
  onClick: () => void;
  icon: string;
  iconColor?: string;
}> = ({ title, onClick, icon, iconColor = Color.onBackground }) => {
  return (
    <button
      {...css(defaultStyles.button.primary, styles.jumbo)}
      onClick={onClick}
    >
      <span className="material-icons" {...css({ color: iconColor })}>
        {icon}
      </span>
      <span>{title}</span>
    </button>
  );
};
