import moment from "moment";
const WINDOW_MARGIN = 0;
const TWENTY_FOUR_HOURS_IN_SECONDS = 86400;
const TWENTY_FOUR_HOURS_IN_MS = TWENTY_FOUR_HOURS_IN_SECONDS * 1000;
const ONE_WEEK_IN_MS = TWENTY_FOUR_HOURS_IN_MS * 7;
const HOUR_IN_MS = 3600000;
const MINUTE_IN_MS = 60000;

export function updateLayerPosition(
  layer: HTMLElement | null,
  ownerId: string,
  matchOwnerWidth: boolean
) {
  const owner = document.getElementById(ownerId);
  if (owner && layer) {
    const ownerBounds = owner.getBoundingClientRect();
    const layerBounds = layer.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    let left = ownerBounds.left;
    let top = ownerBounds.top + ownerBounds.height;
    // auto positioning algorithm
    // try top left of popup at bottom left of owner
    // then bottom left of popup at top left of owner
    // if both exceed screen bounds, choose one with min hidden
    if (top + layerBounds.height > windowHeight - WINDOW_MARGIN) {
      top = ownerBounds.top - layerBounds.height;
    }

    // adjust left of popup if right would be off screen
    if (left + layerBounds.width > windowWidth - WINDOW_MARGIN) {
      left = windowWidth - WINDOW_MARGIN - layerBounds.width;
    }

    layer.style.left = `${left}px`;
    layer.style.top = `${top}px`;
  }
}

export function updateFloatingMenuPosition(
  layer: HTMLElement | null,
  owner: HTMLElement | null,
  matchOwnerWidth: boolean,
  customTopOffset?: number // offset to be used for the position of the menu
) {
  const DEFAULT_TOP_OFFSET = -10; // this offset is used so there's no gap between the trigger button and the menu
  const topOffset = customTopOffset || DEFAULT_TOP_OFFSET;
  if (owner && layer) {
    const ownerBounds = owner.getBoundingClientRect();
    const layerBounds = layer.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    let left = ownerBounds.left;
    let top = ownerBounds.top + ownerBounds.height;
    // auto positioning algorithm
    // try top left of popup at bottom left of owner
    // then bottom left of popup at top left of owner
    // if both exceed screen bounds, choose one with min hidden
    if (top + layerBounds.height > windowHeight - WINDOW_MARGIN) {
      top = ownerBounds.top - layerBounds.height - topOffset;
    } else {
      top = top + topOffset;
    }

    // adjust left of popup if right would be off screen
    if (left + layerBounds.width > windowWidth - WINDOW_MARGIN) {
      left = windowWidth - WINDOW_MARGIN - layerBounds.width;
    }

    layer.style.left = `${left}px`;
    layer.style.top = `${top}px`;

    if (matchOwnerWidth) {
      layer.style.minWidth = `${ownerBounds.width}px`;
    }

    return {
      left: layer.style.left,
      top: layer.style.top,
    };
  } else {
    return {
      top: owner?.getBoundingClientRect()?.top || "0px",
      left: owner?.getBoundingClientRect()?.left || "0px",
    };
  }
}

export const getWeeksRemaining = (date: string) => {
  const now = new Date();
  const momentToday = moment(now);
  const due = new Date(date);
  const momentDue = moment(due);
  const duration = Math.abs(now.getTime() - due.getTime());

  if (duration < MINUTE_IN_MS) {
    return `${momentToday.diff(momentDue, "seconds")} ${
      momentToday.diff(momentDue, "seconds") === 1 ? "second" : "seconds"
    }`;
  }
  if (duration < HOUR_IN_MS) {
    return `${momentToday.diff(momentDue, "minute")} ${
      momentToday.diff(momentDue, "minute") === 1 ? "minute" : "minutes"
    }`;
  }

  if (duration < TWENTY_FOUR_HOURS_IN_MS) {
    return `${momentToday.diff(momentDue, "hour")} ${
      momentToday.diff(momentDue, "hour") === 1 ? "hour" : "hours"
    }`;
  }

  if (duration < ONE_WEEK_IN_MS) {
    return `${momentToday.diff(momentDue, "day")}  ${
      momentToday.diff(momentDue, "day") === 1 ? "day" : "days"
    }`;
  }
  return `${momentToday.diff(momentDue, "week")} ${
    momentToday.diff(momentDue, "week") === 1 ? "week" : "weeks"
  }`;
};

export function padLeft(padding: string, text: string, length: number) {
  let padValue = padding;

  while (padValue.length < length) {
    padValue += padding.substring(
      0,
      Math.min(padding.length, length - padValue.length)
    );
  }

  return padValue.substring(0, padValue.length - text.length) + text;
}

export function parseFlexibleSmpte(value: string) {
  let smpte = value;

  // remove colons
  smpte = smpte.replace(/:/g, "");
  // pad with zeros
  smpte = padLeft("0", smpte, 8);

  const hh = smpte.substring(0, 2);
  const mm = smpte.substring(2, 4);
  const ss = smpte.substring(4, 6);
  const ff = smpte.substring(6, 8);

  return `${hh}:${mm}:${ss}:${ff}`;
}

export function scrollToElement(elementId?: string): HTMLElement | undefined {
  if (!elementId) {
    return undefined;
  }
  let elem = document.getElementById(elementId);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    elem.focus();
  } else {
    console.warn(`no element found to scroll to`);
  }
  return elem || undefined;
}

export function scrollToElementTop(
  elementId?: string
): HTMLElement | undefined {
  if (!elementId) {
    return undefined;
  }
  let elem = document.getElementById(elementId);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    elem.focus();
  } else {
    console.warn(`no element found to scroll to`);
  }
  return elem || undefined;
}

export function focusOnDialogueCharacterInput(
  elementId?: string
): HTMLElement | undefined {
  if (!elementId) {
    return undefined;
  }
  let elem = document.getElementById(`${elementId}-character`);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    elem.focus();
  } else {
    console.warn(`no element found to scroll to`);
  }
  return elem || undefined;
}

export function focusOnDialogueInput(
  elementId?: string
): HTMLElement | undefined {
  if (!elementId) {
    return undefined;
  }
  let elem = document.getElementById(`${elementId}-dialogue`);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    elem.focus();
  } else {
    console.warn(`no element found to scroll to`);
  }
  return elem || undefined;
}

export function focusOnInsertInput(
  elementId?: string
): HTMLElement | undefined {
  if (!elementId) {
    return undefined;
  }
  let elem = document.getElementById(`${elementId}-insert`);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    elem.focus();
  } else {
    console.warn(`no element found to scroll to`);
  }
  return elem || undefined;
}

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
function brightnessByColor(color: string) {
  const isHEX = color.indexOf("#") === 0;
  const isRGB = color.indexOf("rgb") === 0;
  let r: number = 0;
  let g: number = 0;
  let b: number = 0;
  if (isHEX) {
    const m = color
      .substr(1)
      .match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0], 16);
      g = parseInt(m[1], 16);
      b = parseInt(m[2], 16);
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      r = parseInt(m[0]);
      g = parseInt(m[1]);
      b = parseInt(m[2]);
    }
  }

  return (r * 299 + g * 587 + b * 114) / 1000;
}

// Calculating a Contrast Ratio
// Contrast ratios can range from 1 to 21 (commonly written 1:1 to 21:1).
// (L1 + 0.05) / (L2 + 0.05), whereby:
// L1 is the relative luminance of the lighter of the colors, and
// L2 is the relative luminance of the darker of the colors.

export function getContrastRatio(
  backgroundColor: string,
  foregroundColor: string
) {
  const L1 = brightnessByColor(foregroundColor);
  const L2 = brightnessByColor(backgroundColor);
  return (L1 + 0.05) / (L2 + 0.05);
}

export function moveCursorToEndAndFocus(inputId: string) {
  const input: HTMLTextAreaElement = document.getElementById(
    inputId
  ) as HTMLTextAreaElement;

  if (input) {
    const inputLength = input.value.length;
    input.setSelectionRange(inputLength, inputLength);
    input.focus();
  }
}

export function getCursorPos(input: any) {
  // if ("selectionStart" in input && document.activeElement == input) {
  //   return {
  //     start: input.selectionStart,
  //     end: input.selectionEnd,
  //   };
  // } else if (input.createTextRange) {
  //   var sel = document?.selection.createRange();
  //   if (sel.parentElement() === input) {
  //     var rng = input.createTextRange();
  //     rng.moveToBookmark(sel.getBookmark());
  //     for (
  //       var len = 0;
  //       rng.compareEndPoints("EndToStart", rng) > 0;
  //       rng.moveEnd("character", -1)
  //     ) {
  //       len++;
  //     }
  //     rng.setEndPoint("StartToStart", input.createTextRange());
  //     for (
  //       var pos = { start: 0, end: len };
  //       rng.compareEndPoints("EndToStart", rng) > 0;
  //       rng.moveEnd("character", -1)
  //     ) {
  //       pos.start++;
  //       pos.end++;
  //     }
  //     return pos;
  //   }
  // }
  // return -1;
}
