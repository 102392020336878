import React from "react";
import { observer } from "mobx-react";
import { DocumentTypeOptions } from "../store/textEditor/Document";
import { NewDocumentButton } from "../components/NewDocumentButton";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { spacings } from "../styles/default";
import {
  getIconColorForDocumentType,
  getIconForDocumentType,
} from "./finder/Files";
import { FormattedMessage, useIntl } from "react-intl";
import { CONTENT_ANCHOR_ID_GET_STARTED } from "./HomeLinks";
import { queries } from "../styles/mediaQueries";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

export const NewDocumentOptions: React.FC<{ store: AppStore }> = observer(
  ({ store }) => {
    const intl = useIntl();

    const documentTypes = [
      {
        type: DocumentTypeOptions.screenplay,
        label: intl.formatMessage({ id: "document.type.screenplay" }),
        defaultCharacterName: undefined,
      },
      {
        type: DocumentTypeOptions.podcast,
        label: intl.formatMessage({ id: "document.type.podcast" }),
        defaultCharacterName: intl.formatMessage({
          id: "default.podcast.character.name",
        }),
      },
      {
        type: DocumentTypeOptions.outline,
        label: intl.formatMessage({ id: "document.type.outline" }),
        defaultCharacterName: undefined,
      },
    ];
    return (
      <div
        id={CONTENT_ANCHOR_ID_GET_STARTED}
        {...css({
          marginTop: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          [queries.phone]: {
            marginTop: 0,
          },
        })}
      >
        <h1>
          <FormattedMessage id="home.slug" />
        </h1>
        <h2>
          <FormattedMessage id="home.slug.description" />
        </h2>
        <div
          {...css({
            display: "flex",
            gridGap: spacings.space1,
            [queries.phone]: { flexDirection: "column" },
          })}
        >
          {documentTypes.map((document) => {
            const title = intl.formatMessage({ id: "default.document.title" });
            return (
              <NewDocumentButton
                key={`btn-new-${document.type}`}
                title={document.label}
                onClick={() => {
                  if (document.type === DocumentTypeOptions.import) {
                    logEvent(user.analytics, "btn_click_importFromFile");
                    store.importFromFile();
                  } else {
                    logEvent(
                      user.analytics,
                      `btn_click_newDocument_options_${document.type}`
                    );
                    store.openNewDocument(
                      title,
                      document.type,
                      document.defaultCharacterName
                    );
                  }
                }}
                icon={getIconForDocumentType(document.type)}
                iconColor={getIconColorForDocumentType(document.type)}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
