export const sceneOptionsPlace = [
  {
    value: "INT.",
    label: "INT.",
    details: undefined,
    index: 1,
  },
  {
    value: "EXT.",
    label: "EXT.",
    details: undefined,
    index: 2,
  },
  {
    value: "INT./EXT.",
    label: "INT./EXT.",
    details: undefined,
    index: 2,
  },
  {
    value: "EXT./INT.",
    label: "EXT./INT.",
    details: undefined,
    index: 3,
  },
];
