import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";

const Privacy: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  return (
    <div {...css({ maxWidth: "70%" })}>
      <h1>Policies</h1>
      <span>Version 0.1.0 (08/26/2021).</span>
      <p>Coming soon</p>
    </div>
  );
};

export default observer(Privacy);
