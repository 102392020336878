import { css, CSSProperties } from "glamor";
import { Margins } from "../../styles/default";
import { TabItem, TabItemStyles } from "./TabItem";
import { merge } from "lodash";

const defaultStyles = {
  container: {
    display: "flex",
    height: "40px",
    marginRight: Margins.default,
    marginTop: "-15px",
    outline: "none",
  },
};
interface TabsStyles {
  container?: CSSProperties;
  tabItem?: TabItemStyles;
}

interface TabsType {
  items: Array<{
    action: () => void;
    icon?: any;
    id: string;
    label: string;
    type?: string;
  }>;
  selectedId?: string;
  style?: TabsStyles;
}
export const Tabs: (tabs: TabsType) => JSX.Element | null = ({
  items,
  selectedId,
  style,
}) => {
  const styles = merge({}, defaultStyles, style);

  return (
    <div role="navigation" tabIndex={0} {...css(styles.container)}>
      {items.map((item) => (
        <TabItem
          key={item.id}
          item={item}
          isSelected={selectedId === item.id}
          style={styles.tabItem}
        />
      ))}
    </div>
  );
};

export default Tabs;
