import { makeAutoObservable } from "mobx";

export class Midi {
  error: boolean;
  errorMessage?: string;
  constructor() {
    this.error = false;
    if (window) {
      window.addEventListener("load", this.requestMidiAccess);
    }

    makeAutoObservable(this);
  }

  requestMidiAccess = () => {
    const nav: any = navigator;
    if (nav.requestMIDIAccess) {
      const onMIDIInit = this.onMIDIInit;
      const onMIDISystemError = this.onMIDISystemError;
      nav.requestMIDIAccess().then(onMIDIInit, onMIDISystemError);
    } else {
      this.error = true;
      this.errorMessage = "Browser does not support midi";
    }
  };

  onMIDIInit = (midiAccess: any) => {
    const midiMessageReceived = (ev: any) => {
      const cmd = ev.data[0] >> 4;
      const noteNumber = ev.data[1];
      let velocity = 0;
      if (ev.data.length > 2) {
        velocity = ev.data[2];
      }
      const PRESSED = velocity > 0;
      // MIDI noteon with velocity=0 is the same as noteoff
      if (cmd === 8 || (cmd === 9 && velocity === 0)) {
        // noteoff
        // TODO ?
      } else if (cmd === 9) {
        // note on
        // TODO ?
      } else if (cmd === 11) {
        // controller message

        this.handleKey(noteNumber, PRESSED, velocity);
      } else {
        // probably sysex!
      }
    };

    for (const output of midiAccess.outputs.values()) {
      output.send([0x90, 3, 32]);
    }

    for (const input of midiAccess.inputs.values()) {
      input.onmidimessage = midiMessageReceived;
    }
  };

  handleKey = (key: number, pressed: boolean, value: any) => {
    console.log("PARENT:", key, pressed, value);
  };
  onMIDISystemError = (error: any) => {
    // Midi system error
    this.error = true;
    this.errorMessage = error;
  };
}
