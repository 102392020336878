import {
  frameToSmpte,
  fromTag,
  secondsToFrame,
  smpteToFrame,
} from "../lib/framerate-utils/src/index";
import { makeAutoObservable} from "mobx";
const APPLE_IAC_DRIVER_NAME = "IAC Driver Bus 1";
const MTC_MANUFACTURER = "Apple Inc.";
const MTC_STATE = "connected";

export enum FramerateStandards {
  RATE_23_976 = "RATE_23_976",
  RATE_24 = "RATE_24",
  RATE_25 = "RATE_25",
  RATE_29_97 = "RATE_29_97",
  RATE_29_97_DROP = "RATE_29_97_DROP",
  RATE_30 = "RATE_30",
  RATE_50 = "RATE_50",
  RATE_59_94 = "RATE_59_94",
  RATE_59_94_DROP = "RATE_59_94_DROP",
  RATE_60 = "RATE_60",
}

export enum FramerateTags {
  FPS_2397 = "FPS_2397",
  FPS_24 = "FPS_24",
  FPS_2400 = "FPS_2400",
  FPS_25 = "FPS_25",
  FPS_2500 = "FPS_2500",
  FPS_2997 = "FPS_2997",
  FPS_30 = "FPS_30",
  FPS_3000 = "FPS_3000",
  FPS_50 = "FPS_50",
  FPS_5000 = "FPS_5000",
  FPS_5994 = "FPS_5994",
  FPS_60 = "FPS_60",
  FPS_6000 = "FPS_6000",
}
export const DEFAULT_FRAMERATE_TAG = FramerateTags.FPS_24;

export default class MidiTimecode {
  buffer: number[];
  error: boolean;
  errorMessage?: string;
  hasMacOSMTCEnabled: boolean;
  currentFrame?: number;
  mtc: string;
  constructor() {
    this.currentFrame = undefined;
    this.mtc = "00:00:00:00";
    this.buffer = [0, 0, 0, 0, 0, 0, 0, 0];
    this.error = false;
    this.hasMacOSMTCEnabled = false;
    if (window) {
      window.addEventListener("load", this.requestMidiAccess);
    }
    makeAutoObservable(this);
  }

  get timecode(): string {
    return this.mtc;
  }

  get currentFrameString(): string | undefined {
    if (this.currentFrame) {
      return String(this.currentFrame);
    }
    return undefined;
  }

  get mtcConnected(): boolean {
    return this.hasMacOSMTCEnabled;
  }

  requestMidiAccess = () => {
    const nav: any = navigator;
    if (nav.requestMIDIAccess) {
      const onMIDIInit = this.onMIDIInit;
      const onMIDISystemError = this.onMIDISystemError;
      nav.requestMIDIAccess().then(onMIDIInit, onMIDISystemError);
    } else {
      this.error = true;
      this.errorMessage = "Browser does not support midi";
    }
  };

  onMIDIInit = (midiAccess: any) => {
    const midiMessageReceived = (ev: any) => {
      if (MidiTimecode.isMtcQuarterFrameMessage(ev.data)) {
        this.setTimecodeFromBuffer(ev);
      }
    };

    for (const output of midiAccess.outputs.values()) {
      output.send([0x90, 3, 32]);
    }

    for (const input of midiAccess.inputs.values()) {
      if (
        input.manufacturer === MTC_MANUFACTURER &&
        input.name === APPLE_IAC_DRIVER_NAME &&
        input.state === MTC_STATE
      ) {
        this.hasMacOSMTCEnabled = true;
      }
      input.onmidimessage = midiMessageReceived;
    }
  };

  onMIDISystemError = (error: any) => {
    // Midi system error
    this.error = true;
    this.errorMessage = error;
  };

  setTimecodeFromBuffer = (e: any) => {
    const data = e.data[1];

    const frameType = data >> 4;
    const frameData = data & 0xf;
    this.buffer[frameType] = frameData;
    const midiBuffer = MidiTimecode.bufferToTime(this.buffer);
    this.mtc = midiBuffer.timecode;
    const frameRate = fromTag(DEFAULT_FRAMERATE_TAG);
    this.currentFrame =
      secondsToFrame(frameRate, midiBuffer.totalSeconds) +
      midiBuffer.frameNumber;
  };

  static bufferToTime = (buffer: any) => {
    let hours = buffer[6] | ((buffer[7] & 0x1) << 4);
    // hours = hours > 0 ? hours - 1  : 0;
    let minutes = buffer[4] | (buffer[5] << 4);
    let seconds = buffer[2] | (buffer[3] << 4);
    let frameNumber = (buffer[0] | (buffer[1] << 4)) + 0;
    return {
      totalSeconds: MidiTimecode.convertHHMMSStoSeconds(
        hours,
        minutes,
        seconds,
      ),
      frameNumber,
      timecode:
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0") +
        ":" +
        String(frameNumber).padStart(2, "0"),
    };
  };

  static log = (...log: any) => {
    console.log(
      "%c🎹 Midi",
      "background-color: #fc0303;  color: white; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
    );
  };

  static convertHHMMSStoSeconds = (
    hh: number,
    mm: number,
    ss: number,
  ) => {
    // To convert hh:mm:ss time format to seconds: =HOUR(A2)*3600 + MINUTE(A2)*60 + SECOND(A2).
    return hh * 3600 + mm * 60 + ss;
  };

  static isMtcQuarterFrameMessage = (data: any): boolean => {
    // http://midi.teragonaudio.com/tech/mtc.htm
    return data[0] === 0xf1;
  };

  static framesToTimecodeString = (frames: number): string => {
    const frameRate = fromTag(DEFAULT_FRAMERATE_TAG);
    const smpte = frameToSmpte(frameRate, frames);

    return smpte;
  };

  static timecodeToFrames = (
    timecode?: string,
    handle?: number,
  ): string => {
    if (!timecode) {
      return "0";
    }
    let frameHandle = handle || 0;
    const frameRate = fromTag(DEFAULT_FRAMERATE_TAG);
    const frames = (
      smpteToFrame(frameRate, timecode) + frameHandle
    ).toString();
    return frames;
  };
}
