import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";

const PageNotFound: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  return <div>Not Found</div>;
};

export default observer(PageNotFound);
