import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getDatabase, ref, onValue } from "firebase/database";
import { makeAutoObservable } from "mobx";
import { DocumentTypeOptions } from "./textEditor/Document";
import Apis from "./Api";
import { currentLocale } from "./common/i18n/Locale";
import { firebaseConfig } from "./firebaseConfig";

class User {
  authenticated: boolean;
  authorized: boolean;
  displayName: string;
  language: string;
  email?: string;
  errorCode?: string;
  errorMessage?: string;
  firebaseUser: any;
  photoURL?: string;
  uid?: string;
  firebase: any;
  documentRefs: { id: string; type: DocumentTypeOptions }[];
  allowDiscovery: boolean;
  analytics: any;
  connected: boolean;

  constructor() {
    this.authenticated = false; // NOTE: You can be authenticated but not authorized
    this.authorized = false;
    this.language = currentLocale.locale;
    this.allowDiscovery = false;
    this.displayName = "";
    this.documentRefs = [];
    this.connected = false;
    this.initFirebase();

    makeAutoObservable(this);
  }

  get documentRefsByType(): {
    [id: string]: { id: string; type: DocumentTypeOptions }[];
  } {
    const docRefsById: {
      [id: string]: { id: string; type: DocumentTypeOptions }[];
    } = {};
    this.documentRefs.forEach((document) => {
      if (docRefsById[document.type]) {
        docRefsById[document.type] = [...docRefsById[document.type], document];
      } else {
        docRefsById[document.type] = [document];
      }
    });
    return docRefsById;
  }

  initFirebase = () => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(app);
    logEvent(this.analytics, "init_user_firebase");

    // Attach observer
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = auth.currentUser?.uid;
        this.setAuthorized(true);

        await this.fetchUserProfile(user?.email);

        this.setFirebaseUser(user);
        this.setDisplayName(user?.displayName || undefined);
        this.setPhotoURL(user?.photoURL || undefined);
        this.setUid(uid);
        this.setEmail(user?.email || undefined);
        this.setAuthenticated(true);

        this.detectConnectionState();
      } else {
        // User is signed out
        this.setAuthorized(false);
        this.setAuthenticated(true);
      }
    });
  };

  setFirebase = (firebase: any) => {
    this.firebase = firebase;
  };

  setFirebaseUser = (user: any) => {
    this.firebaseUser = user;
  };

  setAuthorized = (value: boolean) => {
    this.authorized = value;
  };

  setLanguage = (value: string) => {
    this.language = value;
    currentLocale.setStringsForLocale(value);
  };

  setEmail = (value?: string) => {
    this.email = value;
  };

  setDisplayName = (value?: string) => {
    this.displayName = value || "";
  };

  setUid = (value?: string) => {
    this.uid = value;
  };

  setErrorCode = (value?: string) => {
    this.errorCode = value;
  };

  setErrorMessage = (value?: string) => {
    this.errorMessage = value;
  };

  setAuthenticated = (value: boolean) => {
    this.authenticated = value;
  };

  setPhotoURL = (value?: string) => {
    this.photoURL = value;
  };

  fetchUserProfile = async (email: string | null) => {
    if (!email) {
      return;
    }
    const profile = await Apis.fetchUserProfile(email);
    // const data = profile?.docs; //profile?.data();
    this.setAllowDiscovery(profile?.allowDiscovery);
    this.setLanguage(profile?.language);
  };

  setAllowDiscovery = (value?: boolean) => {
    this.allowDiscovery = value || false;
  };
  signupWithEmailAndPassword(email: string, password: string) {
    this.setAuthenticated(false);
    // using firebase v8
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        this.setUid(user.uid);
        this.setEmail(user?.email || undefined);
        this.setAuthenticated(true);
        this.setAuthorized(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        this.setAuthenticated(true);
        this.setAuthorized(false);
        this.setErrorMessage(errorMessage);
        this.setErrorCode(errorCode);
      });
  }

  signIn = async (email: string, password: string): Promise<boolean> => {
    this.setAuthenticated(false);
    // using firebase v8
    const auth = getAuth();

    const signedIn = await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        this.setUid(user.uid);
        this.setEmail(user?.email || undefined);
        this.setAuthenticated(true);
        this.setAuthorized(true);
        this.setErrorMessage(undefined);
        this.setErrorCode(undefined);
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this.setAuthenticated(true);
        this.setAuthorized(false);
        this.setErrorMessage(errorMessage);
        this.setErrorCode(errorCode);
        return false;
      });
    return signedIn;
  };

  updateDisplayName = (displayName: string) => {
    if (this.firebaseUser) {
      // Updates the user attributes:
      updateProfile(this.firebaseUser, {
        displayName,
      }).then(
        () => {
          // Profile updated successfully!
          const displayName = this.firebaseUser.displayName;
          this.setDisplayName(displayName);
        },
        (error: any) => {
          // An error happened.
          console.log({ error });
        }
      );
    }
  };

  updatePhotoUrl = (photoURL: string) => {
    if (this.firebaseUser) {
      // Updates the user attributes:
      this.firebaseUser
        .updateProfile({
          photoURL,
        })
        .then(
          () => {
            // Profile updated successfully!
            const photoURL = this.firebaseUser.photoURL;
            this.setPhotoURL(photoURL);
          },
          (error: any) => {
            // An error happened.
            console.log({ error });
          }
        );
    }
  };

  addToDocumentRef = (documentId: string, type: DocumentTypeOptions) => {
    // only keep last edited documents
    // TODO create queue and push and pop
    let found = false;
    // don't allow to add duplicates
    this.documentRefs.forEach((dRef) => {
      if (dRef.id === documentId) {
        found = true;
      }
    });
    if (found) {
      return;
    }
    this.documentRefs = [...this.documentRefs, { id: documentId, type }];
  };

  logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        this.setUid(undefined);
        this.setAuthorized(false);
        this.setAuthenticated(true);
        this.setEmail(undefined);
        this.setDisplayName(undefined);
        this.setPhotoURL(undefined);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  detectConnectionState() {
    const db = getDatabase();
    const connectedRef = ref(db, ".info/connected");
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        this.setConnected(true);
      } else {
        this.setConnected(false);
      }
    });
  }

  setConnected = (value: boolean) => {
    this.connected = value;
  };
}

export default User;
export const user = new User();
