import { observer } from "mobx-react";
import React from "react";
import AppStore from "../../store/AppStore";
import { PopupOptions } from "../../store/Popup";
import PopupCharacter from "../popups/PopupCharacter";
import PopupSignIn from "../popups/PopupSignIn";
import PopupSignUp from "../popups/PopupSignUp";
import PopupLeaveUnsavedDocumentWarning from "../popups/PopupLeaveUnsavedDocumentWarning";
import PopupConfirmBeforeAction from "../popups/PopupConfirmBeforeAction";
import PopupConvertStandaloneToSeries from "../popups/PopupConvertStandaloneToSeries";
import PopupCloudSavingWarning from "../popups/PopupCloudSavingWarning";
import PopupJoinWaitlist from "../popups/PopupJoinWaitlist";
import PopupGenericFormDataUpdate from "../popups/PopupGenericFormDataUpdate";
import PopupDocumentShare from "../popups/PopupDocumentShare";
interface PopupsType {
  store: AppStore;
}

const Popups: React.FC<PopupsType> = ({ store }) => {
  const currentPopup = store.popups.active;
  const currentPopupId = store.popups.active?.id;
  if (!currentPopup) {
    return null;
  }
  return (
    <>
      {currentPopupId === PopupOptions.character && (
        <PopupCharacter store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.signIn && (
        <PopupSignIn store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.signUp && (
        <PopupSignUp store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.leaveDocumentWarning && (
        <PopupLeaveUnsavedDocumentWarning store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.confirmBeforeAction && (
        <PopupConfirmBeforeAction store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.convertStandaloneDocumentToSeries && (
        <PopupConvertStandaloneToSeries store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.cloudSavingWarning && (
        <PopupCloudSavingWarning store={store} popup={currentPopup} />
      )}
      {currentPopupId === PopupOptions.joinWaitlist && (
        <PopupJoinWaitlist store={store} popup={currentPopup} />
      )}

      {currentPopupId === PopupOptions.genericFormUpdate && (
        <PopupGenericFormDataUpdate store={store} popup={currentPopup} />
      )}

      {currentPopupId === PopupOptions.shareDocument && (
        <PopupDocumentShare store={store} popup={currentPopup} />
      )}
    </>
  );
};

export default observer(Popups);
