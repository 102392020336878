export enum FeatureOptions {
  availableInBeta = "availableInBeta",
  createFromAvailablePreset = "createFromAvailablePreset",
  export = "export",
  createAccount = "createAccount",
  createSeries = "createSeries",
  cloud = "cloud",
  collaborate = "collaborate",
  // record = "record",
  // assign = "assign",
  // downloadAudio = "downloadAudio",
  // createPersonalDocPreset = "createPersonalDocPreset",
  joinWaitlist = "joinWaitlist",
  // unlimitedAudioStorage = "unlimitedAudioStorage",
  // sixteenBitRecording = "sixteenBitRecording",
  // twentyFourBitRecording = "twentyFourBitRecording",
}

export interface SubscriptionOptionType {
  planId: string;
  features: string[];
  billMonthly: number;
  color: string;
}

export const subscriptionOptions = [
  {
    planId: "free",
    features: [
      FeatureOptions.availableInBeta,
      FeatureOptions.createFromAvailablePreset,
      FeatureOptions.export,
    ],
    billMonthly: 0,
    color: "#667C89",
  },
  {
    planId: "writer",
    features: [
      FeatureOptions.createAccount,
      FeatureOptions.cloud,
      FeatureOptions.createFromAvailablePreset,
      FeatureOptions.createSeries,
      FeatureOptions.export,
      FeatureOptions.collaborate,
      FeatureOptions.joinWaitlist,
    ],
    billMonthly: 6,
    color: "#97C05C",
  },
  // {
  //   planId: "creator",
  //   features: [
  //     FeatureOptions.assign,
  //     FeatureOptions.cloud,
  //     FeatureOptions.createAccount,
  //     FeatureOptions.createFromAvailablePreset,
  //     FeatureOptions.createSeries,
  //     FeatureOptions.export,
  //     FeatureOptions.record,
  //     FeatureOptions.joinWaitlist,
  //   ],
  //   billMonthly: 11,
  //   color: "#48A8EE",
  // },
  // {
  //   planId: "collaborator",
  //   features: [
  //     FeatureOptions.assign,
  //     FeatureOptions.cloud,
  //     FeatureOptions.createAccount,
  //     FeatureOptions.createFromAvailablePreset,
  //     FeatureOptions.createPersonalDocPreset,
  //     FeatureOptions.createSeries,
  //     FeatureOptions.downloadAudio,
  //     FeatureOptions.export,
  //     FeatureOptions.record,
  //     FeatureOptions.joinWaitlist,
  //   ],
  //   billMonthly: 49.99,
  //   color: P1_COLOR,
  // },
];
