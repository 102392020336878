import { makeAutoObservable } from "mobx";
import { get } from "lodash";
import { v4 } from "uuid";
import Scene, { NO_PREDEFINED_SUBHEADER } from "./textEditor/Scene";
import { Character } from "./textEditor/Character";
import { Dialogue } from "./textEditor/Dialogue";
import { logEvent } from "@firebase/analytics";
import { user } from "./User";
import { isString } from "../lib/audiox/models/utils";
const parser = require("fast-xml-parser");

export class Parser {
  scenes?: { [sceneId: string]: Scene };
  sceneOrder?: string[];
  characters?: { [characterId: string]: Character };
  dialogues?: { [dialogueId: string]: Dialogue };
  dialogueOrder?: string[];
  title?: string;
  constructor() {
    makeAutoObservable(this);
  }

  setScenes = (scenes: { [sceneId: string]: Scene }) => {
    this.scenes = scenes;
  };

  setSceneOrder = (sceneOrder: string[]) => {
    this.sceneOrder = sceneOrder;
  };

  setCharacters = (characters: { [characterId: string]: Character }) => {
    this.characters = characters;
  };

  setDialogues = (dialogues: { [dialogueId: string]: Dialogue }) => {
    this.dialogues = dialogues;
  };

  setDialogueOrder = (dialogueOrder: string[]) => {
    this.dialogueOrder = dialogueOrder;
  };

  readFile = async (file: File) => {
    const fileData = await new Response(file).text();
    return this.openDocumentFromXMLDataString(fileData);
  };

  importFromFDXFile(onParsingStarted: () => void) {
    const uploadInput = document.createElement("input");
    uploadInput.accept = ".fdx";
    uploadInput.type = "file";
    uploadInput.onchange = (e) => {
      const files = get(e, "dataTransfer.files") || get(e, "target.files");
      if (files) {
        this.handleFDXFile(e);
        onParsingStarted();
      }
    };
    uploadInput.click();
  }

  handleFDXFile(e: any) {
    e.preventDefault();

    const files = get(e, "dataTransfer.files") || get(e, "target.files");
    if (!files) {
      return undefined;
    } else {
      const file = files[0];
      return this.readFile(file);
    }
  }

  setTitle(title: string) {
    this.title = title;
  }

  openDocumentFromXMLDataString = (xmlData: any) => {
    const options = {
      attributeNamePrefix: "",
      attrNodeName: "type", //default is 'false'
      textNodeName: "text",
      ignoreAttributes: false,
      ignoreNameSpace: false,
      allowBooleanAttributes: false,
      parseNodeValue: true,
      parseAttributeValue: true,
      trimValues: true,
      cdataTagName: "__cdata", //default is 'false'
      cdataPositionChar: "\\c",
      parseTrueNumberOnly: false,
      // arrayMode: false, //"strict"
      // attrValueProcessor: (val, attrName) => he.decode(val, {isAttributeValue: true}),//default is a=>a
      // tagValueProcessor : (val, tagName) => he.decode(val), //default is a=>a
      stopNodes: ["parse-me-as-string"],
    };
    if (parser.validate(xmlData) === true) {
      //optional (it'll return an object in case it's not valid)
      //const jsonObj = parser.parse(xmlData, options);
    }

    // XML OBJECT FILE
    const tObj = parser.getTraversalObj(xmlData, options);
    const jsonObj = parser.convertToJson(tObj, options);
    const lines = jsonObj.FinalDraft.Content.Paragraph;
    const scenes: { [sceneId: string]: Scene } = {};

    let currentSceneId: string = v4();
    let sceneOrder: string[] = [];
    const characters: { [characterId: string]: Character } = {};

    let currentDialogueId: string | undefined;
    let characterId: string | undefined;
    const sceneSubheader = NO_PREDEFINED_SUBHEADER;
    const characterNames: { [characterName: string]: string } = {};
    let scene = new Scene({ subheader: sceneSubheader, id: currentSceneId });
    let dialogueText: string[] = [];
    let dialogueExt: string[] = [];
    let dialogueParenthetical: string[] = [];
    const dialogues: { [dialogueId: string]: Dialogue } = {};
    let dialogueOrder: string[] = [];

    // START PARSING LINES
    lines.forEach((line: any) => {
      const text = line.Text.text || line.Text;
      const type = line.type.Type;
      dialogueText = [...dialogueText, getText(text)];

      let sceneId = currentSceneId;
      // lets handle scenes
      if (type === "Scene Heading") {
        sceneId = v4();
        const { location, timeOfDay, place, action } = getSlugLine(
          getText(text)
        );
        scene = new Scene({
          subheader: sceneSubheader,
          id: sceneId,
          location,
          timeOfDay,
          place,
        });
        if (action) {
          scene.setAction(action);
        }

        scenes[sceneId] = scene;
        sceneOrder = [...sceneOrder, sceneId];
        dialogueText = [];
      } else if (type === "Character") {
        // let's handle characters
        if (text !== undefined) {
          let character = getCharacterName(text);
          dialogueExt = [...dialogueExt, character.extension || ""];
          const characterName = character.name.toUpperCase().trim();
          // only create new character if character has not yet been added
          if (!characterNames[characterName]) {
            characterId = v4();
            characters[characterId] = new Character({
              name: characterName,
              id: characterId,
              isDefault: false,
            });
            characterNames[characterName] = characterId;
          } else {
            // get character id for this character name
            characterId = characterNames[characterName];
          }
          dialogueText = [];
        }
      } else if (type === "General" || type === "Action" || type === "Insert") {
        currentDialogueId = v4();
        let dialogueType = type.toLowerCase();
        if (dialogueType === "general") {
          dialogueType = "action";
        }

        if (text !== undefined) {
          if (sceneId) {
            const dialogue: Dialogue = new Dialogue({
              id: currentDialogueId,
              text: getText(text),
              type: dialogueType,
            });

            if (currentDialogueId) {
              scene.setDialogueOrder([
                ...scene.dialogueOrder,
                currentDialogueId,
              ]);
              scene.addDialogueLine(dialogue);
            }
            dialogueText = [];
            dialogues[currentDialogueId] = dialogue;
            dialogueOrder = [...dialogueOrder, currentDialogueId];
          }
        }
      } else if (type === "Dialogue") {
        currentDialogueId = v4();
        let dialogueType = type.toLowerCase();
        if (dialogueType === "general") {
          dialogueType = "action";
        }

        if (text !== undefined) {
          if (sceneId) {
            const dialogue: Dialogue = new Dialogue({
              characterId,
              id: currentDialogueId,
              text: getText(dialogueText),
              type: dialogueType,
              characterExtension: dialogueExt.pop(),
              parentheticals: dialogueParenthetical.pop(),
            });

            if (currentDialogueId) {
              scene.setDialogueOrder([
                ...scene.dialogueOrder,
                currentDialogueId,
              ]);
              scene.addDialogueLine(dialogue);
            }
            dialogueText = [];
            dialogues[currentDialogueId] = dialogue;
            dialogueOrder = [...dialogueOrder, currentDialogueId];
          }
        }
      } else if (type === "Parenthetical") {
        dialogueParenthetical.push(text);
      } else if (type === "Transition") {
        scene.setTransition(getText(text));
      } else if (type === "Script Title" || type === "Episode Title") {
        // set document title
        this.setTitle(unquoteText(text));
      } else if (type === "End of Script") {
      } else {
        logEvent(user.analytics, "error_ignored_parsing_line", { type });
      }

      if (!scenes[currentSceneId]) {
        scenes[currentSceneId] = scene;
      }
    });

    this.setDialogues(dialogues);
    this.setDialogueOrder(dialogueOrder);
    this.setCharacters(characters);
    this.setSceneOrder(sceneOrder);
    this.setScenes(scenes);
  };
}

export function getCharacterName(text: any): {
  name: string;
  extension: string | undefined;
} {
  let name: string = getText(text);
  let extension: string | undefined;
  const character = name.split("(");
  if (character.length > 1) {
    name = character[0].trim();
    extension = getCharacterExtension(character[1]);
  }

  return {
    name,
    extension,
  };
}

export function getCharacterExtension(text: any): string | undefined {
  let extension: string | undefined;

  if (text) {
    const ext = text?.toLowerCase().trim();
    if (ext.includes("v.o")) {
      return "voiceOver";
    } else if (ext.includes("o.s")) {
      return "offscreen";
    } else if (ext.includes("cont")) {
      return "continued";
    }
  }
  return extension;
}

function getSlugLine(slug: string) {
  let location = "";
  let place = "";
  let timeOfDay = "";
  if (isString(slug)) {
    const text = slug.toUpperCase();
    if (text.includes("INT.")) {
      const slugLine = text.split("INT.");

      if (slugLine.length > 1) {
        place = "INT.";
        const slugCont = slugLine[1].split("-");
        location = slugCont[0].trim();
        if (slugCont.length > 1) {
          timeOfDay = slugCont[1]?.trim();
        }
      }
    } else if (text.includes("EXT.")) {
      const slugLine = text.split("EXT.");
      if (slugLine.length > 1) {
        place = "EXT.";
        const slugCont = slugLine[1].split("-");
        location = slugCont[0].trim();
        if (slugCont.length > 1) {
          timeOfDay = slugCont[1]?.trim();
        }
      }
    } else {
      return {
        action: text,
      };
    }
  } else {
    logEvent(user.analytics, "error_failed_parsing_slug", { slug });
  }

  return {
    location,
    place,
    timeOfDay,
  };
}

const isObject = (item: any) => {
  if (
    (typeof item === "object" || typeof item === "function") &&
    item !== null
  ) {
    return true;
  }
  return false;
};

export function getText(data: any): string {
  return decodeEntities(getTextFromData(data)) || "";
}
export function unquoteText(text: string) {
  return text.replace(/"/g, "");
}
export function getTextFromData(text: any): string {
  let content: string[] = [];
  let separator = "\n";

  if (isObject(text)) {
    if (Array.isArray(text)) {
      text.forEach((t) => {
        if (t.text) {
          separator = " ";
          content = [...content, t.text];
        } else {
          content = [...content, getText(t)];
        }
      });

      return content.join(separator);
    }
  }

  if (!isObject(text) && !Array.isArray(text)) {
    return text;
  }

  if (Array.isArray(text)) {
    return text.join(separator);
  }

  return text;
}

// encode(decode) html text into html entity
const decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  var element = document.createElement("div");

  function decodeHTMLEntities(str: string | null) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return decodeHTMLEntities;
})();
