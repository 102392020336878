import * as React from "react";
import { AccessibilityHelper } from "./AccessibilityHelper";
import { css } from "glamor";
import {
  SearchInput as SearchInputComponent,
  SearchInputStyleType,
} from "./SearchInput";
import {
  SearchResults as SearchResultsComponent,
  SearchResultsStyleType,
} from "./SearchResults";
import {
  SearchWrapper as SearchWrapperComponent,
  SearchWrapperStyleType,
} from "./SearchWrapper";
import { Loading as LoadingComponent } from "./Loading";
import { ListItem as ListItemComponent } from "./ListItem";
import { merge } from "lodash";
import { TypeAheadSelectedItem, TypeAheadStore } from "./Typeahead";
import { observer } from "mobx-react";

const A11Y_TYPEAHEAD_DESCRIBES_ID = "typeahead-search-instructions";
const A11Y_TYPEAHEAD_RESULTS_ID = "typeahead-search-results";

const defaultStyles = {
  container: {
    position: "relative",
    width: "100%",
  },
};

export interface ComposeTypeaheadStyleType {
  container?: React.CSSProperties;
  containerWithResults?: React.CSSProperties;
  searchInput?: SearchInputStyleType;
  searchResults?: SearchResultsStyleType;
  searchWrapper?: SearchWrapperStyleType;
}

interface TypeAheadV2Type {
  SearchInput?: any; // The component that will be used as the input for the typeahead
  SearchResults?: any; // The component that will be used as the dropdown
  SearchWrapper?: any; // A wrapper UI component that will be used to wrap the Input and dropdown components, usually purely UI
  Loading?: any; // A wrapper UI component that will be used to serve as loading screen
  EmptySuggestion?: any; // The component that will be shown if there are no suggestions for the typeahead
  ListItem?: any; // The component that will be used as the item in the dropdown
}

export const TypeAheadV2: React.FC<{
  ariaInstructionText?: string;
  ariaInstructionUpdates?: string;
  composeTypeahead?: TypeAheadV2Type;
  isValidSearch: boolean;
  showClearSearchBtn: boolean;
  store?: TypeAheadStore;
  style?: ComposeTypeaheadStyleType;
  [x: string]: any;
}> = observer((props) => {
  const {
    composeTypeahead,
    store,
    style,
    ariaInstructionText,
    ariaInstructionUpdates,
    isValidSearch,
    showClearSearchBtn,
  } = props;
  if (!store) {
    return null;
  }

  const disabled = store.disabled;
  const loading = store.searching;
  const searchTerm = props.value || store.searchTerm;
  const options = store.options;
  const styles = merge({}, defaultStyles, style);
  const hasOptions = store.options?.length > 0 || false;
  const SearchWrapper =
    composeTypeahead?.SearchWrapper || SearchWrapperComponent;
  const SearchInput = composeTypeahead?.SearchInput || SearchInputComponent;
  const SearchResults =
    composeTypeahead?.SearchResults || SearchResultsComponent;
  const ListItem = composeTypeahead?.ListItem || ListItemComponent;
  const Loading = composeTypeahead?.Loading || LoadingComponent;
  const selectedIndex = store.hoveredSelectionIndex;
  const selectedOptionId = store.selectedIndex;
  const showOptions = store.showOptions;
  const showEmpty = !hasOptions && !loading;
  const showContainerStylesWithDropdownDecoration =
    (showEmpty && isValidSearch) || showOptions;
  const containerStyles = showContainerStylesWithDropdownDecoration
    ? styles.containerWithResults
    : styles.container;

  return (
    <div {...css(containerStyles)} role="search" data-style="container">
      <SearchWrapper
        // {...props}
        searchTerm={searchTerm}
        showClearSearchBtn={showClearSearchBtn}
        // active={active}
        handleClearInput={() => {
          store.setSearchTerm("");
          store.onSelected(undefined);
          props.onSelected(undefined);
          store.reset();
        }}
        handleShowSuggestions={store.handleShowSuggestions}
        style={styles.searchWrapper}
      >
        <SearchInput
          {...props}
          onChange={(e: any) => {
            const searchTerm = e.target.value;
            if (!disabled) {
              store.setSearchTerm(searchTerm);
            }
            props.onChange(searchTerm, e);
          }}
          onKeyDown={!disabled ? store.onKeyDown : () => {}}
          onFocus={!disabled ? store.handleOnFocus : () => {}}
          onBlur={!disabled ? props.onBlur : () => {}}
          value={searchTerm}
          ariaOwns={A11Y_TYPEAHEAD_RESULTS_ID}
          ariaDescribedby={A11Y_TYPEAHEAD_DESCRIBES_ID}
          expanded={hasOptions}
          style={styles.searchInput}
          // requirementType={requirementType}
        />
        {loading === true && (
          <SearchResults
            // {...props}
            id={A11Y_TYPEAHEAD_RESULTS_ID}
            results={options}
            onSelected={(option: TypeAheadSelectedItem) => {
              store.onSelected(option);
              props.onSelected(option);
            }}
            query={searchTerm}
            selectedIndex={store.hoveredSelectionIndex}
            // sortedByGroup={optionsByGroup}
            selectedOptionId={selectedOptionId}
            ListItem={ListItem}
            style={styles.searchResults}
          >
            <Loading {...loading} />
          </SearchResults>
        )}
        {showOptions && (
          <SearchResults
            {...props}
            id={A11Y_TYPEAHEAD_RESULTS_ID}
            results={options}
            onSelected={(option: TypeAheadSelectedItem) => {
              store.onSelected(option);
              props.onSelected(option);
            }}
            query={searchTerm}
            selectedIndex={selectedIndex}
            // sortedByGroup={optionsByGroup}
            selectedOptionId={selectedOptionId}
            ListItem={ListItem}
            style={styles.searchResults}
          />
        )}

        {showEmpty && (
          <SearchResults
            {...props}
            id={A11Y_TYPEAHEAD_RESULTS_ID}
            results={options}
            onSelected={(option: TypeAheadSelectedItem) => {
              store.onSelected(option);
              props.onSelected(option);
            }}
            query={searchTerm}
            selectedIndex={selectedIndex}
            // sortedByGroup={optionsByGroup}
            selectedOptionId={selectedOptionId}
            ListItem={ListItem}
            style={styles.searchResults}
          >
            {props.emptyLabel}
          </SearchResults>
        )}
      </SearchWrapper>
      <AccessibilityHelper
        ariaDescribedbyId={A11Y_TYPEAHEAD_DESCRIBES_ID}
        instructionText={ariaInstructionText || ""}
        instructionUpdates={ariaInstructionUpdates || ""}
      />
    </div>
  );
});

export default TypeAheadV2;
