import React from "react";
import { observer } from "mobx-react";
import ActionMenu, {
  actionMenu,
  MENU_ID_SELECTOR,
} from "../../store/ActionMenu";
import { css } from "glamor";
import { ZIndexes } from "../../store/constants";
import {
  borderRadius,
  Color,
  defaultStyles,
  FontSizes,
  setAlpha,
  spacings,
} from "../../styles/default";

const Menu: React.FC<{
  menu: ActionMenu;
}> = ({ menu }) => {
  const options = menu.options;
  if (menu.show === false) {
    return null;
  }
  if (options.length === 0) {
    return null;
  }
  return (
    <div
      id={MENU_ID_SELECTOR}
      {...css({
        position: "absolute",
        ...actionMenu.positionStyles,
        backgroundColor: Color.surface,
        padding: spacings.space2,
        borderRadius: borderRadius.borderRadius1,
        border: `1px solid ${setAlpha(Color.onBackground, 0.05)}`,
        width: `${menu.width}px`,
        zIndex: ZIndexes.menu,
      })}
      onBlur={() => {
        menu.setShow(false);
      }}
    >
      {options.map((option) => {
        return (
          <button
            key={option.id}
            {...css({ ...defaultStyles.button.list })}
            onClick={() => {
              option.action();
              menu.setShow(false);
            }}
          >
            <span
              {...css({
                display: "flex",
                gridGap: spacings.space2,
                alignItems: "center",
              })}
            >
              {option.icon && (
                <span
                  className="material-icons"
                  {...css({ color: option.iconColor || Color.onBackground })}
                >
                  {option.icon}
                </span>
              )}
              <span
                {...css({ fontSize: FontSizes.fontSize3, textAlign: "start" })}
              >
                {option.label}
              </span>
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default observer(Menu);
