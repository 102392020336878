/* tslint:disable */
/* eslint-disable */
const _global =
  typeof window === "object" && window.window === window
    ? window
    : typeof self === "object" && self.self === self
    ? self
    : typeof global === "object" && global.global === global
    ? global
    : this;

export const downloadBlob = (blob: Blob, name = "file.webm") => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const downloadFileFromLink = (filepath: string, name = "file.webm") => {
  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = filepath;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);
  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};

export async function saveAs(
  blob: any,
  filename: string,
  opts?: any,
  popup?: any
) {
  // Open a popup immediately to go around popup blocker
  // Mostly only available on user interaction and the fileReader is async so...
  if (popup) {
    popup.document.title = popup.document.body.innerText = filename;
  }

  if (typeof blob === "string") {
    return await download(blob, filename, opts);
  }

  const force = blob.type === "application/octet-stream";
  //@ts-ignore
  const isSafari =
    //@ts-ignore
    /constructor/i.test(_global.HTMLElement) || _global.safari;
  const isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent);

  if ((isChromeIOS || (force && isSafari)) && typeof FileReader === "object") {
    const fileReader: any = FileReader;
    // Safari doesn't allow downloading of blob URLs
    const reader: any = new fileReader();
    reader.onloadend = () => {
      let url = reader.result;
      url = isChromeIOS
        ? url
        : url.replace(/^data:[^;]*;/, "data:attachment/file;");
      if (popup) {
        popup.location.href = url;
      } else {
        window.location = url;
      }
      popup = null; // reverse-tabnabbing #460
    };
    reader.readAsDataURL(blob);
  } else {
    //@ts-ignore
    const URL = _global.URL || _global.webkitURL;
    const url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
    setTimeout(function () {
      URL.revokeObjectURL(url);
      popup?.close();
    }, 4e4); // 40s
  }
}

export const download = (url: string, name: string, opts?: any) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.onload = function () {
    saveAs(xhr.response, name, opts);
  };
  xhr.onerror = function () {
    console.error("could not download file");
  };
  xhr.send();
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: any, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function saveDocumentToComputer(content: string, name: string) {
  var blob = new Blob([content], { type: "text" });
  saveAs(blob, `${name}.postudio`);
}

export function getMonthlyPriceBilledAnnually(monthlyPrice: number): number {
  return monthlyPrice - monthlyPrice * 0.2;
}

export function getAnnualPrice(monthlyPrice: number): number {
  return monthlyPrice * 12;
}

export function getAnnualPriceWithDiscount(monthlyPrice: number): number {
  return monthlyPrice * 12 - monthlyPrice * 12 * 0.2;
}

export function hasSearchTerm(searchTerm?: string, text?: string) {
  if (!isString(text)) {
    return false;
  }
  if (searchTerm === "") {
    return true;
  }
  if (searchTerm !== "" && text !== undefined && searchTerm !== undefined) {
    return text?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase());
  }
  return false;
}

export function isString(x: any) {
  return Object.prototype.toString.call(x) === "[object String]";
}
