import { makeAutoObservable } from "mobx";

export enum NavigationTabOptions {
  starred = "starred",
  recent = "recent",
  all = "all",
  shared = "shared",
  assigned = "assigned",
}

export class Ui {
  showUserProfileMenu: boolean;
  selectedSceneId: { [dialogueId: string]: boolean };
  selectedIds: { [dialogueId: string]: boolean };
  selectedNavigationTab?: NavigationTabOptions;
  filterDialogueByCharacterId: { [characterId: string]: boolean };
  expandedViews: { [viewId: string]: boolean };
  hideAllScenes: boolean;

  constructor() {
    this.showUserProfileMenu = false;
    this.selectedIds = {};
    this.selectedSceneId = {};
    this.filterDialogueByCharacterId = {};
    this.selectedNavigationTab = NavigationTabOptions.recent;
    this.expandedViews = {};
    this.hideAllScenes = true;
    makeAutoObservable(this);
  }

  get hasFiltersByCharacters() {
    const currentFiltersCount = Object.keys(
      this.filterDialogueByCharacterId
    ).length;
    const hasFilters = currentFiltersCount > 0;
    const hasFiltersToggledOff = Object.keys(
      this.filterDialogueByCharacterId
    ).filter((key) => this.filterDialogueByCharacterId[key] === false);
    return hasFilters && hasFiltersToggledOff.length !== currentFiltersCount;
  }

  clearPreviouslySelectedId = () => {
    this.selectedIds = {};
  };

  clearAllDialogueSelections = () => {
    this.selectedIds = {};
    this.selectedSceneId = {};
  };

  setShowScene = (sceneId: string, value: boolean) => {
    this.expandedViews[sceneId] = value;
  };

  setSelectedSceneId = (sceneId?: string) => {
    if (!sceneId) {
      this.selectedSceneId = {};
    } else {
      this.selectedSceneId[sceneId] = true;
    }
  };

  setHideAllScenes = (value: boolean) => {
    this.hideAllScenes = value;
  };

  setFilterDialogueByCharacterId = (characterId?: string, value?: boolean) => {
    if (characterId !== undefined) {
      if (value !== undefined) {
        this.filterDialogueByCharacterId[characterId] = value;
      } else {
        this.filterDialogueByCharacterId[characterId] = true;
      }
    } else {
      this.filterDialogueByCharacterId = {};
    }
  };

  removeFilterDialogueByCharacterId = (characterId?: string) => {
    if (characterId !== undefined) {
      this.filterDialogueByCharacterId[characterId] = false;
    } else {
      this.filterDialogueByCharacterId = {};
    }
  };

  setSelectedId = (id?: string) => {
    if (!id) {
      this.selectedIds = {};
    } else {
      this.selectedIds[id] = true;
    }
  };

  setShowUserProfileMenu = (value: boolean) => {
    this.showUserProfileMenu = value;
  };

  setSelectedNavigationTab = (tab?: NavigationTabOptions) => {
    this.selectedNavigationTab = tab;
  };

  resetSelections = () => {
    this.setSelectedNavigationTab(undefined);
    this.setFilterDialogueByCharacterId(undefined);
    this.setSelectedId(undefined);
    this.clearAllDialogueSelections();
  };
}

export const ui = new Ui();
