import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";
import AppStore from "../../store/AppStore";
import { Form } from "../../store/Form";
import Popup from "../../store/Popup";
import { user } from "../../store/User";
import { spacings } from "../../styles/default";
import { queries } from "../../styles/mediaQueries";
import { Dropdown } from "../common/dropdown/Dropdown";
import DropdownStore from "../common/dropdown/DropdownStore";
import { Input } from "../common/Input";
import { Popup as PopupComponent } from "../common/Popup";
import InviteToSubscribe from "../InviteToSubscribe";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupDocumentShare: React.FC<PopupsType> = ({ store, popup }) => {
  const intl = useIntl();
  const popupForm: Form = popup.data.form;
  const fields = popupForm.fields;
  if (!popupForm) {
    return null;
  }

  if (!popupForm || !fields) {
    return null;
  }
  const canShare = user.authenticated && user.authorized;

  return (
    <PopupComponent
      id="signup-popup"
      title={popup.title}
      cancel={{
        action: () => store.popups.clear(),
        label: intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: () => {
          if (canShare) {
            const formData = popupForm.getData;
            popup.data.onSubmit(formData);
          } else {
            store.popups.clear();
            store.openSignUp(
              intl.formatMessage({ id: "create.account.label" })
            );
          }
        },
        enabled: popupForm.isValid,
        label: canShare
          ? intl.formatMessage({ id: "btn.save" })
          : intl.formatMessage({ id: "create.account.label" }),
      }}
      style={{
        container: {
          width: "600px",
          [queries.phone]: {
            width: "300px",
          },
        },
      }}
    >
      {!canShare && <InviteToSubscribe store={store} />}
      {canShare && <ShareForm store={store} popup={popup} />}
    </PopupComponent>
  );
};

export default observer(PopupDocumentShare);

const ShareForm: React.FC<{ store: AppStore; popup: Popup }> = observer(
  ({ store, popup }) => {
    const intl = useIntl();
    const popupForm: Form = popup.data.form;
    const fields = popupForm.fields;

    if (!popupForm) {
      return null;
    }

    const dropdownOptions = [
      {
        value: "allowEdit",
        label: intl.formatMessage({ id: "form.allowEdit.label" }),
        action: () => {
          fields.permission.set("allowEdit");
        },
      },
      {
        value: "allowRead",
        label: intl.formatMessage({ id: "form.allowRead.label" }),
        action: () => {
          fields.permission.set("allowRead");
        },
      },
    ];

    const dropdownStore = new DropdownStore({
      id: "permissions-references",
      options: dropdownOptions,
      selected: {
        value: fields.permission.value,
        label: intl.formatMessage({
          id: `form.${fields.permission.value}.label`,
        }),
      },
      disabled: false,
    });

    return (
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space5,
          flexDirection: "column",
          gridGap: spacings.space2,
        })}
      >
        <div
          {...css({
            display: "flex",
            gridGap: spacings.space8,
            flexDirection: "row",
            [queries.phone]: {
              flexDirection: "column",
            },
          })}
        >
          <Input
            label={intl.formatMessage({ id: "form.email.label" })}
            value={fields.email.value}
            onChange={(e: any) => fields.email.set(e.target.value)}
            type="text"
            placeholder={intl.formatMessage({ id: "form.email.placeholder" })}
            required
          />

          <Dropdown
            label={intl.formatMessage({
              id: "form.permission.label",
            })}
            dropdownStore={dropdownStore}
            width="160"
          />
        </div>
      </div>
    );
  }
);
