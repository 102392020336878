import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import { ui } from "../../store/Ui";
import Files from "./Files";

const Viewer: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const selectedTab = ui.selectedNavigationTab as string;
  return <Files store={store} title={selectedTab} />;
};

export default observer(Viewer);
