import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import { css } from "glamor";
import DocumentInfo from "./DocumentInfo";
import { Characters } from "./Characters";
import { DocumentActions } from "./DocumentActions";
import {
  Color,
  Elevation,
  FontSizes,
  hint,
  hintLeft,
  spacings,
  Text,
} from "../../styles/default";
import { LayoutHeights, LayoutPaddings, ZIndexes } from "../../store/constants";
import { queries } from "../../styles/mediaQueries";
import { windowSizes } from "../../store/WindowSizeStore";
import ConnectionStatusIcon from "../common/ConnectionStatusIcon";
import Avatar from "react-avatar";
import { FormattedMessage } from "react-intl";

export const EDITOR_MIN_WIDTH = "300px";
const Editor: React.FC<{
  store: AppStore;
}> = ({ store, children }) => {
  const document = store.document;
  if (!document) {
    return null;
  }
  const paddingRight = LayoutPaddings.appContainerContracted.marginLeft;
  return (
    <div data-id="editor">
      <div
        data-id="editor-content"
        {...css({
          display: "flex",
          width: "100%",
          minHeight: windowSizes.height,
          paddingRight,
          boxSizing: "border-box",
          [queries.phone]: {
            paddingRight: spacings.space8,
          },
        })}
      >
        {children}
      </div>
      <EditorPanel store={store} />
      <DocumentActions store={store} document={document} />
    </div>
  );
};

export default observer(Editor);

const EditorPanel: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const [show, setShow] = React.useState(false);
  const document = store.document;
  const content = document?.document;
  if (!document || !content) {
    return null;
  }

  return (
    <div
      {...css({
        backgroundColor: Color.background,
        boxShadow: Elevation.depth10.boxShadow,
        padding: spacings.space4,
        position: "fixed",
        right: 0,
        top: LayoutHeights.appHeader + 1,
        zIndex: ZIndexes.panel,
      })}
    >
      <button
        onClick={() => {
          if (show === false) {
            setShow(true);
          } else {
            setShow(false);
          }
        }}
      >
        <span
          className="material-icons"
          {...css({
            fontSize: FontSizes.fontSize10,
            transform: !show ? "" : "rotate(180deg)",
          })}
        >
          menu_open
        </span>
      </button>
      {show && (
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
          })}
        >
          <SharedUsers store={store} />
          <DocumentInfo store={store} document={document} />
          <Characters
            characters={content?.characters}
            store={store}
            document={document}
          />
        </div>
      )}
      <SharedUsers store={store} vertical />
    </div>
  );
});

const SharedUsers: React.FC<{ store: AppStore; vertical?: boolean }> = observer(
  ({ store, vertical = false }) => {
    const document = store.document;
    if (!document) {
      return null;
    }
    const hasSharedUsers = document.sharedWith.length > 0;

    if (!hasSharedUsers) {
      return null;
    }
    return (
      <div>
        {!vertical && (
          <span {...css(Text.bodyBold)}>
            <FormattedMessage id="shared.with.label" />
          </span>
        )}
        <div
          {...css({
            display: "flex",
            flexDirection: vertical ? "column" : "row",
            gridGap: spacings.space2,
            marginTop: spacings.space1,
          })}
        >
          {document.sharedWith.map((userId) => {
            const connected = false;
            return (
              <div
                aria-label={userId}
                {...css({ position: "relative" }, hint, hintLeft)}
              >
                <div {...css({ opacity: connected ? 1 : 0.6 })}>
                  <Avatar
                    name={userId}
                    size="30"
                    color={connected ? Color.secondary : Color.offline}
                    round={true}
                  />
                </div>
                <ConnectionStatusIcon connected={connected} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
