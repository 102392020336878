import { makeAutoObservable } from "mobx";

const KEY_UP = "ArrowUp";
const KEY_DOWN = "ArrowDown";
const KEY_ENTER = "Enter";
const KEY_ESC = "Escape";

export interface TypeAheadSelectedItem {
  value: string;
  label: string;
  group?: string;
  details?: any;
  index?: number;
}
export class TypeAheadStore {
  searchTerm: string;
  selected?: TypeAheadSelectedItem;
  options: TypeAheadSelectedItem[];
  suggestions: TypeAheadSelectedItem[];
  hoveredSelectionIndex?: number;
  selectedIndex?: number;
  disabled: boolean;
  groupByOptions: boolean;
  searching: boolean;
  hasFocus: boolean;

  constructor() {
    this.searchTerm = "";
    this.options = [];
    this.suggestions = [];
    this.disabled = false;
    this.groupByOptions = false;
    this.searching = false;
    this.hasFocus = false;
    makeAutoObservable(this);
  }

  get showOptions(): boolean {
    return this.options?.length > 0 && this.selected === undefined;
  }

  setHasFocus(value: boolean) {
    this.hasFocus = value;
  }

  setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  setSelected = (selected?: TypeAheadSelectedItem) => {
    this.selected = selected;
  };

  setSelectedIndex = (value?: number) => {
    this.selectedIndex = value;
  };

  setSearching = (value: boolean) => {
    this.searching = value;
  };

  onSelected = (selected: TypeAheadSelectedItem | undefined) => {
    this.setSelected(selected);
    this.setSearchTerm(selected?.label || "");
  };

  setOptions = (options: TypeAheadSelectedItem[]) => {
    this.options = options;
  };

  setHoveredSelectedIndex = (value: number) => {
    this.hoveredSelectionIndex = value;
  };

  setHoveredNext = () => {
    let index = this.hoveredSelectionIndex || -1;
    const length = this.options?.length || 0;
    if (index < length) {
      index = index + 1;
    }

    this.setHoveredSelectedIndex(index);
  };

  setHoveredPrevious = () => {
    let index = this.hoveredSelectionIndex || 0;
    if (index > 0) {
      index = index - 1;
    }

    this.setHoveredSelectedIndex(index);
  };

  handleShowSuggestions() {
    // TODO
  }

  handleClearInput = () => {
    this.setSearchTerm("");
    this.setSelected(undefined);
    this.setOptions([]);
  };

  handleOnFocus() {
    //TODO
  }

  onKeyDown = (e: any) => {
    const key = e.key;

    // if there are no options, let's just return
    if (this.options?.length === 0) {
      return;
    }

    switch (key) {
      case KEY_UP:
        e.preventDefault(); // needed because otherwise cursor goes to the begining of input
        this.setHoveredPrevious();
        break;
      case KEY_DOWN: {
        e.preventDefault();
        this.setHoveredNext();
        break;
      }
      case KEY_ENTER: {
        e.preventDefault();
        // will send the selected option and clear the list
        const index = this.hoveredSelectionIndex;
        if (index !== undefined && index >= 0) {
          if (this.options) {
            const hoveredSelection = this.options[index];
            this.onSelected(hoveredSelection);
            this.setSearchTerm(hoveredSelection?.label || "");
            this.setOptions([]);
            this.setSelectedIndex(undefined);
          }
        }

        break;
      }
      case KEY_ESC: {
        e.stopPropagation();
        e.preventDefault();
        if (this.selected) {
          this.setSearchTerm(this.selected.label);
        }
        break;
      }
      default:
        break;
    }
  };

  reset = () => {
    this.setSelected(undefined);
    this.setSelectedIndex(undefined);
    this.setOptions([]);
    this.setSelectedIndex(undefined);
    this.setSearchTerm("");
  };
}
