import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppStore from "./store/AppStore";
import { observer } from "mobx-react";
import { startRouter } from "./store/router";
import { Shortcuts } from "./store/common/Shortcuts";
import DragSelectStore from "./store/DragSelect";
import { IntlProvider } from "react-intl";
import { currentLocale } from "./store/common/i18n/Locale";
// import DragSelect from "./components/common/DragSelect";

export const dragSelect = new DragSelectStore();
const store = new AppStore();
startRouter(store);

// const formats = {
//   time: {
//     "24hour": { hour12: true, hours: "numeric", minute: "numeric" },
//   },
// };
export const shortcuts = new Shortcuts(store);

const ProductionOneApp = observer(() => {
  const user = store.user;
  if (!user) {
    return null;
  }
  // let theme: "dark" | "light" = "dark";
  // if (store?.settings.theme.theme) {
  //   theme = store.settings.theme.theme;
  // }
  if (!store.user.authenticated) {
    return null;
  }

  const userLanguage = store.user.language || currentLocale.locale;

  return (
    <div
    // draggable
    // onDragStart={(e) => {
    //   if (!dragSelect.blockDrawingSelectionBox) {
    //     dragSelect.setDragType("selecting");
    //     const blankCanvas = document.createElement("canvas");
    //     dragSelect.setStartPosition(e.pageX, e.pageY);
    //     e.dataTransfer.setDragImage(blankCanvas, 0, 0);
    //   }
    // }}
    // onDrag={(e) => {
    //   if (dragSelect.dragType === "selecting") {
    //     dragSelect.setDimensions(e.pageX, e.pageY);
    //   }
    // }}
    // onDragEnd={() => {
    //   dragSelect.clearDraggingData();
    // }}
    >
      {/* <DragSelect dragSelect={dragSelect} /> */}
      <IntlProvider
        locale={userLanguage}
        messages={currentLocale.messages}
        // formats={formats}
      >
        <App store={store} />
      </IntlProvider>
    </div>
  );
});
ReactDOM.render(
  <ProductionOneApp />,
  document.getElementById("root") || document.createElement("div")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
