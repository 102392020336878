import { Router } from "director/build/director";
import { autorun } from "mobx";
import AppStore from "./AppStore";
import { DocumentTypeOptions } from "./textEditor/Document";
import { NavigationTabOptions } from "./Ui";
import { ViewOptionsType } from "./View";

export function startRouter(store: AppStore) {
  // update state on url change
  new Router({
    "/": () => {
      store.removeCurrentDocumentInLocalStorage();
      store.openHome();
    },
    "/pressrelease": () => {
      store.openPressRelease();
    },
    "/terms": () => {
      store.openTerms();
    },
    "/privacy": () => {
      store.openPrivacy();
    },
    "/pricing": () => {
      store.openPricing();
    },
    "/files/:tab": (tab: NavigationTabOptions) => {
      store.removeCurrentDocumentInLocalStorage();
      store.openFilesTab(tab);
    },
    "/document/:type": (type: ViewOptionsType) => {
      store.removeCurrentDocumentInLocalStorage();
      store.openDocumentsTab(type);
    },
    "/document/:type/:documentId": (
      type: DocumentTypeOptions,
      documentId: string
    ) => {
      store.openDocument(type, documentId);
    },
    "/document/:type/:documentId/preview": (
      type: DocumentTypeOptions,
      documentId: string
    ) => {
      store.openPreview(type, documentId);
    },
    "/user": () => {
      store.openProfile();
    },
    "/user/": () => {
      store.openProfile();
    },
  })
    .configure({
      before: () => {},
      notfound: () => store.openNotFound(),
      html5history: true,
    })
    .init();

  // update url on state changes
  autorun(() => {
    let path = store.view.path;
    let title = "";
    if (document && store.view.title) {
      title = document.title;
    }
    window.history.pushState(null, title, path);
  });
}
