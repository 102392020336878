/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { css } from "glamor";
import { merge } from "lodash";
import React from "react";
import {
  Color,
  Family,
  Paddings,
  defaults,
  setAlpha,
} from "../../../styles/default";
export const escapeRegExp = (text: string) => {
  return text?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const defaultStyles = {
  highlighted: {
    color: Color.onPrimary400,
    fontFamily: Family,
    fontSize: defaults.fontSize,
    height: "30px",
    cursor: "pointer",
  },
  item: {
    alignItems: "center",
    backgroundColor: Color.primary400,
    color: setAlpha(Color.onPrimary400, 0.6),
    cursor: "pointer",
    display: "flex",
    fontFamily: Family,
    fontWeight: 700,
    fontSize: defaults.fontSize,
    height: "30px",
    paddingBottom: Paddings.slim,
    paddingLeft: Paddings.default,
    paddingTop: Paddings.slim,
    ":hover": {
      color: Color.onPrimary400,
      backgroundColor: Color.highlight,
    },
  },
  selected: {
    alignItems: "center",
    backgroundColor: Color.highlight,
    color: setAlpha(Color.onPrimary400, 0.6),
    cursor: "pointer",
    display: "flex",
    fontFamily: Family,
    fontWight: 700,
    fontSize: defaults.fontSize,
    height: "30px",
    paddingBottom: Paddings.slim,
    paddingLeft: Paddings.default,
    paddingTop: Paddings.slim,
    ":hover": {
      color: Color.onPrimary400,
      backgroundColor: Color.highlight,
    },
    highlighter: {},
  },
  hightlight: {
    backgroundColor: "#DBD34A",
    color: "black",
    borderRadius: "2px",
    fontWeight: "bold",
  },
};

export interface HighlighterStylesType {
  highlighted?: React.CSSProperties;
  item?: React.CSSProperties;
  highlighter?: React.CSSProperties;
  selected?: React.CSSProperties;
  highlight?: React.CSSProperties;
}

export interface HighlighterType {
  id: string;
  tabIndex: number;
  ariaSelected: boolean;
  /**
   * The set of characters that will be highlighted
   * @type {String}
   */
  query: string;
  /**
   * The text that contains potential content to be highlighted.
   * It may or may not contain text that will be highlighted
   * @type {String}
   */
  content: string;
  /**
   * Substitute styles for highlighter
   * @type {String}
   */
  style?: HighlighterStylesType;
  /**
   * callback function that will be called on selected
   */
  onSelected: () => void;
  defaultSelected: boolean;
}

/**
 * Highlighter will return a component that wraps a styled div around
 * a query parameter on the content given. Note: given that we have to inject
 * DOM elements based on a query given by the user dangerouslySetInnerHTML can be
 * dangerous if you do not know what is in the HTML string you are injecting is.
 * I will use DOMPurify to sanitize the query string. DOMPurify is a
 * DOM-only, super-fast, uber-tolerant XSS sanitizer for HTML, MathML and SVG.
 * read more about DOMPurify here: https://github.com/cure53/DOMPurify
 * @param {Bool} specifies if item is selected or not
 * @param {String} query content you want to be highlighted
 * @param {String} content the string you want to be highlighted
 * @param {String} highlighterStyle the class name for the highligh style
 */
export const Highlighter: React.FC<HighlighterType> = ({
  ariaSelected,
  content,
  id,
  onSelected,
  query,
  defaultSelected,
  style,
}) => {
  const styles = merge({}, defaultStyles, style);
  const filteredQuery = escapeRegExp(query);
  const re = new RegExp(filteredQuery, "gi");
  const highlightedContent =
    content &&
    content.replace(re, (match) => {
      if (filteredQuery === "") {
        return match;
      }
      if (filteredQuery === content) {
        return match;
      }
      // const span = () => {
      //   return <span {...css(style.highlight)}>{match}</span>;
      // };
      // return span();
      return `<span
          style="
            background-color: #DBD34A;
            color: black;
            border-radius: 2px;
            font-weight: bold;
          ">
          ${match}
        </span>`;
    });
  const highlightStyles = ariaSelected ? styles.highlighted : styles.item;
  let DOMCONTENT = `<p style='border:0; padding: 0; margin:0;'>${highlightedContent}</p>`;
  if (defaultSelected) {
    DOMCONTENT = `<p style='border:0; padding: 0; margin:0; margin-left: -14px; color: white'> ✓ ${highlightedContent}</p>`;
  }

  return (
    <div
      role="option"
      {...css(
        styles.highlighter,
        highlightStyles,
        ariaSelected ? styles.selected : {}
      )}
      onClick={() => onSelected()}
      aria-selected={ariaSelected}
      dangerouslySetInnerHTML={{
        __html: DOMCONTENT,
      }}
      data-style="listItem:highlighter, highlighter:item, listItem:selected"
    />
  );
};
