import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { LayoutHeights } from "../store/constants";
import { Color, spacings } from "../styles/default";
import { MenuActionsTrigger } from "./common/MenuActionsTrigger";
import { FormattedMessage, useIntl } from "react-intl";
import { Parser } from "../store/Parser";
import { autorun, toJS } from "mobx";
import { v4 } from "uuid";
import Screenplay from "../store/textEditor/Screenplay";
import {
  Document,
  DocumentTypeOptions,
  NO_SERIES_ATTRIBUTION_ID,
} from "../store/textEditor/Document";
import {
  getIconColorForDocumentType,
  getIconForDocumentType,
} from "./finder/Files";
import { queries } from "../styles/mediaQueries";
import { feedback } from "../store/Feedback";
import { Podcast } from "../store/textEditor/Podcast";

const TriggerStyles = {
  backgroundColor: "transparent",
  fontWeight: 700,
  marginRight: spacings.space4,
  ":hover": {
    backgroundColor: "transparent",
  },
};
const AppActionsBar: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  return (
    <div
      {...css({
        height: LayoutHeights.appBar,
        backgroundColor: Color.background,
        width: "100%",
        paddingLeft: spacings.space8,
        paddingRight: spacings.space8,
        boxSizing: "border-box",
        display: "flex",
        [queries.phone]: {
          paddingLeft: spacings.space6,
          paddingRight: spacings.space6,
        },
      })}
    >
      <MenuFile store={store} />
      <MenuHelp store={store} />
    </div>
  );
};

export default observer(AppActionsBar);

const MenuFile: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const intl = useIntl();
  const untitledTitle = intl.formatMessage({ id: "default.document.title" });
  return (
    <MenuActionsTrigger
      styles={TriggerStyles}
      disappearOnScroll={true}
      options={[
        {
          id: "newScreenplay",
          label: intl.formatMessage({ id: "new.file.screenplay" }),
          icon: getIconForDocumentType(DocumentTypeOptions.screenplay),
          iconColor: getIconColorForDocumentType(
            DocumentTypeOptions.screenplay
          ),
          action: () => {
            store.openNewDocument(
              untitledTitle,
              DocumentTypeOptions.screenplay
            );
          },
        },
        {
          id: "newPodcast",
          label: intl.formatMessage({ id: "new.file.podcast" }),
          icon: getIconForDocumentType(DocumentTypeOptions.podcast),
          iconColor: getIconColorForDocumentType(DocumentTypeOptions.podcast),
          action: () => {
            store.openNewDocument(untitledTitle, DocumentTypeOptions.podcast);
          },
        },
        {
          id: "newOutline",
          label: intl.formatMessage({ id: "new.file.outline" }),
          icon: getIconForDocumentType(DocumentTypeOptions.outline),
          iconColor: getIconColorForDocumentType(DocumentTypeOptions.outline),
          action: () => {
            store.openNewDocument(untitledTitle, DocumentTypeOptions.outline);
          },
        },
        {
          id: "importPoStudioFile",
          label: intl.formatMessage({ id: "import.po.studio.file" }),
          icon: getIconForDocumentType(DocumentTypeOptions.import),
          iconColor: Color.secondary,
          action: () => {
            store.importFromFile();
          },
        },
        {
          id: "importScreenplayFDXFile",
          label: intl.formatMessage({ id: "import.fdx.screenplay.file" }),
          icon: getIconForDocumentType(DocumentTypeOptions.import),
          iconColor: getIconColorForDocumentType(DocumentTypeOptions.import),
          action: () => {
            const fdx = new Parser();
            feedback.setFeedback({
              message: intl.formatMessage({ id: "parsing.file.started" }),
              variant: "info",
            });

            const onParsingStarted = () => {
              feedback.setFeedback({
                message: intl.formatMessage({
                  id: "parsing.file.started",
                }),
                variant: "info",
              });
            };
            fdx.importFromFDXFile(onParsingStarted);

            const disposer = autorun(() => {
              if (fdx.scenes && fdx.sceneOrder) {
                feedback.setFeedback({
                  message: intl.formatMessage({
                    id: "importing.fdx.completed",
                  }),
                  variant: "success",
                });
                const documentTitle = fdx.title;
                const documentId = v4();
                const screenplay = new Screenplay({
                  documentId,
                  scenes: fdx.scenes,
                  characters: fdx.characters,
                  title:
                    documentTitle ||
                    intl.formatMessage({ id: "default.document.title" }),
                  starred: false,
                  sceneOrder: toJS(fdx.sceneOrder),
                  seriesId: NO_SERIES_ATTRIBUTION_ID,
                  scriptType: "spec",
                });

                const document = new Document({
                  id: documentId,
                  type: DocumentTypeOptions.screenplay,
                  title:
                    documentTitle ||
                    intl.formatMessage({ id: "default.document.title" }),
                  createdBy: "unknown",
                  lastUpdatedBy: "unknown",
                  document: screenplay,
                  starred: false,
                  seriesId: NO_SERIES_ATTRIBUTION_ID,
                  ownerUid: "unknown",
                });
                store.openDocumentFromDocument(document);
                store.document?.showAllScenes(false);
                store.setLoadingDocumentForFileType("PENDING_FETCH_ALL");
                disposer();
              }
            });
          },
        },
        {
          id: "importPodcastFDXFile",
          label: intl.formatMessage({ id: "import.fdx.podcast.file" }),
          icon: getIconForDocumentType(DocumentTypeOptions.import),
          iconColor: getIconColorForDocumentType(DocumentTypeOptions.import),
          action: () => {
            const fdx = new Parser();
            feedback.setFeedback({
              message: intl.formatMessage({ id: "parsing.file.started" }),
              variant: "info",
            });

            const onParsingStarted = () => {
              feedback.setFeedback({
                message: intl.formatMessage({
                  id: "parsing.file.started",
                }),
                variant: "info",
              });
            };
            fdx.importFromFDXFile(onParsingStarted);

            const disposer = autorun(() => {
              if (fdx.dialogues && fdx.dialogueOrder) {
                feedback.setFeedback({
                  message: intl.formatMessage({
                    id: "importing.fdx.completed",
                  }),
                  variant: "success",
                });
                const documentTitle = fdx.title;
                const documentId = v4();
                const podcast = new Podcast({
                  documentId,
                  characters: fdx.characters,
                  dialogues: fdx.dialogues,
                  title:
                    documentTitle ||
                    intl.formatMessage({ id: "default.document.title" }),
                  starred: false,
                  dialogueOrder: toJS(fdx.dialogueOrder || []),
                  seriesId: NO_SERIES_ATTRIBUTION_ID,
                });

                const document = new Document({
                  id: documentId,
                  type: DocumentTypeOptions.podcast,
                  title:
                    documentTitle ||
                    intl.formatMessage({ id: "default.document.title" }),
                  createdBy: "unknown",
                  lastUpdatedBy: "unknown",
                  document: podcast,
                  starred: false,
                  seriesId: NO_SERIES_ATTRIBUTION_ID,
                  ownerUid: "unknown",
                });
                store.openDocumentFromDocument(document);
                store.document?.showAllScenes(false);
                store.setLoadingDocumentForFileType("PENDING_FETCH_ALL");
                disposer();
              }
            });
          },
        },
      ]}
    >
      <FormattedMessage id="menu.file" />
    </MenuActionsTrigger>
  );
});

// help

const MenuHelp: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const intl = useIntl();
  return (
    <MenuActionsTrigger
      styles={TriggerStyles}
      disappearOnScroll={true}
      options={[
        {
          id: "aboutUs",
          label: intl.formatMessage({ id: "about.us.label" }),
          icon: "help",
          action: () => {
            store.openPressRelease();
          },
        },
        {
          id: "terms",
          label: intl.formatMessage({ id: "term.link.label" }),
          icon: "policy",
          action: () => {
            store.openTerms();
          },
        },
        {
          id: "feedback",
          label: intl.formatMessage({ id: "term.link.feedback" }),
          icon: "message",
          action: () => {
            store.openPressRelease();
          },
        },
        {
          id: "support",
          label: intl.formatMessage({ id: "term.link.support" }),
          icon: "question_answer",
          action: () => {
            store.openPressRelease();
          },
        },
      ]}
    >
      <FormattedMessage id="menu.help" />
    </MenuActionsTrigger>
  );
});
