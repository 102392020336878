import { Dialogue } from "./textEditor/Dialogue";
import { Document, DocumentTypeOptions } from "./textEditor/Document";
import { Podcast } from "./textEditor/Podcast";
import Scene from "./textEditor/Scene";
import Screenplay from "./textEditor/Screenplay";

export const TOTAL_LINES_PER_PAGE = 48; // MAX 55
export const TOTAL_CHAR_PER_LINE = 48;
export const TOTAL_CHAR_PER_LINE_IN_DIALOGUE = 30;

export const getTotalPagesInDocument = (document: Document) => {
  return getScriptPages(document).length;
};

export enum PaperLineType {
  dialogue = "dialogue",
  action = "action",
  parenthetical = "parenthetical",
  transition = "transition",
  insert = "insert",
  slugLine = "slugLine",
  character = "character",
}

export interface PaperLine {
  text: string;
  type: PaperLineType;
  sceneId?: string;
  characterExtension?: string;
  index: number;
}

export const getScriptPages = (document?: Document): PaperLine[][] => {
  if (!document) {
    return [];
  }
  let papers: { [paperId: string]: PaperLine[] } = {};
  const scriptLines = getScriptLines(document);
  let pageIndex = 0;
  let lineCount = 0;
  scriptLines.forEach((paperLine, i) => {
    //////////// CHECK FOR SCENE HEADING AS LAST ITEM IN PAGE /////////
    if (lineCount === TOTAL_LINES_PER_PAGE - 2) {
      // if found
      if (paperLine.type === PaperLineType.slugLine) {
        // let's send it to next page
        pageIndex = pageIndex + 1;
        lineCount = 0;
      }
      console.log(" we are here", paperLine);
    }
    if (papers[pageIndex]) {
      papers[pageIndex] = [...papers[pageIndex], paperLine];
    } else {
      papers[pageIndex] = [paperLine];
    }

    if (lineCount === TOTAL_LINES_PER_PAGE - 1) {
      lineCount = 0;
      pageIndex = pageIndex + 1;
      //////////// CHECK FOR DIALOGUE LINES AS FIRST ITEM ////////////
      // check for first line of a page to see if it is a dialogue
      // now that we are about to go to the next page, let's
      // see if next page starts with a dialogue
      const nextLineIndex = i + 1;
      if (scriptLines.length >= nextLineIndex) {
        const nextLine = scriptLines[nextLineIndex];
        if (nextLine.type === PaperLineType.dialogue) {
          // let's not let an orphan dialogue line show up in the
          // beginning of the page
          // let's add parenthetical "more" to this page
          papers[pageIndex - 1] = [
            ...papers[pageIndex - 1],
            {
              text: "(MORE)",
              type: PaperLineType.parenthetical,
              sceneId: nextLine.sceneId,
              index: 0,
            },
          ];
          // let's increment the line count
          lineCount = lineCount + 1;

          // Now insert a character in the next page for the next line
          papers[pageIndex] = [
            {
              text: getMostRecentCharacter(scriptLines, i),
              type: PaperLineType.character,
              characterExtension: "continued",
              sceneId: nextLine.sceneId,
              index: 0,
            },
          ];
          // let's increment the line count
          lineCount = lineCount + 1;
        }
      }
      //////////// END CHECK FOR DIALOGUE LINES AS FIRST ITEM ////////////
    } else {
      lineCount = lineCount + 1;
    }
  });
  return Object.keys(papers).map((key) => papers[key]);
};

export const getMostRecentCharacter = (
  scriptLines: PaperLine[],
  index: number
): string => {
  let characterId = "unknown";
  for (let i = index; i >= 0; i--) {
    // go backwards in list
    const line = scriptLines[i];
    if (line.type === PaperLineType.character) {
      if (line.text !== "") {
        characterId = line.text;
        break;
      }
    }
  }
  return characterId;
};

export const getScriptLines = (document?: Document): PaperLine[] => {
  let paperLines: PaperLine[] = [];
  if (document?.type === DocumentTypeOptions.screenplay) {
    const doc = document.document as Screenplay;
    const scenes = doc.scenes;
    const sceneOrder = doc.sceneOrder;

    sceneOrder.forEach((sceneId) => {
      const scene = scenes[sceneId];
      // Add Slug Line
      paperLines = [
        ...paperLines,
        {
          text: getSlugLine(scene),
          type: PaperLineType.slugLine,
          sceneId: scene.id,
          index: 0,
        },
      ];
      // Add Action Lines
      if (scene.action) {
        const actionLines = getPaperLinesForText(
          TOTAL_CHAR_PER_LINE,
          scene.action,
          PaperLineType.action,
          scene.id
        );
        paperLines = [...paperLines, ...actionLines];
      }
      // Add Dialogue Lines
      const transitionLines = getPaperLinesForDialogues(
        scene.dialogueOrder,
        scene.dialogues,
        scene.id
      );
      paperLines = [...paperLines, ...transitionLines];
      // Add Transition Lines
      if (scene.transition) {
        const transitionLines = getPaperLinesForText(
          TOTAL_CHAR_PER_LINE,
          scene.transition,
          PaperLineType.transition,
          scene.id
        );
        paperLines = [...paperLines, ...transitionLines];
      }
    });
  } else if (
    document?.type === DocumentTypeOptions.podcast ||
    document?.type === DocumentTypeOptions.outline
  ) {
    const doc = document.document as Podcast;
    const dialogues = doc.dialogues;
    const dialogueOrder = doc.dialogueOrder;
    paperLines = getPaperLinesForDialogues(dialogueOrder, dialogues);
  }
  return paperLines;
};

export const getSlugLine = (scene: Scene) => {
  return `${scene.place || ""} ${scene.location || ""} ${
    scene.timeOfDay || ""
  }`;
};

export const getPaperLinesForDialogues = (
  dialogueOrder: string[],
  dialogues: {
    [dialogueId: string]: Dialogue;
  },
  sceneId?: string
): PaperLine[] => {
  let paperLine: PaperLine[] = [];

  dialogueOrder.forEach((dialogueId) => {
    const dialogue: Dialogue = dialogues[dialogueId];
    // let's only include line when dialogue has text
    if (dialogue?.text !== undefined && dialogue?.text !== "") {
      // add character
      paperLine = [
        ...paperLine,
        {
          type: PaperLineType.character,
          sceneId,
          text: dialogue.characterId || "",
          characterExtension: dialogue.characterExtension,
          index: 0,
        },
      ];

      // add dialogue lines
      const isDialogue = dialogue.type === "dialogue";
      // the amount of max characters per line in the dialogue will depend on the type
      // of document this is, for podcasts, or outline, we print the whole thing
      const maxCharsPerLine = sceneId
        ? isDialogue
          ? TOTAL_CHAR_PER_LINE_IN_DIALOGUE
          : TOTAL_CHAR_PER_LINE
        : TOTAL_CHAR_PER_LINE;
      const dialogueLines = getPaperLinesForText(
        maxCharsPerLine,
        dialogue.text || "",
        isDialogue ? PaperLineType.dialogue : PaperLineType.action,
        sceneId
      );

      paperLine = [...paperLine, ...dialogueLines];
    }
  });
  return paperLine;
};

export const getPaperLinesForText = (
  maxCharsPerLine: number,
  text: string,
  type: PaperLineType,
  sceneId?: string
): PaperLine[] => {
  let paperLine: PaperLine[] = [];
  const lines = text.split("\n");
  lines.forEach((line) => {
    const paperLines = getPaperLinesForLine(
      maxCharsPerLine,
      line,
      type,
      sceneId
    );

    paperLine = [...paperLine, ...paperLines];
  });

  return paperLine;
};

export const getPaperLinesForLine = (
  maxCharsPerLine: number,
  text: string,
  type: PaperLineType,
  sceneId?: string
): PaperLine[] => {
  // we are going to split lines at exactly the amount of characters we
  // can keep per line
  let paperLine: PaperLine[] = [];
  const lines = text.split("\n");

  lines.forEach((line) => {
    const words = line.split(" ");
    let lineCount = 0;
    let charCount = 0;
    let substring: string[] = [];

    words.forEach((word) => {
      const wordLength = word.length + charCount;
      const keepWordInCurrentLine = wordLength < maxCharsPerLine;

      if (keepWordInCurrentLine) {
        substring = [...substring, word];
        charCount = word.length + charCount;
      } else {
        paperLine = [
          ...paperLine,
          {
            text: substring.join(" ").trim(),
            sceneId,
            type,
            index: lineCount,
          },
        ];
        substring = [word];
        charCount = word.length;
        lineCount = lineCount + 1;
      }
    });

    const currentText = substring.join(" ").trim();
    if (isParenthetical(currentText)) {
      paperLine = [
        ...paperLine,
        {
          text: currentText,
          sceneId,
          type: PaperLineType.parenthetical,
          index: lineCount,
        },
      ];
    } else {
      paperLine = [
        ...paperLine,
        {
          text: currentText,
          sceneId,
          type,
          index: lineCount,
        },
      ];
    }
  });

  return paperLine;
};

export const isParenthetical = (text: string): boolean => {
  const t = text?.trim();
  if (t.indexOf("(") === 0) {
    // it starts with (
    //now let's check if it ends with )
    if (t.indexOf(")") === t.length - 1) {
      return true;
    }
  }
  return false;
};
