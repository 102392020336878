import React from "react";
import { observer } from "mobx-react";
import AppStore from "../../store/AppStore";
import { css } from "glamor";
import {
  borderRadius,
  Color,
  defaultStyles,
  FontSizes,
  setAlpha,
  spacings,
  Text,
  truncateTextWithEllipsis,
} from "../../styles/default";
import { useIntl } from "react-intl";
import DocumentIcon from "../DocumentIcon";
import { FileStore } from "../../store/FileStore";
import moment from "moment";
import { DocumentTypeOptions } from "../../store/textEditor/Document";
import EmptyFolderImage from "../../images/emptyFolder.svg";
import { FileMetadata, FileMetadataType } from "../../store/FileMetadata";
import { MenuOptions } from "../../store/ActionMenu";
import { feedback } from "../../store/Feedback";
import { LoadingScreen } from "../common/LoadingScreen";
import { Dropdown } from "../common/dropdown/Dropdown";
import DropdownStore from "../common/dropdown/DropdownStore";
import { SortByOrderOptions } from "../../store/utils/sort";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import CreateNewDocumentButton from "../document/CreateNewDocumentButton";
import { FormattedMessage } from "react-intl";
import { queries } from "../../styles/mediaQueries";
import { ViewOptionsType } from "../../store/View";
import { logEvent } from "@firebase/analytics";
import { user } from "../../store/User";

const DEFAULT_ICON_SIZE_THUMB = 50;
const DEFAULT_ICON_SIZE_LIST = 24;
const EMPTY_TOP_GUTTER = 100;
export enum LayoutTypeOptions {
  list = "list",
  thumb = "thumb",
}

const ContainerThumbStyle = {
  width: "200px",
  position: "relative",
  borderRadius: borderRadius.borderRadius3,
  backgroundColor: setAlpha(Color.surface, 0.3),
  padding: spacings.space4,
  paddingTop: spacings.space8,
  ":hover": {
    cursor: "pointer",
    backgroundColor: Color.surface,
  },
};
const ContainerListStyle = {
  width: "100%",
  borderRadius: borderRadius.borderRadius3,
  backgroundColor: setAlpha(Color.surface, 0.3),
  display: "grid",
  gridTemplateColumns: "20px 5fr 1fr 50px",
  paddingLeft: spacings.space4,
  paddingRight: spacings.space4,
  gridGap: spacings.space3,
  alignItems: "center",
  ":hover": {
    cursor: "pointer",
    backgroundColor: Color.surface,
  },
};

const Files: React.FC<{
  store: AppStore;
  title: string;
}> = ({ store, title }) => {
  const intl = useIntl();
  const fileStore = store.files;
  const hasFiles = fileStore.hasFiles;
  const recentType = fileStore.filterBy;

  if (store.loadingDocumentForFileType === "PENDING_FETCH_ALL") {
    return (
      <LoadingScreen
        id="loading"
        message={intl.formatMessage({ id: "loading.documents" })}
        spinner={{ thickness: 4 }}
      />
    );
  }

  if (!hasFiles) {
    if (title === "starred") {
      return <EmptyFavorites store={store} />;
    }
    if (title === "shared") {
      return <EmptyShared store={store} />;
    }
    if (title === "assigned") {
      return <EmptyAssigned store={store} />;
    }
    return <EmptyFiles store={store} type={recentType} />;
  }
  const currentViewId = store.view.id;
  const isNotShowingNavigation =
    !store.user.authorized ||
    ![ViewOptionsType.home, ViewOptionsType.finder].includes(currentViewId);
  return (
    <div
      data-id="Files"
      {...css({
        marginTop: spacings.space8,
        marginRight: spacings.space8,
        [queries.phone]: {
          marginLeft: isNotShowingNavigation ? 0 : "60px",
        },
      })}
    >
      <FileHeader fileStore={fileStore} title={title} />
      <FilesList fileStore={fileStore} store={store} />
    </div>
  );
};

export default observer(Files);

const FilesList: React.FC<{ fileStore: FileStore; store: AppStore }> = observer(
  ({ store, fileStore }) => {
    const intl = useIntl();
    const files = fileStore.files;
    const layout = fileStore.viewOption;
    return (
      <div
        {...css({
          display: "flex",
          flexWrap: "wrap",
          gridGap: spacings.space2,
        })}
      >
        {files.map((f: FileMetadata) => {
          const menuOptions = [
            {
              id: "rename",
              label: intl.formatMessage({
                id: "action.btn.document.rename",
              }),
              icon: "drive_file_rename_outline",
              action: async () => {
                alert("TODO");
              },
            },
            {
              id: "duplicate",
              label: intl.formatMessage({
                id: "action.btn.document.duplicate",
              }),
              icon: "file_copy",
              action: async () => {
                alert("TODO");
              },
            },
            {
              id: "delete",
              label: intl.formatMessage({ id: "action.btn.document.delete" }),
              icon: "delete_outline",
              action: async () => {
                feedback.setFeedback({
                  message: intl.formatMessage({
                    id: "feedback.deleting.document",
                  }),
                  variant: "info",
                });
                const deleted = await store.deleteDocument(f.documentId);
                if (deleted === true) {
                  feedback.setFeedback({
                    message: intl.formatMessage({
                      id: "feedback.document.deleted.success",
                    }),
                    variant: "success",
                  });
                  store.files.removeFromRecent(f.documentId);
                } else {
                  feedback.setFeedback({
                    message: intl.formatMessage({
                      id: "feedback.document.deleted.error",
                    }),
                    variant: "error",
                  });
                }
              },
            },
          ];
          if (layout === "thumb") {
            return (
              <Thumb
                key={f.documentId}
                store={store}
                document={f}
                menuOptions={menuOptions}
              />
            );
          }
          return (
            <Row
              key={f.documentId}
              store={store}
              document={f}
              menuOptions={menuOptions}
            />
          );
        })}
      </div>
    );
  }
);

const Row: React.FC<{
  store: AppStore;
  document: FileMetadataType;
  menuOptions: MenuOptions[];
}> = observer(({ store, document, menuOptions }) => {
  const seriesId = document.seriesId;
  const documentSeriesName =
    seriesId && store.folderReferences[seriesId]
      ? store.folderReferences[seriesId].name
      : undefined;

  return (
    <div
      {...css(ContainerListStyle)}
      key={`${document.documentId}-${document.type}`}
      onClick={() => {
        logEvent(user.analytics, `btn_click_openDocument_${document.type}`);
        store.openDocument(document.type, document.documentId);
      }}
    >
      <div
        {...css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <DocumentIcon type={document.type} size={DEFAULT_ICON_SIZE_LIST} />
      </div>

      <p {...css(truncateTextWithEllipsis)}>
        {document.title}{" "}
        <span {...css({ fontStyle: "italic", marginLeft: spacings.space2 })}>
          {documentSeriesName || ""}
        </span>
      </p>
      <p {...css({ fontSize: FontSizes.fontSize1, opacity: 0.5 })}>
        {moment(new Date(document.updatedAt)).fromNow()}{" "}
      </p>

      <MenuActionsTrigger options={menuOptions} />
    </div>
  );
});

const Thumb: React.FC<{
  store: AppStore;
  document: FileMetadataType;
  menuOptions: MenuOptions[];
}> = observer(({ store, document, menuOptions }) => {
  const seriesId = document.seriesId;
  const documentSeriesName =
    seriesId && store.folderReferences[seriesId]
      ? store.folderReferences[seriesId].name
      : undefined;
  return (
    <div
      {...css(ContainerThumbStyle)}
      key={`${document.documentId}-${document.type}`}
      onClick={() => {
        logEvent(user.analytics, `btn_click_openDocument_${document.type}`);
        store.openDocument(document.type, document.documentId);
      }}
    >
      {document.starred && (
        <div
          {...css({
            position: "absolute",
            right: spacings.space2,
            top: spacings.space2,
          })}
        >
          <span className="material-icons" {...css({ color: "yellow" })}>
            star
          </span>
        </div>
      )}
      <div
        {...css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: spacings.space5,
        })}
      >
        <DocumentIcon type={document.type} size={DEFAULT_ICON_SIZE_THUMB} />
      </div>
      <div
        {...css({
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space0,
        })}
      >
        <div {...css({ fontStyle: "italic" })}>{documentSeriesName || ""}</div>
        <div {...css(truncateTextWithEllipsis)}>{document.title}</div>

        <span
          {...css({
            fontSize: FontSizes.fontSize1,
            opacity: 0.5,
            display: "flex",
            gridGap: spacings.space0,
            fontStyle: "italic",
            fontWeight: 700,
          })}
        >
          <span>
            <FormattedMessage id="edited.label" />
          </span>
          <span>{moment(new Date(document.updatedAt)).fromNow()} </span>
        </span>
      </div>

      <div {...css({ display: "flex", justifyContent: "flex-end" })}>
        <MenuActionsTrigger options={menuOptions} />
      </div>
    </div>
  );
});

const FileHeader: React.FC<{
  fileStore: FileStore;
  title: string;
}> = observer(({ fileStore, title }) => {
  const intl = useIntl();
  const titleString = intl.formatMessage({ id: `viewerTitle.${title}` });

  return (
    <div
      {...css({
        display: "flex",
        justifyContent: "space-between",
        [queries.phone]: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginBottom: spacings.space4,
        },
      })}
    >
      <div
        {...css({
          display: "flex",
          alignItems: "center",
          [queries.phone]: {
            justifyContent: "space-between",
          },
        })}
      >
        <h2>
          {titleString} ({fileStore.files.length})
        </h2>
        <div
          {...css({
            display: "none",
            [queries.phone]: {
              display: "block",
            },
          })}
        >
          <ViewOptionsPicker fileStore={fileStore} />
        </div>
      </div>

      <div
        {...css({
          display: "flex",
          alignItems: "center",
          gridGap: spacings.space8,
        })}
      >
        <SortBySelector fileStore={fileStore} />
        <div
          {...css({
            display: "block",
            [queries.phone]: {
              display: "none",
            },
          })}
        >
          <ViewOptionsPicker fileStore={fileStore} />
        </div>
      </div>
    </div>
  );
});

const SortBySelector: React.FC<{ fileStore: FileStore }> = observer(
  ({ fileStore }) => {
    const intl = useIntl();
    const dropdownStore = new DropdownStore({
      id: "files-sortBy",
      options: [
        {
          value: "updatedAt",
          label: intl.formatMessage({
            id: "dropdown.fileSortOptions.updatedAt",
          }),
          action: () => {
            fileStore.setSortBy("updatedAt");
          },
        },
        {
          value: "createdAt",
          label: intl.formatMessage({
            id: "dropdown.fileSortOptions.createdAt",
          }),
          action: () => {
            fileStore.setSortBy("createdAt");
          },
        },
        {
          value: "title",
          label: intl.formatMessage({ id: "dropdown.fileSortOptions.title" }),
          action: () => {
            fileStore.setSortBy("title");
          },
        },
      ],
      selected: {
        value: fileStore.sortBy,
        label: intl.formatMessage({
          id: `dropdown.fileSortOptions.${fileStore.sortBy}`,
        }),
      },
      disabled: false,
    });
    const isInAcendingOrder =
      fileStore.orderBy === SortByOrderOptions.ascending;
    return (
      <div
        {...css({
          display: "flex",
          gridGap: spacings.space2,
          alignItems: "center",
        })}
      >
        <span {...css(Text.overline)}>
          <FormattedMessage id="sortBy.label" />
        </span>

        <Dropdown dropdownStore={dropdownStore} width="200px" />
        <button
          {...css(defaultStyles.button.primary)}
          onClick={() => {
            if (isInAcendingOrder) {
              logEvent(
                user.analytics,
                `btn_select_SortByOrderOptions_descending`
              );
              fileStore.setOrderBy(SortByOrderOptions.descending);
            } else {
              logEvent(
                user.analytics,
                `btn_select_SortByOrderOptions_ascending`
              );
              fileStore.setOrderBy(SortByOrderOptions.ascending);
            }
          }}
        >
          <span className="material-icons">
            {isInAcendingOrder ? "arrow_downward" : "arrow_upward"}
          </span>
        </button>
      </div>
    );
  }
);

const ViewOptionsPicker: React.FC<{ fileStore: FileStore }> = observer(
  ({ fileStore }) => {
    return (
      <div
        {...css({
          display: "flex",
          gridGap: spacings.space2,
          alignItems: "center",
        })}
      >
        <button
          {...css(defaultStyles.button.primary)}
          onClick={() => {
            logEvent(user.analytics, `btn_click_setViewOption_list`);
            fileStore.setViewOption("list");
          }}
        >
          <span className="material-icons">list</span>
        </button>
        <button
          {...css(defaultStyles.button.primary)}
          onClick={() => {
            logEvent(user.analytics, `btn_click_setViewOption_thumb`);
            fileStore.setViewOption("thumb");
          }}
        >
          <span className="material-icons">grid_view</span>
        </button>
      </div>
    );
  }
);

const EmptyFiles: React.FC<{ store: AppStore; type?: DocumentTypeOptions }> =
  observer(({ store, type }) => {
    const intl = useIntl();

    return (
      <div
        {...css({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: EMPTY_TOP_GUTTER,
          width: "80%",
          marginBottom: "300px",
        })}
      >
        <div {...css({ position: "relative" })}>
          <img src={EmptyFolderImage} alt="Empty Folder" width="200px" />
          <div {...css({ position: "absolute", bottom: 50, right: 30 })}>
            <span
              className="material-icons"
              {...css({ opacity: 0.5, fontSize: "50px" })}
            >
              {type && getIconForDocumentType(type)}
            </span>
          </div>
        </div>
        <h2
          {...css({
            opacity: 0.5,
            padding: 0,
            paddingBottom: 0,
            marginBottom: spacings.space1,
          })}
        >
          <FormattedMessage id={`empty.${type || "recent"}.title`} />
        </h2>
        {type && (
          <button
            {...css({ ...defaultStyles.button.link })}
            onClick={() => {
              store.openNewDocument(
                intl.formatMessage({ id: `document.type.${type}` }),
                type
              );
            }}
          >
            <FormattedMessage id="btn.createNew" />
          </button>
        )}
        {!type && (
          <div {...css({ marginTop: spacings.space3 })}>
            <CreateNewDocumentButton store={store} />
          </div>
        )}
      </div>
    );
  });

const EmptyFavorites: React.FC<{
  store: AppStore;
  type?: DocumentTypeOptions;
}> = observer(({ store, type }) => {
  return (
    <div
      {...css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 50,
        marginTop: EMPTY_TOP_GUTTER,
        width: "80%",
      })}
    >
      <div {...css({ position: "relative" })}>
        <img src={EmptyFolderImage} alt="Empty Folder" width="200px" />
        <div {...css({ position: "absolute", bottom: 50, right: 30 })}>
          <span
            className="material-icons"
            {...css({ opacity: 0.5, fontSize: "50px" })}
          >
            star
          </span>
        </div>
      </div>
      <h2
        {...css({
          opacity: 0.5,
          padding: 0,
          paddingBottom: 0,
          marginBottom: spacings.space1,
          textAlign: "center",
        })}
      >
        <FormattedMessage id="empty.starred.title" />
      </h2>
    </div>
  );
});
const EmptyAssigned: React.FC<{
  store: AppStore;
  type?: DocumentTypeOptions;
}> = observer(({ store, type }) => {
  return (
    <div
      {...css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 50,
        marginTop: EMPTY_TOP_GUTTER,
        width: "80%",
      })}
    >
      <div {...css({ position: "relative" })}>
        <img src={EmptyFolderImage} alt="Empty Folder" width="200px" />
        <div {...css({ position: "absolute", bottom: 50, right: 30 })}>
          <span
            className="material-icons"
            {...css({ opacity: 0.5, fontSize: "50px" })}
          >
            assignment_ind
          </span>
        </div>
      </div>
      <h2
        {...css({
          opacity: 0.5,
          padding: 0,
          paddingBottom: 0,
          marginBottom: spacings.space1,
          textAlign: "center",
        })}
      >
        <FormattedMessage id="empty.assigned.title" />
      </h2>
    </div>
  );
});

const EmptyShared: React.FC<{
  store: AppStore;
  type?: DocumentTypeOptions;
}> = observer(({ store, type }) => {
  return (
    <div
      {...css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 50,
        marginTop: EMPTY_TOP_GUTTER,
        width: "80%",
      })}
    >
      <div {...css({ position: "relative" })}>
        <img src={EmptyFolderImage} alt="Empty Folder" width="200px" />
        <div {...css({ position: "absolute", bottom: 50, right: 30 })}>
          <span
            className="material-icons"
            {...css({ opacity: 0.5, fontSize: "50px" })}
          >
            screen_share
          </span>
        </div>
      </div>
      <h2
        {...css({
          opacity: 0.5,
          padding: 0,
          paddingBottom: 0,
          marginBottom: spacings.space1,
          textAlign: "center",
        })}
      >
        <FormattedMessage id="empty.shared.title" />
      </h2>
    </div>
  );
});

export const getIconForDocumentType = (
  docType: DocumentTypeOptions
): string => {
  switch (docType) {
    case DocumentTypeOptions.import:
      return "upload_file";
    case DocumentTypeOptions.podcast:
      return "mic";
    case DocumentTypeOptions.screenplay:
      return "movie";
    case DocumentTypeOptions.outline:
      return "article";
    default:
      return "";
  }
};

export const getIconColorForDocumentType = (
  docType: DocumentTypeOptions
): string => {
  switch (docType) {
    case DocumentTypeOptions.import:
      return "#FFFFFF";
    case DocumentTypeOptions.podcast:
      return "#6296F8";
    case DocumentTypeOptions.screenplay:
      return "#A173F7";
    case DocumentTypeOptions.outline:
      return "#59B559";
    default:
      return Color.onBackground;
  }
};
