import { makeObservable, observable } from "mobx";
import PanoramaP1, { PanoramaActions } from "./nektar/PanoramaP1";

export interface ExternalHardwareActions {
  panoramaP1?: PanoramaActions;
}

export default class ExternalHardware {
  panoramaP1?: PanoramaP1;

  constructor(props?: ExternalHardwareActions) {
    if (props?.panoramaP1) {
      this.panoramaP1 = new PanoramaP1(props.panoramaP1);
    }
   
    makeObservable(this, {
      panoramaP1: observable,
    });
  }
}
