import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import { spacings, Text } from "../../styles/default";
import OopsImage from "../../images/oops.svg";
const SubtleErrorMessage: React.FC<{
  error: string;
}> = ({ error, children }) => {
  return (
    <div
      {...css({
        marginTop: spacings.space4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gridGap: spacings.space2,
      })}
    >
      <img src={OopsImage} alt="Ooops" width={500} />
      <span {...css(Text.bodyBoldFaded)}>{error}</span>
      <div>{children}</div>
    </div>
  );
};

export default observer(SubtleErrorMessage);
