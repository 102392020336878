import { makeAutoObservable } from "mobx";

export interface FormFieldType {
  id: string;
  value: any;
}

export class FormField {
  id: string;
  value: any;

  constructor(props: FormFieldType) {
    this.id = props.id;
    this.value = props.value;
    makeAutoObservable(this);
  }

  set = (value: any) => {
    this.value = value;
  };
}
