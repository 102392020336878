import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { defaultStyles, spacings, Text } from "../styles/default";
import { FormattedMessage, useIntl } from "react-intl";
import SubscriptionPlansTable from "./SubscriptionPlansTable";
import WhatIsProductionOne from "./WhatIsProductionOne";
import {
  CONTENT_ANCHOR_ID_PRICING,
  CONTENT_ANCHOR_ID_PRODUCTION_ONE,
} from "./HomeLinks";
import { queries } from "../styles/mediaQueries";
import SubscriptionPlans from "./SubscriptionPlans";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

const SplashPage: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  return (
    <div
      {...css({
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: spacings.space10,
        width: "100%",
      })}
    >
      <Section
        id={CONTENT_ANCHOR_ID_PRODUCTION_ONE}
        title={intl.formatMessage({
          id: "splash.what.title",
          defaultMessage: "splash.what.title",
        })}
      >
        <WhatIsProductionOne store={store} />
      </Section>

      <Pricing store={store} />
    </div>
  );
};

export default observer(SplashPage);

export const Pricing: React.FC<{ store: AppStore }> = observer(({ store }) => {
  const intl = useIntl();
  return (
    <div
      {...css({
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      })}
    >
      <Section
        id={CONTENT_ANCHOR_ID_PRICING}
        title={intl.formatMessage({
          id: "splash.choosePlan.title",
          defaultMessage: "splash.choosePlan.title",
        })}
      >
        <div
          {...css({
            width: "100%",
            display: "none",
            [queries.phone]: {
              display: "flex",
            },
          })}
        >
          <SubscriptionPlans store={store} />
        </div>
        <div
          {...css({
            [queries.phone]: {
              display: "none",
            },
          })}
        >
          <SubscriptionPlansTable store={store} />
        </div>
      </Section>
      <p {...css(Text.bodyFaded, { marginTop: spacings.space4 })}>
        <FormattedMessage
          id="term.compliance"
          defaultMessage=""
          values={{
            currency: intl
              .formatMessage({
                id: "money.currency",
                defaultMessage: "USD",
              })
              .trim(),
            term: (
              <button
                {...css(defaultStyles.button.textLink)}
                onClick={() => {
                  logEvent(user.analytics, "btn_click_openTerms");
                  store.openTerms();
                }}
              >
                {intl.formatMessage({
                  id: "term.link.label",
                  defaultMessage: "Terms",
                })}
              </button>
            ),
            policy: (
              <button
                {...css(defaultStyles.button.textLink)}
                onClick={() => {
                  logEvent(user.analytics, "btn_click_openPrivacy");
                  store.openPrivacy();
                }}
              >
                {intl.formatMessage({
                  id: "policy.link.label",
                  defaultMessage: "Policies",
                })}
              </button>
            ),
          }}
        />
      </p>
    </div>
  );
});

export const Section: React.FC<{ title: string; id: string }> = observer(
  ({ id, title, children }) => {
    return (
      <div
        id={id}
        {...css({
          alignItems: "center",
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          marginBottom: "300px",
          marginTop: "300px",
          marginRight: spacings.space5,
          paddingTop: spacings.space5,
          width: "100%",
        })}
      >
        <div
          {...css({
            maxWidth: "690px",
          })}
        >
          <h1
            {...css({
              textAlign: "center",
            })}
          >
            {title}
          </h1>
        </div>
        {children}
      </div>
    );
  }
);
