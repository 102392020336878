import Mousetrap from "mousetrap";
import { features } from "./Features";
import AppStore from "../AppStore";
import { autorun, makeAutoObservable } from "mobx";
import { ViewOptionsType } from "../View";
import { ui } from "../Ui";
import {
  focusOnDialogueInput,
  focusOnDialogueCharacterInput,
  focusOnInsertInput,
  scrollToElement,
} from "../../components/common/utils/DomUtils";
import { DocumentTypeOptions } from "../textEditor/Document";
import { logEvent } from "@firebase/analytics";
import { user } from "../User";

export const shortcutCombos = {
  openPreview: "ctrl+o",
  addNewDialogueLine: "ctrl+n",
  addNewInsertLine: "ctrl+i",
  addNewScene: "ctrl+b",
  addNewTransitionScene: "ctrl+t",
  deleteAllLinesInPodcast: "",
  deleteAllLinesInScene: "",
  addNewSceneDialogueAction: "",
  saveFileToDisk: ["ctrl+s", "command+s"],
};
export class Shortcuts {
  shiftPress: boolean;
  constructor(store: AppStore) {
    this.shiftPress = false;

    Mousetrap.bind(
      "shift",
      () => {
        logEvent(user.analytics, "shortcut_press_shift");
        this.setShiftPress(false);
      },
      "keyup"
    );

    Mousetrap.bind(
      "shift",
      () => {
        logEvent(user.analytics, "shortcut_press_shift");
        this.setShiftPress(true);
      },
      "keydown"
    );

    Mousetrap.bind("escape", () => {
      logEvent(user.analytics, "shortcut_press_escape");
      ui.clearAllDialogueSelections();
    });

    Mousetrap.bind(shortcutCombos.saveFileToDisk, (e) => {
      logEvent(
        user.analytics,
        `shortcut_press_${shortcutCombos.saveFileToDisk.join("_")}`
      );
      e.preventDefault();
      e.stopPropagation();
      store.saveDraft("Untitled");
    });

    Mousetrap.bind(shortcutCombos.openPreview, (e) => {
      logEvent(user.analytics, `shortcut_press_${shortcutCombos.openPreview}`);
      e.preventDefault();
      e.stopPropagation();
      const currentDocument = store.document;
      if (currentDocument) {
        store.openPreview(currentDocument.type, currentDocument.id);
      }
    });

    Mousetrap.bind("ctrl+h", () => {
      logEvent(user.analytics, "shortcut_press_ctrl+h");
      if (features.show) {
        features.hideFeatures();
      } else {
        features.showFeatures();
      }
    });

    Mousetrap.bind(shortcutCombos.addNewDialogueLine, async () => {
      logEvent(
        user.analytics,
        `shortcut_press_${shortcutCombos.addNewDialogueLine}`
      );
      if (store.document?.type === DocumentTypeOptions.podcast) {
        const dialogue = await store.document?.addNewLine("dialogue", "");
        dialogue && scrollToElement(dialogue.id);
        dialogue && focusOnDialogueInput(dialogue.id);
      } else if (store.document?.type === DocumentTypeOptions.screenplay) {
        const selectedSceneId = Object.keys(ui.selectedSceneId);

        const sceneId =
          selectedSceneId.length > 0 ? selectedSceneId[0] : undefined;
        if (sceneId) {
          const dialogue = await store.document?.addNewLine(
            "dialogue",
            "",
            sceneId
          );
          dialogue && scrollToElement(dialogue.id);
          dialogue && focusOnDialogueCharacterInput(dialogue.id);
        }
      }
      return false;
    });

    Mousetrap.bind(shortcutCombos.addNewInsertLine, async () => {
      if (store.document?.type === DocumentTypeOptions.podcast) {
        const dialogue = await store.document?.addNewLine("insert", "");
        dialogue && scrollToElement(dialogue.id);
        dialogue && focusOnInsertInput(dialogue.id);
      }
      return false;
    });

    autorun(() => {
      if (store.view.id === ViewOptionsType.podcast) {
        Mousetrap.bind("backspace", (e) => {
          if (ui.selectedIds === undefined) {
            store.deleteSelectedDialogues();
          }
        });
      }
    });

    autorun(() => {
      if (store.view.id === ViewOptionsType.screenplay) {
        Mousetrap.bind("backspace", () => {
          if (ui.selectedIds === undefined) {
            store.deleteSelectedDialoguesInSelectedScene();
          }
        });
      }
    });
    makeAutoObservable(this);
  }

  setShiftPress = (value: boolean) => {
    this.shiftPress = value;
  };
}
