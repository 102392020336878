import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import AppStore from "../../store/AppStore";
import Popup from "../../store/Popup";
import { Color, FontSizes, spacings } from "../../styles/default";
import { Popup as PopupComponent } from "../common/Popup";
import { useIntl } from "react-intl";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

export interface PopupConfirmDataType {
  callback: (decision: "cancelled" | "accepted") => void;
  title: string;
  copy: string;
  additionalCopy?: string;
  confirmBtnLabel?: string; // default will be "confirm"
  cancelBtnLabel?: string; // default will  be cancel
}

const PopupConfirmBeforeAction: React.FC<PopupsType> = ({ store, popup }) => {
  const intl = useIntl();
  return (
    <PopupComponent
      id="confirm-before-action"
      title={popup.title}
      width="575px"
      cancel={{
        action: () => {
          popup.data?.callback?.("cancelled");
          store.popups.clear();
        },
        label:
          popup.data?.cancelBtnLabel ||
          intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: () => {
          popup.data?.callback?.("accepted");
          store.popups.clear();
        },
        enabled: true,
        label:
          popup.data?.confirmBtnLabel ||
          intl.formatMessage({ id: "btn.confirm" }),
      }}
      headerIcon="warning"
      style={{
        btnConfirm: {
          backgroundColor: Color.destructive,
          ":hover": {
            backgroundColor: Color.destructive,
          },
        },
        btnCancel: {
          ":hover": {
            color: Color.destructive,
          },
        },
        headerIcon: {
          color: Color.destructive,
          fontSize: FontSizes.fontSize8,
        },
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space3,
          flexDirection: "column",
          gridGap: spacings.space2,
          width: "100%",
        })}
      >
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            marginBottom: spacings.space4,
          })}
        >
          <h2>{popup.data.title}</h2>
          <span>{popup.data.copy}</span>
          <span>{popup.data.additionalCopy}</span>
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupConfirmBeforeAction);
