import { makeAutoObservable } from "mobx";
import { APP_NAME } from "./constants";

export enum ViewOptionsType {
  document = "document",
  finder = "finder",
  home = "home",
  loading = "loading",
  notFound = "notFound",
  outline = "outline",
  podcast = "podcast",
  pressRelease = "pressRelease",
  preview = "preview",
  pricing = "pricing",
  privacy = "privacy",
  screenplay = "screenplay",
  terms = "terms",
  tutorial = "tutorial",
  userProfile = "userProfile",
  unauthorized = "unauthorized",
}

class View {
  id: ViewOptionsType;
  title: string;
  path: string;
  updated: number;
  tab?: string;
  tabIndex?: number;
  viewIndex: number;
  locationTabId?: string;
  sectionId?: string;
  params?: { [key: string]: string };

  constructor() {
    this.id = ViewOptionsType.loading;
    this.title = APP_NAME;
    this.path = "/";
    this.updated = 0;
    this.viewIndex = 0;
    this.sectionId = undefined;
    this.locationTabId = undefined;
    this.tabIndex = 0;
    this.params = {};
    makeAutoObservable(this);
  }

  get currentViewId() {
    return this.id;
  }

  get currentTabIndex() {
    return this.tabIndex;
  }

  get currentSectionId() {
    return this.sectionId;
  }

  get currentViewIndex() {
    return this.viewIndex;
  }

  get currentTab() {
    return this.tab;
  }

  get currentTabLocationId() {
    return this.locationTabId;
  }

  get currentPath() {
    return this.path;
  }

  get currentParams() {
    if (this.updated > 0) {
      return this.params || {};
    }

    return this.params || {};
  }

  setSectionId = (sectionId?: string): void => {
    this.sectionId = sectionId;
  };

  setTabIndex = (index: number) => {
    this.tabIndex = index;
  };

  update = () => {
    this.updated = new Date().getTime();
  };

  setTab = (tab?: string): void => {
    this.tab = tab;
  };

  setView = (id: ViewOptionsType, path: string, title?: string) => {
    this.id = id;
    this.path = path;
    this.locationTabId = undefined;
    this.title = title || "Recording Tool";
  };

  setLocationTab = (locationTabId: string) => {
    this.locationTabId = locationTabId;
  };

  setParams = (params: { [key: string]: string }): void => {
    this.params = params;
    this.update();
  };
}

export default View;
