import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import Tabs from "./common/Tabs";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "glamor";
import { Color, FontSizes } from "../styles/default";
import { queries } from "../styles/mediaQueries";
import { TabItemOptionType } from "./common/TabItem";

const WhatIsProductionOne: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const [selectedTab, setSelectedTab] = React.useState("write");
  const intl = useIntl();
  const tabItems = [
    {
      label: intl.formatMessage({
        id: "splash.tab.what.write.label",
      }),
      id: "write",
      action: () => {
        setSelectedTab("write");
      },
    },
    {
      label: intl.formatMessage({
        id: "splash.tab.what.preview.label",
      }),
      id: "preview",
      action: () => {
        setSelectedTab("preview");
      },
    },
    // {
    //   label: intl.formatMessage({
    //     id: "splash.tab.what.record.label",
    //   }),
    //   id: "record",
    //   action: () => {
    //     setSelectedTab("record");
    //   },
    // },

    {
      label: intl.formatMessage({
        id: "splash.tab.what.collaborate.label",
      }),
      id: "collaborate",
      action: () => {
        setSelectedTab("collaborate");
      },
    },
    // {
    //   label: intl.formatMessage({
    //     id: "splash.tab.what.download.label",
    //   }),
    //   id: "download",
    //   action: () => {
    //     setSelectedTab("download");
    //   },
    // },
  ];
  return (
    <>
      <div
        {...css({
          display: "none",
          [queries.phone]: {
            display: "flex",
          },
        })}
      >
        <MobileWhat tabItems={tabItems} selectedTab={selectedTab} />
      </div>
      <div
        {...css({
          [queries.phone]: {
            display: "none",
          },
        })}
      >
        <DesktopWhat tabItems={tabItems} selectedTab={selectedTab} />
      </div>
    </>
  );
};

export default observer(WhatIsProductionOne);

const MobileWhat: React.FC<{
  tabItems: TabItemOptionType[];
  selectedTab: string;
}> = observer(({ tabItems, selectedTab }) => {
  return (
    <div>
      {tabItems.map((item) => {
        return (
          <div
            {...css({
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            })}
          >
            <h2 {...css({ color: Color.secondary, textAlign: "center" })}>
              {item.label}
            </h2>

            <p
              {...css({
                fontWeight: 700,
                fontSize: FontSizes.fontSize6,
                maxWidth: "500px",
                textAlign: "center",
                lineHeight: "1.2rem",
                [queries.phone]: {
                  textAlign: "left",
                },
              })}
            >
              <FormattedMessage id={`splash.tab.what.${item.id}.description`} />
            </p>
          </div>
        );
      })}
    </div>
  );
});
const DesktopWhat: React.FC<{
  tabItems: TabItemOptionType[];
  selectedTab: string;
}> = observer(({ tabItems, selectedTab }) => {
  return (
    <div
      {...css({
        [queries.phone]: {
          display: "flex",
          flexDirection: "row",
        },
      })}
    >
      <Tabs
        items={tabItems}
        selectedId={selectedTab}
        style={{
          container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [queries.phone]: { flexDirection: "column" },
          },
          tabItem: {
            container: {
              [queries.phone]: {
                borderBottom: 0,
                fontWeight: 500,
                textAlign: "left",
              },
            },
            selected: {
              [queries.phone]: {
                borderBottom: 0,
                fontWeight: 700,
                color: Color.secondary,
                textAlign: "left",
              },
            },
          },
        }}
      />

      <div>
        <p
          {...css({
            fontWeight: 700,
            fontSize: FontSizes.fontSize6,
            maxWidth: "500px",
            textAlign: "center",
            lineHeight: "1.2rem",
            [queries.phone]: {
              textAlign: "left",
            },
          })}
        >
          <FormattedMessage id={`splash.tab.what.${selectedTab}.description`} />
        </p>
      </div>
    </div>
  );
});
