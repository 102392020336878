import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import {
  spacings,
  TabSelectionStyleBackground,
  TabSelectionStyleText,
} from "../styles/default";
import { LayoutHeights } from "../store/constants";
import { NavigationTabOptions, ui } from "../store/Ui";
import { useIntl } from "react-intl";
import { ViewOptionsType } from "../store/View";
import { queries } from "../styles/mediaQueries";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

const Navigation: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  const currentViewId = store.view.id;
  if (
    !store.user.authorized ||
    ![ViewOptionsType.home, ViewOptionsType.finder].includes(currentViewId)
  ) {
    return null;
  }
  const selectedTab = ui.selectedNavigationTab;
  return (
    <div
      data-id="Navigation"
      {...css({ top: LayoutHeights.appHeader, position: "absolute" })}
    >
      <div
        {...css({
          padding: spacings.space4,
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space1,
          paddingLeft: spacings.space10,
          justifyContent: "start",
          marginLeft: "-70px",
        })}
      >
        {/* <NavigationItem
          store={store}
          title="My files"
          icon="description"
          selected={selectedTab === NavigationTabOptions.all}
          onClick={() => {
            ui.setSelectedNavigationTab(NavigationTabOptions.all);
            store.openFinderView(NavigationTabOptions.all);
          }}
        /> */}
        <NavigationItem
          store={store}
          title={intl.formatMessage({ id: "viewerTitle.recent" })}
          icon="watch_later"
          selected={selectedTab === NavigationTabOptions.recent}
          onClick={() => {
            logEvent(user.analytics, "btn_click_navigation_recent");
            ui.setSelectedNavigationTab(NavigationTabOptions.recent);
            store.openFinderView(NavigationTabOptions.recent);
          }}
        />
        <NavigationItem
          store={store}
          title={intl.formatMessage({ id: "viewerTitle.starred" })}
          icon="star"
          selected={selectedTab === NavigationTabOptions.starred}
          onClick={() => {
            logEvent(user.analytics, "btn_click_navigation_starred");
            ui.setSelectedNavigationTab(NavigationTabOptions.starred);
            store.openFinderView(NavigationTabOptions.starred);
          }}
        />

        <NavigationItem
          store={store}
          title={intl.formatMessage({ id: "viewerTitle.shared" })}
          icon="screen_share"
          selected={selectedTab === NavigationTabOptions.shared}
          onClick={() => {
            logEvent(user.analytics, "btn_click_navigation_shared");
            ui.setSelectedNavigationTab(NavigationTabOptions.shared);
            store.openFinderView(NavigationTabOptions.shared);
          }}
        />
        <NavigationItem
          store={store}
          title={intl.formatMessage({ id: "viewerTitle.assigned" })}
          icon="assignment_ind"
          selected={selectedTab === NavigationTabOptions.assigned}
          onClick={() => {
            logEvent(user.analytics, "btn_click_navigation_assigned");
            ui.setSelectedNavigationTab(NavigationTabOptions.assigned);
            store.openFinderView(NavigationTabOptions.assigned);
          }}
        />
      </div>
    </div>
  );
};

export default observer(Navigation);

const NavigationItem: React.FC<{
  store: AppStore;
  title: string;
  icon: string;
  iconColor?: string;
  selected?: boolean;
  onClick: () => void;
}> = observer(({ store, icon, title, selected = false, onClick }) => {
  return (
    <div
      {...css(
        {
          padding: spacings.space3,
          paddingLeft: spacings.space10,
        },
        TabSelectionStyleBackground(selected)
      )}
    >
      <button
        {...css(
          {
            display: "flex",
            gridGap: spacings.space2,
            alignItems: "center",
          },
          TabSelectionStyleText(selected)
        )}
        onClick={onClick}
      >
        <span className="material-icons">{icon}</span>
        <span
          {...css({
            [queries.phone]: {
              display: "none",
            },
          })}
        >
          {title}
        </span>
      </button>
    </div>
  );
});
