import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AppStore from "../../store/AppStore";
import Popup from "../../store/Popup";
import { spacings } from "../../styles/default";
import { Popup as PopupComponent } from "../common/Popup";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupLeaveUnsavedDocumentWarning: React.FC<PopupsType> = ({
  store,
  popup,
}) => {
  const intl = useIntl();

  return (
    <PopupComponent
      id="unsaved-document-warning"
      title={popup.title}
      width="575px"
      cancel={{
        action: () => {
          popup.data?.callback?.("cancelled");
          store.saveDraft(
            intl.formatMessage({
              id: "default.document.title",
              defaultMessage: "",
            })
          );
          store.removeCurrentDocumentInLocalStorage();
          store.clearFiles();
          store.popups.clear();
        },
        label: intl.formatMessage({ id: "btn.save.document.label" }),
      }}
      confirm={{
        action: () => {
          popup.data?.callback?.("accepted");
          store.removeCurrentDocumentInLocalStorage();
          store.clearFiles();
          store.popups.clear();
        },
        enabled: true,
        label: intl.formatMessage({ id: "btn.leave" }),
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space3,
          flexDirection: "column",
          gridGap: spacings.space2,
          width: "100%",
        })}
      >
        <div
          {...css({
            display: "flex",
            flexDirection: "column",
            marginBottom: spacings.space4,
          })}
        >
          <h2>
            <FormattedMessage id="unsaved.warning.title" />
          </h2>
          <span>
            <FormattedMessage id="unsaved.warning.description" />
          </span>
          <span>
            <FormattedMessage id="unsaved.warning.confirm" />
          </span>
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupLeaveUnsavedDocumentWarning);
