import { css } from "glamor";
import React from "react";
import { DocumentTypeOptions } from "../store/textEditor/Document";

const DocumentIcon: React.FC<{
  type: DocumentTypeOptions;
  size?: number;
}> = ({ type, size = 24 }) => {
  let icon;
  let iconColor;
  if (type === DocumentTypeOptions.podcast) {
    icon = "mic";
    iconColor = "#6296F8";
  } else if (type === DocumentTypeOptions.screenplay) {
    icon = "movie";
    iconColor = "#A173F7";
  } else if (type === DocumentTypeOptions.outline) {
    icon = "article";
    iconColor = "#59B559";
  }
  if (!icon || !iconColor) {
    return null;
  }
  return (
    <span
      className="material-icons"
      {...css({ color: iconColor, fontSize: size })}
    >
      {icon}
    </span>
  );
};

export default DocumentIcon;
