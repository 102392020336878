import { makeAutoObservable } from "mobx";
import { Suggestion, SuggestionOptionType } from "./Suggestion";

export class Suggestions {
  features: { [id: string]: Suggestion };

  constructor() {
    this.features = {};
    makeAutoObservable(this);
  }

  get current() {
    return this.features;
  }

  set = (
    api: (searchTerm: string) => any, //IPromiseBasedObservable<any>,
    id: string,
    normalizer: (response: any) => SuggestionOptionType[],
    options: SuggestionOptionType[],
    initialSuggestions?: SuggestionOptionType[]
  ) => {
    this.features[id] = new Suggestion({
      api,
      id,
      initialSuggestions,
      options,
      normalizer,
    });
  };

  get = (id: string) => {
    return this.features[id];
  };
}
