import { css } from "glamor";
import { observer } from "mobx-react";
import * as React from "react";
import AppStore from "../store/AppStore";
import {
  Color,
  FontSizes,
  Margins,
  SearchInputStyle,
  setAlpha,
  spacings,
} from "../styles/default";
import { queries } from "../styles/mediaQueries";

const defaultStyles = {
  container: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  icon: {
    position: "absolute",
    top: "10px",
    left: Margins.relaxed,
    color: Color.primary100,
  },
  input: {
    ...SearchInputStyle,
    width: "300px",
  },
};

const DocumentSearch: React.FC<{
  store: AppStore;
  placeholder: string;
  onChange: (searchTerm: string) => void;
}> = ({ store, placeholder, onChange }) => {
  return (
    <div {...css(defaultStyles.container)}>
      <span
        className="material-icons"
        {...css({
          color: setAlpha(Color.onBackground, 0.5),
          position: "absolute",
          left: 0,
          marginTop: spacings.space2,
          marginLeft: spacings.space3,
          fontSize: FontSizes.fontSize6,
          opacity: 1,
        })}
      >
        search
      </span>

      <input
        {...css({
          ...defaultStyles.input,
          paddingLeft: "30px",
          [queries.phone]: {
            width: "180px",
          },
        })}
        onFocus={(e) => {}}
        onBlur={() => {}}
        placeholder={placeholder}
        autoComplete="off"
      />
    </div>
  );
};

export default observer(DocumentSearch);
