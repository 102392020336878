import debounce from "lodash/debounce";
import inRange from "lodash/inRange";
import reduce from "lodash/reduce";
import { makeAutoObservable } from "mobx";
import { MIN_MOBILE_WIDTH } from "../styles/mediaQueries";

class WindowSizeStore {
  windowWidth: any;
  windowSizes: any;
  screenWindow: any;
  windowHeight: any;
  panels: any;

  constructor() {
    this.windowWidth = null;
    this.windowHeight = null;
    this.screenWindow = null;
    this.windowSizes = null;
    this.setWindow();
    this.panels = {};
    makeAutoObservable(this);
  }

  get hasMinimalPortraitMobileWidth() {
    return this.width <= MIN_MOBILE_WIDTH;
  }

  get width() {
    return this.windowWidth;
  }

  get height() {
    return this.windowHeight;
  }

  get panelSizes() {
    return this.panels;
  }

  get getWindowSizes() {
    const SIZES = {
      sm: inRange(this.width, 0, 639),
      m: inRange(this.width, 640, 1023),
      l: inRange(this.width, 1024, 1250),
      xl: inRange(this.width, 1250, Number.POSITIVE_INFINITY),
      ns: inRange(this.width, 640, Number.POSITIVE_INFINITY),
    };

    return reduce(
      SIZES,
      (result: any, value, key) => {
        if (value) {
          result = [...result, key];
        }
        return result;
      },
      []
    );
  }

  setWindow = () => {
    if (typeof window === "object") {
      this.screenWindow = window;
      this.handleWindowWidthChange();
      this.screenWindow.addEventListener(
        "resize",
        this.handleWindowWidthChange
      );
      this.screenWindow.addEventListener(
        "dragover",
        function (e: any) {
          // eslint-disable-next-line
          e = e || event;
          e.preventDefault();
        },
        false
      );
      this.screenWindow.addEventListener(
        "drop",
        function (e: any) {
          // eslint-disable-next-line
          e = e || event;
          e.preventDefault();
        },
        false
      );
    }
  };

  handleWindowWidthChange = debounce(() => {
    const width = this.screenWindow.innerWidth;
    const height = this.screenWindow.innerHeight;
    this.setWindowWidth(width);
    this.setWindowHeight(height);
  }, 100);

  setWindowWidth = (width: number) => {
    this.windowWidth = width;
  };

  setWindowHeight = (height: number) => {
    this.windowHeight = height;
  };

  setAppPanels = (panels: string[]) => {
    panels.forEach((panel) => {
      this.panels[panel] = true;
    });
  };

  expandPanel = (panelId: string) => {
    this.panels[panelId] = !this.panels[panelId];
  };
}

export default WindowSizeStore;

export const windowSizes = new WindowSizeStore();
