import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { FontSizes, spacings, Text } from "../styles/default";
import { FormattedMessage } from "react-intl";

const SearchResultSummary: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const doc = store.document?.document;
  if (!doc) {
    return null;
  }
  const searchTerm = doc?.searchTerm;

  if (searchTerm === "") {
    return null;
  }
  return (
    <div
      {...css({
        marginBottom: spacings.space4,
        fontSize: FontSizes.fontSize8,
      })}
    >
      <FormattedMessage id="searching.for" />{" "}
      <span {...css(Text.bodyBold, { fontSize: FontSizes.fontSize8 })}>
        "{searchTerm}"
      </span>
    </div>
  );
};

export default observer(SearchResultSummary);
