import { makeAutoObservable } from "mobx";
import { windowSizes } from "../WindowSizeStore";

export const MENU_ID_SELECTOR = "portal-x";
export type MenuOpenPosition = "right" | "left";

export default class Portal {
  id?: string;
  show: boolean;
  y: number;
  x: number;
  width: number;

  constructor() {
    this.show = false;
    this.y = 0;
    this.x = 0;
    this.width = 300;
    makeAutoObservable(this);
  }

  get positionStyles() {
    // if there's enough room to show right
    const x = this.x;
    const y = this.y;
    // assuming width of menu is 200px
    // TODO: figure out width of menu and measure by that instead

    if (x + this.width < windowSizes.width) {
      return {
        top: y,
        left: x,
      };
    }
    return {
      top: y,
      left: x - this.width,
    };
  }

  open = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setY(e.pageY);
    this.setX(e.pageX);
    this.setShow(true);
  };

  setY = (value: number) => {
    this.y = value;
  };

  setX = (value: number) => {
    this.x = value;
  };

  setShow = (value: boolean) => {
    this.show = value;
    if (this.show === true) {
      document.addEventListener(
        "click",
        this.handleActionMenuClickOutside,
        true
      );
    } else {
      document.removeEventListener(
        "click",
        this.handleActionMenuClickOutside,
        true
      );
    }
  };

  handleActionMenuClickOutside = (e: any) => {
    const id = this.id;
    if (id) {
      const elemWrapper = document.getElementById(id);
      if (!elemWrapper?.contains(e.target)) {
        // clicked outside the menu
        this.setShow(false);
      }
    }
  };

  setId = (id: string) => {
    this.id = id;
  };
}
export const portal = new Portal();
