import { makeAutoObservable } from "mobx";
import { FormOptions } from "./AppStore";
import { Form, FormType } from "./Form";

export class Forms {
  forms: Record<string, Form>;
  updated: number;

  constructor() {
    this.forms = {};
    this.updated = new Date().getTime();
    makeAutoObservable(this);
  }

  get registered() {
    if (this.updated > 0) {
      return this.forms;
    }
    return this.forms;
  }

  forceUpdate = () => {
    this.updated = new Date().getTime();
  };

  init = (form: FormType) => {
    this.forms[form.id] = new Form(form);
  };

  deleteForm = (formId: FormOptions) => {
    delete this.forms[formId];
    this.forceUpdate();
  };

  getForm = (formId: string): Form => {
    return this.registered[formId] || undefined;
  };

  clearAllFields = (formId: string) => {
    const form: Form = this.getForm(formId);
    if (form) {
      form.clearFields();
    }
  };

  getFormData = (formId: string) => {
    const fields = this.getForm(formId).fields;
    const formdata: Record<string, any> = {};
    Object.keys(fields).forEach((key) => {
      formdata[key] = fields[key].value;
    });
    return formdata;
  };
}

export const forms = new Forms();
