import { makeAutoObservable } from "mobx";

export default class LocalStorage {
  db: Storage;

  constructor() {
    this.db = window.localStorage;
    makeAutoObservable(this);
  }

  set(id: string, data: any) {
    const value = JSON.stringify(data);
    this.db.setItem(id, value);
  }

  get(id: string): string | undefined {
    return this.db.getItem(id) || undefined;
  }

  remove(id: string) {
    this.db.removeItem(id);
  }
}

export const localStorage = new LocalStorage();
