export const firebaseConfig = {
  apiKey: "AIzaSyD6RWruGaaObzhVf-DBKu2vuuyiQGwglG0",
  authDomain: "production-one-studios.firebaseapp.com",
  databaseURL: "https://production-one-studios-default-rtdb.firebaseio.com",
  projectId: "production-one-studios",
  storageBucket: "production-one-studios.appspot.com",
  messagingSenderId: "935629340185",
  appId: "1:935629340185:web:5fbd98eb1d8f38ca33b488",
  measurementId: "G-W8W1TGC2SG",
};
