import { css } from "glamor";
import { observer } from "mobx-react";
import { Color, FontFamilies, setAlpha, spacings } from "../../styles/default";

interface CrumbType {
  id: string;
  title: string;
  onClick: () => void;
}
const Breadcrumbs: React.FC<{
  crumbs: CrumbType[];
  current: string;
}> = ({ crumbs, current }) => {
  return (
    <div {...css({ display: "flex" })}>
      {crumbs.map((crumb, i) => {
        const isCurrent = current === crumb.id;
        const isNotLast = i < crumbs.length - 1;
        return (
          <span
            key={crumb.id}
            {...css({
              display: "flex",
              alignItems: "center",
              gridGap: spacings.space1,
            })}
          >
            <a
              onClick={crumb.onClick}
              {...css(
                {
                  color: setAlpha(Color.onBackground, 0.4),
                  fontFamily: FontFamilies.dialogue,
                  ":hover": {
                    color: Color.secondary,
                    textDecoration: "underline",
                  },
                },
                isCurrent
                  ? {
                      fontWeight: "bold",
                      color: setAlpha(Color.onBackground, 0.9),
                    }
                  : {}
              )}
            >
              {crumb.title}
            </a>
            {isNotLast && <span className="material-icons">chevron_right</span>}
          </span>
        );
      })}
    </div>
  );
};

export default observer(Breadcrumbs);
