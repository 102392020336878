import React from "react";
import { observer } from "mobx-react";
import { css } from "glamor";
import {
  borderRadius,
  Color,
  defaultStyles,
  FontSizes,
  setAlpha,
  spacings,
} from "../../styles/default";

const Tip: React.FC<{
  message: string;
}> = ({ message }) => {
  const [show, setShow] = React.useState<boolean>(false);
  return (
    <div>
      <button
        {...css(defaultStyles.button.primary)}
        onClick={() => setShow(!show)}
        onBlur={() => setShow(false)}
        onTouchMove={() => setShow(false)}
      >
        <span
          className="material-icons"
          {...css({
            fontSize: FontSizes.fontSize4,
            color: Color.warning,
          })}
        >
          tips_and_updates
        </span>
      </button>

      {show && (
        <div
          {...css({
            position: "absolute",
            width: "180px",
            backgroundColor: Color.surface100,
            padding: spacings.space2,
            borderRadius: borderRadius.borderRadius2,
            border: `1px solid ${setAlpha(Color.onBackground, 0.1)}`,
            margin: spacings.space2,
          })}
        >
          <span
            {...css({
              fontSize: FontSizes.fontSize2,
              color: Color.onBackground,
            })}
          >
            {message}
          </span>
        </div>
      )}
    </div>
  );
};

export default observer(Tip);
