import { css } from "glamor";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";
import AppStore from "../../store/AppStore";
import { Form } from "../../store/Form";
import Popup from "../../store/Popup";
import { borders, Color, spacings } from "../../styles/default";
import { Input } from "../common/Input";
import InputWithSuggestion from "../common/InputWithSuggestion";
import { Popup as PopupComponent } from "../common/Popup";
import Tip from "../common/Tip";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupGenericFormDataUpdate: React.FC<PopupsType> = ({ store, popup }) => {
  const intl = useIntl();
  const popupForm: Form = popup.data.form;
  if (!popupForm) {
    return null;
  }

  const fields = popupForm.fields;

  if (!popupForm || !fields) {
    return null;
  }
  return (
    <PopupComponent
      id="signup-popup"
      title={popup.title}
      width="360px"
      cancel={{
        action: () => store.popups.clear(),
        label: intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: () => {
          const formData = popupForm.getData;
          popup.data.onSubmit(formData);
        },
        enabled: popupForm.isValid,
        label: intl.formatMessage({ id: "btn.save" }),
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space5,
          flexDirection: "column",
          gridGap: spacings.space2,
        })}
      >
        {popup.data.tip && <Tip message={popup.data.tip} />}
        {popup.data.description || ""}
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
            flexDirection: "column",
          })}
        >
          {Object.keys(fields).map((key) => {
            const field = fields[key];
            if (popupForm.hiddenFields.includes(key)) {
              return null;
            }
            const id = `${popupForm.id}.${key}`;
            const suggestions = popupForm.options[key];
            let value = field.value;
            if (value && popupForm.options.allowTranslation) {
              value = intl.formatMessage({
                id: `generic.term.${field.value}`,
                defaultMessage: field.value,
              });
            }

            // if there are suggestions for this field, let's show input with suggestion instead
            if (suggestions !== undefined || !isEmpty(suggestions)) {
              return (
                <InputWithSuggestion
                  key={id}
                  suggestions={suggestions}
                  placeholder={intl
                    .formatMessage({
                      id: `${id}.placeholder`,
                    })
                    .trim()}
                  id={id}
                  disabled={false}
                  defaultValue={value}
                  onFocus={() => {}}
                  onChange={(value: string, id?: string) => {
                    if (id) {
                      field.set(id);
                    } else {
                      field.set(value);
                    }
                  }}
                  onBlur={() => {}}
                  value={value}
                  styles={{
                    border: borders.default,
                    padding: spacings.space2,
                    ":focus": {
                      border: borders.default,
                      borderColor: Color.secondary,
                    },
                  }}
                />
              );
            }
            return (
              <Input
                label={key}
                value={field.value}
                onChange={(e: any) => field.set(e.target.value)}
                type="text"
                required
              />
            );
          })}
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupGenericFormDataUpdate);
