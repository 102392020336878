import { makeAutoObservable } from "mobx";
export interface TalentType {
  name?: string;
  email?: string;
}
export class Talent {
  name?: string;
  email?: string;
  constructor(talent?: TalentType) {
    this.name = talent?.name;
    this.email = talent?.email;
    makeAutoObservable(this);
  }

  setName(name?: string) {
    this.name = name;
  }

  setEmail(email?: string) {
    this.email = email;
  }
}
