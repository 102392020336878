import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { Document, DocumentTypeOptions } from "../store/textEditor/Document";
import { css } from "glamor";
import { LoadingScreen } from "./common/LoadingScreen";
import { useIntl, FormattedMessage } from "react-intl";
import {
  ButtonStylesForLightBackgrounds,
  FontFamilies,
  spacings,
} from "../styles/default";
import { queries } from "../styles/mediaQueries";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";
import { PaperStyles, StyledScriptFormat } from "../styles/papers";
import { windowSizes } from "../store/WindowSizeStore";
import { getScriptPages, PaperLine, PaperLineType } from "../store/pageStats";
import { Character } from "../store/textEditor/Character";

const PRINT_PAGE_WIDTH = "20cm";

const PrintPreview: React.FC<{
  store: AppStore;
  document?: Document;
}> = ({ store, document }) => {
  const intl = useIntl();
  const doc = document?.document;
  const pageCount = Math.ceil(document?.pages || 0);
  const scriptPages = getScriptPages(document);
  const documentType = document?.type;
  const pages = Array.from(Array(pageCount).keys());
  const sceneList = document?.scenesList || [];

  const folderReferences = store.folderReferences;
  const seriesId = document?.document?.seriesId;
  const folderName =
    seriesId && folderReferences[seriesId]
      ? folderReferences[seriesId].name
      : "";
  if (!document) {
    return (
      <LoadingScreen
        id="loading-preview"
        message={intl.formatMessage({ id: "loading.preview" })}
      />
    );
  }
  return (
    <div
      {...css(PaperStyles.body, {
        [queries.phone]: {
          width: windowSizes.width,
        },
        [queries.print]: {
          width: PRINT_PAGE_WIDTH,
        },
      })}
    >
      <Cover
        author={store.user.displayName || "Unknown"}
        title={doc?.title}
        seriesTitle={folderName}
      />
      <PrintActions store={store} document={document} />
      {pages.map((pageNumber) => {
        const linesForThisPage = scriptPages[pageNumber];
        if (!linesForThisPage) {
          return null;
        }
        return (
          <>
            <div
              key={pageNumber}
              {...css(PaperStyles.sheet.page, PaperStyles.script.page)}
            >
              <div
                {...css({
                  fontFamily: FontFamilies.dialoguePrint,
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                {pageNumber + 1}.
              </div>
              {linesForThisPage.map((line) => {
                return (
                  <PaperPrintLine
                    sceneList={sceneList}
                    line={line}
                    characters={doc?.characters || {}}
                    documentType={documentType}
                  />
                );
              })}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default observer(PrintPreview);

const PaperPrintLine: React.FC<{
  sceneList: string[];
  line: PaperLine;
  characters: { [characterId: string]: Character };
  documentType?: DocumentTypeOptions;
}> = observer(({ line, sceneList, characters, documentType }) => {
  const shouldAddMarginTop =
    line.index === 0 &&
    line.type !== PaperLineType.dialogue &&
    line.type !== PaperLineType.parenthetical;
  return (
    <div
      {...css({
        display: "flex",
        fontFamily: FontFamilies.dialoguePrint,
        fontSize: "12pt",
        marginTop: shouldAddMarginTop ? "9px" : 0,
      })}
    >
      {line.type === PaperLineType.slugLine && (
        <SceneHeadings sceneList={sceneList} line={line} />
      )}

      {line.type === PaperLineType.action && (
        <FullLine line={line} align="left" />
      )}

      {line.type === PaperLineType.parenthetical && (
        <ParentheticalLine line={line} />
      )}

      {line.type === PaperLineType.character && (
        <CharacterLine
          line={line}
          align="left"
          characters={characters}
          documentType={documentType}
        />
      )}

      {line.type === PaperLineType.dialogue &&
        documentType === DocumentTypeOptions.screenplay && (
          <DialogueLine line={line} align="left" />
        )}

      {line.type === PaperLineType.dialogue &&
        documentType !== DocumentTypeOptions.screenplay && (
          <FullLine line={line} align="left" />
        )}

      {line.type === PaperLineType.transition && (
        <TransitionLine line={line} align="left" />
      )}
    </div>
  );
});

const DialogueLine: React.FC<{ line: PaperLine; align: "left" | "right" }> =
  observer(({ line, align }) => {
    return (
      <div
        {...css({
          display: "flex",
          justifyContent: align === "right" ? "flex-end" : "flex-start",
          marginLeft: StyledScriptFormat.dialogueMarginLeft,
        })}
      >
        {line.text}
      </div>
    );
  });

const CharacterLine: React.FC<{
  line: PaperLine;
  align: "left" | "right";
  characters: { [characterId: string]: Character };
  documentType?: DocumentTypeOptions;
}> = observer(({ line, align, characters, documentType }) => {
  const intl = useIntl();
  const isScreenplay = documentType === DocumentTypeOptions.screenplay;
  const character: Character | undefined = characters[line.text];
  let characterName = character?.name;
  if (!characterName) {
    characterName = line.text;
  }

  return (
    <div
      {...css({
        display: "flex",
        justifyContent: align === "right" ? "flex-end" : "flex-start",
        marginLeft: isScreenplay ? StyledScriptFormat.character : 0,
        textDecoration: !isScreenplay ? "underline" : "none",
      })}
    >
      {characterName.toLocaleUpperCase()}{" "}
      {line.characterExtension
        ? "(" +
          intl
            .formatMessage({
              id: `screenplay.characterExtension.${line.characterExtension}.abrev`,
              defaultMessage: line.characterExtension,
            })
            .trim() +
          ")"
        : ""}
    </div>
  );
});

const ParentheticalLine: React.FC<{ line: PaperLine }> = observer(
  ({ line }) => {
    return (
      <div
        {...css({
          display: "flex",
          width: "100%",
          marginLeft: StyledScriptFormat.parentheticalLeft,
        })}
      >
        {line.text}
      </div>
    );
  }
);

const TransitionLine: React.FC<{ line: PaperLine; align: "left" | "right" }> =
  observer(({ line, align }) => {
    return (
      <div
        {...css({
          display: "flex",
          width: "100%",
          justifyContent: align === "right" ? "flex-end" : "flex-start",
        })}
      >
        {line.text}
      </div>
    );
  });

const FullLine: React.FC<{ line: PaperLine; align: "left" | "right" }> =
  observer(({ line, align }) => {
    return (
      <div
        {...css({
          display: "flex",
          width: "100%",
          justifyContent: align === "right" ? "flex-end" : "flex-start",
        })}
      >
        {line.text}
      </div>
    );
  });

const SceneHeadings: React.FC<{ sceneList: string[]; line: PaperLine }> =
  observer(({ sceneList, line }) => {
    if (sceneList.length > 0) {
      const showSceneNumber = false; // TODO: only show scene number for shooting scripts
      const sceneIndex = sceneList.indexOf(line?.sceneId || "") + 1;
      return (
        <div
          {...css({
            position: "relative",
            width: "100%",
          })}
        >
          {showSceneNumber && (
            <span
              {...css({
                position: "absolute",
                fontWeight: 700,
                left: -70,
                top: 0,
              })}
            >
              {sceneIndex}
            </span>
          )}
          <span
            {...css({
              fontFamily: FontFamilies.dialoguePrint,
              fontSize: "12pt",
            })}
          >
            {line.text}
          </span>
          {showSceneNumber && (
            <span
              {...css({
                position: "absolute",
                fontWeight: 700,
                right: 0,
                top: 0,
              })}
            >
              {sceneIndex}
            </span>
          )}
        </div>
      );
    }
    return null;
  });

const PrintActions: React.FC<{ store: AppStore; document: Document }> =
  observer(({ store, document }) => {
    return (
      <div
        data-id="preview-action"
        {...css({
          position: "fixed",
          display: "flex",
          gridGap: spacings.space2,
          top: spacings.space4,
          left: spacings.space4,
          [queries.print]: {
            visibility: "hidden",
          },
        })}
      >
        <button
          {...css(ButtonStylesForLightBackgrounds.dark)}
          onClick={() => {
            setTimeout(() => {
              logEvent(user.analytics, `btn_openDocument_${document.type}`);
              store.openDocument(document.type, document.id);
            }, 30);
          }}
        >
          <span className="material-icons">arrow_back</span>
          <FormattedMessage id="btn.back.label" defaultMessage="Back" />
        </button>
        <button
          {...css(ButtonStylesForLightBackgrounds.dark)}
          onClick={() => {
            window.print();
          }}
        >
          <span className="material-icons">print</span>
          <FormattedMessage id="print" />
        </button>
      </div>
    );
  });

const Cover: React.FC<{
  author: string;
  title?: string;
  seriesTitle?: string;
}> = ({ author, title, seriesTitle }) => {
  const intl = useIntl();

  return (
    <div
      {...css(PaperStyles.sheet.page, PaperStyles.script.page, {
        [queries.phone]: {
          width: "100%",
          paddingLeft: spacings.space4,
          paddingRight: spacings.space4,
          margin: 0,
          padding: 0,
          marginRight: 0,
        },
        [queries.print]: {
          height: "21cm",
        },
      })}
    >
      <div
        {...css({
          fontFamily: FontFamilies.dialoguePrint,
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gridGap: spacings.space5,
          width: "100%",
          height: "21cm",
        })}
      >
        <span
          {...css({
            fontSize: StyledScriptFormat.fontSize,
            textTransform: "uppercase",
          })}
        >
          {seriesTitle}
        </span>
        <span {...css({ fontSize: StyledScriptFormat.fontSize })}>
          "{title?.trim()}"
        </span>
        <span>
          <FormattedMessage
            id="preview.written.by"
            defaultMessage="written by"
          />
        </span>
        <span>
          {author ||
            intl.formatMessage({
              id: "author.unknown",
              defaultMessage: "Unknown",
            })}
        </span>
      </div>
    </div>
  );
};
