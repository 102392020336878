import React from "react";
import { observer } from "mobx-react";
import { Document as DocumentType } from "../store/textEditor/Document";
import { PodcastDocument } from "./document/PodcastDocument";
import { ScreenplayDocument } from "./document/ScreenplayDocument";
import AppStore from "../store/AppStore";
import { ViewOptionsType } from "../store/View";
import { OutlineDocument } from "./document/OutlineDocument";

export const Document: React.FC<{ document?: DocumentType; store: AppStore }> =
  observer(({ document, store }) => {
    const currentViewId = store.view.id;
    if (currentViewId === ViewOptionsType.podcast) {
      return <PodcastDocument store={store} document={document} />;
    }

    if (currentViewId === ViewOptionsType.outline) {
      return <OutlineDocument store={store} document={document} />;
    }

    if (currentViewId === ViewOptionsType.screenplay) {
      return <ScreenplayDocument store={store} document={document} />;
    }

    return null;
  });
