import { makeAutoObservable } from "mobx";
import Apis from "./Api";
import { SuggestionOptionType } from "./Suggestion";

export default class Users {
  constructor() {
    makeAutoObservable(this);
  }

  static searchUsers = (searchTerm: string) => {
    // only call an api if search term is a valid email
    const isValidEmail = Users.validateEmail(searchTerm);

    if (isValidEmail) {
      return Apis.searchUsers(searchTerm);
    } else {
      return new Promise((accept) => accept([]));
    }
  };

  static normalizeUsersResponse = (response: any): SuggestionOptionType[] => {
    const users =
      response.docs?.map((doc: any, i: number) => {
        const user = doc.data();
        return {
          value: user.userId,
          label: user.displayName,
          details: {
            imageUrl: user.imageUrl,
            email: user.email,
          },
          index: i,
        };
      }) || [];
    return users;
  };

  static validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
}
