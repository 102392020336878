import { makeAutoObservable } from "mobx";
import { DEFAULT_STREAM_DETECTION_SETTINGS } from "../lib/audiox/models/Microphone";

export const DEFAULT_AUDIO_DETECTION_SETTINGS = {
  minAudioDuration:
    DEFAULT_STREAM_DETECTION_SETTINGS.minAudioDuration,
  silenceDelay: DEFAULT_STREAM_DETECTION_SETTINGS.silenceDelay,
  minSoundLevel: DEFAULT_STREAM_DETECTION_SETTINGS.minSoundLevel,
};

export interface AudioDetectionSettingsType {
  minAudioDuration?: number;
  minSoundLevel?: number;
  silenceDelay?: number;
}

export class AudioDetectionSettings {
  minAudioDuration: number; // min duration for take any duration less than this amount will not be recorded
  minSoundLevel: number; //minDecibels what level of sound is consider silence, if that's too low we will catch every sound: actor breathing, page turning...
  silenceDelay: number; //silence_delay how long should the silence be before we consider the take/rep to be done.

  constructor(settings?: AudioDetectionSettingsType) {
    this.minAudioDuration =
      settings?.minAudioDuration ||
      DEFAULT_AUDIO_DETECTION_SETTINGS.minAudioDuration;

    this.minSoundLevel =
      settings?.minSoundLevel ||
      DEFAULT_AUDIO_DETECTION_SETTINGS.minSoundLevel;

    this.silenceDelay =
      settings?.silenceDelay ||
      DEFAULT_AUDIO_DETECTION_SETTINGS.silenceDelay;

    makeAutoObservable(this);
  }

  get value() {
    return {
      minAudioDuration: this.minAudioDuration,
      minSoundLevel: this.minSoundLevel,
      silenceDelay: this.silenceDelay,
    };
  }

  updateSettings(settings: AudioDetectionSettingsType) {
    this.setMinAudioDurationForRecording(
      settings.minAudioDuration ||
        DEFAULT_AUDIO_DETECTION_SETTINGS.minAudioDuration,
    );
    this.setMinSoundLevel(
      settings.minSoundLevel ||
        DEFAULT_AUDIO_DETECTION_SETTINGS.minSoundLevel,
    );
    this.setSilenceDelay(
      settings.silenceDelay ||
        DEFAULT_AUDIO_DETECTION_SETTINGS.silenceDelay,
    );
  }

  setSilenceDelay(value: number) {
    this.silenceDelay = value;
  }

  setMinSoundLevel(value: number) {
    this.minSoundLevel = value;
  }

  setMinAudioDurationForRecording(value: number) {
    this.minAudioDuration = value;
  }
}

export default AudioDetectionSettings;
