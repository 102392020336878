import { makeAutoObservable } from "mobx";
import {
  DarkThemeColors,
  LightThemeColors,
  STORAGE_APP_NAME,
} from "../styles/default";

const STORAGE_CUSTOM_COLORS = "theme.custom.colors";

export enum DEFAULT_THEME_OPTIONS {
  DARK = "dark",
  LIGHT = "light",
}

export const LABELS_THEME_OPTIONS = {
  [DEFAULT_THEME_OPTIONS.DARK]: "Dark",
  [DEFAULT_THEME_OPTIONS.LIGHT]: "Light",
};

class Theme {
  appName: string;
  theme: DEFAULT_THEME_OPTIONS;
  customColors: ColorThemeType;
  changed: number;
  editKey: string;
  overrideTheme: boolean;

  constructor(appName?: string) {
    this.appName = appName || "productionOne";
    if (appName) {
      this.setStorageAppName(appName);
    }
    // Hard coding theme to DARK because the light theme hasn't been tested thoroughly
    // this.theme = this.getStorageTheme();
    this.theme = DEFAULT_THEME_OPTIONS.DARK;
    this.setLocalStorageTheme(this.theme);
    const customColors = this.getCustomColors();
    if (customColors) {
      this.customColors = customColors;
    } else {
      this.overrideTheme = false;
      if (this.theme === DEFAULT_THEME_OPTIONS.DARK) {
        this.customColors = DarkThemeColors;
      } else {
        this.customColors = LightThemeColors;
      }
    }

    this.changed = 0;
    this.editKey = "primary";
    this.overrideTheme = false;
    makeAutoObservable(this);
  }

  get override() {
    return this.overrideTheme;
  }

  get activeColorkey() {
    return this.editKey;
  }
  get Color() {
    if (this.changed >= 0) {
      if (this.overrideTheme) {
        return Object.assign({}, this.customColors);
      }
      if (this.theme === DEFAULT_THEME_OPTIONS.DARK) {
        return DarkThemeColors;
      } else if (this.theme === DEFAULT_THEME_OPTIONS.LIGHT) {
        return LightThemeColors;
      }
      // dark is the default theme
      return DarkThemeColors;
    }
    return DarkThemeColors;
  }

  get currentTheme() {
    return this.theme;
  }

  getStorageTheme = () => {
    let theme = DEFAULT_THEME_OPTIONS.LIGHT;
    if (typeof localStorage !== "undefined") {
      const storageTheme = localStorage["theme"];
      if (storageTheme === "undefined") {
        return theme;
      } else {
        theme = storageTheme;
      }
    }
    return theme;
  };

  setStorageAppName = (appName: string) => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(STORAGE_APP_NAME, appName);
    }
  };

  setLocalStorageTheme = (theme: DEFAULT_THEME_OPTIONS) => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("theme", theme);
    }
  };

  setTheme = (theme: any) => {
    this.theme = theme;
    this.setLocalStorageTheme(theme);
  };

  themeResetColors = (theme: DEFAULT_THEME_OPTIONS) => {
    if (theme === DEFAULT_THEME_OPTIONS.DARK) {
      this.customColors = DarkThemeColors;
    } else if (theme === DEFAULT_THEME_OPTIONS.LIGHT) {
      this.customColors = LightThemeColors;
    }
  };

  setColor = (color: any) => {
    this.changed = this.changed + 1;
    this.overrideTheme = true;
    //@ts-ignore
    this.customColors[this.editKey] = color;
  };

  setEditKey = (key: any) => {
    this.editKey = key;
  };

  padZero = (str: any, len?: any) => {
    len = len || 2;
    const zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  };
  invertColor = (hex: string, bw: boolean) => {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // invert color components
    const R = (255 - r).toString(16);
    const G = (255 - g).toString(16);
    const B = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + this.padZero(R) + this.padZero(G) + this.padZero(B);
  };

  getCustomColors = (): ColorThemeType | undefined => {
    if (typeof window !== "undefined") {
      const customColors = window.localStorage[STORAGE_CUSTOM_COLORS];
      if (customColors) {
        return JSON.parse(customColors);
      }
    }

    return undefined;
  };

  saveCustomColors = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        STORAGE_CUSTOM_COLORS,
        JSON.stringify(this.customColors)
      );
    }
  };
}

export default Theme;

interface ColorThemeType {
  primary: string;
  primary10: string;
  primary100: string;
  primary200: string;
  primary300: string;
  primary400: string;
  onPrimary: string;
  onPrimary10: string;
  onPrimary200: string;
  onPrimary100: string;
  onPrimary300: string;
  onPrimary400: string;
  secondary: string;
  onSecondary: string;
  background: string;
  surface: string;
  surface100: string;
  error: string;
  onBackground: string;
  onBackgroundVariant: string;
  onSurface: string;
  onError: string;
  dark: string;
  light: string;
  completed: string;
  decoration: string;
  done: string;
  inProgress: string;
  onCompleted: string;
  onDone: string;
  onInProgress: string;
  onOverdue: string;
  onSuccess: string;
  onPending: string;
  overdue: string;
  pending: string;
  rejection: string;
  success: string;
  shadow: string;
  toggleBackground: string;
  toggleThumb: string;
}
