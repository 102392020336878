import { makeAutoObservable } from "mobx";
import { FileMetadata, FileMetadataType } from "./FileMetadata";
import {
  Document,
  DocumentTypeOptions,
  NO_SERIES_ATTRIBUTION_ID,
} from "./textEditor/Document";
import { sortByField, SortByOrderOptions } from "./utils/sort";

export type FileSortByOptions = "updatedAt" | "createdAt" | "title";
export type FileViewOptions = "thumb" | "list";

export class FileStore {
  list: { [documentId: string]: FileMetadata };
  filterBy?: DocumentTypeOptions;
  sortBy: FileSortByOptions;
  orderBy: SortByOrderOptions;
  viewOption: FileViewOptions;

  constructor(files?: FileMetadataType[]) {
    this.list = this.createList(files);
    this.viewOption = "thumb";
    this.sortBy = "updatedAt";
    this.orderBy = SortByOrderOptions.descending;
    makeAutoObservable(this);
  }

  get files(): FileMetadata[] {
    const sortBy = this.sortBy;
    const filterBy = this.filterBy;
    const orderBy = this.orderBy;
    return Object.keys(this.list)
      .map((key: string) => this.list[key])
      .filter((r) => {
        if (filterBy) {
          return r.type === this.filterBy;
        } else {
          return true;
        }
      })
      .sort((a, b) => sortByField(a, b, sortBy, orderBy));
  }

  get hasFiles(): boolean {
    return Object.keys(this.list).length > 0;
  }

  setViewOption(option: "thumb" | "list") {
    this.viewOption = option;
  }

  setFilterBy(filterBy?: DocumentTypeOptions) {
    this.filterBy = filterBy;
  }

  setOrderBy(orderBy: SortByOrderOptions) {
    this.orderBy = orderBy;
  }

  clear = () => {
    this.list = {};
  };

  createList = (
    files?: FileMetadataType[]
  ): { [documentId: string]: FileMetadata } => {
    const f: { [documentId: string]: FileMetadata } = {};
    files?.forEach((metadata: FileMetadataType) => {
      f[metadata.documentId] = new FileMetadata(metadata);
    });
    return f || {};
  };

  setSortBy(sortBy: FileSortByOptions) {
    this.sortBy = sortBy;
  }

  setFiles(recent?: FileMetadataType[]) {
    const list = this.createList(recent);
    this.setList(list);
  }

  setList(files?: { [documentId: string]: FileMetadata }) {
    this.list = files || {};
  }

  updateRecentWithChange(documentId: string, updatedTitle: string) {
    const listIndex = this.getIndexForDocumentId(documentId);
    // first let's see if this document is already on the list
    if (listIndex >= 0) {
      // update last modified date
      this.list[listIndex].setLastModified(new Date().getTime());
      this.list[listIndex].setDocumentTitle(updatedTitle);
    } else {
      // no document was found, no update has been made
      // this should never occur
    }
  }

  // returns the index of the position if found, returns -1 if not found
  getIndexForDocumentId(documentId: string): number {
    let found = -1;
    this.files.forEach((recent, i) => {
      if (recent.documentId === documentId) {
        found = i;
      }
    });
    return found;
  }

  add(document: Document) {
    // first let's see if this document is already on the list
    if (this.list[document.id]) {
      // if it does, let's just updated last modified
      this.list[document.id].setLastModified(new Date().getTime());
    } else {
      this.list[document.id] = new FileMetadata({
        documentId: document.id,
        title: getDocumentTitle(document),
        updatedAt: document.updatedAt,
        createdAt: document.createdAt,
        type: document.type,
        starred: document.document?.starred || false,
        seriesId: document.document?.seriesId || NO_SERIES_ATTRIBUTION_ID,
      });
    }
  }

  removeFromRecent(documentId: string) {
    if (this.list[documentId]) {
      delete this.list[documentId];
    }
  }

  updateFileTitle = (documentId: string, title: string) => {
    const file = this.list[documentId];
    if (file) {
      file.setDocumentTitle(title);
      file.setLastModified(new Date().getTime());
    }
  };
}

function getDocumentTitle(document: Document): string {
  let title = document.document?.title;
  if (!title) {
    title = `${document.type} title`;
  }

  return title;
}
