import { makeAutoObservable, toJS } from "mobx";
import { v4 } from "uuid";
import {
  Character,
  CharacterType,
  DefaultCharacterType,
  DEFAULT_CHARACTER_COLOR,
} from "./Character";
import { Dialogue, DialogueType } from "./Dialogue";
import "firebase/firestore";
import { DocumentTypeOptions } from "./Document";
import Apis from "../Api";

export enum OutlineContentOptions {
  dialogue = "dialogue",
}

type DialogueIdType = string;

export interface OutlineType {
  characters?: { [characterId: string]: Character };
  dialogues?: { [id: string]: Dialogue };
  defaultCharacter?: DefaultCharacterType;
  dialogueOrder: string[];
  documentId: string;
  starred: boolean;
  title: string;
  seriesId: string;
}

export class Outline {
  characters: { [characterId: string]: Character };
  dialogues: { [id: string]: Dialogue };
  defaultCharacter?: DefaultCharacterType;
  dialogueOrder: DialogueIdType[]; // mapping keeps the order in which the dialogues appear on the document
  documentId: string;
  starred: boolean;
  title: string;
  seriesId: string;
  searchTerm: string;

  constructor(outline: OutlineType) {
    this.characters = outline.characters || {};
    this.dialogues = outline.dialogues || {};
    this.defaultCharacter = outline.defaultCharacter;
    this.dialogueOrder = outline.dialogueOrder;
    this.documentId = outline.documentId;
    this.starred = outline.starred || false;
    this.title = outline.title;
    this.seriesId = outline.seriesId;
    this.searchTerm = "";

    makeAutoObservable(this);
  }

  get charactersByName(): { [characterName: string]: Character } {
    const charsByName: { [characterName: string]: Character } = {};
    Object.keys(this.characters).forEach((id) => {
      const character = this.characters[id];
      const name = character.name.trim().toLocaleUpperCase();
      if (!charsByName[name]) {
        charsByName[name] = character;
      }
    });
    return charsByName;
  }

  setDialogueOrder(order: DialogueIdType[]) {
    this.dialogueOrder = order;
  }

  insertNewDialogueInDialogueOrder(dialogueId: string, atIndex?: number) {
    if (atIndex === 0) {
      // append to beginning
      this.setDialogueOrder([dialogueId, ...this.dialogueOrder]);
    }
    if (atIndex && atIndex > 0) {
      // insert at position
      const currentOrder = toJS(this.dialogueOrder);
      currentOrder.splice(atIndex, 0, dialogueId);
      this.setDialogueOrder(currentOrder);
    } else {
      // append to end
      this.setDialogueOrder([...this.dialogueOrder, dialogueId]);
    }
  }

  removeFromDialogueOrder(dialogueId: string) {
    const dialogueOrder = toJS(this.dialogueOrder);
    const index = dialogueOrder.indexOf(dialogueId);
    dialogueOrder.splice(index, 1);
    this.setDialogueOrder(dialogueOrder);
  }

  isCharacterAssignedToDialogue = (characterId: string): boolean => {
    let isAssigned: boolean = false;
    Object.keys(this.dialogues).forEach((dialogueId) => {
      if (this.dialogues[dialogueId]) {
        if (this.dialogues[dialogueId].characterId === characterId) {
          isAssigned = true;
        }
      }
    });
    return isAssigned;
  };

  search(term: string) {
    this.setSearchTerm(term);
  }

  setSearchTerm(term: string) {
    this.searchTerm = term;
  }

  updateCharacter(character: CharacterType): boolean {
    if (this.characters[character.id]) {
      this.characters[character.id].update(character);
      return true;
    }
    return false;
  }

  setCharacters(characters: { [characterId: string]: Character }) {
    this.characters = characters;
  }

  setSeriesId(seriesId: string) {
    this.seriesId = seriesId;
  }

  addCharacter(character: CharacterType): string {
    let characterId = character.id || v4();
    if (this.characters[characterId]) {
      // character already exists, let's update it
      this.updateCharacter(character);
    } else {
      // character does not exist, let's create it
      this.characters[characterId] = new Character({
        ...character,
        id: characterId,
      });
    }
    return characterId;
  }

  addNewCharacter(
    characterName: string,
    isDefault: boolean = false
  ): Character {
    const characterId: string = v4();
    this.characters[characterId] = new Character({
      id: characterId,
      name: characterName,
      color: DEFAULT_CHARACTER_COLOR,
      isDefault,
    });
    return this.characters[characterId];
  }

  addCharacterIfDoesNotExist = (characterName?: string): string | undefined => {
    const newCharacterName = characterName?.trim().toLocaleUpperCase();
    if (newCharacterName && characterName) {
      if (!this.charactersByName[newCharacterName]) {
        const characterId = v4();
        // if this character does not exist yet, automatically create new character
        this.addCharacter({
          name: characterName,
          id: characterId,
          isDefault: false,
        });
        return characterId;
      } else {
        return this.charactersByName[newCharacterName].id;
      }
    }

    return undefined;
  };

  setTitle(title: string) {
    this.title = title;
  }

  removeLine(id: string) {
    if (this.dialogues[id]) {
      delete this.dialogues[id];
    }
  }

  deleteAllLines() {
    this.dialogues = {};
  }

  addContent(dialogue: DialogueType): Dialogue {
    const id = dialogue.id || v4();
    this.dialogues[id] = new Dialogue({
      ...dialogue,
      id,
    });
    return this.dialogues[id];
  }

  setStarred(value: boolean) {
    this.starred = value;
  }

  sync_addAllContent = () => {
    Object.keys(this.dialogues).forEach((key) => {
      const item = this.dialogues[key];
      Apis.addDialogue(DocumentTypeOptions.outline, this.documentId, item);
    });
  };

  sync_allCharacters = async () => {
    await Object.keys(this.characters).forEach(async (characterId) => {
      const character = this.characters[characterId];
      await Apis.addCharacter(
        DocumentTypeOptions.outline,
        this.documentId,
        character
      );
    });
  };
  updateDefaultCharacterName(name: string) {
    if (this.defaultCharacter) {
      this.defaultCharacter = {
        ...this.defaultCharacter,
        name,
      };
    }
  }

  updateDefaultCharacterColor(color: string) {
    if (this.defaultCharacter) {
      this.defaultCharacter = {
        ...this.defaultCharacter,
        color,
      };
    }
  }

  updateDefaultCharacterId(id: string) {
    if (this.defaultCharacter) {
      this.defaultCharacter = {
        ...this.defaultCharacter,
        id,
      };
    }
  }

  getDocumentMetadata() {
    return {
      id: this.documentId,
      title: this.title,
      type: DocumentTypeOptions.outline,
      defaultCharacterName: this.defaultCharacter?.name || "",
      defaultCharacterColor:
        this.defaultCharacter?.color || DEFAULT_CHARACTER_COLOR,
      defaultCharacterId: this.defaultCharacter?.id || "",
      starred: this.starred,
      seriesId: this.seriesId,
    };
  }

  updateAllLinesWithUpdatedCharacter(character: CharacterType) {
    // TODO: after updating a character name, update all lines with that character
  }
}
