import { Color } from "./default";
import { queries } from "./mediaQueries";

//  Around 55 lines per page regardless of paper size. This excludes the page number and spaces after it
export const StyledScriptFormat = {
  fontFamily: "courier",
  fontSize: "12pt", //12-point Courier Font
  marginLeft: "3.81cm", //1.5in left margin
  marginRight: "2.54cm", //1-inch right margin (between 0.5 inches and 1.25 inches), ragged
  parentheticalLeft: "4.4cm", // 3.1in Actor parenthetical (wrylies) 3.1 inches from the left side of the page (1.6 from the margin)
  character: "5.588cm", // Dialogue speaker names (in all caps) 3.7 inches from the left side of the page (2.2 from the margin)
  dialogueMarginLeft: "2.8cm", //Dialogue 2.5 inches from the left side of the page (1.5 inches from margin)
  marginBottom: "2.54cm", // 1in
  marginTop: "1.6cm", // 1in
};
export const PaperStyles = {
  body: {
    margin: 0,
    padding: 0,
  },
  /* Defines a class for manual page breaks via inserted .page-break element */
  pageBreak: {
    pageBreakAfter: "always",
  },
  sheet: {
    page: {
      /* Styles for better appearance on screens only -- are reset to defaults in print styles later */
      /* Divide single pages with some space and center all pages horizontally */
      margin: "1cm auto",
      boxSizing: "border-box",
      color: Color.onLightBackground,
      /* Define a white paper background that sticks out from the darker overall background */
      background: "#fff",
      /* Show a drop shadow beneath each page */
      boxShadow: "0 4px 5px rgba(75, 75, 75, 0.2)",
      /* Override outline from user agent stylesheets */
      outline: "0",
      letterSpacing: "-0.85px",
      [queries.print]: {
        /* Reset all page styles that have been for better screen appearance only */
        /* Break cascading by using the !important rule */
        /* These resets are absolute must-haves for the print styles and the specificity may be higher elsewhere */
        size: "A4 portrait",
        border: "initial",
        borderRadius: "initial",
        background: "#FFF",
        backgroundColor: "#FFF",
        boxShadow: "initial",
        /* Force page breaks after each .page element of the document */
        pageBreakAfter: "always",
      },
    },
  },

  script: {
    page: {
      backgroundColor: "#FFF",
      color: "#000",
      width: "21cm",
      minHeight: "29cm",
      /* Styles for better appearance on screens only -- are reset to defaults in print styles later */
      boxSizing: "border-box",
      /* Reflect the actual page margin/padding on paper in the screen rendering (must match margin from @page rule) */
      paddingLeft: StyledScriptFormat.marginLeft,
      paddingRight: StyledScriptFormat.marginRight,
      paddingTop: StyledScriptFormat.marginTop,
      [queries.print]: {
        /* You can only change the size, margins, orphans, widows and page breaks here */
        /* Paper size and page orientation */
        width: "14cm",
        minHeight: "24cm",
        /* Margin per single side of the page */
        background: "#FFF",
        backgroundColor: "#FFF",
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        marginLeft: StyledScriptFormat.marginLeft,
        marginTop: StyledScriptFormat.marginTop,
        marginRight: StyledScriptFormat.marginRight,
        marginBottom: StyledScriptFormat.marginBottom,
        /* Force page breaks after each .page element of the document */
        pageBreakAfter: "always",
      },
    },
  },
};
