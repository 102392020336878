import { makeAutoObservable } from "mobx";
import { Talent, TalentType } from "./Talent";

export type CharacterId = string;
export const DEFAULT_CHARACTER_COLOR = "#3f51b5";

export interface DefaultCharacterType {
  id: string;
  name: string;
  color: string;
  isDefault: boolean;
}

export interface CharacterType {
  isDefault: boolean;
  afraidOf?: string;
  color?: string;
  id: string;
  imageUrl?: string;
  motivation?: string;
  name: string;
  notes?: string;
  talent?: TalentType;
  voiceOver?: boolean;
}

export class Character {
  isDefault: boolean;
  afraidOf?: string;
  color?: string;
  id: string;
  imageUrl?: string;
  motivation?: string;
  name: string;
  notes?: string;
  talent?: Talent;
  voiceOver?: boolean;

  constructor(character: CharacterType) {
    this.isDefault = character.isDefault;
    this.afraidOf = character.afraidOf;
    this.color = character.color || DEFAULT_CHARACTER_COLOR;
    this.id = character.id;
    this.imageUrl = character.imageUrl;
    this.motivation = character.motivation;
    this.name = character.name;
    this.notes = character.notes;
    this.talent = new Talent(character.talent);
    this.voiceOver = character.voiceOver;

    makeAutoObservable(this);
  }

  setIsDefault(value: boolean) {
    this.isDefault = value;
  }

  setName(name: string) {
    this.name = name;
  }

  setMotivation(motivation?: string) {
    this.motivation = motivation;
  }

  setImageUrl(imageUrl?: string) {
    this.imageUrl = imageUrl;
  }

  setColor(color?: string) {
    this.color = color;
  }

  setNotes(notes?: string) {
    this.notes = notes;
  }

  setAfraidOf(afraidOf?: string) {
    this.afraidOf = afraidOf;
  }

  setTalent(talent?: TalentType) {
    if (talent) {
      this.talent = new Talent(talent);
    }
  }

  update(character: CharacterType) {
    this.setAfraidOf(character.afraidOf);
    this.setColor(character.color);
    this.setImageUrl(character.imageUrl);
    this.setMotivation(character.motivation);
    this.setName(character.name);
    this.setNotes(character.notes);
    this.setTalent(character.talent);
  }

  fromValue(): CharacterType {
    return {
      afraidOf: this.afraidOf || "",
      color: this.color || DEFAULT_CHARACTER_COLOR,
      id: this.id,
      imageUrl: this.imageUrl || "",
      motivation: this.motivation || "",
      name: this.name || "",
      notes: this.notes || "",
      isDefault: this.isDefault,
      talent: {
        name: this.talent?.name || "",
        email: this.talent?.email || "",
      },
    };
  }
}
