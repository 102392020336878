/**
 * App Specific Colors
 */
export const appColors: any = {
  scratchRecording: "cyan",
  productionRecording: "violet",
};
/**
 * END - App Specific Colors
 */

// Helper function to transform a hex color into RGB numbers into a comma
// separated string. Example: #FFFFFF will return '255,255,255'
export const hexToRGB = (hex: string): string => {
  if (typeof hex !== "string") {
    throw new TypeError("Expected a string");
  }
  let hexColor = hex.replace(/^#/, "");
  if (hexColor.length === 3) {
    hexColor = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const num = parseInt(hexColor, 16);
  return `${num >> 16}, ${(num >> 8) & 255}, ${num & 255}`;
};

export const toRem = (value: number): string => `${value * 0.0625}rem`;

export const setAlpha = (hex: string, alpha: number): string =>
  `rgba(${hexToRGB(hex)}, ${alpha})`;
export const STORAGE_APP_NAME = "appName";

export const borderRadius = {
  borderRadius0: "4px",
  borderRadius1: "6px",
  borderRadius2: "8px",
  borderRadius3: "12px",
  borderRadius4: "16px",
  borderRadius5: "20px",
  borderRadius8: "38px",
  borderRadius9: "42px",
  borderRadius10: "50px",
};

export const spacings = {
  space0: "4px",
  space1: "6px",
  space2: "8px",
  space3: "10px",
  space4: "14px",
  space5: "20px",
  space6: "28px",
  space7: "34px",
  space8: "38px",
  space9: "42px",
  space10: "50px",
};

export const Regular = "";
export const Bold = {
  fontWeight: "bold",
};

const win = {
  localStorage: {},
};

let localStorage: any = win.localStorage;

if (typeof window !== "undefined") {
  localStorage = window.localStorage;
}

export const STORAGE_THEME_ID: string = "theme";
export const STORAGE_DEFAULT_FILTER_ID = "defaultFilter";
export const STORAGE_NOTIFICATIONS_ID = "notifications";
export const STORAGE_CUSTOM_COLORS = "theme.custom.colors";
export const STORAGE_THEME_OVERRIDE = "theme.override";

export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";
export const THEME_CUSTOM = "custom";

if (localStorage) {
  if (!localStorage[STORAGE_THEME_ID]) {
    localStorage[STORAGE_THEME_ID] = THEME_DARK;
  }
}
//@ts-ignore
const CURRENT_THEME = localStorage[STORAGE_THEME_ID];

const WHITE = "#EEEEEE";
const DARK = "#000000";
const BLUE_DE_FRANCE = "#4F68DC";
const DOVEGRAY = "#747474";
const HUNTER_GREEN = "#242526";
const NERO = "#272525";
const OCEAN_GREEN = "#009966";
const RAGOON_GREEN = "#18191A";
const DUBBING_PURPLE = "#7157E6";
const ONIX = "#0F0F0F";
// const P1_COLOR = "#1A5DF6"; // blue
export const P1_COLOR = "#EA33BA"; //pink
export const P1_COLOR_2 = "34EA64";
export const P1_COLOR_A_2 = "#A134EA";
export const P1_COLOR_A_3 = "#EA3440";
export const Borders = {
  radius: toRem(5),
};

export const Opacity = {
  light: 0.1,
};

export const Margins = {
  large: "48px",
  default: "24px",
  relaxed: "12px",
  regular: "8px",
  slim: "6px",
};

export const PaddingsNumbers = {
  default: 24,
  relaxed: 12,
  regular: 8,
  slim: 6,
};

export const Paddings = {
  default: `${PaddingsNumbers.default}px`,
  relaxed: `${PaddingsNumbers.relaxed}px`,
  regular: `${PaddingsNumbers.regular}px`,
  slim: `${PaddingsNumbers.slim}px`,
};

export const Family =
  '"Circular EP", -apple-system, "Helvetica Neue", "Lucida Grande", sans-serif';
export const productiononeFamily = '"Helvetica Neue", Arial, sans-serif';

export const appVariationColors = {
  brand: DUBBING_PURPLE,
  completed: OCEAN_GREEN,
  decoration: "orange",
  done: "#189309",
  highlight: "#242424",
  inProgress: OCEAN_GREEN,
  onBrand: WHITE,
  onCompleted: "#FFFFFF",
  onDone: "#225d1b",
  onInProgress: "#FFFFFF",
  onOverdue: "#FFFFFF",
  onPending: "#FFFFFF",
  onSuccess: "#FFFFFF",
  onWarning: "#000",
  overdue: "red",
  pending: DOVEGRAY,
  recording: "#7F050B",
  rejection: "#cc3333",
  shadow: DARK,
  success: "#4BA441",
  warning: "#f7b300",
  destructive: "#EA3440",
  lightBackground: WHITE,
  onLightBackground: "#000000",
  online: "#4BA441",
  offline: "gray",
};
export const DarkThemeColors = {
  primary: RAGOON_GREEN,
  primary10: "#AAAAAA",
  primary100: "#505050",
  primary200: "#242424",
  primary300: "#323232",
  primary400: "#181818",
  onPrimary: WHITE,
  onPrimary10: "#505050",
  onPrimary200: WHITE,
  onPrimary100: WHITE,
  onPrimary300: WHITE,
  onPrimary400: WHITE,
  secondary: P1_COLOR,
  tertiary: P1_COLOR_2,
  onSecondary: "#FFFFFF",
  background: RAGOON_GREEN,
  fadedBackground: setAlpha(RAGOON_GREEN, 0.5),
  surface: HUNTER_GREEN,
  surface10: ONIX,
  surface100: "#323232",
  error: "#e81919",
  onBackground: WHITE,
  onBackgroundVariant: WHITE,
  onSurface: WHITE,
  onError: "#FFFFFF",
  dark: DARK,
  light: WHITE,
  foreground: WHITE,
  // app variations (decoration)
  ...appVariationColors,
  toggleBackground: DARK,
  toggleThumb: NERO,
};
export const LightThemeColors = {
  primary: "#FFFFFF",
  primary10: "#AAAAAA",
  primary100: "#818181",
  primary200: "#FFFFFF",
  primary300: "#F9FAFC",
  primary400: "#f6f6f6",
  onPrimary: DARK,
  onPrimary10: "#5a5a5a",
  onPrimary200: "#545454",
  onPrimary100: "#545454",
  onPrimary300: "#545454",
  onPrimary400: DARK,
  secondary: P1_COLOR,
  tertiary: P1_COLOR_2,
  onSecondary: "#FFFFFF",
  background: "#FFFFFF",
  fadedBackground: setAlpha("#FFFFFF", 0.3),
  surface10: "#FFFFFF",
  surface: "#fdfbfb",
  surface100: "#F9FAFC",
  error: "#e81919",
  onBackground: DARK,
  onBackgroundVariant: DARK,
  onSurface: DARK,
  onError: "#FFFFFF",
  dark: WHITE,
  light: "#666666",
  foreground: "#666666",
  // app variations (decoration)
  ...appVariationColors,
  toggleBackground: "#e2e1e1",
  toggleThumb: BLUE_DE_FRANCE,
};

let selectedColors =
  CURRENT_THEME === THEME_DARK ? DarkThemeColors : LightThemeColors;

const customColors = localStorage[STORAGE_CUSTOM_COLORS];
const themeOverrideFlag = localStorage[STORAGE_THEME_OVERRIDE] || undefined;
const themeOverride = themeOverrideFlag ? JSON.parse(themeOverrideFlag) : false;

if (customColors && themeOverride) {
  selectedColors = JSON.parse(customColors);
}
export const TabSelectionStyleText = (selected: boolean) => {
  return {
    color: selected ? Color.secondary : Color.onBackground,
    fontWeight: selected ? 700 : 500,
  };
};
export const TabSelectionStyleBackground = (selected: boolean) => {
  return {
    backgroundColor: selected ? setAlpha(Color.secondary, 0.2) : "transparent",
    borderRadius: borderRadius.borderRadius10,
    ":hover": {
      backgroundColor: selected ? {} : setAlpha(Color.onBackground, 0.1),
    },
  };
};
export const defaults = {
  fontSize: "13px",
  smallFontSize: "11px",
  foregroundColor: selectedColors.foreground,
  inputBorderWidth: "2px",
};

export const fontFamily = '"Helvetica Neue", Helvetica, Arial, sans-serif';

export const Color = selectedColors;
export const InputStyle = {
  fontFamily: fontFamily,
  backgroundColor: "transparent",
  color: Color.onPrimary200,
  fontSize: defaults.fontSize,
  letterSpacing: "0.8px",
  border: `0.092rem solid ${setAlpha(Color.onBackground, 0.1)})`,
  margin: 0,
  outline: 0,
  marginTop: Margins.slim,
  marginBottom: Margins.slim,
  paddingLeft: spacings.space2,
  borderRadius: "3px",
  ":focus": {
    border: `0.092rem solid ${Color.secondary}`,
  },
  ":disabled": {
    border: `0.092rem solid ${Color.primary200}`,
    backgroundColor: "transparent",
    cursor: "not-allowed",
    color: setAlpha(Color.onPrimary200, 0.5),
  },
  ":invalid": {
    // border: `0.092rem solid ${Color.error}`,
  },
};

const folderColor = Color.surface100;

export const borderColor = setAlpha(Color.onBackground, 0.2);
export const borderFaded = setAlpha(Color.onBackground, 0.1);

export const borders = {
  clear: `1px solid transparent`,
  faded: `1px solid ${borderFaded}`,
  light: `1px solid ${setAlpha(Color.onBackground, 0.3)}`,
  default: `1px solid ${borderColor}`,
  thick: `2px solid ${borderColor}`,
  highlighted: `1px solid ${Color.onBackground}`,
};
export const file = (width: string, height: string, color?: string) => {
  return {
    width,
    height,
    position: "relative",
    backgroundColor: color || folderColor,
    borderRadius: borderRadius.borderRadius1,
    zIndex: 100,
    marginBottom: spacings.space2,
  };
};

export const folder = (width: string, height: string, color?: string) => {
  return {
    width,
    height,
    position: "relative",
    backgroundColor: color || folderColor,
    borderRadius: `0 ${borderRadius.borderRadius1} ${borderRadius.borderRadius1} ${borderRadius.borderRadius1}`,
    zIndex: 100,
    marginBottom: spacings.space2,
    ":before": {
      content: "''",
      width: "50%",
      height: "20px",
      borderRadius: "10px 10px 0 0",
      backgroundColor: color || folderColor,
      position: "absolute",
      opacity: 0.6,
      top: "-20px",
      left: "0px",
    },
  };
};

export const Hover = {
  items: {
    color: setAlpha(Color.surface10, 0.9),
  },
};

export const hint = {
  display: "inline-block",
  position: "relative",
  // ARROW
  ":before": {
    background: "transparent",
    border: "6px solid transparent",
    borderTopColor: "#383838",
    content: "''",
    opacity: "0",
    pointerEvents: "none",
    position: "absolute",
    transform: "translate3d(0, 0, 0)",
    transition: "0.3s ease",
    transitionDelay: "0ms",
    visibility: "hidden",
    zIndex: "1000000",
  },
  // BODY
  ":after": {
    background: "#383838",
    borderRadius: "4px",
    color: "white",
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: "12px",
    lineHeight: "12px",
    opacity: "0",
    padding: "8px 10px",
    pointerEvents: "none",
    position: "absolute",
    transform: "translate3d(0, 0, 0)",
    transition: "0.3s ease",
    transitionDelay: "0ms",
    visibility: "hidden",
    whiteSpace: "nowrap",
    zIndex: "1000000",
  },
  ":hover:before": {
    opacity: "1",
    visibility: "visible",
  },
  ":hover:after": {
    opacity: "1",
    visibility: "visible",
  },
  "[aria-label]:after": {
    content: "attr(aria-label)",
  },
};

export const hintTop = {
  ":before": {
    bottom: "100%",
    left: "calc(50% - 6px)",
    marginBottom: "-11px",
  },
  ":after": {
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
  },
  ":hover:before": {
    transform: "translateY(-8px)",
  },
  ":hover:after": {
    transform: "translateX(-50%) translateY(-8px)",
  },
};
export const hintLeft = {
  ":before": {
    bottom: "50%",
    marginBottom: "-6px",
    marginRight: "-11px",
    right: "100%",
  },
  ":after": {
    bottom: "50%",
    marginBottom: "-14px",
    right: "100%",
  },
  ":hover:before": {
    transform: "translateX(-8px) rotate(-90deg)",
  },
  ":hover:after": {
    transform: "translateX(-8px)",
  },
};

export const hintBottom = {
  ":before": {
    left: "calc(50% - 6px)",
    marginTop: "-11px",
    top: "100%",
    transform: "translateX(-50%)",
  },
  ":after": {
    left: "50%",
    top: "100%",
    transform: "translateX(-50%)",
  },
  ":hover:before": {
    transform: "translateY(8px) rotate(180deg)",
  },
  ":hover:after": {
    transform: "translateX(-50%) translateY(8px)",
  },
};

export const hintRight = {
  ":before": {
    bottom: "50%",
    left: "100%",
    marginBottom: "-6px",
    marginLeft: "-11px",
  },
  ":after": {
    marginBottom: "-14px",
    left: "100%",
    bottom: "50%",
  },
  ":hover:before": {
    transform: "translateX(8px) rotate(90deg)",
  },
  ":hover:after": {
    transform: "translateX(8px)",
  },
};

export const hintTopLeft = {
  ":before": {
    bottom: "100%",
    left: "calc(50% - 6px)",
    marginBottom: "-11px",
  },
  ":after": {
    bottom: "100%",
    left: "50%",
    marginLeft: "12px",
    transform: "translateX(-100%)",
  },
  ":hover:before": {
    transform: "translateY(-8px)",
  },
  ":hover:after": {
    transform: "translateX(-100%) translateY(-8px)",
  },
};

export const hintTopRight = {
  ":before": {
    marginBottom: "-11px",
    bottom: "100%",
    left: "calc(10% - 6px)",
  },
  ":after": {
    bottom: "100%",
    left: "10%",
    transform: "translateX(0)",
    marginLeft: "-12px",
  },
  ":hover:before": {
    transform: "translateY(-8px)",
  },
  ":hover:after": {
    transform: "translateY(-8px)",
  },
};

export const hintBottomLeft = {
  ":before": {
    marginTop: "-11px",
    top: "100%",
    left: "calc(50% - 6px)",
  },
  ":after": {
    top: "100%",
    left: "50%",
    transform: "translateX(-100%)",
    marginLeft: "12px",
  },
  ":hover:before": {
    transform: "translateY(8px) rotate(180deg)",
  },
  ":hover:after": {
    transform: "translateX(-100%) translateY(8px)",
  },
};

export const hintBottomRight = {
  ":before": {
    marginTop: "-11px",
    top: "100%",
    left: "calc(50% - 6px)",
  },
  ":after": {
    top: "100%",
    left: "50%",
    transform: "translateX(0)",
    marginLeft: "-12px",
  },
  ":hover:before": {
    transform: "translateY(8px) rotate(180deg)",
  },
  ":hover:after": {
    transform: "translateY(8px)",
  },
};

export const Elevation = {
  depth0: {
    position: "relative",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 0,
  },
  depth1: {
    position: "relative",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 1,
  },
  depth2: {
    position: "relative",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 2,
  },
  depth3: {
    position: "relative",
    boxShadow:
      "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 3,
  },
  depth4: {
    position: "relative",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 4,
  },
  depth4Reverse: {
    position: "relative",
    boxShadow:
      "0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 4,
  },
  depth5: {
    position: "relative",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 5,
  },
  depth6: {
    position: "relative",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 6,
  },
  depth7: {
    position: "relative",
    boxShadow:
      "0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    zIndex: 7,
  },
  depth8: {
    position: "relative",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    zIndex: 8,
  },
  depth9: {
    position: "relative",
    boxShadow:
      "0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    zIndex: 9,
  },
  depth10: {
    position: "relative",
    boxShadow:
      "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    zIndex: 10,
  },
  depth11: {
    position: "relative",
    boxShadow:
      "0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    zIndex: 11,
  },
  depth12: {
    position: "relative",
    boxShadow:
      "0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    zIndex: 12,
  },
  depth13: {
    position: "relative",
    boxShadow:
      "0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    zIndex: 13,
  },
  depth14: {
    position: "relative",
    boxShadow:
      "0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    zIndex: 14,
  },
  depth15: {
    position: "relative",
    boxShadow:
      "0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    zIndex: 15,
  },
  depth16: {
    position: "relative",
    boxShadow:
      "0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    zIndex: 16,
  },
  depth17: {
    position: "relative",
    boxShadow:
      "0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    zIndex: 17,
  },
  depth18: {
    position: "relative",
    boxShadow:
      "0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    zIndex: 18,
  },
  depth19: {
    position: "relative",
    boxShadow:
      "0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    zIndex: 19,
  },
  depth20: {
    position: "relative",
    boxShadow:
      "0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    zIndex: 20,
  },
  depth21: {
    position: "relative",
    boxShadow:
      "0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    zIndex: 21,
  },
  depth22: {
    position: "relative",
    boxShadow:
      "0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    zIndex: 22,
  },
  depth23: {
    position: "relative",
    boxShadow:
      "0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    zIndex: 23,
  },
  depth24: {
    position: "relative",
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    zIndex: 24,
  },
};

export const DefaultScrollbarStyle = {
  "::-webkit-scrollbar": {
    width: "10px",
    overflow: "overlay",
    display: "none",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: "transparent",
    borderRadius: "5px",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: "black",
  },
  "::-webkit-scrollbar-corner": {
    background: "transparent",
  },
};

export const FontSizes = {
  fontSize0: "10px",
  fontSize1: "12px",
  fontSize2: "13px",
  fontSize3: "14px",
  fontSize4: "16px",
  fontSize5: "18px",
  fontSize6: "20px",
  fontSize7: "24px",
  fontSize8: "28px",
  fontSize9: "32px",
  fontSize10: "40px",
  fontSize11: "52px",
  fontSize12: "68px",
  fontSize13: "88px",
};

export const FontFamilies = {
  default: "Arial, Helvetica, sans-serif",
  dialoguePrint: `'Roboto Mono', monospace`,
  dialogue: `'Inconsolata', monospace`,
  heading: `'Roboto', sans-serif`,
  cursive: `'Amatic SC', cursive`,
};

export const InputStyleH1 = {
  border: `1px solid transparent`,
  fontFamily: FontFamilies.heading,
  fontSize: FontSizes.fontSize11,
  fontWeight: "bold",
  letterSpacing: "-4px",
  padding: spacings.space2,
  resize: "none",
  wordWrap: "wrap",
};

export const InputStyleDialogue = {
  border: `1px solid transparent`,
  fontFamily: FontFamilies.dialogue,
  fontSize: FontSizes.fontSize5,
  height: "20px",
  padding: 0,
  resize: "none",
  ":focus": {
    border: `1px solid transparent`,
  },
  ":disabled": {
    border: 0,
  },
};
export const InputStyleCharacter = {
  fontFamily: FontFamilies.dialogue,
  fontSize: FontSizes.fontSize5,
  fontWeight: "500",
};

export const AvatarStyles = (size: number, borderColor: string = "#000") => ({
  verticalAlign: "middle",
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: "50%",
  border: `2px solid ${borderColor}`,
});

export const SearchInputStyle = {
  backgroundColor: "#3A3B3C",
  border: 0,
  borderBottomLeftRadius: borderRadius.borderRadius10,
  borderBottomRightRadius: borderRadius.borderRadius10,
  borderTopLeftRadius: borderRadius.borderRadius10,
  borderTopRightRadius: borderRadius.borderRadius10,
  fontSize: FontSizes.fontSize3,
  ":focus": {
    border: 0,
  },
};

export const truncateTextWithEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const Text = {
  body: {
    fontFamily: FontFamilies.default,
    fontSize: FontSizes.fontSize3,
  },
  bodyFaded: {
    fontFamily: FontFamilies.default,
    fontSize: FontSizes.fontSize3,
    color: setAlpha(Color.onBackground, 0.5),
  },
  bodyBold: {
    fontFamily: FontFamilies.default,
    fontSize: FontSizes.fontSize3,
    fontWeight: 700,
  },
  bodyBoldFaded: {
    fontFamily: FontFamilies.default,
    fontSize: FontSizes.fontSize3,
    fontWeight: 700,
    color: setAlpha(Color.onBackground, 0.5),
  },
  h2: {
    fontFamily: FontFamilies.heading,
    fontSize: FontSizes.fontSize10,
    fontWeight: 600,
    letterSpacing: "-4px",
  },
  overline: {
    fontFamily: FontFamilies.default,
    fontSize: FontSizes.fontSize1,
    color: setAlpha(Color.onBackground, 0.8),
    fontWeight: 700,
  },
};

export const ButtonStylesForLightBackgrounds = {
  icon: {
    alignItems: "center",
    backgroundColor: setAlpha(Color.onBackground, 0.05),
    borderRadius: borderRadius.borderRadius10,
    cursor: "pointer",
    display: "flex",
    gridGap: spacings.space2,
    padding: spacings.space1,
    ":hover": {
      backgroundColor: setAlpha(Color.onBackground, 0.1),
    },
  },
  list: {
    alignItems: "center",
    borderRadius: borderRadius.borderRadius1,
    cursor: "pointer",
    display: "flex",
    gridGap: spacings.space2,
    padding: spacings.space4,
    width: "100%",
    ":hover": {
      width: "100%",
      backgroundColor: setAlpha(Color.onBackground, 0.1),
    },
  },
  default: {
    alignItems: "center",
    backgroundColor: Color.secondary,
    borderRadius: borderRadius.borderRadius2,
    color: Color.onSecondary,
    cursor: "pointer",
    display: "flex",
    fontSize: FontSizes.fontSize4,
    fontWeight: "bold",
    gridGap: "6px",
    margin: 0,
    outline: "none",
    padding: spacings.space4,
    ":hover": {
      backgroundColor: Color.secondary,
      cursor: "pointer",
    },
    ":focus": {
      border: `setAlpha(Color.onBackground, 0.1) solid 1px`,
      boxShadow: "0px 0px 0px 1px #fff inset",
    },
  },
  secondary: {
    alignItems: "center",
    borderRadius: "16px",
    color: Color.dark,
    cursor: "pointer",
    display: "flex",
    fontSize: FontSizes.fontSize4,
    fontWeight: "bold",
    gridGap: "6px",
    margin: 0,
    outline: "none",
    padding: "4px 10px 4px 10px",
    ":hover": {
      color: Color.secondary,
      cursor: "pointer",
    },
    ":focus": {
      border: `setAlpha(Color.onBackground, 0.1) solid 1px`,
      borderRadius: "16px",
      boxShadow: "0px 0px 0px 1px #fff inset",
    },
  },
  dark: {
    alignItems: "center",
    backgroundColor: Color.dark,
    border: `1px solid ${setAlpha(Color.dark, 0.3)}`,
    borderRadius: "16px",
    color: Color.light,
    cursor: "pointer",
    display: "flex",
    fontSize: FontSizes.fontSize4,
    fontWeight: "bold",
    gridGap: "6px",
    margin: 0,
    outline: "none",
    padding: "4px 10px 4px 10px",
    ":hover": {
      backgroundColor: setAlpha(Color.dark, 0.9),
    },
    ":focus": {
      border: `setAlpha(Color.dark, 0.1) solid 1px`,
      borderRadius: "16px",
      boxShadow: "0px 0px 0px 1px #fff inset",
    },
  },
  primary: {
    alignItems: "center",
    backgroundColor: "transparent",
    border: `1px solid ${setAlpha(Color.dark, 0.3)}`,
    borderRadius: "16px",
    color: Color.dark,
    cursor: "pointer",
    display: "flex",
    fontSize: FontSizes.fontSize4,
    fontWeight: "bold",
    gridGap: "6px",
    margin: 0,
    outline: "none",
    padding: "4px 10px 4px 10px",
    ":hover": {
      backgroundColor: setAlpha(Color.onBackground, 0.1),
    },
    ":focus": {
      border: `setAlpha(Color.dark, 0.1) solid 1px`,
      borderRadius: "16px",
      boxShadow: "0px 0px 0px 1px #fff inset",
    },
  },
  link: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: FontSizes.fontSize4,
    fontWeight: "bold",
    gridGrap: "6px",
    ":hover": {
      color: Color.secondary,
      textDecoration: "underline",
    },
  },
  clearLink: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    color: Color.onLightBackground,
    fontSize: FontSizes.fontSize2,
    fontWeight: "bold",
    gridGrap: "6px",
    ":hover": {
      color: Color.onLightBackground,
      textDecoration: "underline",
    },
  },
  clear: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    color: Color.onLightBackground,
    fontSize: FontSizes.fontSize2,
    fontWeight: "bold",
    gridGrap: "6px",
    ":hover": {
      color: Color.onLightBackground,
    },
  },
};

export const defaultStyles = {
  button: {
    cleanSecondary: {
      color: Color.secondary,
      fontFamily: FontFamilies.default,
      fontWeight: 700,
      ":hover": {
        color: "#FFFFFF",
      },
    },
    icon: {
      alignItems: "center",
      backgroundColor: setAlpha(Color.onBackground, 0.05),
      borderRadius: borderRadius.borderRadius10,
      cursor: "pointer",
      display: "flex",
      gridGap: spacings.space2,
      padding: spacings.space1,
      ":hover": {
        backgroundColor: setAlpha(Color.onBackground, 0.1),
      },
    },
    list: {
      alignItems: "center",
      borderRadius: borderRadius.borderRadius1,
      cursor: "pointer",
      display: "flex",
      gridGap: spacings.space2,
      padding: spacings.space4,
      width: "100%",
      ":hover": {
        width: "100%",
        backgroundColor: setAlpha(Color.onBackground, 0.1),
      },
    },
    defaultSmall: {
      alignItems: "center",
      backgroundColor: Color.secondary,
      borderRadius: borderRadius.borderRadius2,
      color: Color.onSecondary,
      cursor: "pointer",
      display: "flex",
      fontSize: FontSizes.fontSize2,
      fontWeight: "bold",
      gridGap: "6px",
      margin: 0,
      outline: "none",
      padding: spacings.space1,
      ":hover": {
        backgroundColor: Color.secondary,
        cursor: "pointer",
      },
      ":focus": {
        border: `setAlpha(Color.onBackground, 0.1) solid 1px`,
        boxShadow: "0px 0px 0px 1px #fff inset",
      },
    },
    default: {
      alignItems: "center",
      backgroundColor: Color.secondary,
      borderRadius: borderRadius.borderRadius2,
      color: Color.onSecondary,
      cursor: "pointer",
      display: "flex",
      fontSize: FontSizes.fontSize4,
      fontWeight: "bold",
      gridGap: "6px",
      margin: 0,
      outline: "none",
      padding: spacings.space4,
      ":hover": {
        backgroundColor: Color.secondary,
        cursor: "pointer",
      },
      ":focus": {
        border: `setAlpha(Color.onBackground, 0.1) solid 1px`,
        boxShadow: "0px 0px 0px 1px #fff inset",
      },
    },
    secondary: {
      alignItems: "center",
      borderRadius: "16px",
      color: Color.onBackground,
      cursor: "pointer",
      display: "flex",
      fontSize: FontSizes.fontSize4,
      fontWeight: "bold",
      gridGap: "6px",
      margin: 0,
      outline: "none",
      padding: "4px 10px 4px 10px",
      ":hover": {
        color: Color.secondary,
        cursor: "pointer",
      },
      ":focus": {
        border: `setAlpha(Color.onBackground, 0.1) solid 1px`,
        borderRadius: "16px",
        boxShadow: "0px 0px 0px 1px #fff inset",
      },
    },
    primary: {
      alignItems: "center",
      backgroundColor: "transparent",
      border: `1px solid ${setAlpha(Color.onBackground, 0.3)}`,
      borderRadius: "16px",
      color: "white",
      cursor: "pointer",
      display: "flex",
      fontSize: FontSizes.fontSize4,
      fontWeight: "bold",
      gridGap: "6px",
      margin: 0,
      outline: "none",
      padding: "4px 10px 4px 10px",
      ":hover": {
        backgroundColor: setAlpha(Color.onBackground, 0.1),
      },
      ":focus": {
        border: `setAlpha(Color.onBackground, 0.1) solid 1px`,
        borderRadius: "16px",
        boxShadow: "0px 0px 0px 1px #fff inset",
      },
    },
    link: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      fontSize: FontSizes.fontSize4,
      fontWeight: "bold",
      gridGrap: "6px",
      ":hover": {
        color: Color.secondary,
        textDecoration: "underline",
      },
    },
    textLink: {
      alignItems: "center",
      cursor: "pointer",
      display: "inline-flex",
      fontWeight: "bold",
      gridGrap: "6px",
      ":hover": {
        color: Color.secondary,
        textDecoration: "underline",
      },
    },
    clearLink: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      color: setAlpha(Color.onBackground, 0.4),
      fontSize: FontSizes.fontSize2,
      fontWeight: "bold",
      gridGrap: "6px",
      ":hover": {
        color: Color.onBackground,
        textDecoration: "underline",
      },
    },
  },
  ellipsisWrapper: {
    color: Color.onBackground,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};
