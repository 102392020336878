import { css } from "glamor";
import TextareaAutosize from "react-textarea-autosize";
import { observer } from "mobx-react";
import {
  borderRadius,
  Color,
  InputStyle,
  InputStyleDialogue,
  setAlpha,
  spacings,
} from "../../styles/default";
import { Dialogue } from "../../store/textEditor/Dialogue";
import { Document } from "../../store/textEditor/Document";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import { DialogueBindings } from "./DialogueBinding";
import { ui } from "../../store/Ui";
import { useIntl } from "react-intl";
import Draggable from "../common/Draggable";
import Droppable from "../common/dragNdrop/Droppable";
import { dragSelect } from "../..";
import AppStore from "../../store/AppStore";

const NonCharacterDialogue: React.FC<{
  store: AppStore;
  disableTextEdits: boolean;
  dialogue: Dialogue;
  document: Document;
  sceneId?: string;
  index: number;
}> = ({ document, dialogue, disableTextEdits, sceneId, index, store }) => {
  const dialogueId = dialogue.id;
  return (
    <div
      data-attrdocumentitem={true}
      key={dialogueId}
      onDrop={() => {
        if (!disableTextEdits) {
          document.handleListItemSwap(
            dragSelect.draggingItemListIndex,
            dragSelect.dropAtIndex
          );
          dragSelect.clearDraggingData();
          ui.setSelectedId(undefined);
        }
      }}
    >
      <Draggable
        itemId={dialogue.id}
        index={index}
        disabled={disableTextEdits}
        onDrop={() => {
          if (!disableTextEdits) {
            document.handleListItemSwap(
              dragSelect.draggingItemListIndex,
              dragSelect.dropAtIndex
            );
            dragSelect.clearDraggingData();
            ui.setSelectedId(undefined);
          }
        }}
      >
        <NonCharacterDialogueEditor
          store={store}
          document={document}
          dialogue={dialogue}
          disableTextEdits={disableTextEdits}
          sceneId={sceneId}
        />
      </Draggable>
      <Droppable
        index={index + 1}
        onDrop={(draggedItemIndex?: number, droppedIndex?: number) => {
          if (!disableTextEdits) {
            document.handleListItemSwap(draggedItemIndex, droppedIndex);
          }
        }}
      />
    </div>
  );
};

export default observer(NonCharacterDialogue);

const NonCharacterDialogueEditor: React.FC<{
  store: AppStore;
  dialogue: Dialogue;
  disableTextEdits: boolean;
  document: Document;
  sceneId?: string;
}> = observer(({ store, dialogue, disableTextEdits, document, sceneId }) => {
  const intl = useIntl();
  const actionsMenuOptions = [
    {
      id: "delete",
      label: intl.formatMessage({ id: "action.dialogue.delete" }),
      icon: "delete_outline",
      action: (e?: any) => {
        document.removeDialogue(dialogue.id, sceneId);
      },
    },
  ];
  const value = dialogue.text;
  return (
    <div data-attrdocumentitem={true} key={dialogue.id}>
      <DialogueBindings disableTextEdits={disableTextEdits} dialogue={dialogue}>
        <div
          {...css({
            alignItems: "flex-start",
            backgroundColor: setAlpha(Color.surface100, 0.5),
            borderRadius: borderRadius.borderRadius2,
            boxSizing: "border-box",
            display: "flex",
            gridGap: spacings.space1,
            justifyContent: "flex-start",
            margin: 0,
            padding: spacings.space4,
            paddingLeft: spacings.space8,
            width: "100%",
          })}
        >
          <div {...css({ width: "100%", marginTop: spacings.space2 })}>
            <TextareaAutosize
              {...css(InputStyle, InputStyleDialogue, {
                paddingTop: spacings.space0,
                marginTop: 0,
                paddingLeft: 0,
                marginLeft: 0,
                width: "100%",
              })}
              id={`${dialogue.id}-insert`}
              className="mousetrap"
              onFocus={() => {
                if (!disableTextEdits) {
                  dialogue.setFocus(true);
                }
              }}
              disabled={disableTextEdits}
              onBlur={() => {
                if (!disableTextEdits) {
                  dialogue.setFocus(false);
                }
              }}
              value={value}
              onChange={(e) => {
                if (!disableTextEdits) {
                  ui.setSelectedId(undefined);
                  dialogue.setText(e.target.value);
                  document.sync_updateDialogue(document.id, dialogue, sceneId);
                }
              }}
              spellCheck={true}
              placeholder={intl.formatMessage({
                id: `${dialogue.type}.placeholder`,
              })}
            />
          </div>
          {!disableTextEdits && (
            <MenuActionsTrigger options={actionsMenuOptions} />
          )}
        </div>
      </DialogueBindings>
    </div>
  );
});
