import { makeAutoObservable } from "mobx";
import AudioDetectionSettings from "./AudioDetectionSettings";
import ExternalHardware, {
  ExternalHardwareActions,
} from "./ExternalHardwareActions";
import MidiTimecode from "./MidiTimecode";
import Theme, { DEFAULT_THEME_OPTIONS } from "./Theme";

/**
 * Settings is the class to store state for current app
 * settings such as theme, external hardwares or any other
 * configuration you would like to persist during the web
 * app session
 */
export default class Settings {
  appVersion: string;
  appVersionDescription: string;
  externalHardware: ExternalHardware;
  mtc: MidiTimecode;
  theme: Theme;
  audioDetectionSettings: AudioDetectionSettings;

  constructor(props?: ExternalHardwareActions) {
    this.theme = new Theme("productionOne");
    this.externalHardware = new ExternalHardware(props);
    this.mtc = new MidiTimecode();
    this.appVersion = "Beta";
    this.appVersionDescription = "";
    this.audioDetectionSettings = new AudioDetectionSettings();

    makeAutoObservable(this);
  }

  get version() {
    return this.appVersion;
  }

  setAppVersion(version: string) {
    this.appVersion = version;
  }

  setAppVersionDescription(description: string) {
    this.appVersionDescription = description;
  }

  setTheme(theme: DEFAULT_THEME_OPTIONS) {
    this.theme.setTheme(theme);
  }
}
