import { logEvent } from "@firebase/analytics";
import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AppStore, { FormOptions } from "../../store/AppStore";
import Popup from "../../store/Popup";
import { defaultStyles, spacings } from "../../styles/default";
import { queries } from "../../styles/mediaQueries";
import { Input } from "../common/Input";
import { Popup as PopupComponent } from "../common/Popup";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupSignIn: React.FC<PopupsType> = ({ store, popup }) => {
  const intl = useIntl();
  const form = store.forms.getForm(FormOptions.signIn);
  const fields = form.fields;
  const user = store.user;
  const errorCode = user.errorCode;
  if (!form || !fields) {
    return null;
  }
  return (
    <PopupComponent
      id="signin-popup"
      title={popup.title}
      width="575px"
      cancel={{
        action: () => store.popups.clear(),
        label: intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: async () => {
          const formData = form.getData;
          const signedIn = await store.signIn(
            formData.username,
            formData.password
          );

          if (signedIn) {
            store.popups.clear();
          }
        },
        enabled: form.isValid,
        label: intl.formatMessage({ id: "label.sign.in" }),
      }}
    >
      <div
        {...css({
          display: "flex",
          flexDirection: "column",
          gridGap: spacings.space2,
          justifyContent: "center",
          padding: spacings.space5,
          width: "100%",
          [queries.phone]: {
            boxSizing: "border-box",
            flexDirection: "column",
            gridGap: spacings.space2,
            margin: 0,
            padding: 0,
            alignItems: "center",
            paddingTop: spacings.space4,
            marginLeft: "-20px",
          },
        })}
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: "25px",
            [queries.phone]: {
              width: "100%",
              boxSizing: "border-box",
              flexDirection: "column",
              gridGap: spacings.space2,
              margin: 0,
              padding: 0,
              paddingTop: spacings.space4,
            },
          })}
        >
          <Input
            name="username"
            label={intl.formatMessage({
              id: "username.label",
            })}
            value={fields.username.value}
            onChange={(e: any) => fields.username.set(e.target.value)}
            type="text"
            width="180px"
          />
          <Input
            name="password"
            label={intl.formatMessage({
              id: "password.label",
            })}
            value={fields.password.value}
            onChange={(e: any) => fields.password.set(e.target.value)}
            type="password"
            width="180px"
          />
        </div>
        <div>{user.authenticated && errorCode}</div>
        <div>
          <span>
            <FormattedMessage id="missing.account.title" />
          </span>
          <button
            {...css({ ...defaultStyles.button.link })}
            onClick={() => {
              logEvent(user.analytics, `btn_click_openSignUp`);
              store.openSignUp(intl.formatMessage({ id: "label.sign.up" }));
            }}
          >
            <FormattedMessage id="create.account.label" />
          </button>
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupSignIn);
