import { makeAutoObservable } from "mobx";

export interface Position {
  x: number;
  y: number;
}

export type DragTypes = "selecting" | "moving";
class DragSelectStore {
  registeredClick: boolean;
  startPosition?: Position;
  width?: number;
  height?: number;
  draggingId?: string;
  draggingItemListIndex?: number;
  dropAtIndex?: number;
  draggingItem?: any;
  draggedItemDimensions?: {
    width?: number;
    height?: number;
  };
  dragType?: DragTypes;
  blockDrawingSelectionBox: boolean;

  constructor() {
    this.registeredClick = false;
    this.blockDrawingSelectionBox = true;
    makeAutoObservable(this);
  }

  get isSelecting(): boolean {
    return (
      this.selectionBoxStyle.width > 0 || this.selectionBoxStyle.height > 0
    );
  }
  get selectionBoxStyle(): {
    top: number;
    left: number;
    width: number;
    height: number;
  } {
    return {
      top: this.startPosition?.x || 0,
      left: this.startPosition?.y || 0,
      width: this.width || 0,
      height: this.height || 0,
    };
  }

  get droppableStyle(): { width: number; height: number } {
    return {
      width: this.width || 0,
      height: this.height || 0,
    };
  }

  setDragType(type?: DragTypes) {
    this.dragType = type;
  }

  setBlockDrawingSelectionBox(value: boolean) {
    this.blockDrawingSelectionBox = value;
  }

  setDraggingItem(item: any) {
    this.draggingItem = item;
  }

  setDraggingItemListIndex(index?: number) {
    this.setBlockDrawingSelectionBox(true);
    this.draggingItemListIndex = index;
  }

  setDraggingId(id?: string) {
    this.setBlockDrawingSelectionBox(true);
    this.draggingId = id;
  }

  setDropAtIndex(index?: number) {
    if (index !== this.dropAtIndex) {
      this.dropAtIndex = index;
    }
  }

  setDraggingItemDimensions(width?: number, height?: number) {
    if (!this.blockDrawingSelectionBox) {
      this.draggedItemDimensions = {
        width,
        height,
      };
    }
  }

  clearDraggingData() {
    this.setDraggingId(undefined);
    this.setDraggingItemListIndex(undefined);
    this.setDraggingItem(undefined);
    this.setDropAtIndex(undefined);
    this.setDimensionValues(0, 0);
    this.setStartPosition(0, 0);
    this.setBlockDrawingSelectionBox(false);
    this.setDragType(undefined);
  }

  // setRegisteredClick(e: any) {
  //   this.registeredClick = !this.registeredClick;
  //   if (this.registeredClick === true) {
  //     this.setStartPosition(e.pageX, e.pageY);
  //   } else {
  //     this.setDimensionValues(0, 0);
  //   }
  // }

  setDimensions = (x: number, y: number) => {
    if (x && y) {
      const pos = this.startPosition;
      if (pos) {
        this.setDimensionValues(x - pos.x, y - pos.y);
      }
    }
  };

  setDimensionValues(width: number | undefined, height: number | undefined) {
    this.width = width;
    this.height = height;
  }

  setStartPosition(x: number, y: number) {
    this.startPosition = { x, y };
  }
}

export default DragSelectStore;
