import {
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  set,
} from "firebase/database";
import { DB } from "./Firestore";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { initializeApp } from "@firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { makeAutoObservable } from "mobx";

export interface ConnectedUserType {
  state: string;
  documentId: string;
  email: string;
  last_changed: string;
}
export default class OnlinePresence {
  documentId: string;
  connectedUsers: { [email: string]: ConnectedUserType };

  constructor(documentId: string) {
    this.documentId = documentId;
    this.connectedUsers = {};
    makeAutoObservable(this);
  }

  initializeOnlinePresence() {
    this.init();
    this.monitorUserPresence();
  }

  init() {
    const documentId = this.documentId;
    const auth = getAuth();
    // Fetch the current user's ID from Firebase Authentication.
    const uid = auth.currentUser?.uid;
    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const userStatusDatabaseRef = ref(db, `/status/` + uid);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    const timestamp = new Date().getTime();
    const isOfflineForDatabase = {
      state: "offline",
      documentId,
      email: auth.currentUser?.email,
      last_changed: timestamp,
    };

    const isOnlineForDatabase = {
      state: "online",
      documentId,
      email: auth.currentUser?.email,
      last_changed: timestamp,
    };

    const isOnlineForFirestore = {
      state: "online",
      documentId,
      email: auth.currentUser?.email,
      last_changed: timestamp,
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    const dbRef = ref(db, ".info/connected");

    const firestoreDB = getFirestore();
    const userStatusFirestoreRef = doc(firestoreDB, "/status/" + uid);

    onValue(dbRef, (snapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return;
      }
      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      const disconnection = onDisconnect(userStatusDatabaseRef);
      disconnection.set(isOfflineForDatabase).then(() => {
        set(userStatusDatabaseRef, isOnlineForDatabase);
        // The promise returned from .onDisconnect().set() will
        // resolve as soon as the server acknowledges the onDisconnect()
        // request, NOT once we've actually disconnected:
        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

        // We can now safely set ourselves as 'online' knowing that the
        // server will mark us as offline once we lose connection.
        setDoc(userStatusFirestoreRef, isOnlineForFirestore);
      });
    });
  }

  monitorUserPresence = () => {
    const cRef = collection(DB.db, "status");
    const w = where("state", "==", "online");
    const w2 = where("documentId", "==", this.documentId);
    const q = query(cRef, w, w2);

    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data: ConnectedUserType = change.doc.data() as ConnectedUserType;
        if (data.state === "online") {
          this.setConnectedUser(data);
        }

        if (data.state === "offline") {
          this.removeConnectedUser(data.email);
        }
      });
    });
  };

  setConnectedUser = (connectedUser: ConnectedUserType) => {
    this.connectedUsers[connectedUser.email] = connectedUser;
  };

  removeConnectedUser = (email: string) => {
    if (this.connectedUsers[email]) {
      delete this.connectedUsers[email];
    }
  };
}
