import React from "react";
import { List, ListStyleType } from "./List";
import { ListItem as ListItemComponent } from "./ListItem";
import { HighlighterStylesType } from "./Highlighter";
import { TypeAheadSelectedItem } from "./Typeahead";

export interface SearchResultsStyleType {
  list?: ListStyleType;
  listItem?: HighlighterStylesType;
}
interface SearchResultsType {
  id: string;
  results: { id: string }[];
  query: string;
  selectedIndex: number;
  onSelected: (selected: TypeAheadSelectedItem) => void;
  selectedOptionId?: string;
  ListItem?: any;
  style?: SearchResultsStyleType;
}

export const SearchResults: React.FC<SearchResultsType> = ({
  id,
  results,
  query,
  selectedIndex,
  selectedOptionId,
  onSelected,
  children,
  ListItem = ListItemComponent,
  style = {},
}) => {
  if (results && results.length === 0 && query === "") {
    return null;
  }
  if (results && results.length === 0 && query !== "") {
    return (
      <List key={`list-${id}`} id={id}>
        {children}
      </List>
    );
  }
  return (
    <List key={`list-${id}`} id={id} style={style.list}>
      {results &&
        results.map((result, i) => (
          <ListItem
            selectedOptionId={selectedOptionId}
            key={`result-${result.id}`}
            option={result}
            index={i}
            query={query}
            selectedIndex={selectedIndex}
            onSelected={onSelected}
            style={style.listItem}
          />
        ))}
    </List>
  );
};
