import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import { css } from "glamor";
import { Color, hint, hintRight, spacings } from "../styles/default";
import { useIntl } from "react-intl";

const UnsavedDocumentWarning: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  if (store.user.authorized) {
    return null;
  }
  return (
    <div
      {...css({
        position: "fixed",
        bottom: spacings.space2,
        left: spacings.space2,
      })}
    >
      <div
        aria-label={intl.formatMessage({
          id: "warning.unsaved.document.due.to.logged.out",
        })}
        {...css(hint, hintRight)}
      >
        <button
          onClick={() => {
            store.openCloudSavingWarningPopup(
              intl.formatMessage({
                id: "popup.warning.cloud.saving.title",
              })
            );
          }}
        >
          <span
            className="material-icons"
            {...css({
              color: Color.warning,
            })}
          >
            cloud_off
          </span>
        </button>
      </div>
    </div>
  );
};

export default observer(UnsavedDocumentWarning);
