import { makeAutoObservable } from "mobx";
import { DocumentTypeOptions } from "./textEditor/Document";

export interface FileMetadataType {
  documentId: string;
  title: string;
  updatedAt: number;
  createdAt: number;
  type: DocumentTypeOptions;
  starred: boolean;
  seriesId: string;
}

export class FileMetadata {
  documentId: string;
  title: string;
  updatedAt: number;
  createdAt: number;
  type: DocumentTypeOptions;
  starred: boolean;
  seriesId: string;

  constructor(fileMetatadata: FileMetadataType) {
    this.documentId = fileMetatadata.documentId;
    this.title = fileMetatadata.title;
    this.updatedAt = fileMetatadata.updatedAt;
    this.createdAt = fileMetatadata.createdAt;
    this.type = fileMetatadata.type;
    this.starred = fileMetatadata.starred;
    this.seriesId = fileMetatadata.seriesId;

    makeAutoObservable(this);
  }

  setDocumentTitle(value: string) {
    this.title = value;
  }

  setLastModified(value: number) {
    this.updatedAt = value;
  }

  setSeriesId(seriesId: string) {
    this.seriesId = seriesId;
  }
}
