import { makeAutoObservable } from "mobx";

export enum PopupOptions {
  character = "character",
  signUp = "signUp",
  signIn = "signIn",
  leaveDocumentWarning = "leaveDocumentWarning",
  confirmBeforeAction = "confirmBeforeAction",
  convertStandaloneDocumentToSeries = "convertStandaloneDocumentToSeries",
  cloudSavingWarning = "cloudSavingWarning",
  joinWaitlist = "joinWaitlist",
  genericFormUpdate = "genericFormUpdate",
  shareDocument = "shareDocument",
}

interface PopupType {
  data: any;
  id: PopupOptions;
  index: number;
  title?: string;
}

class Popup {
  data: any;
  id: PopupOptions;
  index: number;
  active: boolean;
  title?: string;

  constructor(props: PopupType) {
    const { data, id, index, title } = props;
    this.data = data || {};
    this.id = id;
    this.index = index || 0;
    this.active = true;
    this.title = title;
    makeAutoObservable(this);
  }

  setActive = () => {
    this.active = true;
  };

  setInactive = () => {
    this.active = false;
  };
}

export default Popup;
