import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";

export const CharacterExtensionTypes = [
  "voiceOver",
  "offscreen",
  "continued",
  "intoDevices",
  "onThePhone",
  "preLap",
] as const;
export type DialogueTypeOptions = "dialogue" | "insert" | "note" | "action";
export type CharacterExtensionType = typeof CharacterExtensionTypes[number];

export interface DialogueType {
  text?: string;
  characterId?: string;
  type: DialogueTypeOptions;
  characterName?: string;
  id?: string;
  characterExtension?: string | CharacterExtensionType;
  parentheticals?: string;
}

export class Dialogue {
  id: string;
  text?: string;
  characterId?: string;
  type: DialogueTypeOptions;
  editedCharacter?: string;
  focus?: boolean;
  characterExtension?: string | CharacterExtensionType;
  parentheticals?: string;

  constructor(dialogue: DialogueType) {
    this.id = dialogue.id || v4();
    this.text = dialogue.text;
    this.characterId = dialogue.characterId;
    this.type = dialogue.type;
    this.editedCharacter = dialogue.characterName;
    this.focus = false;
    this.characterExtension = dialogue.characterExtension;
    this.parentheticals = dialogue.parentheticals;
    makeAutoObservable(this);
  }

  addCharacterId(id: string) {
    this.characterId = id;
  }

  setCharacterId(characterId: string) {
    this.characterId = characterId;
  }

  setCharacterExtension(value?: CharacterExtensionType | string) {
    this.characterExtension = value;
  }

  setParentheticals(value: string) {
    this.parentheticals = value;
  }

  setEditedCharacter(value: string) {
    this.editedCharacter = value;
  }

  setText(text: string) {
    this.text = text;
  }

  setFocus(value: boolean) {
    this.focus = value;
  }
}
