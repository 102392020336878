import React, { useState } from "react";
import { observer } from "mobx-react";
import AutoSizedInput from "./AutoSizedInput";
import { SuggestionOptionType } from "../../store/Suggestion";
import {
  borderRadius,
  borders,
  Color,
  FontFamilies,
  FontSizes,
  InputStyleDialogue,
  spacings,
} from "../../styles/default";
import { css } from "glamor";
import { merge } from "lodash";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const InputWithSuggestion: React.FC<{
  defaultValue?: string;
  disabled?: boolean;
  id: string;
  onBlur?: () => void;
  onChange: (label: string, id?: string) => void;
  onFocus?: () => void;
  placeholder: string;
  styles?: CSSProperties;
  suggestions: SuggestionOptionType[];
  value: string | undefined;
}> = ({
  defaultValue,
  id,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  styles,
  suggestions,
  value,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [index, setIndex] = useState(-1);
  let filteredSuggestions = suggestions;

  if (value !== "" && value !== undefined) {
    filteredSuggestions = suggestions.filter(
      (suggestion) =>
        value &&
        suggestion?.label
          ?.toLocaleLowerCase()
          .includes(value?.toLocaleLowerCase())
    );
  }

  const handleKeyDown = (e: any) => {
    switch (e.code) {
      case "Enter": {
        e.preventDefault();
        e.stopPropagation();
        if (filteredSuggestions.length === 1) {
          // there's only one suggestion, let's set that one to be the one
          onChange(filteredSuggestions[0].label, filteredSuggestions[0].value);
          setShowOptions(false);
        } else if (index >= 0) {
          if (filteredSuggestions.length > index) {
            onChange(
              filteredSuggestions[index].label,
              filteredSuggestions[index].value
            );
            setShowOptions(false);
          }
        }
        return 0;
      }
      case "ArrowDown": {
        if (index < filteredSuggestions.length) {
          setIndex(index + 1);
        }

        return 0;
      }
      case "ArrowUp": {
        if (index > 0) {
          setIndex(index - 1);
        }
        return 0;
      }
      default:
        return 0;
    }
  };

  return (
    <div>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onFocus={(e) => {
          setShowOptions(true);
          setIndex(-1);
          onFocus && onFocus();
        }}
        styles={styles}
        onBlur={(e) => {
          setTimeout(() => {
            setShowOptions(false);
            onBlur && onBlur();
          }, 300);
        }}
        id={id}
        defaultValue={defaultValue}
        onKeyDown={handleKeyDown}
      />
      {showOptions && filteredSuggestions.length > 0 && (
        <div
          {...css({
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            zIndex: 2,
            backgroundColor: Color.background,
            padding: `${spacings.space2} ${spacings.space5}`,
            border: borders.faded,
            borderRadius: borderRadius.borderRadius3,
          })}
        >
          {filteredSuggestions.map((option, i) => {
            return (
              <div
                key={option.value}
                {...css({
                  marginTop: spacings.space1,
                  marginBottom: spacings.space1,
                  cursor: "pointer",
                  fontFamily: FontFamilies.dialogue,
                  ":hover": {
                    fontWeight: 700,
                  },
                  fontWeight: index === i ? 700 : 300,
                })}
                onClick={(e) => {
                  onChange(option.label, option.value);
                  setShowOptions(false);
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default observer(InputWithSuggestion);

const Input: React.FC<{
  defaultValue?: string;
  id: string;
  onBlur?: (e: any) => void;
  onChange: (e: any) => void;
  onFocus?: (e: any) => void;
  onKeyDown: (e: any) => void;
  placeholder: string;
  value: string | undefined;
  styles?: CSSProperties;
}> = ({
  defaultValue,
  id,
  onBlur = () => {},
  onChange,
  onFocus = () => {},
  onKeyDown,
  placeholder,
  value,
  styles = {},
}) => {
  const styleHeadingInput = {
    ...InputStyleDialogue,
    fontSize: FontSizes.fontSize7,
    fontWeight: 700,
  };
  const style = merge({}, styleHeadingInput, styles);
  return (
    <AutoSizedInput
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder.trim()}
      style={style}
      onFocus={onFocus}
      onBlur={onBlur}
      defaultValue={defaultValue}
      onKeyDown={onKeyDown}
    />
  );
};
