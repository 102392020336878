import React from "react";
import { observer } from "mobx-react";
import { Character } from "../../store/textEditor/Character";
import { css } from "glamor";
import Avatar from "react-avatar";
import {
  setAlpha,
  spacings,
  Color,
  borderRadius,
  Bold,
  Text,
  hintTop,
  hint,
  FontFamilies,
  FontSizes,
} from "../../styles/default";
import { MenuActionsTrigger } from "../common/MenuActionsTrigger";
import { MenuOptions } from "../../store/ActionMenu";
import { useIntl } from "react-intl";

const ContainerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gridGap: spacings.space2,
  padding: spacings.space2,
  borderRadius: borderRadius.borderRadius1,

  ":hover": {
    backgroundColor: setAlpha(Color.onBackground, 0.1),
    fontWeight: Bold,
    cursor: "pointer",
  },
};
export const CharacterCard: React.FC<{
  actionOptions: MenuOptions[];
  character: Character;
  disableEdits: boolean;
  isDefault: boolean;
  onClick: () => void;
  selected: boolean;
}> = observer(
  ({
    disableEdits,
    character,
    onClick,
    actionOptions,
    isDefault,
    selected,
  }) => {
    const intl = useIntl();
    return (
      <div
        role="button"
        {...css(ContainerStyles, {
          backgroundColor: selected
            ? setAlpha(Color.onBackground, 0.1)
            : "transparent",
        })}
        onClick={() => {
          onClick();
        }}
      >
        <div
          {...css({
            display: "flex",
            gridGap: spacings.space2,
            alignItems: "center",
          })}
        >
          <Avatar
            name={character.name}
            size="30"
            color={character.color}
            round={true}
          />
          <span
            {...css(Text.body, {
              display: "flex",
              alignItems: "center",
              gridGap: spacings.space2,
              fontFamily: FontFamilies.dialogue,
              fontSize: FontSizes.fontSize4,
              fontWeight: 700,
            })}
          >
            {character.name}
            {isDefault && (
              <span
                aria-label={intl.formatMessage({
                  id: "popup.character.default.label",
                })}
                {...css(hint, hintTop)}
              >
                <span {...css({ opacity: 0.2 })} className="material-icons">
                  manage_accounts
                </span>
              </span>
            )}
          </span>
        </div>
        {!disableEdits && <MenuActionsTrigger options={actionOptions} />}
      </div>
    );
  }
);
