import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { css } from "glamor";
import { merge } from "lodash";
import React from "react";
import { SearchInputStyle } from "../../../styles/default";

export interface SearchInputStyleType {
  input?: CSSProperties;
}
export interface SearchInputOption {
  value: string;
  label: string;
}

export interface SearchInputType {
  id: string;
  onChange: () => void;
  label?: string;
  onKeyDown: () => void;
  onFocus: () => void;
  onBlur: () => void;
  disabled?: boolean;
  ariaDescribedby: string;
  value: string;
  ariaOwns: string;
  style?: SearchInputStyleType;
  placeholder?: string;
  name: string;
  requirementType: "required" | "optional" | "required-star" | undefined;
}

export const SearchInput: React.FC<SearchInputType> = ({
  id,
  ariaDescribedby,
  ariaOwns,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  placeholder = "Search projects",
  disabled,
  name,
  label,
  value,
  style,
  requirementType,
}) => {
  const styles = merge({}, SearchInputStyle, style?.input || {});
  return (
    <input
      /* An empty div to account for the
       * one added by the type ahead wrapper.
       */
      type="search"
      aria-invalid="false"
      disabled={disabled}
      id={id}
      spellCheck="false"
      autoComplete="off"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      aria-describedby={ariaDescribedby}
      aria-owns={ariaOwns}
      aria-autocomplete="both"
      aria-activedescendant=""
      {...css({
        ...styles,
        width: "100%",
      })}
    />
  );
};
