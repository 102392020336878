import React from "react";
import { observer } from "mobx-react";
import AppStore from "../store/AppStore";
import Tabs from "./common/Tabs";
import { FontSizes, spacings } from "../styles/default";
import { css } from "glamor";
import { scrollToElementTop, scrollToElement } from "./common/utils/DomUtils";
import { ViewOptionsType } from "../store/View";
import { queries } from "../styles/mediaQueries";
import { useIntl } from "react-intl";
import { logEvent } from "@firebase/analytics";
import { user } from "../store/User";

export const CONTENT_ANCHOR_ID_GET_STARTED = "getStarted";
export const CONTENT_ANCHOR_ID_PRODUCTION_ONE = "productionOne";
export const CONTENT_ANCHOR_ID_PRICING = "pricing";

const HomeLinks: React.FC<{
  store: AppStore;
}> = ({ store }) => {
  const intl = useIntl();
  const [selectedId, setSelectedId] = React.useState<string | undefined>(
    undefined
  );
  return (
    <div
      {...css({
        marginTop: spacings.space2,
        [queries.phone]: {
          display: "none",
        },
      })}
    >
      <Tabs
        items={[
          {
            id: CONTENT_ANCHOR_ID_GET_STARTED,
            label: intl.formatMessage({ id: "tab.get.start.label" }),
            action: () => {
              logEvent(user.analytics, "btn_click_getStarted");
              if (store.view.id !== ViewOptionsType.home) {
                store.openHome();
                setTimeout(() => {
                  setSelectedId(CONTENT_ANCHOR_ID_GET_STARTED);
                  scrollToElement(CONTENT_ANCHOR_ID_GET_STARTED);
                }, 300);
              } else {
                setSelectedId(CONTENT_ANCHOR_ID_GET_STARTED);
                scrollToElement(CONTENT_ANCHOR_ID_GET_STARTED);
              }
            },
          },
          {
            id: CONTENT_ANCHOR_ID_PRODUCTION_ONE,
            label: "Production One",
            action: () => {
              logEvent(user.analytics, "btn_click_productionOne");
              if (store.view.id !== ViewOptionsType.home) {
                store.openHome();
                setTimeout(() => {
                  setSelectedId(CONTENT_ANCHOR_ID_PRODUCTION_ONE);
                  scrollToElement(CONTENT_ANCHOR_ID_PRODUCTION_ONE);
                }, 300);
              } else {
                setSelectedId(CONTENT_ANCHOR_ID_PRODUCTION_ONE);
                scrollToElement(CONTENT_ANCHOR_ID_PRODUCTION_ONE);
              }
            },
          },
          {
            id: CONTENT_ANCHOR_ID_PRICING,
            label: intl.formatMessage({ id: "tab.pricing.label" }),
            action: () => {
              logEvent(user.analytics, "btn_click_pricing");
              if (store.view.id !== ViewOptionsType.home) {
                store.openHome();
                setTimeout(() => {}, 300);
              } else {
                setSelectedId(CONTENT_ANCHOR_ID_PRICING);
                scrollToElementTop(CONTENT_ANCHOR_ID_PRICING);
              }
            },
          },
        ]}
        selectedId={selectedId}
        style={{
          tabItem: {
            container: {
              borderBottom: 0,
              fontSize: FontSizes.fontSize3,
            },
            selected: {
              borderBottom: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default observer(HomeLinks);
