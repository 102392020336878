import { logEvent } from "@firebase/analytics";
import { css } from "glamor";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Apis from "../../store/Api";
import AppStore, { FormOptions } from "../../store/AppStore";
import { feedback } from "../../store/Feedback";
import Popup from "../../store/Popup";
import Users from "../../store/Users";
import { defaultStyles, spacings } from "../../styles/default";
import { queries } from "../../styles/mediaQueries";
import { Input } from "../common/Input";
// import { Input } from "../common/Input";
import { Popup as PopupComponent } from "../common/Popup";
// import SubscriptionPlansTable from "../SubscriptionPlansTable";

interface PopupsType {
  store: AppStore;
  popup: Popup;
}

const PopupSignUp: React.FC<PopupsType> = ({ store, popup }) => {
  const [email, setEmail] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const intl = useIntl();

  const form = store.forms.getForm(FormOptions.signUp);
  const fields = form.fields;
  const user = store.user;

  if (!form || !fields) {
    return null;
  }
  return (
    <PopupComponent
      id="signup-popup"
      title={popup.title}
      width="700px"
      cancel={{
        action: () => store.popups.clear(),
        label: intl.formatMessage({ id: "btn.cancel" }),
      }}
      confirm={{
        action: () => {
          const formData = form.getData;
          user.signupWithEmailAndPassword(formData.username, formData.password);
          const disposer = autorun(() => {
            if (user.authenticated) {
              if (user.authorized) {
                // close popup
                store.popups.clear();
              } else {
                // display error
              }
              disposer();
            }
          });
        },
        enabled: form.isValid,
        label: intl.formatMessage({ id: "label.sign.in" }),
      }}
    >
      <div
        {...css({
          display: "flex",
          justifyContent: "center",
          padding: spacings.space5,
          flexDirection: "column",
          gridGap: spacings.space2,
        })}
      >
        <div
          {...css({
            display: "flex",
            alignItems: "center",
            gridGap: spacings.space2,
            flexDirection: "column",
          })}
        >
          <h1>
            <FormattedMessage id="oops.label" />
          </h1>
          <h2>
            <FormattedMessage id="beta.no.sign.ups.allowed" />
          </h2>

          {submitted && <FormattedMessage id="join.wait.list.joined" />}

          {!submitted && (
            <>
              <FormattedMessage id="join.wait.list" />
              <div
                {...css({
                  alignItems: "center",
                  display: "flex",
                  [queries.phone]: {
                    flexDirection: "column",
                  },
                })}
              >
                <Input
                  placeholder={intl.formatMessage({ id: "enter.email" })}
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
                <div
                  {...css({
                    marginLeft: spacings.space4,
                    [queries.phone]: {},
                  })}
                >
                  <button
                    {...css(defaultStyles.button.default, {
                      paddingTop: spacings.space2,
                      paddingBottom: spacings.space2,
                      marginLeft: spacings.space1,
                    })}
                    onClick={async () => {
                      logEvent(user.analytics, `btn_click_join_waitlist`);
                      const isValid = Users.validateEmail(email);
                      if (isValid) {
                        await Apis.joinAlphaWaitList(email);
                        feedback.setFeedback({
                          message: intl.formatMessage({
                            id: "wait.list.joined.success",
                          }),
                          variant: "success",
                        });

                        setEmail("");
                        setSubmitted(true);
                      } else {
                        feedback.setFeedback({
                          message: intl.formatMessage({
                            id: "wait.list.joined.failed",
                          }),
                          variant: "error",
                        });
                      }
                    }}
                  >
                    <FormattedMessage id="join" />
                  </button>
                </div>
              </div>
            </>
          )}

          {/* <Input
            label="Email"
            value={fields.username.value}
            onChange={(e: any) => fields.username.set(e.target.value)}
            type="text"
            required
          />
          <div {...css({ display: "flex", flexDirection: "column" })}>
            <Input
              label="Password"
              value={fields.password.value}
              onChange={(e: any) => fields.password.set(e.target.value)}
              type="password"
              required
            />
            <Input
              label="Confirm Password"
              value={fields.passwordConfirmation.value}
              onChange={(e: any) =>
                fields.passwordConfirmation.set(e.target.value)
              }
              type="password"
              required
            />
          </div> */}
          <div>{user.authenticated && user.errorCode}</div>
        </div>
      </div>
    </PopupComponent>
  );
};

export default observer(PopupSignUp);
